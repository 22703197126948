import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type Panel,
  SidePanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isLocationSelectionParameter,
  isPlainTextContentParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildLocationHierarchyPanel,
  buildNoDataAvailableLocationHierarchyPanel,
  buildPlainTextContentPanel,
  buildSegmentationPanel,
  buildSingleSelectPanel,
  buildTimePeriodPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { type RootState } from "../../store";
import {
  onChannelChange,
  onCompStoreChange,
  onLocationChange,
  onMetricSetChange,
  onSegmentationChange,
  selectLocalSelections,
} from "../services/gains-and-loss-slice";

export const GainsAndLossSidePanel = ({
  eventTrackingService,
}: {
  eventTrackingService: Function;
}) => {
  // Store
  const dispatch = useDispatch();

  const localSelections = useSelector(selectLocalSelections);
  const { localParameters } = useSelector((state: RootState) => ({
    localParameters: state.gainsAndLoss.localParameters,
  }));

  const generatedPanels = useCallback((): Panel[] => {
    const panels: Panel[] = [];
    for (const localParameter of localParameters) {
      if (
        localParameter.id === LocalParameters.Channel &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onChannelSelection = (value: PanelOption) => {
          dispatch(onChannelChange(value));
        };

        panels.push(
          buildSingleSelectPanel(
            localParameter,
            onChannelSelection,
            localSelections.channel.value.toString()
          )
        );
      } else if (
        localParameter.id === LocalParameters.LocationHierarchy &&
        isLocationSelectionParameter(localParameter)
      ) {
        if (localParameter.selections.length === 0) {
          panels.push(
            buildNoDataAvailableLocationHierarchyPanel(localParameter.id)
          );
        } else {
          const onLocationSelection = (
            selection: LocalHierarchyNodeSelection
          ) => {
            dispatch(onLocationChange(selection));
          };

          panels.push(
            buildLocationHierarchyPanel(
              localParameter,
              onLocationSelection,
              localSelections.location
            )
          );
        }
      } else if (
        localParameter.id === LocalParameters.CompStore &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onCompStoreSelection = (value: PanelOption) => {
          dispatch(onCompStoreChange(value));
        };

        panels.push(
          buildSingleSelectPanel(
            localParameter,
            onCompStoreSelection,
            localSelections.compStore.value.toString()
          )
        );
      } else if (
        localParameter.id === LocalParameters.MetricSet &&
        isSingleSelectionParameter(localParameter)
      ) {
        const onMetricSetSelection = (value: PanelOption) => {
          dispatch(onMetricSetChange(value));
        };

        panels.push(
          buildSingleSelectPanel(
            localParameter,
            onMetricSetSelection,
            localSelections.metricSet.value.toString()
          )
        );
      } else if (localParameter.id === LocalParameters.Segmentation) {
        if (isSegmentationParameter(localParameter)) {
          const onSegmentSelection = (value: SegmentOption) => {
            dispatch(onSegmentationChange(value));
          };

          panels.push(
            buildSegmentationPanel(localParameter, onSegmentSelection, [
              localSelections.segmentation.value as string,
              localSelections.segment.value as string,
            ])
          );
        } else if (isPlainTextContentParameter(localParameter)) {
          panels.push(buildPlainTextContentPanel(localParameter));
        }
      } else if (isTimePeriodParameter(localParameter)) {
        panels.push(buildTimePeriodPanel(localParameter));
      }
    }

    return panels;
  }, [dispatch, localParameters, localSelections]);

  return (
    <SidePanel
      eventTrackingService={eventTrackingService}
      panels={generatedPanels()}
    />
  );
};
