import {
  FormBlock,
  FormBlockEditability,
  FormBlockType,
  Input,
  RadioButton,
  Select,
  SelectOption,
  Spinner,
  TypeaheadMulti,
} from "@qbit/react";
import { type Option } from "@qbit/react/dist/typeahead";
import {
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  UnknownTrackingPropertyValue,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback } from "react";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import {
  LocationOptionSchema,
  type LocationOption,
} from "../../fast-report/api/globalParameterConfiguration";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import styles from "./LocationFilterContent.module.css";

const serializeOption = (option: LocationOption) =>
  `${option?.level}:${option?.code}`;

const deserializeOption = (value: string) => {
  if (value === ":") {
    return null;
  }

  const sections = value.split(":");

  return { level: sections[0], code: sections[1] } as LocationOption;
};

export const LocationFilterContent = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.location,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.Location,
    LocationOptionSchema,
    (config) => config.globalParameters.location
  );

  const locationDefaultSelectionHandler = useCallback(() => {
    if (reportConfig.configuration) {
      const locations = reportConfig.configuration.defaultOption;

      // location levels for default option
      const selectedLevels = locations.map((location) => location?.level);

      for (const level of selectedLevels) {
        const parameter = reportConfig.configuration.levels.find((option) =>
          level ? option.value === level : option.value === null
        );

        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingGlobal,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.LocationLevelSelected,
            parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
          )
        );
      }

      // location codes for default option
      const selectedCodes = locations.map((location) => location?.code);

      for (const code of selectedCodes) {
        const parameter = reportConfig.configuration.options.find(
          (option) => option.value?.code === code
        );

        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingGlobal,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.LocationSelected,
            parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
          )
        );
      }

      setSelection(locations);
    }
  }, [reportConfig.configuration, setSelection, eventTrackingService]);

  const locationSelectionHandler = useCallback(
    (locations: Array<LocationOption | null>) => {
      if (
        selection &&
        locations.length > selection.length &&
        reportConfig.configuration
      ) {
        // Send events for new unique location codes
        const newCodes = locations.map((location) => location?.code);
        const existingCodes = selection.map((location) => location?.code);

        // Unique selected locations codes
        const selectedCodes = newCodes.filter(
          (code) => !existingCodes.includes(code)
        );
        for (const code of selectedCodes) {
          const parameter = reportConfig.configuration.options.find(
            (option) => option.value?.code === code
          );

          eventTrackingService.trackEvent(
            TrackingComponent.FastReportingGlobal,
            TrackingEvent.Parameters,
            GenericTrackingProperties.single(
              ParametersTrackingProperty.LocationSelected,
              parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
            )
          );
        }
      }

      setSelection(locations);
    },
    [selection, reportConfig.configuration, eventTrackingService, setSelection]
  );

  const selectedLevel = selection ? selection[0]?.level ?? null : null;

  const handleLevelChanged = (levelValue: string | null) => {
    const newSelection = reportConfig.configuration?.options.find(
      (opt) => opt.value?.level === levelValue
    );

    if (reportConfig.configuration) {
      const locationLevelParameter = reportConfig.configuration.levels.find(
        (option) =>
          newSelection?.value === null
            ? option.value === null
            : option.value === newSelection?.value?.level
      );

      eventTrackingService.trackEvent(
        TrackingComponent.FastReportingGlobal,
        TrackingEvent.Parameters,
        GenericTrackingProperties.single(
          ParametersTrackingProperty.LocationLevelSelected,
          locationLevelParameter?.displayName ??
            UnknownTrackingPropertyValue.Unknown
        )
      );

      // If location is not total we send event for default selected location code
      if (newSelection?.value !== null) {
        const locationCodeParameter = reportConfig.configuration.options.find(
          (option) => option.value?.code === newSelection?.value?.code
        );

        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingGlobal,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.LocationSelected,
            locationCodeParameter?.displayName ??
              UnknownTrackingPropertyValue.Unknown
          )
        );
      }
    }

    setSelection([newSelection?.value ?? null]);
  };

  const levelSelectionElement = reportConfig.configuration ? (
    <div data-cy="LevelSelector">
      {reportConfig.configuration.levels.map((level) => (
        <FormBlock blockType={FormBlockType.Radio} key={level.value ?? ""}>
          <Input>
            <RadioButton
              checked={level.value === selectedLevel}
              className={styles.radioButton}
              data-key={level.value ?? "null"}
              data-selected={level.value === selectedLevel}
              editability={
                reportConfig.configuration?.isReadOnly
                  ? FormBlockEditability.Disabled
                  : FormBlockEditability.Editable
              }
              id={level.value ?? "null"}
              label={level.displayName}
              name="LocationLevelSelect"
              onChange={() => handleLevelChanged(level.value)}
            />
          </Input>
        </FormBlock>
      ))}
    </div>
  ) : (
    <Spinner />
  );

  const handleLocationChanged = (newSelection: Option[]) => {
    if (newSelection.length === 0) {
      locationDefaultSelectionHandler();
      return;
    }

    locationSelectionHandler(
      newSelection.map((value) => deserializeOption(value.value as string))
    );
  };

  const availableOptions = reportConfig.configuration?.options
    .filter((opt) => (opt.value?.level ?? null) === selectedLevel)
    .map((opt) => ({
      label: opt.displayName,
      value: serializeOption(opt.value),
    }));

  const selectedItemValues = selection?.map((item) => serializeOption(item));
  const selectedItems = availableOptions?.filter((opt) =>
    selectedItemValues?.includes(opt.value)
  );

  const selectElement =
    reportConfig.configuration && availableOptions ? (
      <FormBlock data-cy="ItemSelector">
        <span className={styles.fieldLabel}>Selection</span>
        <Input>
          {availableOptions.length <= 1 ? (
            <Select
              disabled
              id="disabledSelect"
              value={availableOptions[0]?.value}
            >
              {availableOptions.map((opt) => (
                <SelectOption
                  key={opt.value}
                  text={opt.label}
                  value={opt.value}
                />
              ))}
            </Select>
          ) : (
            <TypeaheadMulti
              disabled={reportConfig.configuration.isReadOnly === true}
              id="LocationSelect"
              onChange={handleLocationChanged}
              options={[
                {
                  options: availableOptions,
                },
              ]}
              selectedItems={selectedItems}
            />
          )}
        </Input>
      </FormBlock>
    ) : (
      <Spinner />
    );

  return (
    <div data-cy="LocationGlobalParameter">
      {levelSelectionElement}
      {selectElement}
    </div>
  );
};
