import {
  Spinner,
  SpinnerSize,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import {
  type HierarchyRequestDto,
  type HierarchyType,
  HierarchyItemType,
  useGetAncestorsQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useMemo, useState, useCallback, type PropsWithChildren } from "react";
import { HierarchyLevelIcon } from "../hierarchy-level-icon/HierarchyLevelIcon";
import styles from "./ParentTooltip.module.css";

export type ParentTooltipProps = {
  code?: string;
  shortName: string;
  type: HierarchyType | HierarchyType.Product;
};

export const ParentTooltip = ({
  children,
  code = "",
  shortName,
  type,
}: PropsWithChildren<ParentTooltipProps>) => {
  const { name: divisionName } = useDivision();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const request: HierarchyRequestDto = useMemo(
    () => ({
      filters: [{ shortName, codes: [code] }],
      page: 0,
    }),
    [shortName, code]
  );

  const {
    data: parents,
    isLoading,
    isError,
    isFetching,
  } = useGetAncestorsQuery(
    {
      division: divisionName,
      hierarchyType: type,
      payload: request,
    },
    { skip: !divisionName || !isHovered }
  );

  const tooltipContent = useMemo(() => {
    if (isLoading || isFetching) {
      return (
        <span className={styles.loading}>
          <Spinner size={SpinnerSize.XSmall} />
        </span>
      );
    }

    if (isError || !parents?.results.length) {
      return "";
    }

    return parents.results.map((parent) => (
      <div className={styles.parent} key={parent.code}>
        <HierarchyLevelIcon
          shortName={parent.shortName}
          type={HierarchyItemType.Hierarchy}
        />
        <span className={styles.parentName}>{parent.name}</span>
      </div>
    ));
  }, [parents, isLoading, isFetching, isError]);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const triggerElement = useMemo(
    () => (
      <div onMouseEnter={handleMouseEnter} role="button" tabIndex={0}>
        {children}
      </div>
    ),
    [children, handleMouseEnter]
  );

  return (
    <>
      {tooltipContent ? (
        <Tooltip
          placement={TooltipPlacement.RightCentre}
          spaceInside={TooltipSpaceInside.Small}
          trigger={triggerElement}
          variant={TooltipVariant.ArrowDark}
        >
          {tooltipContent}
        </Tooltip>
      ) : (
        triggerElement
      )}
    </>
  );
};
