import {
  type DivisionDto,
  FeatureFlag,
  type FocalItem,
  formatShortDate,
  HierarchyItemType,
  HierarchyShortName,
  HierarchyType,
  TransactionSource,
  useGetItemsQuery,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { useMemo, useCallback } from "react";
import { useReportTabState } from "report-tabs-ui";
import { FAST_REPORTING_FEATURE_NAME } from "../FastReporting";
import { useReportConfigurationQuery } from "../fast-report/api/fastReportConfigurationApi";
import { FocusPeriodOptionWithCustomFlagSchema } from "../fast-report/api/globalParameterConfiguration";
import { type SelectParameterConfiguration } from "../fast-report/api/parameterConfiguration";
import { type ReportingConfigurationDto } from "../fast-report/api/reportConfigurationDto";
import { areParameterValuesEquivalent } from "../parameterUtilities";
import { useActiveItem } from "../useActiveItem";
import { Parameter } from "../useFastReportingParameterState";
import { useGlobalParameters } from "../useGlobalParameters";
import { CUSTOMER_PROFILING_REPORT_FEATURE_NAME } from "./customer-profiling/CustomerProfilingReportPanel";
import { KEY_DRIVER_TREE_REPORT_FEATURE_NAME } from "./key-driver-tree/KeyDriverTreeReportPanel";
import { PERFORMANCE_REPORT_FEATURE_NAME } from "./performance/PerformanceReportPanel";
import { RANGE_REPORT_FEATURE_NAME } from "./range/RangeReportPanel";
import { STORE_BY_STORE_REPORT_FEATURE_NAME } from "./store-by-store/StoreByStorePanel";
import { TOP_AND_BOTTOM_REPORT_FEATURE_NAME } from "./top-and-bottom/TopAndBottomReportPanel";

export const useFilterCustomerMetrics = () => {
  const [globalParameters] = useGlobalParameters();

  const transactionSourceFilterEnabled = globalParameters.transactionSource;
  const customerTransactionSourceSelected =
    globalParameters.transactionSource === TransactionSource.Customer;

  return (
    Boolean(transactionSourceFilterEnabled) &&
    !customerTransactionSourceSelected
  );
};

export const useDisplayEntitlements = () => {
  const [globalParameters] = useGlobalParameters();
  const { data: user } = useGetUserQuery();
  const displayEntitlements = useMemo(
    () => globalParameters.transactionSource ?? user?.isSupplier,
    [globalParameters, user]
  );

  return displayEntitlements;
};

/* eslint-disable @typescript-eslint/no-explicit-any -- internally uses a method for comparison that can accept any type */
const useGetParameters = () => {
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery({ division: division.name });

  return useCallback(
    (
      configSelector: (
        config: ReportingConfigurationDto
      ) => SelectParameterConfiguration,
      value: any
    ) => {
      if (!reportConfig.currentData) {
        return undefined;
      }

      const config = configSelector(reportConfig.currentData);
      if (!config) {
        return undefined;
      }

      const valueAsArray = Array.isArray(value) ? value : [value];
      return valueAsArray.map((value_) =>
        config.options.find((opt) =>
          areParameterValuesEquivalent(opt.value, value_)
        )
      );
    },
    [reportConfig]
  );
};

export const useGetParameterDisplayNames = () => {
  const getParameters = useGetParameters();
  return useCallback(
    (
      configSelector: (
        config: ReportingConfigurationDto
      ) => SelectParameterConfiguration,
      value: any
    ) =>
      getParameters(configSelector, value)?.map(
        (parameter) => parameter?.displayName
      ),
    [getParameters]
  );
};

export const useGetParameterValues = () => {
  const getParameters = useGetParameters();
  return useCallback(
    (
      configSelector: (
        config: ReportingConfigurationDto
      ) => SelectParameterConfiguration,
      value: any
    ) =>
      getParameters(configSelector, value)?.map(
        (parameter) => parameter?.value
      ),
    [getParameters]
  );
};

export const useParameterValuesForCSVExport = (): [
  string[],
  (
    configSelector: (
      config: ReportingConfigurationDto
    ) => SelectParameterConfiguration,
    value: any
  ) => string | undefined
] => {
  const [globalParameters] = useGlobalParameters();

  const getParameterDisplayNames = useGetParameterDisplayNames();
  const getParameterText = useCallback(
    (
      configSelector: (
        config: ReportingConfigurationDto
      ) => SelectParameterConfiguration,
      value: any
    ) => {
      const displayNames = getParameterDisplayNames(configSelector, value);
      return displayNames?.join(", ");
    },
    [getParameterDisplayNames]
  );

  // If focus period is custom, override the display name as it doesn't have one from report-config
  const [focusPeriod] = useReportTabState(
    Parameter.FocusPeriod,
    FocusPeriodOptionWithCustomFlagSchema
  );
  const focusPeriodDisplayName = useMemo(() => {
    if (!focusPeriod) {
      return undefined;
    }

    return focusPeriod.isCustom
      ? `Custom (${formatShortDate(focusPeriod.startDate)} - ${formatShortDate(
          focusPeriod.endDate
        )})`
      : getParameterText(
          (config) => config.globalParameters.focusPeriod,
          globalParameters.focusPeriod
        );
  }, [focusPeriod, getParameterText, globalParameters.focusPeriod]);

  const globalParameterFields = useMemo(() => {
    const globalParameterValues: Record<string, string | undefined> = {
      "Data type": getParameterText(
        (config) => config.globalParameters.transactionSource,
        globalParameters.transactionSource
      ),
      "Focus period": focusPeriodDisplayName,
      Location: getParameterText(
        (config) => config.globalParameters.location,
        globalParameters.location
      ),
      Channel: getParameterText(
        (config) => config.globalParameters.channel,
        globalParameters.channel
      ),
      Promotion: getParameterText(
        (config) => config.globalParameters.promotion,
        globalParameters.promotion
      ),
      Banner: getParameterText(
        (config) => config.globalParameters.banner,
        globalParameters.banner
      ),
      CompStore: getParameterText(
        (config) => config.globalParameters.compStore,
        globalParameters.compStore
      ),
    };

    return Object.keys(globalParameterValues)
      .filter((key) => globalParameterValues[key])
      .map((key) => `${key}: ${globalParameterValues[key]}`);
  }, [globalParameters, getParameterText, focusPeriodDisplayName]);

  return [globalParameterFields as string[], getParameterText];
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const useFilenameForExport = (reportName: string) => {
  const activeItem = useActiveItem();
  const [globalParameters] = useGlobalParameters();
  const [, getParameterCsvValue] = useParameterValuesForCSVExport();

  const getFilenameForExport = useMemo(() => {
    const locationValues = getParameterCsvValue(
      (config) => config.globalParameters.location,
      globalParameters.location
    );

    const focusPeriodValue = getParameterCsvValue(
      (config) => config.globalParameters.focusPeriod,
      globalParameters.focusPeriod
    )?.replaceAll(" ", "");

    return cleanFilename(
      `${activeItem?.displayName}_${reportName}_${focusPeriodValue}_${locationValues}`
    );
  }, [getParameterCsvValue, globalParameters, activeItem, reportName]);

  return getFilenameForExport;
};

export const useGetFeaturesForFocalItem = (
  division: DivisionDto,
  activeItem: FocalItem | undefined
): string[] | undefined => {
  const getCodeAndShortName = (): Array<string | undefined> => {
    switch (activeItem?.type) {
      case HierarchyItemType.Hierarchy:
      case HierarchyItemType.Leaf:
        return [activeItem.shortName, activeItem.code];
      case HierarchyItemType.Attribute:
        return [
          activeItem.additionalHierarchyFilter?.shortName,
          activeItem.additionalHierarchyFilter?.code,
        ];
      default:
        break;
    }

    return [undefined, undefined];
  };

  const [shortName, code] = getCodeAndShortName();

  const flags = useFlags();

  const { data: itemsData } = useGetItemsQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      payload: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Skipped if undefined
        items: [{ code: code!, shortName: shortName! }],
        page: 0,
        pageSize: 1,
        featureModules: [FAST_REPORTING_FEATURE_NAME],
        featureFilter: { module: FAST_REPORTING_FEATURE_NAME },
      },
    },
    {
      skip: !shortName && !code,
    }
  );

  // Call product groups service to get features once https://quantium.atlassian.net/browse/CO3-8018 is done
  // Return all features to prevent blocking product groups for now
  if (
    !flags[FeatureFlag.ScanFeatureEntitlements] ||
    activeItem?.type === HierarchyShortName.ProductGroup
  ) {
    // Return all features if flag is disabled
    return [
      PERFORMANCE_REPORT_FEATURE_NAME,
      KEY_DRIVER_TREE_REPORT_FEATURE_NAME,
      TOP_AND_BOTTOM_REPORT_FEATURE_NAME,
      CUSTOMER_PROFILING_REPORT_FEATURE_NAME,
      STORE_BY_STORE_REPORT_FEATURE_NAME,
      RANGE_REPORT_FEATURE_NAME,
    ];
  }

  return itemsData?.results[0].features?.[FAST_REPORTING_FEATURE_NAME];
};
