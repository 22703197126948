import { Select, SelectOption } from "@qbit/react";
import { useMemo } from "react";
import { type SingleSelectParameterConfiguration } from "./api/parameterConfiguration";

type DisabledParameterSingleSelectProps<TOptionType> = {
  configuration?: SingleSelectParameterConfiguration<TOptionType>;
  selection?: TOptionType;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const DisabledParameterSingleSelect = <TOptionType extends unknown>({
  configuration,
  selection,
}: DisabledParameterSingleSelectProps<TOptionType>) => {
  const selectionOptionValue = useMemo(
    () =>
      (
        configuration?.options.find((opt) => opt.value === selection)
          ?.displayName ?? ""
      ).toString(),
    [selection, configuration?.options]
  );

  return (
    <Select
      ariaDescribedby="disabled-select-parameter-single-select"
      data-testid="disabled-select-parameter-single-select"
      disabled
      id="disabled-select-parameter-single-select"
    >
      <SelectOption text={selectionOptionValue} value={selectionOptionValue} />
    </Select>
  );
};

export default DisabledParameterSingleSelect;
