import {
  mapFolderDto,
  useMoveGroupFolderMutation,
  useMoveGroupMutation,
  type HierarchyType,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { MoveItemModal } from "components-ui/src/modals";
import {
  type Folder,
  type MoveItemModalSubmitProps,
} from "components-ui/src/modals/move-item-modal/MoveItemModal";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFoldersRaw,
  selectMoveFolderOrGroup,
  setMoveFolderOrGroup,
} from "../../states/group-list-slice";

export type MoveHierarchyGroupModalProps = {
  hierarchyType: HierarchyType;
};

export const MoveHierarchyGroupModal = ({
  hierarchyType,
}: MoveHierarchyGroupModalProps) => {
  const dispatch = useDispatch();
  const division = useDivision();
  const divisionName = division.name;
  const selectedMoveGroupOrFolder = useSelector(selectMoveFolderOrGroup);
  const folder = useSelector(selectFoldersRaw);

  const [moveGroup] = useMoveGroupMutation();
  const [moveFolder] = useMoveGroupFolderMutation();

  const moveRootFolderName = useMemo(
    () => `${hierarchyType.toString()} groups`,
    [hierarchyType]
  );

  const onMoveGroupOrFolder = async ({
    newParentFolderId,
  }: MoveItemModalSubmitProps): Promise<void> => {
    const returnPromise = new Promise<void>((resolve, reject) => {
      if (!selectedMoveGroupOrFolder) {
        reject(new Error("Move Group or Folder is undefined"));
        return;
      }

      (selectedMoveGroupOrFolder.isFolder
        ? moveFolder({
            divisionName,
            moveRequest: {
              folderId: selectedMoveGroupOrFolder.id ?? "",
              hierarchyType,
              targetParentFolderId: newParentFolderId,
            },
          })
        : moveGroup({
            divisionName,
            moveRequest: {
              groupId: selectedMoveGroupOrFolder.id ?? "",
              hierarchyType,
              targetFolderId: newParentFolderId,
            },
          })
      )
        .unwrap()
        .then(async () => {
          resolve();
        })
        .catch(async (error) => {
          reject(new Error(error));
        })
        .finally(() => {
          dispatch(setMoveFolderOrGroup(undefined));
        });
    });
    await returnPromise;
  };

  const mappedGroupFolders = useMemo(
    (): Folder[] =>
      // map only folders, ignore groups, ignore sharing
      folder
        ? mapFolderDto(
            folder,
            undefined,
            undefined,
            (f2, depth, ancestorIds, parentId): Folder => ({
              parentId,
              id: f2.id ?? "",
              name: f2.name ?? "",
              creationDateUTC: f2.creationDateUtc ?? "",
            }),
            undefined
          )
        : [],
    [folder]
  );

  return (
    <MoveItemModal
      folders={mappedGroupFolders}
      key={selectedMoveGroupOrFolder?.id}
      onClose={() => dispatch(setMoveFolderOrGroup(undefined))}
      onSubmit={onMoveGroupOrFolder}
      rootFolderName={moveRootFolderName}
      selectedItemFolderId={selectedMoveGroupOrFolder?.parentId}
      selectedItemId={selectedMoveGroupOrFolder?.id ?? ""}
      show={Boolean(selectedMoveGroupOrFolder)}
    />
  );
};
