import {
  Button,
  ButtonHeight,
  ButtonVariant,
  FormBlock,
  Input,
  InputStatus,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  QbitToastPosition,
  Spinner,
  SpinnerSize,
  Text,
  Textarea,
  TextVariant,
  Toggle,
  ToggleLabelPosition,
  ToggleLayout,
  ToggleSize,
  ToggleVariant,
} from "@qbit/react";
import {
  EMIT_TOAST_DURATION,
  type UpdateActivationNotesRequestDto,
  useGetUserQuery,
  useUpdateActivationMutation,
  useUpdateActivationNotesMutation,
  type SharedUserDto,
} from "@quantium-enterprise/common-ui";
import { getTimeAgoString } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { GroupType } from "../../../enums/group-type";
import { setScrollToId } from "../../../states/group-list-slice";
import { getGroupListPath } from "../../../utilities/route-path-formats";
import { type SegmentRow } from "../../segment-library-table/SegmentLibraryTableCells";
import { StatusLabel } from "../../segment-library-table/SegmentLibraryTableCells";
import { SegmentLibraryMediaActivationIcon } from "../group-info-panel-header/SegmentLibraryMediaActivationIcon";
import styles from "./SegmentSummary.module.scss";

type NotesInputProps = {
  segment: SegmentRow | undefined;
};

const NotesInput = ({ segment }: NotesInputProps) => {
  const [notesText, setNotesText] = useState(segment?.activationNotes ?? "");
  const [initialNotesText, setInitialNotesText] = useState(
    segment?.activationNotes ?? ""
  );
  const division = useDivision();
  const divisionName = division.name;
  const [saveActivationNote, { isLoading: isSaveNotesLoading }] =
    useUpdateActivationNotesMutation();

  useEffect(() => {
    setNotesText(segment?.activationNotes ?? "");
    setInitialNotesText(segment?.activationNotes ?? "");
  }, [segment]);

  const handleSaveNotes = useCallback(async () => {
    if (segment) {
      try {
        await saveActivationNote({
          divisionName,
          payload: {
            activateNotes: notesText,
            groupId: segment.customerGroupId,
            segmentKey: segment.key,
          } as UpdateActivationNotesRequestDto,
        });

        QbitEmitToast(
          <QbitToastMessage
            content={<p>Notes updated</p>}
            showIcon
            variant={MessageVariant.Success}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
            position: QbitToastPosition.BottomRight,
          }
        );
      } catch {
        QbitEmitToast(
          <QbitToastMessage
            content={<p>An unknown error has occurred</p>}
            heading={<h5>Error</h5>}
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
            position: QbitToastPosition.BottomRight,
          }
        );
      }
    }
  }, [divisionName, notesText, saveActivationNote, segment]);

  const maxCharacters = 256;

  const charactersLeft = useMemo(
    () => maxCharacters - notesText.length,
    [maxCharacters, notesText]
  );

  const charactersLeftMessage = useMemo(
    () => `${charactersLeft} character${charactersLeft === 1 ? "" : "s"} left`,
    [charactersLeft]
  );

  return (
    <>
      <FormBlock>
        <Input>
          <Textarea
            autofocus
            id="notes"
            // @ts-expect-error property works, but undefined in Textarea type
            maxLength={maxCharacters}
            onChange={(event) => setNotesText(event.target.value)}
            placeholder="Make a note about this segment and its use (optional)"
            value={notesText}
          />
        </Input>
        <InputStatus
          className={styles.inputStatus}
          id="input-status"
          text={notesText === "" ? "" : charactersLeftMessage}
        />
      </FormBlock>
      <div className={styles.notesButtons}>
        {initialNotesText !== "" && initialNotesText !== notesText && (
          <Button
            disabled={isSaveNotesLoading}
            height={ButtonHeight.XSmall}
            onClick={() => setNotesText(initialNotesText)}
            variant={ButtonVariant.Secondary}
          >
            <div>Discard changes</div>
          </Button>
        )}
        {(notesText !== "" || initialNotesText !== "") &&
          initialNotesText !== notesText && (
            <Button
              disabled={isSaveNotesLoading}
              height={ButtonHeight.XSmall}
              onClick={handleSaveNotes}
              variant={ButtonVariant.Primary}
            >
              <div>
                {initialNotesText === "" ? "Save note" : "Save changes"}
              </div>
            </Button>
          )}
      </div>
    </>
  );
};

type SegmentSummaryProps = {
  owner: SharedUserDto | undefined;
  ownerIsLoading: boolean;
  segment: SegmentRow | undefined;
};

export const SegmentSummary = ({
  segment,
  owner,
  ownerIsLoading,
}: SegmentSummaryProps) => {
  const { groupId } = useParams();
  const activationDate = segment?.activationDate;
  const { name: divisionName } = useDivision();
  const [checked, setChecked] = useState<boolean>(Boolean(activationDate));
  const [updateActivation] = useUpdateActivationMutation();
  const { data, isLoading } = useGetUserQuery();
  const currentUser = useMemo(() => data, [data]);
  const currentUserIsLoading = useMemo(() => isLoading, [isLoading]);
  const dispatch = useDispatch();

  useEffect(
    () => setChecked(Boolean(activationDate)),
    [setChecked, activationDate]
  );
  const onClickHandler = useCallback(async () => {
    if (!segment) return;
    let content;
    let heading;
    try {
      await updateActivation({
        payload: {
          activate: !checked,
          groupId: segment.customerGroupId,
          segmentKey: segment.key,
        },
        divisionName,
      }).unwrap();
      content = checked ? "Segment deactivated" : "Segment activated";

      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          showIcon
          variant={MessageVariant.Success}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
          position: QbitToastPosition.BottomRight,
        }
      );
    } catch {
      content = "An unknown error has occurred";
      heading = "Error";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
          position: QbitToastPosition.BottomRight,
        }
      );
    }

    setChecked(!checked);
  }, [checked, divisionName, segment, setChecked, updateActivation]);

  return (
    <>
      <div className={styles.activationToggle}>
        <div className={styles.activationToggleIcon}>
          <SegmentLibraryMediaActivationIcon active={checked} />
        </div>
        <Toggle
          checked={checked}
          label="Media activation"
          labelPosition={ToggleLabelPosition.Start}
          layout={ToggleLayout.Split}
          onClick={onClickHandler}
          size={ToggleSize.Small}
          variant={ToggleVariant.TickAndCross}
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.lineItem}>
          <Text variant={TextVariant.FormLabel}>Segment</Text>
          <Text>{segment?.name ?? ""}</Text>
        </div>
        <div className={styles.lineItem}>
          <Text variant={TextVariant.FormLabel}>Customer count</Text>
          <Text>{(segment?.count ?? 0).toLocaleString()}</Text>
        </div>
        <div className={styles.lineItem}>
          <Text variant={TextVariant.FormLabel}>Created</Text>
          <Text>
            {segment?.customerGroupCreatedDate
              ? getTimeAgoString(segment.customerGroupCreatedDate)
              : "-"}
          </Text>
        </div>
        <div className={styles.lineItem}>
          <Text variant={TextVariant.FormLabel}>Created by</Text>
          {ownerIsLoading ? (
            <Spinner size={SpinnerSize.XSmall} />
          ) : (
            <Text>{owner ? `${owner.firstName} ${owner.lastName}` : ""}</Text>
          )}
        </div>
        <div className={styles.lineItem}>
          <Text variant={TextVariant.FormLabel}>Media status</Text>
          <StatusLabel
            activationDateUtc={segment?.activationDate}
            showInactiveLabel
          />
        </div>
      </div>
      {!ownerIsLoading &&
        !currentUserIsLoading &&
        owner?.salesforceUserId === currentUser?.salesForceId && (
          <div className={styles.linkToGroup}>
            <Link
              onClick={() => {
                dispatch(
                  setScrollToId({
                    groupType: GroupType.Customer,
                    scrollToId: groupId,
                  })
                );
              }}
              to={getGroupListPath(divisionName, GroupType.Customer, groupId)}
            >
              <Text>View original customer group</Text>
            </Link>
          </div>
        )}
      <div className={styles.divider} />
      <div className={styles.notesTitle}>Notes</div>
      <NotesInput segment={segment} />
    </>
  );
};
