import { type StorageService } from "@quantium-enterprise/common-ui";
import { type Middleware } from "redux";
import { type RootState } from "../store";
import { STORAGE_KEYS } from "./storage-keys";

/**
 * Middleware to store Range Performance data in localStorage when it changes
 */
export const createStorageMiddleware =
  (storage: StorageService): Middleware<{}, RootState> =>
  (store) =>
  (next) =>
  (action) => {
    const result = next(action);

    // Get the updated state after the reducer has run
    const newState = store.getState();

    const selectedParameters =
      newState.rangePerformance.parameters.selectedParameters;

    // Store the updated selectedParameters in localStorage
    storage.set(STORAGE_KEYS.SELECTED_PARAMETERS, selectedParameters);

    return result;
  };
