import { ReportType } from "./ReportType";

export const HypercubeExportReports: ReportType[] = [
  ReportType.KeyDriverTree,
  ReportType.AggregateRank,
  ReportType.GainsAndLoss,
  ReportType.KeyMeasureTrends,
  ReportType.CompareMetrics,
  ReportType.CustomerProfiling,
  ReportType.TrialAndRepeat,
];
