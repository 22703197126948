import tokens from "@qbit/core/dist/tokens.json";
import {
  Button,
  ButtonVariant,
  IconSize,
  InlineIcon,
  InlineIconGlyph,
} from "@qbit/react";
import {
  MetricTypes,
  useDivision,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { type ColumnPinningState, type Column } from "@tanstack/react-table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  calculatePercentile,
  PercentileScaleLegendReverse,
  ScaleLegendColors,
} from "components-ui/src/tables/common/legend/ScaleLegend";
import {
  GraphicsCell,
  GraphicsCellMetricType,
} from "components-ui/src/tables/common/table-cell/GraphicsCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { format } from "date-fns";
import { type CSSProperties } from "react";
import { useCallback, useMemo, useState } from "react";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import {
  type StoreByStoreMetricMetadataDto,
  StoreByStoreSorting,
} from "../../fast-report/api/reportConfigurationDto";
import { useGlobalParameters } from "../../useGlobalParameters";
import { type FastReportingTableSorting } from "../common-table-utils/hooks";
import { useFastReportingTableSorting } from "../common-table-utils/hooks";
import styles from "./StoreByStoreTable.module.css";
import {
  type StoreByStoreMetricResult,
  StoreByStoreResultType,
  type StoreByStoreReportResponse,
  type StoreByStoreRow,
} from "./storeByStoreApi";

export type StoreByStoreTableProps = {
  data: StoreByStoreReportResponse;
  metadata: StoreByStoreMetricMetadataDto[];
  onSetSortedStoreByStoreRows: (
    SortedStoreByStoreRows: StoreByStoreRow[]
  ) => void;
};

export const StoreByStoreTable = ({
  data,
  metadata,
  onSetSortedStoreByStoreRows,
}: StoreByStoreTableProps) => {
  const cumulativeDisplayName = "Cumulative stores share of units";

  const getCellStyling = (
    column: Column<StoreByStoreRow, unknown>,
    sortedColumn: string
  ): React.CSSProperties => {
    let currentStyling: React.CSSProperties = {};

    // If sorted by column, make header a different shade
    if (column.id === sortedColumn) {
      currentStyling = {
        ...currentStyling,
        background: tokens.colour["shade-2"],
      };
    }

    return currentStyling;
  };

  const formatter = useFormatter();
  const [globalParameters] = useGlobalParameters();
  const division = useDivision();
  const { configuration } = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.storeByStore,
        state,
      }),
      skip: !division.name,
    }
  );

  const [sorting, setSorting] = useState<FastReportingTableSorting>();

  const { toggleSorting } = useFastReportingTableSorting(
    "StoreByStore_Sorting",
    setSorting,
    data.rows[0]
      ? data.rows[0].results.map((metric) => metric.displayName)
      : [],
    (columnIds) => {
      const columnName =
        configuration && columnIds.includes(configuration.defaultSorting.column)
          ? configuration.defaultSorting.column
          : columnIds[0];
      const isDescending =
        configuration?.defaultSorting.direction ===
        StoreByStoreSorting.Descending;

      return {
        columnId: columnName,
        isDescending,
      };
    }
  );

  const columnHelper = createColumnHelper<StoreByStoreRow>();
  const formattedEndDate = globalParameters.focusPeriod
    ? format(new Date(globalParameters.focusPeriod.endDate), "dd MMM yyyy")
    : "";

  const getColumnPinnedState = (): ColumnPinningState => {
    const locationIds = data.rows[0].results
      .filter((result) => result.resultType === StoreByStoreResultType.Location)
      .map((location) => location.displayName);

    return {
      left: locationIds,
      right: [],
    };
  };

  const metricCellFormatter = useCallback(
    (rowResults: StoreByStoreMetricResult): React.ReactNode => {
      if (rowResults.rank) {
        return (
          <GraphicsCell
            color={
              ScaleLegendColors[
                calculatePercentile(rowResults.rank, data.rows.length)
              ]
            }
            formatter={formatter(rowResults.formatType)}
            type={GraphicsCellMetricType.Percentile}
            value={rowResults.value}
          />
        );
      } else if (rowResults.isGrowth) {
        return (
          <GraphicsCell
            formatter={formatter(rowResults.formatType)}
            type={GraphicsCellMetricType.Growth}
            value={rowResults.value}
          />
        );
      } else {
        return (
          <ValueCell
            formatter={formatter(rowResults.formatType)}
            value={rowResults.value}
          />
        );
      }
    },
    [data.rows.length, formatter]
  );

  const metricColumns = data.rows[0].results.map((rowResult, index) =>
    columnHelper.accessor(
      (row) =>
        row.results.find(
          (result) => result.displayName === rowResult.displayName
        )?.value,
      {
        id: rowResult.displayName,
        // eslint-disable-next-line react/no-unstable-nested-components -- This isn't a component
        header: (properties) => {
          const isSortedColumn =
            sorting?.columnId === properties.header.column.id;
          const sortDirection = sorting?.isDescending
            ? StoreByStoreSorting.Descending
            : StoreByStoreSorting.Ascending;
          const sortIcon =
            sortDirection === StoreByStoreSorting.Descending
              ? InlineIconGlyph.ArrowsSortingDown
              : InlineIconGlyph.ArrowsSortingUp;
          return (
            <span className={styles.sortedHeader}>
              <Button
                data-cy="MetricHeader"
                data-key={rowResult.displayName}
                data-sorting={sortDirection}
                onClick={() =>
                  toggleSorting(
                    properties.header.column.id,
                    rowResult.formatType === MetricTypes.String
                  )
                }
                text={
                  metadata.find(
                    (meta) => meta.metricName === rowResult.displayName
                  )?.displayName ?? rowResult.displayName
                }
                variant={ButtonVariant.Link}
              />
              {isSortedColumn && (
                <InlineIcon
                  glyph={sortIcon}
                  size={IconSize.Small}
                  text={`sorted ${sortDirection}`}
                />
              )}
            </span>
          );
        },
        cell: (properties) =>
          rowResult.resultType === StoreByStoreResultType.Location
            ? formatter(rowResult.formatType)(properties.getValue())
            : metricCellFormatter(properties.row.original.results[index]),
      }
    )
  );

  metricColumns.push(
    columnHelper.accessor(
      (row) =>
        row.results.find(
          (result) => result.displayName === cumulativeDisplayName
        )?.value,
      {
        id: cumulativeDisplayName,
        // eslint-disable-next-line react/no-unstable-nested-components -- This isn't a component
        header: () => (
          <span className={styles.sortedHeader}>
            <Button
              data-cy="MetricHeader"
              data-key={cumulativeDisplayName}
              text={cumulativeDisplayName}
              variant={ButtonVariant.Link}
            />
          </span>
        ),
        cell: (properties) => {
          const cumulativeResult = properties.row.original.results.find(
            (result) => result.displayName === cumulativeDisplayName
          );

          return cumulativeResult
            ? metricCellFormatter(cumulativeResult)
            : null;
        },
      }
    )
  );

  const sortComparer = useCallback(
    (
      aValue: string,
      bValue: string,
      valueFormat: string,
      isDescending: boolean
    ) => {
      if (valueFormat === MetricTypes.String) {
        return isDescending
          ? bValue.localeCompare(aValue, undefined, {
              numeric: true,
              sensitivity: "base",
            })
          : aValue.localeCompare(bValue, undefined, {
              numeric: true,
              sensitivity: "base",
            });
      }

      if (isDescending) {
        if (bValue < aValue) return -1;
        if (bValue > aValue) return 1;
      } else {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
      }

      return 0;
    },
    []
  );

  const sortedRows = useMemo(() => {
    if (sorting) {
      const valueFormat = data.rows[0].results.find(
        (result) => result.displayName === sorting.columnId
      )?.formatType;

      if (valueFormat) {
        return [...data.rows].sort((a, b) => {
          const aValue =
            a.results.find((result) => result.displayName === sorting.columnId)
              ?.value ?? "0";
          const bValue =
            b.results.find((result) => result.displayName === sorting.columnId)
              ?.value ?? "0";

          let sortValue = 0;

          sortValue = sortComparer(
            aValue,
            bValue,
            valueFormat,
            sorting.isDescending
          );

          if (sortValue === 0 && configuration) {
            const valueFormatDefault = data.rows[0].results.find(
              (result) =>
                result.displayName === configuration.defaultSorting.column
            )?.formatType;

            if (valueFormatDefault) {
              const aValueDefault =
                a.results.find(
                  (result) =>
                    result.displayName === configuration.defaultSorting.column
                )?.value ?? "0";

              const bValueDefault =
                b.results.find(
                  (result) =>
                    result.displayName === configuration.defaultSorting.column
                )?.value ?? "0";

              sortValue = sortComparer(
                aValueDefault,
                bValueDefault,
                valueFormatDefault,
                sorting.isDescending
              );
            }
          }

          return sortValue;
        });
      }
    }

    return [];
  }, [data.rows, sorting, configuration, sortComparer]);

  const sortedRowsWithCumulativeStoreShare = useMemo(() => {
    let cumulativeValue = 0;
    const rows: StoreByStoreRow[] = [];
    for (let row of sortedRows) {
      const cumulativeResult = row.results.find(
        (result) => result.resultType === StoreByStoreResultType.Cumulative
      );

      if (cumulativeResult) {
        cumulativeValue += Number.parseFloat(cumulativeResult.value);
        rows.push(
          (row = {
            results: [
              ...row.results,
              {
                displayName: "Cumulative stores share of units",
                value: cumulativeValue.toString(),
                formatType: MetricTypes.Decimal,
                isGrowth: false,
                resultType: StoreByStoreResultType.Cumulative,
                rank: null,
              },
            ],
          })
        );
      }
    }

    onSetSortedStoreByStoreRows(rows);
    return rows;
  }, [sortedRows, onSetSortedStoreByStoreRows]);

  const table = useReactTable<StoreByStoreRow>({
    data: sortedRowsWithCumulativeStoreShare,
    columns: metricColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: 120,
    },
    state: {
      columnPinning: getColumnPinnedState(),
    },
  });

  const getCommonPinningStyles = (
    column: Column<StoreByStoreRow>
  ): CSSProperties => {
    const isPinned = column.getIsPinned();

    return {
      left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
      position: isPinned ? "sticky" : "relative",
      minWidth: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      padding: isPinned ? "var(--qbit-space-xsmall)" : "",
      verticalAlign: isPinned ? "top" : "",
    };
  };

  return (
    <div>
      <div
        className={styles.promoweekBanner}
      >{`PROMO WEEK: ${formattedEndDate}`}</div>
      <div className={styles.container}>
        <table className={styles.storeByStoreTable} data-cy="StoreByStoreTable">
          <colgroup>
            {table.getVisibleLeafColumns().map((column) => (
              <col key={column.id} width={column.getSize()} />
            ))}
          </colgroup>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    colSpan={header.colSpan}
                    key={header.id}
                    style={{
                      ...getCellStyling(header.column, sorting?.columnId ?? ""),
                      ...getCommonPinningStyles(header.column),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getSortedRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{ ...getCommonPinningStyles(cell.column) }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PercentileScaleLegendReverse title="Rank %:" />
    </div>
  );
};
