import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../FilterSummary.module.css";

export const TransactionSourceFilterSummary = () => {
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.transactionSource,
        state,
      }),
      skip: !division.name,
    }
  );

  const [globalParameters] = useGlobalParameters();
  const transactionSource = globalParameters.transactionSource;

  const displayName = reportConfig.configuration?.options.find(
    (opt) => opt.value === transactionSource
  )?.displayName;
  const text = <span>{displayName}</span>;

  return <div className={styles.summaryText}>{text}</div>;
};
