import { Group, Item, ItemHalign, Nav, NavButton, NavSize } from "@qbit/react";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { GroupType } from "../../enums/group-type";
import { getGroupListPath } from "../../utilities/route-path-formats";
import styles from "./GroupManagement.module.css";

export const GroupManagementTabs = () => {
  const division = useDivision();
  const divisionName = division.name;
  const location = useLocation();

  const tabs = useMemo(() => {
    const result = [];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Causes crashes if not checked
    if (division.moduleAccess) {
      if (division.moduleAccess.groups.productGroups.hasAccess) {
        result.push({
          groupType: GroupType.Product,
          key: 0,
        });
      }

      if (division.moduleAccess.groups.locationGroups.hasAccess) {
        result.push({
          groupType: GroupType.Location,
          key: 1,
        });
      }

      if (division.moduleAccess.groups.customerGroups.hasAccess) {
        result.push({
          groupType: GroupType.Customer,
          key: 2,
        });
      }
    }

    return result;
  }, [division]);

  return (
    <Group className={styles.sectionTabNav}>
      <Item halign={ItemHalign.Centre}>
        <Nav
          activeIndex={tabs.findIndex((tab) =>
            location.pathname.includes(tab.groupType)
          )}
          size={NavSize.Medium}
        >
          {tabs.map((tab) => (
            <Link
              key={tab.key}
              to={getGroupListPath(divisionName, tab.groupType)}
            >
              <NavButton>{`${tab.groupType} groups`}</NavButton>
            </Link>
          ))}
        </Nav>
      </Item>
    </Group>
  );
};
