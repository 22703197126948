import { Button, Dropdown } from "@qbit/react";
import { type DatePickerProps } from "components-ui/src/date-picker/DatePicker";
import DatePicker from "components-ui/src/date-picker/DatePicker";
import { format } from "date-fns";
import styles from "./CustomDatePicker.module.css";

export type CustomDatePickerProps = Omit<
  DatePickerProps,
  "focusDate" | "selected"
> & {
  selectedDate: Date;
};

const timePeriodFormat = "dd MMM yy";

export const CustomDatePicker = ({
  selectedDate,
  ...datePickerProps
}: CustomDatePickerProps) => (
  <Dropdown
    trigger={
      <Button
        className={styles.buttonAsInput}
        text={format(selectedDate, timePeriodFormat)}
      />
    }
  >
    <DatePicker
      data-cy="TimePeriodGlobalParameterCustom"
      {...datePickerProps}
      focusDate={selectedDate}
      selected={selectedDate}
    />
  </Dropdown>
);

export default CustomDatePicker;
