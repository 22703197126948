import * as tokens from "@qbit/core/dist/tokens.json";
import {
  Spinner,
  SpinnerSize,
  SelectOption,
  FormBlock,
  FormBlockType,
  FormInputHeight,
  Label,
  Input,
  Select,
} from "@qbit/react";
import { type HierarchyValue } from "@quantium-enterprise/common-ui";
import {
  useDivision,
  useFlags,
  useNumberFormat,
} from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import {
  type DriverTreeType,
  DriverTree,
} from "components-ui/src/driver-tree/DriverTree";
import { DriverTreeHeatMap } from "components-ui/src/driver-tree/DriverTreeHeatMap";
import { DriverTreeNode } from "components-ui/src/driver-tree/DriverTreeNode";
import { KeyDriverTreeLegendContent } from "components-ui/src/driver-tree/key-driver-tree/KeyDriverTreeLegendContent";
import { KeyDriverTreeNodeContent } from "components-ui/src/driver-tree/key-driver-tree/KeyDriverTreeNodeContent";
import { getHeatMapColour } from "components-ui/src/driver-tree/utils";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { EMPTY_NODE_NUMBER } from "../../common/constants";
import { type BenchmarkMeasureValue } from "../../key-measure-trends/models/ChartResponseDto";
import { type RootState } from "../../store";
import { KeyDriverTreeFeatureFlags } from "../constants/key-driver-tree-feature-flags";
import { type DriverTreeRequestDto } from "../models/DriverTreeRequestDto";
import {
  type FormattedValue,
  type DriverTreeNodeData,
} from "../models/DriverTreeResponseDto";
import { useGetKeyDriverTreeDriverTreeDataQuery } from "../services/key-driver-tree-driver-tree-api-slice";
import {
  onBenchmarkChange,
  onDriverTreeDataChange,
} from "../services/key-driver-tree-slice";
import {
  selectFocalItem,
  selectLocalParameterSelections,
  selectLocalParametersInitialised,
} from "../services/key-driver-tree-slice-selectors";
import getUserSelections from "../utils/getUserSelections";
import styles from "./KeyDriverTreeDriverTree.module.css";

const dottedNodes = ["Store distribution"];
const convertTree = (
  treeData: DriverTreeNodeData,
  maxContribution: number,
  metricFormatter: Function,
  benchmarkData?: {
    benchmarkItem: HierarchyValue;
    measureValues: BenchmarkMeasureValue[];
  },
  hideBenchmark?: boolean
): DriverTreeType => {
  const formatMetric = (metric?: FormattedValue) =>
    metric ? metricFormatter(metric.format, metric.value, true) : undefined;

  const benchmarkForMetric = benchmarkData?.measureValues.find(
    (measure) => measure.groupName === treeData.measureName
  );

  const formattedBenchmarkForMetric =
    benchmarkForMetric?.value &&
    formatMetric({
      format: "Percentage",
      value: Number(benchmarkForMetric.value),
    });

  const benchmark =
    formattedBenchmarkForMetric ?? formatMetric(treeData.benchmark);

  const nodeElement = (
    <DriverTreeNode
      barColour={getHeatMapColour(
        maxContribution,
        treeData.contribution?.value
      )}
      content={
        <KeyDriverTreeNodeContent
          benchmark={!hideBenchmark && benchmark}
          change={formatMetric(treeData.change)}
          contribution={formatMetric(treeData.contribution)}
          measure={treeData.measureName}
          value={metricFormatter(treeData.value.format, treeData.value.value)}
        />
      }
      key={treeData.measureName}
    />
  );

  let children;
  if (treeData.children && treeData.children.length > 0) {
    children = treeData.children.map((child) =>
      convertTree(
        child,
        maxContribution,
        metricFormatter,
        benchmarkData,
        hideBenchmark
      )
    );
  }

  const parentConnector = dottedNodes.includes(treeData.measureName)
    ? "dashed"
    : undefined;

  const childConnector =
    !treeData.children || treeData.children.length === 0
      ? "hidden"
      : treeData.children.every((child) =>
          dottedNodes.includes(child.measureName)
        )
      ? "dashed"
      : undefined;

  return {
    children,
    nodes: [
      {
        element: nodeElement,
        connectorStyles: {
          child: childConnector,
          parent: parentConnector,
        },
      },
    ],
  };
};

export const DriverTreeChart = () => {
  const { metricFormatter, currencyFormatter } = useNumberFormat();
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[KeyDriverTreeFeatureFlags.ReportExport] ?? false;

  const focalItem = useSelector(selectFocalItem);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const localParametersSelections = useSelector(selectLocalParameterSelections);

  const { benchmark, driverTreeData, reportId } = useSelector(
    (state: RootState) => ({
      benchmark: state.keyDriverTree.benchmark,
      driverTreeData: state.keyDriverTree.driverTreeData,
      reportId: state.keyDriverTree.metaData.reportId,
    })
  );

  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Key_Driver_Tree_Chart_${localParametersSelections.TimePeriodLength}_${localParametersSelections.LocationHierarchy.name}`
      ),
    [localParametersSelections]
  );
  const parameterSummary = useMemo(
    () => [
      {
        name: "Focal item",
        value: `(${hierarchyLevelDisplayLabel(focalItem.shortName)}) ${
          focalItem.name
        }`,
      },
      {
        name: "Time",
        value: localParametersSelections.Time,
      },
      {
        name: "Benchmark",
        value: benchmark
          ? `(${hierarchyLevelDisplayLabel(benchmark.shortName)}) ${
              benchmark.name
            }`
          : "",
      },
      { name: "Key driver", value: localParametersSelections.KeyDriver.label },
      {
        name: "Channel",
        value: localParametersSelections.Channel.label,
      },
      {
        name: "Promotion",
        value: localParametersSelections.Promotion.label,
      },
      {
        name: "Segmentation",
        value: localParametersSelections.Segmentation.label,
      },
      {
        name: "Customer segment",
        value: localParametersSelections.Segment.label,
      },
      { name: "Comp store", value: localParametersSelections.CompStore.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localParametersSelections.LocationHierarchy.shortName
        )}) ${localParametersSelections.LocationHierarchy.name}`,
      },
    ],
    [localParametersSelections, focalItem, benchmark]
  );

  const driverTreeRequest = {
    focalItem: {
      itemCode: focalItem.code,
      name: focalItem.name,
      nodeNumber: focalItem.nodeNumber,
      shortName: focalItem.shortName,
    } as HierarchyValue,
    localSelectedValues: getUserSelections(localParametersSelections),
    reportId,
  } as DriverTreeRequestDto;

  const isDataLoaded =
    localParametersInitialised &&
    reportId &&
    focalItem.nodeNumber !== EMPTY_NODE_NUMBER;

  const {
    data: driverTreeChartData,
    isFetching: isDriverTreeChartDataFetching,
  } = useGetKeyDriverTreeDriverTreeDataQuery(
    {
      divisionName: activeDivisionName,
      requestPayload: driverTreeRequest,
    },
    {
      skip: !isDataLoaded,
    }
  );

  const driverTreeChartDataJson = JSON.stringify(driverTreeChartData);
  const driverTreeDataJson = JSON.stringify(driverTreeData);

  useEffect(() => {
    if (driverTreeChartDataJson !== driverTreeDataJson) {
      dispatch(onDriverTreeDataChange(driverTreeChartData ?? driverTreeData));
    }
  }, [
    dispatch,
    driverTreeChartDataJson,
    driverTreeDataJson,
    driverTreeChartData,
    driverTreeData,
  ]);

  useEffect(() => {
    if (focalItem.name) {
      if (
        driverTreeChartData?.benchmarkData &&
        driverTreeChartData.benchmarkData.length > 0
      ) {
        dispatch(
          onBenchmarkChange(driverTreeChartData.benchmarkData[0].benchmarkItem)
        );
      } else {
        dispatch(onBenchmarkChange(undefined));
      }
    }
  }, [
    dispatch,
    focalItem.name,
    driverTreeDataJson,
    driverTreeChartData?.benchmarkData,
  ]);

  const updateBenchmarkItem = (newBenchmark: HierarchyValue) => {
    dispatch(onBenchmarkChange(newBenchmark));
  };

  if (!isDataLoaded || isDriverTreeChartDataFetching) {
    return (
      <div className={styles.driverTreeContainer}>
        <Spinner
          data-testid="data-table-loading-icon"
          size={SpinnerSize.Large}
        />
      </div>
    );
  }

  const menuItems: JSX.Element[] = [];
  let selectedBenchmarkIndex: number | undefined;

  if (driverTreeChartData?.benchmarkData) {
    for (
      let index = driverTreeChartData.benchmarkData.length - 1;
      index >= 0;
      index--
    ) {
      if (
        driverTreeChartData.benchmarkData[index].benchmarkItem.shortName ===
        benchmark?.shortName
      )
        selectedBenchmarkIndex = index;
      menuItems.push(
        <SelectOption
          key={driverTreeChartData.benchmarkData[index].benchmarkItem.shortName}
          text={`(${hierarchyLevelDisplayLabel(
            driverTreeChartData.benchmarkData[index].benchmarkItem.shortName
          )}) ${driverTreeChartData.benchmarkData[index].benchmarkItem.name}`}
          value={String(index)}
        />
      );
    }
  }

  if (menuItems.length === 0) {
    selectedBenchmarkIndex = 0;
    menuItems.push(
      <SelectOption
        key={focalItem.shortName}
        text={`(${hierarchyLevelDisplayLabel(focalItem.shortName)}) ${
          focalItem.name
        }`}
        value={String(0)}
      />
    );
  }

  if (driverTreeChartData?.data) {
    const maxContribution = driverTreeChartData.maxContribution;
    const primaryMeasure = driverTreeChartData.data.measureName;
    return (
      <div className={styles.reportletWrapper}>
        <div className={styles.chartOptionsContainer}>
          <FormBlock blockType={FormBlockType.Select}>
            <Label
              className={styles.benchmarkTitle}
              htmlFor="Benchmark-select"
              text="Benchmark"
            />
            <Input>
              <Select
                className={styles.benchmarkSelect}
                disabled={
                  !driverTreeChartData.benchmarkData ||
                  driverTreeChartData.benchmarkData.length <= 1
                }
                height={FormInputHeight.XSmall}
                id="benchmark-select"
                onChange={(event) =>
                  driverTreeChartData.benchmarkData &&
                  updateBenchmarkItem(
                    driverTreeChartData.benchmarkData[
                      Number(event.target.value)
                    ].benchmarkItem
                  )
                }
                value={String(selectedBenchmarkIndex)}
              >
                {menuItems}
              </Select>
            </Input>
          </FormBlock>
          <ChartOptions
            filename={exportFilename}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
          />
        </div>
        <div className={styles.driverTreeScrollableArea}>
          <div className={styles.driverTreeResizableContainer}>
            <ChartFooterWrapper
              height="612px"
              parameters={parameterSummary}
              ref={chartContainerRef}
            >
              <div className={styles.driverTreeLegends}>
                <DriverTreeNode.LegendNode
                  barColour={tokens.colour["shade-16"]}
                  content={
                    <KeyDriverTreeLegendContent
                      primaryMeasure={primaryMeasure}
                    />
                  }
                  nodeHeight={primaryMeasure === "Sales" ? 64 : 76}
                />
                <DriverTreeHeatMap
                  maxAbsGrowth={currencyFormatter(maxContribution)}
                />
              </div>
              <div className={styles.conditionalFlexAlignCenter}>
                <div className={styles.conditionalMinContent}>
                  <DriverTree
                    createHiddenNode={DriverTreeNode.HiddenNode}
                    root
                    tree={convertTree(
                      driverTreeChartData.data,
                      maxContribution,
                      metricFormatter,
                      selectedBenchmarkIndex
                        ? driverTreeChartData.benchmarkData?.[
                            selectedBenchmarkIndex
                          ]
                        : undefined,
                      !driverTreeChartData.benchmarkData
                    )}
                  />
                </div>
              </div>
            </ChartFooterWrapper>
          </div>
        </div>
      </div>
    );
  }

  return <div className={styles.driverTreeContainer}>No data to display</div>;
};

export const KeyDriverTreeDriverTree = () => {
  const [isDriverTreeReportletOpen, setIsDriverTreeReportletOpen] =
    useState(true);
  return (
    <Accordion
      accordionState={{
        isAccordionOpen: isDriverTreeReportletOpen,
        toggleOpen: setIsDriverTreeReportletOpen,
      }}
      subtitle="Identify key drivers of performance in your chosen time periods."
      title="Key driver tree"
    >
      <ErrorBoundary>
        <DriverTreeChart />
      </ErrorBoundary>
    </Accordion>
  );
};

export default KeyDriverTreeDriverTree;
