import { Spinner } from "@qbit/react";
import {
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import {
  MetricNameSchema,
  MetricsTypeahead,
} from "../../fast-report-content/MetricsTypeahead";
import { type MetricKeyDisplayName } from "../../fast-report-content/MetricsTypeahead";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useFilterCustomerMetrics } from "../hooks";
import { PerformanceAggregateValues } from "./PerformanceAggregateValues";
import styles from "./PerformanceParametersHeader.module.css";

export const PerformanceParametersHeader = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.performance,
        state,
      }),
      skip: !division.name,
    }
  );

  const filterCustomerMetrics = useFilterCustomerMetrics();

  const [selections, setSelections] = useFastReportingParameterState(
    Parameter.PerformanceMetrics,
    MetricNameSchema,
    (config) => config.reports.performance?.metrics
  );

  const availableSelections = useMemo(() => {
    const config = reportConfig.configuration;
    if (config) {
      const metrics = config.metrics?.options.map(
        (metric) =>
          ({
            key: metric.value,
            displayName: metric.displayName,
          } as MetricKeyDisplayName)
      );

      if (filterCustomerMetrics) {
        const customerMetrics = config.metricMetadata
          .filter((metadata) => metadata.isCustomerMetric)
          .map((metadata) => metadata.key);
        return metrics?.filter(
          (metric) => !customerMetrics.includes(metric.key)
        );
      } else {
        return metrics;
      }
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want this to run on initial load or when filter customer metrics flag changes
  }, [reportConfig.configuration, filterCustomerMetrics]);

  useEffect(() => {
    if (selections && availableSelections) {
      const availableSelectionsKeys = availableSelections.map(
        (selection) => selection.key
      );
      if (
        selections.some(
          (selection) => !availableSelectionsKeys.includes(selection)
        )
      ) {
        setSelections(
          selections.filter((selection) =>
            availableSelectionsKeys.includes(selection)
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run this when available selection changes due to filtering customer metrics and we have to modify selection
  }, [availableSelections]);

  const selectedSelections = useMemo(() => {
    if (!selections) {
      return [];
    }

    const newSelection = [] as MetricKeyDisplayName[];
    for (const selection of selections) {
      const availableSelection = availableSelections?.find(
        (item) => item.key === selection
      );
      if (availableSelection) {
        newSelection.push({
          key: selection,
          displayName: availableSelection.displayName,
        });
      }
    }

    return newSelection;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want this to run when selection changes
  }, [selections]);

  const selectionChangedHandler = useCallback(
    (selection: MetricKeyDisplayName[]) => {
      if (selection.length > selectedSelections.length) {
        const existingMetricKeys = selectedSelections.map(
          (metric) => metric.key
        );

        const selectedMetrics = selection.filter(
          (metric) => !existingMetricKeys.includes(metric.key)
        );

        for (const selectedMetric of selectedMetrics) {
          eventTrackingService.trackEvent(
            TrackingComponent.FastReportingPerformanceReport,
            TrackingEvent.Parameters,
            GenericTrackingProperties.single(
              ParametersTrackingProperty.MetricSelected,
              selectedMetric.displayName
            )
          );
        }
      } else {
        const selectedMetricKeys = selection.map((metric) => metric.key);

        const deselectedMetrics = selectedSelections.filter(
          (metric) => !selectedMetricKeys.includes(metric.key)
        );

        for (const deselectedMetric of deselectedMetrics) {
          eventTrackingService.trackEvent(
            TrackingComponent.FastReportingPerformanceReport,
            TrackingEvent.Parameters,
            GenericTrackingProperties.single(
              ParametersTrackingProperty.MetricDeselected,
              deselectedMetric.displayName
            )
          );
        }
      }

      setSelections(selection.map((metric) => metric.key));
    },
    [selectedSelections, setSelections, eventTrackingService]
  );

  const parametersElement =
    availableSelections && selections ? (
      <>
        <MetricsTypeahead
          metrics={availableSelections}
          selectedMetrics={selectedSelections}
          setSelectedMetrics={selectionChangedHandler}
        />
        <PerformanceAggregateValues selectedMetricKeys={selections} />
      </>
    ) : (
      <Spinner />
    );

  return <div className={styles.parametersContainer}>{parametersElement}</div>;
};
