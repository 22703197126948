import { LocalParameters } from "../../common/models/LocalParameterId";
import { type GainsAndLossReportTableRequestDto } from "../models/GainsAndLossReportTableRequestDto";
import { type GainsAndLossLocalSelections } from "../services/gains-and-loss-slice";

const TABLE_PAGE_SIZE = 50;

export const getTableRequestDto = (
  activeTab: string,
  localSelections: GainsAndLossLocalSelections,
  page: number,
  reportId: string,
  searchQuery: string
) => {
  const payload: GainsAndLossReportTableRequestDto = {
    loaShortName: activeTab,
    localSelectedValues: [
      {
        id: LocalParameters.MetricSet,
        values: [localSelections.metricSet.value as string],
      },
      {
        id: LocalParameters.Channel,
        values: [localSelections.channel.value as string],
      },
      {
        id: LocalParameters.CompStore,
        values: [localSelections.compStore.value as string],
      },
      {
        id: LocalParameters.LocationHierarchy,
        values: [localSelections.location.nodeNumber.toString()],
      },
      {
        id: LocalParameters.Segmentation,
        values: [
          localSelections.segmentation.value.toString(),
          localSelections.segment.value.toString(),
        ],
      },
    ],
    page,
    pageSize: TABLE_PAGE_SIZE,
    reportId,
    searchQuery,
  };
  return payload;
};
