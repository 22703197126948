import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Row, type RowSelectionState } from "@tanstack/react-table";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  buildNestedRows,
  insertChildrenIntoPosition,
} from "../../common/utils/data-table-utils";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import {
  type FetchChildrenTableResponse,
  type InitialTableResponse,
  type RefetchTableResponse,
  type CompareMetricsTableMetaData,
  type CompareMetricsTableRow,
} from "../models/compare-metrics-data-table-models";
import {
  type CompareMetricsBreakdownOption,
  type CompareMetricsMetricGrouping,
  type CompareMetricsLocalParametersResponse,
} from "../models/compare-metrics-local-parameters";
import { type ReportletDataResponseDto } from "../models/reportlet-response";
import {
  getAllSelectionSingle,
  getDefaultSelections,
  getDefaultSelectionSingle,
} from "../utils/local-parameter-utils";

const getPersistenceKey = (reportId: string) => `compare-metrics-${reportId}`;

export type CompareMetricsLocalSelections = {
  Channel: PanelOption[];
  CompStore: PanelOption[];
  ComparsionPeriod: PanelOption[];
  LocationHierarchy: PanelOption[];
  Promotion: PanelOption[];
  Segmentation: PanelOption[];
  Time: PanelOption[];
};

export type CompareMetricsPersistedSelections = {
  chartContentSelection: string;
  focalItemSelection?: number;
  focalItemTableRowSelectionState: RowSelectionState;
  focalItems: HierarchySliceNodeDto[];
  localParametersSelections: CompareMetricsLocalSelections;
  metricSelection: string;
  xAxisSelection: string;
};

const focalItemsString = "FocalItems";

export type CompareMetricsState = {
  buttonSelections: {
    chartTableSelection: string;
    metricTypeSelection: string;
  };
  // FOCAL ITEM TABLE
  contributionMetrics: CompareMetricsMetricGrouping[];
  focalItemParents: HierarchySliceNodeDto[];
  focalItemSearchText: string;
  focalItemTableInitialised: boolean;
  focalItemTableMetaData: CompareMetricsTableMetaData;
  focalItemTableRows: CompareMetricsTableRow[];
  // LOCAL PARAMETERS
  localParametersConfig: SidePanelParameter[];
  localParametersInitialised: boolean;
  metaData: {
    reportId: string;
    reportName: string;
  };
  metricDataTypes: string[];
  persistedSelections: CompareMetricsPersistedSelections;
  persistedSelectionsLoaded: boolean;
  reportletBreakdownOptions: CompareMetricsBreakdownOption[];
  reportletData: ReportletDataResponseDto;
  reportletDataReceived: boolean;
  reportletMetricOptions: CompareMetricsMetricGrouping[];
  showChartDataLabels: boolean;
};

export const initialState: CompareMetricsState = {
  focalItemTableInitialised: false,
  focalItemParents: [],
  focalItemTableRows: [],
  focalItemTableMetaData: {
    headers: [],
    metric: {
      label: "",
      format: "",
    },
  },
  buttonSelections: {
    chartTableSelection: "Chart",
    metricTypeSelection: "Actual",
  },
  focalItemSearchText: "",
  contributionMetrics: [],
  localParametersConfig: [],
  localParametersInitialised: false,
  persistedSelections: {
    chartContentSelection: "",
    focalItemTableRowSelectionState: {},
    focalItems: [],
    focalItemSelection: undefined,
    localParametersSelections: {
      Time: [
        {
          value: "",
          label: "",
        },
      ],
      ComparsionPeriod: [
        {
          value: "",
          label: "",
        },
      ],
      Channel: [
        {
          value: "",
          label: "",
        },
      ],
      CompStore: [
        {
          value: "",
          label: "",
        },
      ],
      LocationHierarchy: [
        {
          value: "",
          label: "",
        },
      ],
      Promotion: [
        {
          value: "",
          label: "",
        },
      ],
      Segmentation: [
        {
          value: "",
          label: "",
        },
      ],
    },
    metricSelection: "",
    xAxisSelection: "",
  },
  persistedSelectionsLoaded: false,
  metaData: {
    reportId: "",
    reportName: "compare metrics",
  },
  metricDataTypes: [],
  reportletBreakdownOptions: [],
  reportletMetricOptions: [],
  reportletData: {
    chartContentMetaData: [],
    itemData: [],
    xAxisMetaData: [],
  } as ReportletDataResponseDto,
  reportletDataReceived: false,
  showChartDataLabels: false,
};

export const CompareMetricsSlice = createSlice({
  initialState,
  name: "compare-metrics",
  reducers: {
    onFocalItemChange: (
      state: CompareMetricsState,
      action: PayloadAction<Row<CompareMetricsTableRow>>
    ) => {
      const newFocalItems = action.payload.getIsSelected()
        ? state.persistedSelections.focalItems.filter(
            (value) =>
              value.nodeNumber !==
              action.payload.original.hierarchyItem.nodeNumber
          )
        : [
            ...state.persistedSelections.focalItems,
            action.payload.original.hierarchyItem,
          ];

      const nodeNumber =
        action.payload.original.hierarchyItem.nodeNumber.toString();
      state.persistedSelections.focalItemTableRowSelectionState[nodeNumber] =
        !state.persistedSelections.focalItemTableRowSelectionState[nodeNumber];

      const previousFocalItems = state.persistedSelections.focalItems;
      state.persistedSelections.focalItems = newFocalItems;

      // If there is no more focal items set the item selection to undefined.
      if (newFocalItems.length === 0) {
        state.persistedSelections.focalItemSelection = undefined;
        persistSelections(
          getPersistenceKey(state.metaData.reportId),
          state.persistedSelections
        );
        return;
      }

      // If the selection was previously undefined then take the first as the new selected focal item.
      if (
        state.persistedSelections.focalItemSelection === undefined &&
        state.persistedSelections.chartContentSelection !== focalItemsString &&
        state.persistedSelections.xAxisSelection !== focalItemsString
      ) {
        state.persistedSelections.focalItemSelection =
          newFocalItems[0].nodeNumber;

        persistSelections(
          getPersistenceKey(state.metaData.reportId),
          state.persistedSelections
        );
        return;
      }

      // If the currently selected focal item has been deselected
      if (
        !newFocalItems.some(
          (item) =>
            item.nodeNumber === state.persistedSelections.focalItemSelection
        ) &&
        state.persistedSelections.chartContentSelection !== focalItemsString &&
        state.persistedSelections.xAxisSelection !== focalItemsString
      ) {
        const removedOptionIndex = previousFocalItems.findIndex(
          (item) =>
            item.nodeNumber === state.persistedSelections.focalItemSelection
        );

        state.persistedSelections.focalItemSelection =
          removedOptionIndex > 0
            ? previousFocalItems[removedOptionIndex - 1].nodeNumber
            : newFocalItems[0].nodeNumber;
      }

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onFocalItemTableRowSelectionStateChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<string>
    ) => {
      state.persistedSelections.focalItemTableRowSelectionState[payload] =
        !state.persistedSelections.focalItemTableRowSelectionState[payload];

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onMetadataSuccess: (
      state: CompareMetricsState,
      { payload }: PayloadAction<{ reportId: string; reportName: string }>
    ) => {
      state.metaData = payload;
    },
    onInitialTableDataReceived: (
      state: CompareMetricsState,
      { payload }: PayloadAction<InitialTableResponse>
    ) => {
      state.focalItemTableMetaData = payload.tableMetaData;
      state.focalItemTableRows = buildNestedRows(payload.tableRows);
      state.focalItemTableInitialised = true;
    },
    onTableChildrenDataRecieved: (
      state: CompareMetricsState,
      { payload }: PayloadAction<FetchChildrenTableResponse>
    ) => {
      state.focalItemTableRows = insertChildrenIntoPosition(
        state.focalItemTableRows,
        payload.tableRows,
        payload.parentNode
      );
    },
    onTableDataRecieved: (
      state: CompareMetricsState,
      { payload }: PayloadAction<RefetchTableResponse>
    ) => {
      state.focalItemTableMetaData = payload.tableMetaData;
      state.focalItemTableRows = payload.isSearching
        ? payload.tableRows
        : buildNestedRows(payload.tableRows);
    },
    onLocalParametersReceived: (
      state: CompareMetricsState,
      { payload }: PayloadAction<CompareMetricsLocalParametersResponse>
    ) => {
      const reportletDto = payload.reportletFilters;

      if (!state.persistedSelectionsLoaded) {
        // handle focal item table selections
        state.persistedSelections.focalItems = payload.defaultFocalItems;
        for (const focalItem of payload.defaultFocalItems) {
          state.persistedSelections.focalItemTableRowSelectionState[
            focalItem.nodeNumber
          ] = true;
        }

        // Handle default local parameter selections
        state.persistedSelections.localParametersSelections =
          getDefaultSelections(payload.localParameters);

        // Handle defaults for reportlet selections
        state.persistedSelections.chartContentSelection =
          reportletDto.dropdownOptions[reportletDto.chartContentDefault].value;
        state.persistedSelections.xAxisSelection =
          reportletDto.dropdownOptions[reportletDto.xAxisDefault].value;
        state.persistedSelections.metricSelection = reportletDto.metricDefault;
      }

      state.localParametersConfig = payload.localParameters;
      state.reportletBreakdownOptions =
        payload.reportletFilters.dropdownOptions;
      state.reportletMetricOptions = payload.reportletFilters.metricOptions;
      state.contributionMetrics = payload.contributionMetrics;

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onReportletDataReceived: (
      state: CompareMetricsState,
      { payload }: PayloadAction<ReportletDataResponseDto>
    ) => {
      state.reportletData = payload;
      state.metricDataTypes = payload.itemData.map((object) => object.type);
      state.reportletDataReceived = true;
    },
    onChannelChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<PanelOption[]>
    ) => {
      state.persistedSelections.localParametersSelections.Channel = payload.map(
        (option) => ({
          label: option.label,
          value: option.value,
        })
      );

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onCompStoreChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<PanelOption[]>
    ) => {
      state.persistedSelections.localParametersSelections.CompStore =
        payload.map((option) => ({
          label: option.label,
          value: option.value,
        }));

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onPromotionChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<PanelOption[]>
    ) => {
      state.persistedSelections.localParametersSelections.Promotion =
        payload.map((option) => ({
          label: option.label,
          value: option.value,
        }));

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<SegmentOption[]>
    ) => {
      const newSegmentation = {
        value: payload[0].segmentationValue,
        label: payload[0].segmentationLabel,
      };

      const newSegments = payload.map((segment) => ({
        value: segment.segmentValue,
        label: segment.segmentLabel,
      }));

      state.persistedSelections.localParametersSelections.Segmentation = [
        newSegmentation,
        ...newSegments,
      ];

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<PanelOption[]>
    ) => {
      state.persistedSelections.localParametersSelections.LocationHierarchy =
        payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onTableChartChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<string>
    ) => {
      state.buttonSelections.chartTableSelection = payload;
    },
    onMetricTypeChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<string>
    ) => {
      state.buttonSelections.metricTypeSelection = payload;
    },
    onXAxisSwitch: (state: CompareMetricsState) => {
      const xAxisSelectionHold = state.persistedSelections.xAxisSelection;
      state.persistedSelections.xAxisSelection =
        state.persistedSelections.chartContentSelection;
      state.persistedSelections.chartContentSelection = xAxisSelectionHold;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onXAxisSelectionChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<string>
    ) => {
      const previousXAxisSelection = state.persistedSelections.xAxisSelection;

      if (payload in state.persistedSelections.localParametersSelections) {
        const parameterConfig = state.localParametersConfig.find(
          (parameter) => parameter.id === payload
        );
        if (parameterConfig !== undefined) {
          state.persistedSelections.localParametersSelections[
            payload as keyof CompareMetricsLocalSelections
          ] = getAllSelectionSingle(
            parameterConfig,
            state.persistedSelections.localParametersSelections
          );
        }
      } else if (payload === focalItemsString) {
        state.persistedSelections.focalItemSelection = undefined;
      }

      if (
        previousXAxisSelection in
        state.persistedSelections.localParametersSelections
      ) {
        const parameterConfig = state.localParametersConfig.find(
          (parameter) => parameter.id === previousXAxisSelection
        );
        if (parameterConfig !== undefined) {
          state.persistedSelections.localParametersSelections[
            previousXAxisSelection as keyof CompareMetricsLocalSelections
          ] = getDefaultSelectionSingle(parameterConfig);
        }
      } else if (
        previousXAxisSelection === focalItemsString &&
        state.persistedSelections.focalItems.length > 0
      ) {
        state.persistedSelections.focalItemSelection =
          state.persistedSelections.focalItems[0].nodeNumber;
      }

      state.persistedSelections.xAxisSelection = payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onChartContentSelectionChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<string>
    ) => {
      const previousChartContentSelection =
        state.persistedSelections.chartContentSelection;

      if (payload in state.persistedSelections.localParametersSelections) {
        const parameterConfig = state.localParametersConfig.find(
          (parameter) => parameter.id === payload
        );
        if (parameterConfig !== undefined) {
          state.persistedSelections.localParametersSelections[
            payload as keyof CompareMetricsLocalSelections
          ] = getAllSelectionSingle(
            parameterConfig,
            state.persistedSelections.localParametersSelections
          );
        }
      } else if (payload === focalItemsString) {
        state.persistedSelections.focalItemSelection = undefined;
      }

      if (
        previousChartContentSelection in
        state.persistedSelections.localParametersSelections
      ) {
        const parameterConfig = state.localParametersConfig.find(
          (parameter) => parameter.id === previousChartContentSelection
        );
        if (parameterConfig !== undefined) {
          state.persistedSelections.localParametersSelections[
            previousChartContentSelection as keyof CompareMetricsLocalSelections
          ] = getDefaultSelectionSingle(parameterConfig);
        }
      } else if (previousChartContentSelection === focalItemsString) {
        state.persistedSelections.focalItemSelection =
          state.persistedSelections.focalItems[0].nodeNumber;
      }

      state.persistedSelections.chartContentSelection = payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onFocalItemSelectionChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<number>
    ) => {
      state.persistedSelections.focalItemSelection = payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onMetricSelectionChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<string>
    ) => {
      state.persistedSelections.metricSelection = payload;
      state.buttonSelections.metricTypeSelection = "Actual";

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    toggleChartDataLabels: (state: CompareMetricsState) => {
      state.showChartDataLabels = !state.showChartDataLabels;
    },
    onSearchChange: (
      state: CompareMetricsState,
      { payload }: PayloadAction<string>
    ) => {
      state.focalItemSearchText = payload;
    },
    onReportOpen: (
      state: CompareMetricsState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          metaData: {
            ...initialState.metaData,
            reportId: action.payload.reportId,
          },
        };
      }

      const persistedSelections: CompareMetricsPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          metaData: {
            ...initialState.metaData,
            reportId: action.payload.reportId,
          },
        };
      }

      return {
        ...initialState,
        metaData: {
          ...initialState.metaData,
          reportId: action.payload.reportId,
        },
        persistedSelections,
        persistedSelectionsLoaded: true,
      };
    },
    onParentNodeDataReceived: (
      state: CompareMetricsState,
      { payload }: PayloadAction<HierarchySliceNodeDto[]>
    ) => {
      state.focalItemParents = payload;
    },
    reset: () => initialState,
  },
});

export const {
  onFocalItemChange,
  onFocalItemTableRowSelectionStateChange,
  onInitialTableDataReceived,
  onTableChildrenDataRecieved,
  onTableDataRecieved,
  onMetadataSuccess,
  onLocalParametersReceived,
  onReportletDataReceived,
  onChannelChange,
  onCompStoreChange,
  onPromotionChange,
  onSegmentationChange,
  onLocationChange,
  onTableChartChange,
  onXAxisSwitch,
  onMetricTypeChange,
  onXAxisSelectionChange,
  onChartContentSelectionChange,
  onMetricSelectionChange,
  onFocalItemSelectionChange,
  onReportOpen,
  onSearchChange,
  onParentNodeDataReceived,
  reset,
  toggleChartDataLabels,
} = CompareMetricsSlice.actions;

export default CompareMetricsSlice.reducer;
