import {
  type UserDto,
  type FolderOrReportDto,
  type FolderDtoReduced,
  type NestedRowDto,
  type SharedUserDto,
  type GeneratedReportDto,
  ReportStatus,
  type TransactionSource,
  getTimeAgoString,
  TIME_ELAPSED_UPDATE_INTERVAL_MS,
  setShowMoveItemModal,
  setShowDeleteItemModal,
  setShowShareModal,
  setFolderExpandedState,
  setRenameId,
  setEditingState,
  setQuickActionRowId,
  setIsPollingEnabled,
  useEventTrackingServiceContext,
  TrackingComponent,
  GenericTrackingProperties,
  TrackingEvent,
  getChildIds,
  useGetUserQuery,
  convertUserDtoToSharedUserDto,
  FeatureFlag,
  isTimeLeftLessThanThreeDays,
  getExpiresReportStatus,
  HypercubeExportReports,
  type ReportType,
} from "@quantium-enterprise/common-ui";
import { type TransactionSourceDto } from "@quantium-enterprise/common-ui/src/models/transaction-source-dto";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  type RowSelectionState,
  type ColumnDef,
  type CellContext,
} from "@tanstack/react-table";
import classNames from "classnames";
import { FolderIcon } from "components-ui/src/assets/icons/FolderIcon";
import { ReportIcon } from "components-ui/src/assets/icons/ReportIcon";
import { ButtonDropdown } from "components-ui/src/button-dropdown/ButtonDropdown";
import { EditableField } from "components-ui/src/editable-field/EditableField";
import { ExpandChevron } from "components-ui/src/icons";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import {
  DownloadDataButton,
  SaveParametersButton,
} from "components-ui/src/info-panel/info-panel-header/InfoPanelButtons";
import { ReportStatusTag } from "components-ui/src/report-status-tag/ReportStatusTag";
import { SharedUserIconDisplay } from "components-ui/src/shared-user-icon-display/SharedUserIconDisplay";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { type RootState } from "../store";
import styles from "./MyReportsGrid.module.css";
import {
  ShareButton,
  DeleteButton,
  MoveButton,
  RenameButton,
} from "./MyReportsGridQuickActions";

export const isFolderRow = (
  row: FolderOrReportDto | undefined
): row is FolderDtoReduced & NestedRowDto => Boolean(row?.isFolder);

export const isReportRow = (
  row: FolderOrReportDto | undefined
): row is GeneratedReportDto & NestedRowDto => !row?.isFolder;

export type EditableFieldState = Record<string, boolean>;

export type MyReportsGridWithFoldersProps = {
  exportHypercube: (reportId: string) => Promise<void>;
  renameFolder: (newItemName: string, itemId?: string) => Promise<boolean>;
  renameReport: (newItemName: string, itemId?: string) => Promise<boolean>;
  saveParameters: (reportId: string) => Promise<void>;
};

const NameHeader = () => (
  <span className={classNames(styles.nameHeader)}>Name</span>
);

type NameCellProps = {
  color?: string;
  depth: number;
  hasChildren: boolean;
  id?: string;
  isFolder: boolean;
  name: string;
  reportStatus?: ReportStatus;
};

const getStatus = (
  info: CellContext<FolderOrReportDto, unknown>
): ReportStatus => {
  const row = info.row.original;
  if (
    isReportRow(row) &&
    isTimeLeftLessThanThreeDays(row.createDateUtc, row.retentionDays)
  ) {
    return getExpiresReportStatus(row.createDateUtc, row.retentionDays);
  }

  return info.getValue<ReportStatus>();
};

const StatusHeader = () => <span>Status</span>;
const StatusCell = (info: CellContext<FolderOrReportDto, unknown>) => (
  <span className={styles.status}>
    {Boolean(info.getValue()) && <ReportStatusTag status={getStatus(info)} />}
  </span>
);

const CreatedHeader = () => <span className={styles.sortedBy}>Created</span>;
const TimeElapsedCell = ({
  info,
}: {
  info: CellContext<FolderOrReportDto, unknown>;
}) => {
  const [timeAgoString, setTimeAgoString] = useState(
    getTimeAgoString(info.getValue<string>())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgoString(getTimeAgoString(info.getValue<string>()));
    }, TIME_ELAPSED_UPDATE_INTERVAL_MS);
    return () => clearInterval(interval);
  }, [info]);

  return <span className={styles.createdDate}>{timeAgoString}</span>;
};

const DatasetHeader = () => <span>Dataset</span>;
const DatasetCell = (
  info: CellContext<FolderOrReportDto, unknown>,
  availableTransactionSources: TransactionSourceDto[]
) => (
  <span className={styles.transactionSourceIconContainer}>
    {info.getValue<TransactionSource[] | null>()?.map((source) => (
      <TransactionSourceIcon
        availableTransactionSources={availableTransactionSources}
        key={source}
        transactionSource={source}
      />
    ))}
  </span>
);

const SharedHeader = () => <span>Shared</span>;
const SharedCell = (
  allReportSharedUsers: SharedUserDto[],
  currentUser: UserDto | undefined,
  info: CellContext<FolderOrReportDto, unknown>
) => (
  <SharedUserIconDisplay
    className={styles.sharedColumn}
    owner={
      isReportRow(info.row.original)
        ? allReportSharedUsers.find((user) => {
            if (!isReportRow(info.row.original)) return false;
            return user.salesforceUserId === info.row.original.sharedByUserId;
          }) ?? convertUserDtoToSharedUserDto(currentUser)
        : undefined
    }
    users={
      isReportRow(info.row.original)
        ? allReportSharedUsers.filter((user) =>
            isReportRow(info.row.original)
              ? info.row.original.sharedWithUserIds.includes(
                  user.salesforceUserId
                )
              : []
          )
        : []
    }
  />
);

const MyReportsGridWithFolders = ({
  renameReport,
  renameFolder,
  saveParameters,
  exportHypercube,
}: MyReportsGridWithFoldersProps) => {
  const dispatch = useDispatch();
  const { id: focalRowId } = useParams();
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const eventTrackingService = useEventTrackingServiceContext();
  const {
    name: activeDivisionName,
    transactionSources: availableTransactionSources,
  } = useDivision();

  const { data: user } = useGetUserQuery();
  const flags = useFlags();
  useEffect(() => {
    setRowSelection(focalRowId ? { [focalRowId]: true } : {});
  }, [focalRowId]);

  const { rowData, allReportSharedUsers, expandedIds, renameState } =
    useSelector((state: RootState) => ({
      rowData: state.generatedReports.rowData,
      allReportSharedUsers: state.generatedReports.allReportSharedUsers,
      expandedIds: state.generatedReports.expandedIds,
      renameState: state.generatedReports.renameState,
    }));

  const filteredFoldersAndReports = useMemo(
    () =>
      Object.values(rowData).filter(
        (x) => !x.parentId || expandedIds.includes(x.parentId)
      ),
    [expandedIds, rowData]
  );

  const setIsEditingLocal = (editing: boolean) =>
    dispatch(setEditingState({ editing }));

  const useNameCell = (info: CellContext<FolderOrReportDto, unknown>) => {
    const { id, depth, color, isFolder, hasChildren, name } =
      info.getValue<NameCellProps>();

    const reportStatus =
      "reportStatus" in info.row.original &&
      info.row.original.reportStatus === ReportStatus.Failed
        ? ReportStatus.Failed
        : ReportStatus.Completed;
    const toggleFolder = (): void => {
      if (isFolder && id) {
        dispatch(
          setFolderExpandedState({
            id,
            isExpanded: !expandedIds.includes(id),
          })
        );
        if (
          expandedIds.includes(id) &&
          getChildIds(id, rowData).includes(focalRowId ?? "")
        ) {
          navigate(
            generatePath(`/:division/my-reports`, {
              division: activeDivisionName,
            })
          );
        }
      }
    };

    const stylePadding = {
      paddingLeft: (depth - 1) * 24 + (hasChildren ? 0 : 22),
    };

    const rowStyle =
      reportStatus === ReportStatus.Failed ? styles.rowFailed : styles.row;

    const editableFieldContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          dispatch(setRenameId({ id: undefined }));
        }
      };

      const container = editableFieldContainerRef.current;
      container?.addEventListener("keydown", handleKeyDown);

      return () => {
        container?.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    return (
      <div className={rowStyle} style={stylePadding}>
        <div
          onClick={(event) => {
            if (renameState.id === id) {
              event.stopPropagation();
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleFolder();
            }
          }}
          ref={editableFieldContainerRef}
          role="button"
          style={{ display: "flex", alignItems: "center" }}
          tabIndex={0}
        >
          {isFolder ? (
            <>
              {hasChildren ? (
                <ExpandChevron
                  isCompact={false}
                  isExpanded={expandedIds.includes(id ?? "")}
                  isLoading={false}
                  onClick={() => toggleFolder()}
                />
              ) : (
                <div className={styles.expandChevronSpacer} />
              )}
              <FolderIcon
                folderColour={
                  color &&
                  color.toLowerCase() !== "white" &&
                  color.toLowerCase() !== "#ffffff"
                    ? color
                    : undefined
                }
              />
              <EditableField
                editableFieldState={{
                  isEditing: renameState.id === id,
                  toggleEditing: setIsEditingLocal,
                }}
                onlyExternalState
                save={async (value: string) =>
                  await renameFolder(value, renameState.id)
                }
                stopEditing={() => dispatch(setRenameId({ id: undefined }))}
                value={name}
              />
            </>
          ) : (
            <>
              <ReportIcon reportFailed={reportStatus === ReportStatus.Failed} />
              <EditableField
                editableFieldState={{
                  isEditing: renameState.id === id,
                  toggleEditing: setIsEditingLocal,
                }}
                onlyExternalState
                save={async (value: string) =>
                  await renameReport(value, renameState.id)
                }
                stopEditing={() => dispatch(setRenameId({ id: undefined }))}
                value={name}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const quickActionsCell = useCallback(
    (info: CellContext<FolderOrReportDto, unknown>) => (
      <ButtonDropdown
        buttons={[
          [
            {
              ...MoveButton,
              handleClick: () => {
                dispatch(setQuickActionRowId(info.row.original.id));
                dispatch(setShowMoveItemModal(true));
              },
            },
            {
              ...RenameButton,
              handleClick: () => {
                dispatch(setQuickActionRowId(info.row.original.id));
                dispatch(setIsPollingEnabled(false));
                dispatch(setRenameId({ id: info.row.original.id }));
                setIsEditingLocal(true);
              },
            },
            ...(info.row.original.isFolder
              ? []
              : [
                  {
                    ...ShareButton,
                    handleClick: () => {
                      dispatch(setQuickActionRowId(info.row.original.id));
                      dispatch(setShowShareModal(true));
                    },
                  },
                ]),
            ...(isReportRow(info.row.original)
              ? [
                  {
                    ...SaveParametersButton,
                    handleClick: async () => {
                      await saveParameters(info.row.original.id);
                    },
                  },
                ]
              : []),

            ...(flags[FeatureFlag.HypercubeExport] &&
            // Only show it if the row contains a Report AND is a report that has Hypercube Export
            HypercubeExportReports.some((reportType) =>
              isReportRow(info.row.original)
                ? reportType === (info.row.original.reportType as ReportType)
                : false
            )
              ? [
                  {
                    ...DownloadDataButton,
                    handleClick: async () => {
                      await exportHypercube(info.row.original.id);
                    },
                  },
                ]
              : []),
          ],
          [
            {
              ...DeleteButton,
              handleClick: () => {
                dispatch(setQuickActionRowId(info.row.original.id));
                dispatch(setShowDeleteItemModal(true));
              },
            },
          ],
        ]}
        className={classNames(styles.quickActionsColumn, "quick-actions")}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const defaultMyReportColumns: Array<ColumnDef<FolderOrReportDto>> = [
    {
      accessorFn: (row): NameCellProps => ({
        id: row.id,
        depth: row.depth,
        color: row.isFolder ? (row as unknown as FolderDtoReduced).color : "",
        hasChildren: row.hasChildren,
        isFolder: row.isFolder,
        name: row.name,
      }),
      cell: useNameCell,
      header: NameHeader,
      id: "name",
      minSize: 100,
      maxSize: 900,
      size: 300,
    },
    {
      accessorFn: (row) => row.id,
      cell: quickActionsCell,
      header: "",
      id: "quickActions",
      minSize: 20,
      size: 60,
      enableSorting: false,
    },
    {
      accessorFn: (row) => (isReportRow(row) ? row.reportStatus : undefined),
      cell: StatusCell,
      footer: (properties) => properties.column.id,
      header: StatusHeader,
      id: "reportStatus",
      enableSorting: false,
    },
    {
      accessorFn: (row) =>
        isFolderRow(row) ? row.creationDateUTC : row.createDateUtc,

      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => <TimeElapsedCell info={info} />,
      footer: (properties) => properties.column.id,
      header: CreatedHeader,
      id: "created",
    },
    {
      accessorFn: (row) => (isReportRow(row) ? row.dataSource : undefined),
      cell: (info) => DatasetCell(info, availableTransactionSources),
      footer: (properties) => properties.column.id,
      header: DatasetHeader,
      id: "data",
      enableSorting: false,
    },
    {
      accessorFn: (row) =>
        isReportRow(row) ? row.sharedWithUserIds : undefined,
      cell: (info) => SharedCell(allReportSharedUsers, user, info),
      footer: (properties) => properties.column.id,
      header: SharedHeader,
      id: "shared",
      enableSorting: false,
    },
  ];

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    rowId: number,
    selectedRowData: FolderOrReportDto
  ) => {
    if ((event.target as Element).closest(".quick-actions")) {
      return;
    }

    if (event.detail === 2) {
      if (
        isReportRow(selectedRowData) &&
        selectedRowData.reportStatus === ReportStatus.Completed
      ) {
        navigate(
          generatePath(`/:division/${selectedRowData.reportType}/:id`, {
            division: activeDivisionName,
            id: selectedRowData.id,
          })
        );
        eventTrackingService.trackEvent(
          [TrackingComponent.MyReports, TrackingComponent.Report],
          TrackingEvent.Opened,
          new GenericTrackingProperties({
            reportId: selectedRowData.id,
            reportName: selectedRowData.name.split(" - ")[0],
            method: "Double click row",
          })
        );
      }
    } else if (selectedRowData.id === focalRowId) {
      navigate(
        generatePath(`/:division/my-reports`, {
          division: activeDivisionName,
        })
      );
    } else {
      navigate(
        generatePath(`/:division/my-reports/:focalRowId`, {
          division: activeDivisionName,
          focalRowId: selectedRowData.id,
        })
      );
    }
  };

  return (
    <div className={styles.virtuosoContainer}>
      <VirtuosoTableComponent
        columns={defaultMyReportColumns}
        data={filteredFoldersAndReports}
        getRowId={(row: FolderOrReportDto) => row.id}
        onRowClick={onRowClick}
        rowSelectionState={rowSelection}
      />
    </div>
  );
};

export { MyReportsGridWithFolders };
