import {
  type TrackingComponent,
  type TrackingEvent,
  type LocalSelectedValueDto,
  GenericTrackingProperties,
  useEventTrackingServiceContext,
  useGetReportParametersQuery,
  useRenameReportMutation,
  FeatureFlag,
  ReportType,
  TransactionSource,
} from "@quantium-enterprise/common-ui";
import {
  useDivision,
  useFormatter,
  getNumberFormat,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { CustomLegend } from "components-ui/src/charts/CustomLegend";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { type DataLabelsOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { type Series, type Metric } from "components-ui/src/charts/models";
import { toggleAddToSeries } from "components-ui/src/charts/npb-chart/npb-chart-utils";
import { PerformanceTrendChart } from "components-ui/src/charts/performance-trend/PerformanceTrendChart";
import { getPerformanceTrendChartFormatter } from "components-ui/src/charts/performance-trend/utils/getPerformanceTrendChartTooltip";
import { mapToPerformanceTrendSeries } from "components-ui/src/charts/performance-trend/utils/mapToPerformanceTrendSeries";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import {
  ReportView,
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useEffect, useMemo, useRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import ErrorBoundary from "../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../common/components/NoDataChartWrapper";
import { LocalParameters } from "../common/models/LocalParameterId";
import { useFetchNodesQuery } from "../common/services/hierarchy-slice";
import { getFocalItemSummaryWithParents } from "../common/utils/export-parameter-summary-utils";
import { parentNodesToFetch } from "../common/utils/focal-item-labels-utils";
import { type RootState } from "../store";
import styles from "./KeyMeasureTrendsReport.module.css";
import { KeyMeasureTrendsSidePanel } from "./components/KeyMeasureTrendsSidePanel";
import { KeyMeasureTrendsTopDrawerProductTableQuery } from "./components/KeyMeasureTrendsTopDrawerProductTableQuery";
import { SegmentationBreakdownChart } from "./components/segmentation-breakdown/SegmentationBreakdownChart";
import { KeyMeasureTrendsFeatureFlags } from "./constants/key-measure-trends-feature-flags";
import { csvTransformation } from "./csvTransformation";
import {
  createPerformanceTrendSeries,
  createSegmentBreakdownSeries,
} from "./services/KeyMeasureTrendServiceUtils";
import {
  useLazyGetKeyMeasureTrendsSegmentationBreakdownDataQuery,
  useLazyGetKeyMeasureTrendsTrendsChartDataQuery,
} from "./services/key-measure-trends-charts-api-slice";
import { useLazyGetLocalParametersQuery } from "./services/key-measure-trends-local-parameters-api-slice";
import {
  onPrimaryHiddenChange,
  onReportParamtersReceived,
  onShowTrendedChartDataLabelsChange,
  onSecondaryHiddenChange,
  onReportOpen,
  selectFocalItems,
  selectReportId,
  selectMetrics,
  selectChangeOn,
  selectChannel,
  selectLocationHierarchy,
  selectPromotion,
  selectSegmentation,
  selectTime,
  selectTimePeriodLength,
  selectDataset,
  onParentNodeDataReceived,
  selectFocalItemParents,
  selectCompStore,
} from "./services/key-measure-trends-slice";

export const KeyMeasureTrendsReport = () => {
  // FIXME: `id` should not be an empty string by the time we arrive here
  const { id } = useParams();
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();
  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  const formatter = useFormatter();

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[KeyMeasureTrendsFeatureFlags.ReportExport] ?? false;
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;

  useEffect(() => {
    if (id) {
      dispatch(
        onReportOpen({
          reportId: id,
          isTabsEnabled,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTabsEnabled]);

  const { data: focalReport, isLoading: isFocalReportLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: id ?? "" },
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      { skip: id === undefined || activeDivisionName === undefined }
    );

  const reportId = useSelector(selectReportId);
  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);
  const changeOn = useSelector(selectChangeOn);
  const channel = useSelector(selectChannel);
  const compStore = useSelector(selectCompStore);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const metrics = useSelector(selectMetrics);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const dataset = useSelector(selectDataset);

  const {
    hasSegmentation,
    reportName,
    trendsChartResponse,
    segmentationBreakdownResponse,
    promoWeeks,
    localParametersInitialised,
    showSegmentationChartDataLabels,
    showTrendedChartDataLabels,
    hiddenPrimary,
    hiddenSecondary,
  } = useSelector((state: RootState) => ({
    hasSegmentation: state.keyMeasureTrends.hasSegmentation,
    reportName: state.keyMeasureTrends.reportName,
    trendsChartResponse: state.keyMeasureTrends.trendsChartResponse,
    segmentationBreakdownResponse:
      state.keyMeasureTrends.segmentationBreakdownResponse,
    promoWeeks: state.keyMeasureTrends.promoWeeks,
    localParametersInitialised:
      state.keyMeasureTrends.localParametersInitialised,
    showSegmentationChartDataLabels:
      state.keyMeasureTrends.showSegmentationChartDataLabels,
    showTrendedChartDataLabels:
      state.keyMeasureTrends.showTrendedChartDataLabels,
    hiddenPrimary: state.keyMeasureTrends.hiddenPrimary,
    hiddenSecondary: state.keyMeasureTrends.hiddenSecondary,
  }));

  const eventTrackingService = useEventTrackingServiceContext();

  const eventTrackingServiceWithMetaData = (
    componentHierarchy: TrackingComponent[],
    event: TrackingEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: Record<string, any>
  ) =>
    eventTrackingService.trackEvent(
      componentHierarchy,
      event,
      new GenericTrackingProperties({
        reportName: "Key measure trends",
        reportId: id,
        ...properties,
      })
    );

  const isSegmentationDisabled = useMemo(
    () => dataset.value === TransactionSource.Total,
    [dataset]
  );

  const togglePrimary = (index: number) => {
    toggleAddToSeries(index, hiddenPrimary, (series: number[]) => {
      dispatch(onPrimaryHiddenChange(series));
    });
  };

  const toggleSecondary = (index: number) => {
    toggleAddToSeries(index, hiddenSecondary, (series: number[]) => {
      dispatch(onSecondaryHiddenChange(series));
    });
  };

  const parentNodeNumbers = useMemo(
    () => parentNodesToFetch(focalItems),
    [focalItems]
  );

  const { data: hierarchySliceParentNodes } = useFetchNodesQuery(
    {
      hierarchyType: "Product",
      nodeNumbers: parentNodeNumbers,
      reportId,
    },
    {
      skip: parentNodeNumbers.length === 0 || !reportId,
    }
  );

  useEffect(() => {
    if (hierarchySliceParentNodes) {
      dispatch(onParentNodeDataReceived(hierarchySliceParentNodes.nodes));
    }
  }, [dispatch, hierarchySliceParentNodes]);

  const [
    primaryMetric,
    primaryMetricUIName,
    primarySeriesOptions,
    secondaryMetric,
    secondaryMetricUIName,
    dataLabelsOptions,
    secondarySeriesOptions,
    performanceTrendSeries,
  ] = useMemo(() => {
    const performanceTrendChartData = createPerformanceTrendSeries(
      trendsChartResponse,
      Object.values(metrics).map((option) => option.value.toString()),
      formatter,
      showTrendedChartDataLabels
    );

    // To get the metrics used for the charts...
    // Currently doing it with a very dirty way
    let primaryReportMetric: Metric | undefined;
    let primaryMetricUIDisplayName: string | undefined;
    let primaryReportSeries: Series[] = [];
    let secondaryReportMetric: Metric | undefined;
    let secondaryMetricUIDisplayName: string | undefined;
    const metricDataLabelsOptions: DataLabelsOptions[] = [];
    let secondaryReportSeries: Series[] = [];

    if (metrics[0]) {
      primaryReportMetric = performanceTrendChartData.find(
        (series) => series.metric.name === metrics[0].value.toString()
      )?.metric;
      primaryMetricUIDisplayName = primaryReportMetric?.label;
      metricDataLabelsOptions.push({
        isSelected: showTrendedChartDataLabels[0],
        label: primaryMetricUIDisplayName,
        value: "0",
      });
      primaryReportSeries = performanceTrendChartData.filter(
        (item) => item.metric.name === primaryReportMetric?.name
      );
    }

    if (metrics[1]) {
      secondaryReportMetric = performanceTrendChartData.find(
        (series) => series.metric.name === metrics[1].value.toString()
      )?.metric;
      secondaryMetricUIDisplayName = secondaryReportMetric?.label;
      metricDataLabelsOptions.push({
        isSelected: showTrendedChartDataLabels[1],
        label: secondaryMetricUIDisplayName,
        value: "1",
      });
      secondaryReportSeries = performanceTrendChartData.filter(
        (item) => item.metric.name === secondaryReportMetric?.name
      );
    }

    const primary = mapToPerformanceTrendSeries(
      primaryReportSeries,
      hiddenPrimary,
      secondaryReportMetric
    );
    const secondary = mapToPerformanceTrendSeries(
      secondaryReportSeries,
      hiddenSecondary,
      secondaryReportMetric
    );
    return [
      primaryReportMetric,
      primaryMetricUIDisplayName,
      primary,
      secondaryReportMetric,
      secondaryMetricUIDisplayName,
      metricDataLabelsOptions,
      secondary,
      performanceTrendChartData,
    ];
  }, [
    trendsChartResponse,
    metrics,
    formatter,
    showTrendedChartDataLabels,
    hiddenPrimary,
    hiddenSecondary,
  ]);
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const segmentBreakdownSeries = useMemo(
    () =>
      createSegmentBreakdownSeries(
        segmentationBreakdownResponse,
        Object.values(metrics).map((option) => option.value.toString()),
        formatter,
        showSegmentationChartDataLabels
      ),
    [
      segmentationBreakdownResponse,
      metrics,
      formatter,
      showSegmentationChartDataLabels,
    ]
  );

  const [getLocalParameters] = useLazyGetLocalParametersQuery();

  const fetchLocalParameters = useCallback(
    async (division: string, activeReportId: string) => {
      await getLocalParameters({
        divisionName: division,
        reportId: activeReportId,
      });
    },
    [getLocalParameters]
  );

  useEffect(() => {
    if (activeDivisionName && id && !localParametersInitialised) {
      void fetchLocalParameters(activeDivisionName, id);
    }
  }, [
    activeDivisionName,
    id,
    fetchLocalParameters,
    localParametersInitialised,
  ]);

  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Key_Measure_Trends_Chart_${timePeriodLength}_${locationHierarchy.name}`
      ),
    [timePeriodLength, locationHierarchy]
  );
  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        trendsChartResponse,
        Object.values(metrics).map((data) => data.label),
        currencySymbol
      ),
    [trendsChartResponse, metrics, currencySymbol]
  );
  // TRENDS CHART
  // Memoise the relevant local parameter selections - to avoid unnecessary refetches
  const trendsChartLocalParameterSelections: LocalSelectedValueDto[] = useMemo(
    () => [
      {
        id: LocalParameters.ChangeOn,
        values: [changeOn],
      },
      {
        id: LocalParameters.Dataset,
        values: [dataset.value as string],
      },
      {
        id: LocalParameters.Channel,
        values: [channel.value as string],
      },
      {
        id: LocalParameters.CompStore,
        values: [compStore.value as string],
      },
      {
        id: LocalParameters.Promotion,
        values: [promotion.value as string],
      },
      {
        id: LocalParameters.LocationHierarchy,
        values: [locationHierarchy.nodeNumber.toString()],
      },
    ],
    [
      changeOn,
      dataset,
      channel,
      compStore,
      locationHierarchy.nodeNumber,
      promotion,
    ]
  );

  const [trendsChartDataTrigger, { isFetching: isTrendsDataFetching }] =
    useLazyGetKeyMeasureTrendsTrendsChartDataQuery();

  useEffect(() => {
    if (id && localParametersInitialised) {
      void trendsChartDataTrigger({
        divisionName: activeDivisionName,
        payload: {
          reportId: id,
          focalItems,
          localSelectedValues: trendsChartLocalParameterSelections,
        },
      });
    }
  }, [
    activeDivisionName,
    dispatch,
    focalItems,
    id,
    localParametersInitialised,
    trendsChartDataTrigger,
    trendsChartLocalParameterSelections,
  ]);

  // SEGMENTATION BREAKDOWN
  const segmentationBreakdownLocalParameterSelections: LocalSelectedValueDto[] =
    useMemo(
      () => [
        {
          id: LocalParameters.ChangeOn,
          values: [changeOn],
        },
        {
          id: LocalParameters.Dataset,
          values: [dataset.value as string],
        },
        {
          id: LocalParameters.Channel,
          values: [channel.value as string],
        },
        {
          id: LocalParameters.CompStore,
          values: [compStore.value as string],
        },
        {
          id: LocalParameters.Promotion,
          values: [promotion.value as string],
        },
        {
          id: LocalParameters.LocationHierarchy,
          values: [locationHierarchy.nodeNumber.toString()],
        },
        {
          id: LocalParameters.Segmentation,
          values: [segmentation.value as string],
        },
      ],
      [
        changeOn,
        dataset,
        channel,
        compStore,
        locationHierarchy.nodeNumber,
        promotion,
        segmentation,
      ]
    );
  const [
    segmentationBreakdownDataTrigger,
    { isFetching: isSegmentationBreakdownFetching },
  ] = useLazyGetKeyMeasureTrendsSegmentationBreakdownDataQuery();

  useEffect(() => {
    if (
      id &&
      id === reportId &&
      localParametersInitialised &&
      hasSegmentation &&
      !isSegmentationDisabled
    ) {
      void segmentationBreakdownDataTrigger({
        divisionName: activeDivisionName,
        payload: {
          reportId: id,
          focalItems,
          localSelectedValues: segmentationBreakdownLocalParameterSelections,
        },
      });
    }
  }, [
    activeDivisionName,
    dispatch,
    focalItems,
    hasSegmentation,
    id,
    localParametersInitialised,
    reportId,
    segmentationBreakdownDataTrigger,
    segmentationBreakdownLocalParameterSelections,
    isSegmentationDisabled,
  ]);

  const isTrendsDataLoading = useMemo(
    () => isTrendsDataFetching || !localParametersInitialised,
    [isTrendsDataFetching, localParametersInitialised]
  );

  const isSegmentationBreakdownLoading = useMemo(
    () => isSegmentationBreakdownFetching || !localParametersInitialised,
    [isSegmentationBreakdownFetching, localParametersInitialised]
  );

  useEffect(() => {
    if (focalReport) {
      dispatch(onReportParamtersReceived(focalReport));
    }
  }, [focalReport, dispatch]);

  const parameterSummary = useMemo(
    () => [
      {
        name: "Focal item",
        value: getFocalItemSummaryWithParents(focalItems, focalItemParents),
      },
      {
        name: "Time",
        value: time,
      },
      ...(dataset.label
        ? [{ name: "Transaction set", value: dataset.label }]
        : []),
      {
        name: "Metric",
        value:
          metrics.length > 1
            ? ` ${metrics[0]?.label ?? ""} , ${metrics[1]?.label ?? ""}`
            : metrics[0]?.label ?? "",
      },
      { name: "Channel", value: channel.label },
      {
        name: "Promotion",
        value: promotion.label,
      },
      {
        name: "Segmentation",
        value: isSegmentationDisabled ? "N/A" : segmentation.label,
      },
      { name: "Comp store", value: compStore.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(locationHierarchy.shortName)}) ${
          locationHierarchy.name
        }`,
      },
    ],
    [
      focalItems,
      focalItemParents,
      time,
      dataset,
      metrics,
      channel,
      compStore,
      promotion,
      segmentation,
      locationHierarchy,
      isSegmentationDisabled,
    ]
  );

  const [trendedPerformanceOptions, setTrendedPerformanceOptions] =
    useState<HighchartsReactProps>();

  const seriesWithOptions = useMemo(
    () => primarySeriesOptions.concat(secondarySeriesOptions),
    [primarySeriesOptions, secondarySeriesOptions]
  );

  const customLegend = (
    <>
      {primarySeriesOptions.length > 0 && (
        <div className={styles.legendContainer}>
          <CustomLegend
            chartData={primarySeriesOptions}
            legendTitle={primaryMetricUIName}
            toggleSeriesVisibility={togglePrimary}
          />
        </div>
      )}
      {secondarySeriesOptions.length > 0 && (
        <div className={styles.legendContainer}>
          <CustomLegend
            chartData={secondarySeriesOptions}
            dashed
            legendTitle={secondaryMetricUIName}
            toggleSeriesVisibility={toggleSecondary}
          />
        </div>
      )}
    </>
  );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <KeyMeasureTrendsTopDrawerProductTableQuery
            eventTrackingService={eventTrackingServiceWithMetaData}
            formatter={formatter}
            renameReport={handleRenameReport}
            reportName={reportName}
            reportType={ReportType.KeyMeasureTrends}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <KeyMeasureTrendsSidePanel
            eventTrackingService={eventTrackingServiceWithMetaData}
          />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          <Accordion
            className={styles.reportlet}
            subtitle="View your product performance over time in this chart."
            title="Trended performance"
          >
            <div className={styles.chartOptionsContainer}>
              <ChartOptions
                dataLabelsOptions={dataLabelsOptions}
                downloadWizardOptions={
                  trendedPerformanceOptions
                    ? {
                        chartOptions: trendedPerformanceOptions,
                        chartTitle: `Trended performance - ${reportName}`,
                        reportIcon: (
                          <ReportIcon type={ReportType.KeyMeasureTrends} />
                        ),
                        reportTitle: reportName,
                        customLegend,
                      }
                    : undefined
                }
                filename={exportFilename}
                getCSVData={chartCsvTransformationCallback}
                getElementToExport={() => chartContainerRef.current}
                isFeatureEnabled={isExportEnabled}
                localParameters={parameterSummary}
                reportParameters={focalReport}
                toggleDataLabels={(value) =>
                  dispatch(onShowTrendedChartDataLabelsChange(value))
                }
              />
            </div>
            <ChartFooterWrapper
              height="600px"
              parameters={parameterSummary}
              ref={chartContainerRef}
            >
              <ErrorBoundary>
                <NoDataChartWrapper
                  isLoading={isTrendsDataLoading}
                  noData={performanceTrendSeries.length === 0}
                >
                  <div>
                    <PerformanceTrendChart
                      dates={promoWeeks}
                      legendEnabled={false}
                      onOptionsChanged={setTrendedPerformanceOptions}
                      primaryMetric={primaryMetric}
                      primaryMetricUIName={primaryMetricUIName}
                      secondaryMetric={secondaryMetric}
                      secondaryMetricUIName={secondaryMetricUIName}
                      series={performanceTrendSeries}
                      seriesWithOptions={seriesWithOptions}
                      tooltipFormatter={getPerformanceTrendChartFormatter}
                    />
                    {customLegend}
                  </div>
                </NoDataChartWrapper>
              </ErrorBoundary>
            </ChartFooterWrapper>
          </Accordion>
          {hasSegmentation && !isSegmentationDisabled && (
            <Accordion
              className={styles.reportlet}
              subtitle="View the profile of the customer segment that purchases your focal item."
              title="Segmentation breakdown"
            >
              <ErrorBoundary>
                <NoDataChartWrapper
                  isLoading={isSegmentationBreakdownLoading}
                  noData={segmentBreakdownSeries.length === 0}
                >
                  <SegmentationBreakdownChart
                    dates={promoWeeks}
                    primaryMetric={primaryMetric}
                    primaryMetricUIName={primaryMetricUIName}
                    reportName={reportName}
                    secondaryMetric={secondaryMetric}
                    secondaryMetricUIName={secondaryMetricUIName}
                    series={segmentBreakdownSeries}
                  />
                </NoDataChartWrapper>
              </ErrorBoundary>
            </Accordion>
          )}
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={focalReport}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isFocalReportLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <KeyMeasureTrendsReport />,
  path: "key-measure-trends/:id",
};

export default KeyMeasureTrendsReport;
