import {
  type DivisionDto,
  type FocalItem,
} from "@quantium-enterprise/common-ui";
import { useEffect, useMemo, useState } from "react";
import { useGlobalParameters } from "../../useGlobalParameters";
import { convertFocalItemToDto } from "../focalItemDto";
import { type LevelOfAnalysisValue } from "./LevelOfAnalysisDropdown";
import { type TopAndBottomReportMetric } from "./TopAndBottomReportMetric";
import { useTopAndBottomMeasuresQuery } from "./api/topAndBottomApi";
import { type topAndBottomResponse } from "./api/topAndBottomDtos";

export const useGetTopAndBottomMeasures = (
  focalItem: FocalItem | undefined,
  selectedMetrics: TopAndBottomReportMetric[],
  levelOfAnalysis: LevelOfAnalysisValue | undefined,
  metricBreakdown: string | null | undefined,
  division: DivisionDto
) => {
  const [measures, setMeasures] = useState<topAndBottomResponse | undefined>();
  const [globalParameters, areGlobalParametersSet] = useGlobalParameters();

  // if global parameters or focal item changes reset the data in state to execute query hook
  useEffect(() => {
    setMeasures(undefined);
  }, [focalItem, levelOfAnalysis, metricBreakdown, globalParameters]);

  const isMetricAvailable = useMemo(
    () =>
      measures &&
      selectedMetrics.every((sm) => measures.measureAggregateResults[sm.key]),
    [measures, selectedMetrics]
  );

  const query = useTopAndBottomMeasuresQuery(
    {
      metrics: selectedMetrics.map((metric) => metric.key),
      division: division.name,
      focusPeriod: globalParameters.focusPeriod,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- request skipped if this null
      item: focalItem ? convertFocalItemToDto(focalItem) : null!,
      location: globalParameters.location,
      channel: globalParameters.channel,
      promotion: globalParameters.promotion,
      banner: globalParameters.banner,
      levelOfAnalysis: levelOfAnalysis ?? null,
      transactionSource: globalParameters.transactionSource,
      storeFormat: globalParameters.storeFormat,
      metricBreakdown: metricBreakdown ?? null,
      compStore: globalParameters.compStore,
    },
    {
      skip:
        !division.name ||
        selectedMetrics.length <= 0 ||
        !levelOfAnalysis ||
        !focalItem ||
        !areGlobalParametersSet ||
        isMetricAvailable,
    }
  );

  // if we get new data from the endpoint store that in state
  // using currentData which allows you to access cached data while requests are going on
  // makes switching back and forth between tabs work as you can see previous data of loaded tabs
  // while new tab data is being fetched
  useEffect(() => {
    if (query.currentData) {
      setMeasures(query.currentData);
    }
  }, [query.currentData, setMeasures]);

  return {
    data: measures,
    isFetching: query.isFetching,
    isError: query.isError,
    refetch: query.refetch,
    requestId: query.requestId,
  };
};
