import { Spinner } from "@qbit/react";
import {
  HierarchyItemType,
  useLazyGetAncestorsQuery,
  useLazyGetRootNodesQuery,
  ddLog,
  useEventTrackingServiceContext,
  TrackingComponent,
  TrackingEvent,
  GenericTrackingProperties,
  ParametersTrackingProperty,
  UnknownTrackingPropertyValue,
  HierarchyType,
  HierarchyShortName,
  type HierarchyServiceResponseDto,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useState, useEffect, useCallback } from "react";
import Zod from "zod";
import { ParameterSingleSelect } from "../../fast-report/ParameterSingleSelect";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";

export const BenchmarkValueSchema = Zod.string();
export type BenchmarkValue = Zod.infer<typeof BenchmarkValueSchema>;

export const BenchmarkDropdown = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();
  const activeItem = useActiveItem();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.keyDriverTree?.benchmark,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.KDTBenchmark,
    BenchmarkValueSchema,
    (config) => config.reports.keyDriverTree?.benchmark
  );

  const [options, setOptions] = useState<Array<{
    displayName: string;
    value: string;
  }> | null>(null);

  useEffect(() => setOptions(null), [activeItem]);

  const [ancestorsQuery] = useLazyGetAncestorsQuery();
  const [rootNodesQuery] = useLazyGetRootNodesQuery();

  const getAncestors = useCallback(
    async (shortName: string, code: string) =>
      await ancestorsQuery({
        division: division.name,
        hierarchyType: HierarchyType.Product,
        payload: {
          filters: [
            {
              shortName,
              codes: [code],
            },
          ],
          page: 0,
          pageSize: 25,
        },
      }),
    [ancestorsQuery, division.name]
  );

  const getRootNodes = useCallback(
    async () =>
      await rootNodesQuery({
        division: division.name,
        hierarchyType: HierarchyType.Product,
        payload: { page: 0 },
      }),
    [division.name, rootNodesQuery]
  );

  const getDisplayName = useCallback(
    async (
      benchmark: string,
      baseDisplayName: string,
      ancestorsData: HierarchyServiceResponseDto | undefined
    ) => {
      if (
        !activeItem ||
        (activeItem.type !== HierarchyItemType.Leaf &&
          activeItem.type !== HierarchyItemType.Hierarchy &&
          activeItem.type !== HierarchyItemType.Attribute)
      ) {
        return baseDisplayName;
      }

      if (activeItem.shortName === benchmark) {
        return activeItem.displayName;
      }

      if (
        activeItem.type === HierarchyItemType.Attribute &&
        activeItem.additionalHierarchyFilter?.shortName === benchmark
      ) {
        const { data: rootNodesData } = await getRootNodes();
        return (
          rootNodesData?.results.find(
            (result) =>
              result.shortName ===
                activeItem.additionalHierarchyFilter?.shortName &&
              result.code === activeItem.additionalHierarchyFilter.code
          )?.name ?? baseDisplayName
        );
      }

      return (
        ancestorsData?.results.find((result) => result.shortName === benchmark)
          ?.name ?? baseDisplayName
      );
    },
    [activeItem, getRootNodes]
  );

  const getOptions = useCallback(async () => {
    if (!activeItem || activeItem.type === HierarchyShortName.ProductGroup) {
      return;
    }

    const [currentShortName, currentCode] =
      activeItem.type === HierarchyItemType.Attribute
        ? [
            activeItem.additionalHierarchyFilter?.shortName,
            activeItem.additionalHierarchyFilter?.code,
          ]
        : [activeItem.shortName, activeItem.code];

    if (
      reportConfig.configuration?.options &&
      currentShortName &&
      currentCode
    ) {
      const { data: ancestorsData } = await getAncestors(
        currentShortName,
        currentCode
      );

      const rootOption = reportConfig.configuration.options[0];
      if (currentShortName === rootOption.value) {
        // If active item is at root level, add the first option only
        const displayName = await getDisplayName(
          rootOption.value,
          rootOption.displayName,
          ancestorsData
        );
        setOptions([{ value: rootOption.value, displayName }]);
      } else {
        const newOptions: Array<{ displayName: string; value: string }> = [];

        for (const option of reportConfig.configuration.options) {
          if (currentShortName === option.value) {
            // Stop at the active item hierarchy level
            break;
          }

          const displayName = await getDisplayName(
            option.value,
            option.displayName,
            ancestorsData
          );
          newOptions.push({ value: option.value, displayName });
        }

        setOptions(newOptions);
      }
    }
  }, [
    reportConfig.configuration?.options,
    activeItem,
    getAncestors,
    getDisplayName,
  ]);

  useEffect(() => {
    getOptions().catch((error) => {
      // FIXME: throw this somewhere
      ddLog("ERROR", {}, "error", error);
    });
  }, [getOptions]);

  const selectionChangedHandler = useCallback(
    (value: string) => {
      if (selection && value !== selection && reportConfig.configuration) {
        const parameter = reportConfig.configuration.options.find(
          (option) => option.value === value
        );
        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingKDTReport,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.BenchmarkSelected,
            parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
          )
        );
      }

      setSelection(value);
    },
    [reportConfig.configuration, selection, eventTrackingService, setSelection]
  );

  return reportConfig.configuration && selection && options ? (
    <ParameterSingleSelect
      configuration={{
        defaultOption: reportConfig.configuration.defaultOption,
        isReadOnly: reportConfig.configuration.isReadOnly,
        options,
      }}
      data-cy="BenchmarkSelector"
      onSelection={selectionChangedHandler}
      selection={selection}
    />
  ) : (
    <Spinner />
  );
};

export default BenchmarkDropdown;
