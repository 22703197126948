import { type PropsWithChildren } from "react";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import { CustomerGroupInfoPanel } from "./CustomerGroupInfoPanel";
import { SegmentLibraryInfoPanel } from "./SegmentLibraryInfoPanel";

export type GroupInfoPanelProps = {
  customerGroupTab: CustomerGroupTab;
};

export const GroupInfoPanel = ({
  children,
  customerGroupTab,
}: PropsWithChildren<GroupInfoPanelProps>) => {
  if (customerGroupTab === CustomerGroupTab.CustomerGroups) {
    return <CustomerGroupInfoPanel>{children}</CustomerGroupInfoPanel>;
  } else {
    return <SegmentLibraryInfoPanel>{children}</SegmentLibraryInfoPanel>;
  }
};
