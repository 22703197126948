import tokens from "@qbit/core/dist/tokens.json";
import { Tag, TagColour } from "@qbit/react";
import { type TagProps } from "@qbit/react/dist/tag";
import { ReportStatus } from "@quantium-enterprise/common-ui";
import { type ReactElement } from "react";
import styles from "./ReportStatusTag.module.css";

export const ReportStatusTag = ({
  status,
}: {
  status: ReportStatus;
}): ReactElement => {
  const tagProps = ((reportStatus): Partial<TagProps> => {
    switch (reportStatus) {
      case ReportStatus.Failed:
        return {
          customBackground: tokens.colour["brand-coral-palette"].A200,
          customColour: "#bf2d2b",
        };
      case ReportStatus.Cancelled:
        return { colour: TagColour.Neutral };
      case ReportStatus.Completed:
        return { colour: TagColour.Good };
      case ReportStatus.InProgress:
        return { colour: TagColour.Notification, text: "IN PROGRESS" };
      case ReportStatus.ExpiresOneDay:
        return {
          colour: TagColour.Warning,
          customBackground: tokens.colour["brand-yellow-palette"][100],
          text: "EXPIRES 1 DAY",
        };
      case ReportStatus.ExpiresTwoDays:
        return {
          colour: TagColour.Warning,
          customBackground: tokens.colour["brand-yellow-palette"][100],
          text: "EXPIRES 2 DAYS",
        };
      case ReportStatus.ExpiresThreeDays:
        return {
          colour: TagColour.Warning,
          customBackground: tokens.colour["brand-yellow-palette"][100],
          text: "EXPIRES 3 DAYS",
        };
      default:
        return { colour: TagColour.Neutral };
    }
  })(status);

  return (
    <Tag
      className={styles.reportStatusTag}
      text={status.toUpperCase()}
      {...tagProps}
    />
  );
};
