// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This method is designed to work with any type
export const areParameterValuesEquivalent = (left: any, right: any) => {
  // Early exit if reference equality
  if (left === right) {
    return true;
  }

  // If a type isn't an object, then it is a literal
  if (typeof left !== "object" || typeof right !== "object") {
    return left === right;
  }

  // typeof null is an object, so need to do an explicit check
  if (left === null || right === null) {
    return left === right;
  }

  // If an array, check that array entries are equal
  if (Array.isArray(left)) {
    if (!Array.isArray(right)) {
      return false;
    }

    if (left.length !== right.length) {
      return false;
    }

    for (const [index, element] of left.entries()) {
      if (!areParameterValuesEquivalent(element, right[index])) {
        return false;
      }
    }

    return true;
  }

  // If any other object, check the object keys for equality
  const leftKeys = [...Object.keys(left)].sort((a, b) => a.localeCompare(b));
  const rightKeys = [...Object.keys(right)].sort((a, b) => a.localeCompare(b));
  if (!areParameterValuesEquivalent(leftKeys, rightKeys)) {
    return false;
  }

  for (const key of leftKeys) {
    if (!areParameterValuesEquivalent(left[key], right[key])) {
      return false;
    }
  }

  return true;
};
