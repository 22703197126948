import { type TextPanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PanelType } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PromotionFilterContent } from "./PromotionFilterContent";
import { PromotionFilterSummary } from "./PromotionFilterSummary";

export const getPromotionPanel = () => {
  const panel = {
    content: <PromotionFilterContent />,
    id: "FastReportingPromotionSelect",
    label: "Promotion",
    panelType: PanelType.TEXT,
    summary: <PromotionFilterSummary />,
  } as TextPanel;

  return [panel];
};
