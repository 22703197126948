import { type TextPanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PanelType } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { BannerFilterContent } from "./BannerFilterContent";
import { BannerFilterSummary } from "./BannerFilterSummary";

export const getBannerPanel = () => {
  const panel = {
    content: <BannerFilterContent />,
    id: "FastReportingBannerSelect",
    label: "Banner",
    panelType: PanelType.TEXT,
    summary: <BannerFilterSummary />,
  } as TextPanel;

  return [panel];
};
