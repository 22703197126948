import {
  Button,
  ButtonVariant,
  Text,
  Tooltip,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import {
  type FocalItem,
  type HierarchyItemDto,
} from "@quantium-enterprise/common-ui";
import {
  HierarchyItemType,
  HierarchyType,
  ItemTrackingProperties,
  ItemTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  useGetAncestorsQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import useOpenFastReportingTabs from "../useOpenFastReportingTabs";
import styles from "./FocalItemBreadCrumbs.module.css";

export type FocalItemBreadCrumbsProps = {
  code: string;
  shortName: string;
};

export const FocalItemBreadCrumbs = ({
  shortName,
  code,
}: FocalItemBreadCrumbsProps) => {
  const division = useDivision();
  const ancestors = useGetAncestorsQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      payload: {
        filters: [{ shortName, codes: [code] }],
        page: 0,
      },
    },
    { skip: !division.name }
  );

  let crumbs: HierarchyItemDto[] = [];
  if (ancestors.data) {
    const results = [...ancestors.data.results];
    crumbs = results.sort((a, b) => a.depth - b.depth);
  }

  const eventTrackingService = useEventTrackingServiceContext();
  const openTabs = useOpenFastReportingTabs();
  const openReportTab = (item: HierarchyItemDto) => {
    const focalItem: FocalItem = {
      type: HierarchyItemType.Hierarchy,
      shortName: item.shortName,
      code: item.code,
      displayName: item.name,
    };
    openTabs([focalItem]);

    eventTrackingService.trackEvent(
      TrackingComponent.HierarchyBreadcrumb,
      TrackingEvent.Navigated,
      ItemTrackingProperties.item(
        ItemTrackingProperty.Hierarchy,
        item.shortName
      )
    );
  };

  return (
    <>
      {crumbs.length > 0 && (
        <ol className={styles.breadCrumbs} data-cy="Breadcrumbs">
          {crumbs.map((crumb) => (
            <li className={styles.crumb} key={crumb.shortName}>
              {crumb.transactionSourceAccess?.[crumb.shortName]?.length ===
              0 ? (
                <Tooltip
                  spaceInside={TooltipSpaceInside.Medium}
                  trigger={
                    <span className={styles.disabled}>
                      <HierarchyLevelIcon
                        shortName={crumb.shortName}
                        type={HierarchyItemType.Hierarchy}
                      />
                      <Text className={styles.name}>{crumb.name}</Text>
                    </span>
                  }
                  variant={TooltipVariant.ArrowDark}
                >
                  <div className={styles.tooltip}>
                    This hierarchy level is not available based on your
                    entitlements.
                  </div>
                </Tooltip>
              ) : (
                <Button
                  onClick={() => openReportTab(crumb)}
                  variant={ButtonVariant.Link}
                >
                  <HierarchyLevelIcon
                    shortName={crumb.shortName}
                    type={HierarchyItemType.Hierarchy}
                  />
                  <Text className={styles.name}>{crumb.name}</Text>
                </Button>
              )}
            </li>
          ))}
        </ol>
      )}
    </>
  );
};

export default FocalItemBreadCrumbs;
