import { Group, Item, ItemHalign } from "@qbit/react";
import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { type ReactNode } from "react";
import styles from "./FastReportingTopDrawer.module.css";
import FocalItemButton from "./FocalItemButton";
import FocalItemBreadCrumbs from "./fast-report/FocalItemBreadCrumbs";
import { ReportSummaryButton } from "./report-summary/ReportSummaryButton";
import { useActiveItem } from "./useActiveItem";

export type FastReportingTopDrawerProps = {
  onShowReportSummary: () => void;
};

type BlankChildrenProps = {
  children?: ReactNode | ReactNode[];
};

const FastReportingDrawerContainer = ({ children }: BlankChildrenProps) => (
  <div className={styles.headerContainer} data-cy="FastReportingTopDrawer">
    {children}
  </div>
);

export const FastReportingTopDrawer = ({
  onShowReportSummary,
}: FastReportingTopDrawerProps) => {
  const activeItem = useActiveItem();

  return activeItem ? (
    <FastReportingDrawerContainer>
      {(activeItem.type === HierarchyItemType.Hierarchy ||
        activeItem.type === HierarchyItemType.Leaf) && (
        <Item halign={ItemHalign.Left}>
          <FocalItemBreadCrumbs
            code={activeItem.code}
            shortName={activeItem.shortName}
          />
        </Item>
      )}
      <Group>
        <Item halign={ItemHalign.Left}>
          <FocalItemButton item={activeItem} />
        </Item>
        {activeItem.type === HierarchyItemType.Leaf && (
          <Item halign={ItemHalign.Right}>
            <ReportSummaryButton onClick={onShowReportSummary} />
          </Item>
        )}
      </Group>
    </FastReportingDrawerContainer>
  ) : (
    <FastReportingDrawerContainer />
  );
};

export default FastReportingTopDrawer;
