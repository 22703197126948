import { type ParameterGroupDto } from "@quantium-enterprise/common-ui";
import { useMemo } from "react";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { Wizard } from "../Wizard";
import { ParameterItem } from "../parameters/ParameterItem";
import ParameterGroupAlerts from "./ParameterGroupAlerts";
import styles from "./ParameterGroupTab.module.css";
import { TabbedParameters } from "./TabbedParameters";

export type ParameterTabProps = {
  parameterGroupDto: ParameterGroupDto;
};

export const ParameterGroupTab = ({ parameterGroupDto }: ParameterTabProps) => {
  const tabbedParameters = useMemo(
    () =>
      parameterGroupDto.parameters.filter(
        (parameter) => parameter.attributes.isTabbedDisplay
      ),
    [parameterGroupDto.parameters]
  );

  const otherParameters = useMemo(
    () =>
      parameterGroupDto.parameters.filter(
        (parameter) => !parameter.attributes.isTabbedDisplay
      ),
    [parameterGroupDto.parameters]
  );

  return (
    <ErrorBoundary>
      <Wizard.ContentItem key={parameterGroupDto.label}>
        <div className={styles.parameterTabContainer}>
          <div className={styles.parameterTabHeader}>
            <h3 className={styles.header}>{parameterGroupDto.label}</h3>
            {parameterGroupDto.description && (
              <p className={styles.description}>
                {parameterGroupDto.description}
              </p>
            )}
          </div>

          <ParameterGroupAlerts
            className={styles.parameterTabAlerts}
            parameterGroupDto={parameterGroupDto}
          />

          <div className={styles.parameterTabContent}>
            {tabbedParameters.length > 0 && (
              <TabbedParameters parameters={tabbedParameters} />
            )}

            {otherParameters.length > 0 && (
              <div className={styles.nonTabbedParameter}>
                {otherParameters.map((parameter) => (
                  <ParameterItem
                    key={parameter.name}
                    parameterDto={parameter}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Wizard.ContentItem>
    </ErrorBoundary>
  );
};

export default ParameterGroupTab;
