import { useCallback, useMemo } from "react";
import type Zod from "zod";
import { selectActiveTab, setTabState } from "../services/report-tabs-slice";
import { store } from "../store";
import { useReportTabSelector } from "./hooks";

export const useReportTabState =
  /**
   * Stores state against a report tab
   *
   * @template T The type of value to store as state
   * @param {string} key A unique key for this value. This should be the same across tabs
   * @param {(Zod.ZodType<T> | undefined)} schema A Zod schema that describes the type of value you want stored. If the current value set in state doesn't match the schema, the value will be returned as undefined
   * @returns {([T | undefined, (value: T) => void])} An array, with the first item being the current value, and the second being a function you can call to set the state
   */
  <T,>(
    key: string,
    schema: Zod.ZodType<T> | undefined
  ): [T | undefined, (value: T) => void] => {
    const dispatch = store.dispatch;
    const currentTab = useReportTabSelector(selectActiveTab);
    const currentState = currentTab?.state[key];

    const currentValue = useMemo(() => {
      if (currentState === undefined) {
        return undefined;
      }

      const validatedValue = schema
        ? schema.safeParse(currentState)
        : {
            success: true,
            data: currentState as T,
          };

      if (!validatedValue.success) {
        return undefined;
      }

      return validatedValue.data;
    }, [currentState, schema]);

    const setValue = useCallback(
      (value: T) => {
        if (currentTab !== undefined) {
          dispatch(setTabState({ key, value }));
        }
      },
      [currentTab, dispatch, key]
    );

    return [currentValue, setValue];
  };
