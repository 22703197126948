import tokens from "@qbit/core/dist/tokens.json";
import { type TransactionSource } from "@quantium-enterprise/common-ui";
import { HierarchyShortName } from "@quantium-enterprise/common-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type SortingFn,
  type Table,
  type ColumnDef,
  type Column,
  getSortedRowModel,
} from "@tanstack/react-table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReportLoadingWrapper from "../../fast-report/ReportLoadingWrapper";
import { type RangeReportConfigurationDto } from "../../fast-report/api/reportConfigurationDto";
import { useActiveItem } from "../../useActiveItem";
import { useGlobalParameters } from "../../useGlobalParameters";
import { type FastReportingTableSorting } from "../common-table-utils/hooks";
import { useFastReportingTableSorting } from "../common-table-utils/hooks";
import { convertFocalItemToDto, isNewFocalItem } from "../focalItemDto";
import { useFilenameForExport, useParameterValuesForCSVExport } from "../hooks";
import styles from "./RangeReport.module.css";
import { type ProductsRangeReportItemResult } from "./rangeProductsReportApi";
import { useProductsRangeReportQuery } from "./rangeProductsReportApi";
import { renderHeader } from "./utils";

type ProductsRangeProps = {
  configuration: RangeReportConfigurationDto;
  onTransactionSourceChanged: (
    transactionSource: TransactionSource | null
  ) => void;
};

type ColumnMeta = {
  displayName: string;
  isNumeric: boolean;
  isSticky: boolean;
  stickyWidth: number;
};

const sortNumericFunction: SortingFn<ProductsRangeReportItemResult> = (
  rowA,
  rowB,
  _columnId
) => {
  const statusA = rowA.original.metricResults[_columnId];
  const statusB = rowB.original.metricResults[_columnId];
  return statusA - statusB;
};

const ProductsRangeReport = ({
  configuration,
  onTransactionSourceChanged,
}: ProductsRangeProps) => {
  const division = useDivision();
  const [globalParameters, areGlobalParametersSet] = useGlobalParameters();
  const activeItem = useActiveItem();

  const [globalParameterCsvValues] = useParameterValuesForCSVExport();
  const filename = useFilenameForExport("RangeProducts");

  const getNameCell = useCallback(
    (result: ProductsRangeReportItemResult) => (
      <div className={styles.nameCellContainer} title={result.productName}>
        {result.productName}
        {result.isDeletedLine && (
          <div
            className={styles.tagContainer}
            title="This product is no longer in the range."
          >
            <HierarchyLevelIcon
              shortName={HierarchyShortName.DeletedLine}
              type={HierarchyShortName.DeletedLine}
            />
          </div>
        )}
        {isNewFocalItem(result, globalParameters.focusPeriod?.startDate) && (
          <div
            className={styles.tagContainer}
            title="This product has been launched within your selected time period."
          >
            <HierarchyLevelIcon
              shortName={HierarchyShortName.NewLine}
              type={HierarchyShortName.NewLine}
            />
          </div>
        )}
      </div>
    ),
    [globalParameters.focusPeriod?.startDate]
  );

  const getCellStyling = (
    column: Column<ProductsRangeReportItemResult, unknown>,
    table: Table<ProductsRangeReportItemResult>,
    isHeader: boolean
  ): React.CSSProperties => {
    const columnMeta = column.columnDef.meta as ColumnMeta | undefined;

    let currentStyling: React.CSSProperties = {};

    // If the column is sticky, set the position of each cell to be the combined width of previous columns
    if (columnMeta?.isSticky) {
      const columns = table.getVisibleLeafColumns();
      const selectedColumnPosition = columns.indexOf(column);

      // Sum the sticky width of all previous columns
      const stickyPosition = columns
        .slice(0, selectedColumnPosition)
        .map(
          (previousColumn) =>
            (previousColumn.columnDef.meta as ColumnMeta | undefined)
              ?.stickyWidth
        )
        .reduce((previous, current) => (previous ?? 0) + (current ?? 0), 0);

      currentStyling = {
        ...currentStyling,
        position: "sticky",
        left: stickyPosition,
        zIndex: isHeader ? 2 : 1,
        width: columnMeta.stickyWidth,
        minWidth: columnMeta.stickyWidth,
        maxWidth: columnMeta.stickyWidth,
      };
    }

    // If column is numeric and in table body, align right
    if (columnMeta?.isNumeric && !isHeader) {
      currentStyling = {
        ...currentStyling,
        textAlign: "end",
      };
    }

    // If sorted by column, make header a different shade
    if (isHeader && column.getIsSorted()) {
      currentStyling = {
        ...currentStyling,
        background: tokens.colour["shade-2"],
      };
    }

    return currentStyling;
  };

  const results = useProductsRangeReportQuery(
    {
      division: division.name,
      focusPeriod: globalParameters.focusPeriod,
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      item: activeItem ? convertFocalItemToDto(activeItem) : null!,
      location: globalParameters.location,
      channel: globalParameters.channel,
      promotion: globalParameters.promotion,
      banner: globalParameters.banner,
      transactionSource: globalParameters.transactionSource,
      storeFormat: globalParameters.storeFormat,
      compStore: globalParameters.compStore,
    },
    {
      skip: !division.name || !areGlobalParametersSet,
    }
  );

  const columnHelper = createColumnHelper<ProductsRangeReportItemResult>();
  const formattedEndDate = globalParameters.focusPeriod
    ? format(new Date(globalParameters.focusPeriod.endDate), "dd MMM yyyy")
    : "";
  const metricFormatter = useFormatter();

  const templateResult = results.data?.items[0];
  const metricColumnKeys = templateResult
    ? Object.keys(templateResult.metricResults)
    : [];

  const [sorting, setSorting] = useState<FastReportingTableSorting>();

  const { toggleSorting, reactTableSortingState } =
    useFastReportingTableSorting(
      "ProductsRange_Sorting",
      setSorting,
      ["hierarchy", "productName", ...metricColumnKeys],
      () => ({
        columnId: "productName",
        isDescending: false,
      })
    );

  const nameColumns = useMemo(
    () => [
      columnHelper.accessor((row) => row.hierarchyName, {
        id: "hierarchy",
        header: renderHeader(
          results.data?.hierarchyLevel ?? "Segment",
          "hierarchy",
          sorting,
          toggleSorting,
          true
        ),
        cell: (item) => item.cell.row.original.hierarchyName,
        meta: {
          displayName: results.data?.hierarchyLevel ?? "Segment",
        } as ColumnMeta,
      }),
      columnHelper.accessor((row) => row.productName, {
        id: "productName",
        header: renderHeader(
          "Product name",
          "productName",
          sorting,
          toggleSorting,
          true
        ),
        cell: (item) => getNameCell(item.cell.row.original),
        meta: { displayName: "Product name" } as ColumnMeta,
      }),
    ],
    [
      columnHelper,
      getNameCell,
      results.data?.hierarchyLevel,
      sorting,
      toggleSorting,
    ]
  );

  const metricColumns = configuration.metricMetadata
    .map((meta) => {
      const exists = metricColumnKeys.includes(meta.key);

      if (exists) {
        return columnHelper.accessor((row) => row, {
          id: meta.key,
          header: renderHeader(
            meta.displayName,
            meta.key,
            sorting,
            toggleSorting
          ),
          cell: (item) =>
            metricFormatter(meta.format)(
              item.cell.row.original.metricResults[meta.key]
            ),
          meta: {
            displayName: meta.displayName,
            isNumeric: true,
          } as ColumnMeta,
          sortingFn: sortNumericFunction,
        });
      }

      return undefined;
    })
    .filter((item) => item !== undefined);

  const tableColumns = useMemo(
    () => [...nameColumns, ...metricColumns],
    [metricColumns, nameColumns]
  );

  const rangeTable = useReactTable<ProductsRangeReportItemResult>({
    data: results.data?.items ?? new Array<ProductsRangeReportItemResult>(),
    columns: tableColumns as Array<
      ColumnDef<ProductsRangeReportItemResult, unknown>
    >,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: reactTableSortingState,
    },
  });

  useEffect(() => {
    onTransactionSourceChanged(results.currentData?.transactionSource ?? null);
  }, [onTransactionSourceChanged, results.currentData?.transactionSource]);

  const getDataForCsvExport = useCallback(() => {
    const parameters = [...globalParameterCsvValues];

    const header = tableColumns.map(
      (column) => (column?.meta as ColumnMeta).displayName
    );

    header.push("New / Deleted");

    const data = rangeTable.getSortedRowModel().rows.map((row) => {
      const originalItem = row.original;
      const values = row.getVisibleCells().map((cell) => {
        const value = cell.getValue();
        if (
          typeof value === "object" &&
          value !== null &&
          "metricResults" in value
        ) {
          return (value as ProductsRangeReportItemResult).metricResults[
            cell.column.id
          ];
        } else {
          return value;
        }
      });

      const newDeletedResults = [];

      if (
        isNewFocalItem(originalItem, globalParameters.focusPeriod?.startDate)
      ) {
        newDeletedResults.push("New");
      }

      if (originalItem.isDeletedLine) {
        newDeletedResults.push("Deleted");
      }

      values.push(newDeletedResults.join(" "));

      return values;
    });

    return [parameters, header, ...data];
  }, [
    globalParameterCsvValues,
    rangeTable,
    tableColumns,
    globalParameters.focusPeriod?.startDate,
  ]);

  return (
    <ReportLoadingWrapper
      data-cy="RangeProducts"
      isError={results.isError}
      isLoading={results.isFetching}
      noData={!results.data}
      reportMinimumHeight={560}
      retry={results.refetch}
    >
      <DataTableOptions
        disabled={
          !results.data || results.isFetching || results.data.items.length === 0
        }
        filename={filename}
        invokeCSVDownload={getDataForCsvExport}
        isFeatureEnabled
      />
      {!results.isFetching && (
        <>
          <div className={styles.promoweekBanner}>
            {`PROMO WEEK: ${formattedEndDate}`}
          </div>
          <div className={styles.container}>
            <table
              className={styles.rangeReportTable}
              data-cy="ProductsRangeReport"
            >
              <colgroup>
                {rangeTable.getVisibleLeafColumns().map((column) => (
                  <col key={column.id} width={column.getSize()} />
                ))}
              </colgroup>
              <thead>
                {rangeTable.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        colSpan={header.colSpan}
                        key={header.id}
                        style={getCellStyling(header.column, rangeTable, true)}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {rangeTable.getSortedRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={getCellStyling(cell.column, rangeTable, false)}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </ReportLoadingWrapper>
  );
};

export default ProductsRangeReport;
