import {
  Button,
  ButtonHeight,
  ButtonVariant,
  FormBlock,
  FormBlockDesignVariant,
  FormBlockType,
  Group,
  Icon,
  IconGlyph,
  Input,
  Item,
  ItemHalign,
  Text,
} from "@qbit/react";
import { type HierarchyType } from "@quantium-enterprise/common-ui";
import {
  useCreateGroupFolderMutation,
  FeatureFlag,
  AppContext,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { useCallback, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SearchBox } from "../../../../components/src/search-box/SearchBox";
import {
  selectFocalGroup,
  selectFoldersRaw,
  selectSelectedFolderOrGroups,
  setDeleteFolderOrGroupsToCurrentSelection,
  setEditingState,
  setFolderExpandedState,
  setRenameId,
  setSearchText,
} from "../../states/group-list-slice";
import { isFolder, findAncestors } from "../../utilities/folder-helper";
import {
  getGroupCreatorPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import styles from "./GroupManagement.module.css";

export type HierarchyGroupManagementProps = {
  hierarchyType: HierarchyType;
};

export const HierarchyGroupManagementSearchAndActions = ({
  hierarchyType,
}: HierarchyGroupManagementProps) => {
  const { userState } = useContext(AppContext);
  const navigate = useNavigate();
  const division = useDivision();
  const divisionName = division.name;
  const flags = useFlags();
  const dispatch = useDispatch();
  const focalGroup = useSelector(selectFocalGroup);
  const folder = useSelector(selectFoldersRaw);
  const selectedFolderOrGroups = useSelector(selectSelectedFolderOrGroups);

  const [createFolderTrigger] = useCreateGroupFolderMutation();

  const createFolderButton = useMemo(() => {
    const createFolderPayload = {
      hierarchyType,
      parentFolderGuid: isFolder(focalGroup) ? focalGroup.id : undefined,
    };
    return (
      flags[FeatureFlag.FoldersForGroups] && (
        <Button
          height={ButtonHeight.XSmall}
          id={styles.createFolderButton}
          onClick={async () => {
            navigate(getGroupListPath(divisionName, hierarchyType));
            const response = await createFolderTrigger({
              divisionName,
              payload: createFolderPayload,
            }).unwrap();
            const id = response.folderId;
            const parentFolderGuid = createFolderPayload.parentFolderGuid;

            if (parentFolderGuid && folder) {
              // it doesn't like it when id is used
              const ancestorFoldersGuid = findAncestors(
                parentFolderGuid,
                folder
              );
              // expand all the parents ancestors if there are any
              for (const ancestorsGuid of ancestorFoldersGuid) {
                dispatch(
                  setFolderExpandedState({
                    currentUserId: userState.currentUser?.salesForceId,
                    id: ancestorsGuid,
                    isExpanded: true,
                  })
                );
              }

              // expand the parent of the new folder
              dispatch(
                setFolderExpandedState({
                  currentUserId: userState.currentUser?.salesForceId,
                  id: parentFolderGuid,
                  isExpanded: true,
                })
              );
            }

            dispatch(setRenameId({ id }));
            dispatch(setEditingState({ editing: true }));
          }}
          variant={ButtonVariant.Secondary}
        >
          <Icon
            glyph={IconGlyph.AddAndPlusFolderPlus}
            id={styles.createFolderIcon}
            text="New folder"
          />
          <Text>New folder</Text>
        </Button>
      )
    );
  }, [
    flags,
    hierarchyType,
    navigate,
    divisionName,
    createFolderTrigger,
    focalGroup,
    folder,
    dispatch,
    userState,
  ]);

  const onSearch = useCallback(
    (searchTerm: string) => {
      dispatch(setSearchText(searchTerm));
    },
    [dispatch]
  );

  const onBulkDeleteGroupsAndFolders = useCallback(() => {
    dispatch(setDeleteFolderOrGroupsToCurrentSelection());
  }, [dispatch]);

  const trueSharedGroupsSelected = useMemo(
    () =>
      selectedFolderOrGroups.some(
        (selectedFolderOrGroup) =>
          userState.currentUser?.salesForceId !== selectedFolderOrGroup.userId
      ),
    [selectedFolderOrGroups, userState.currentUser?.salesForceId]
  );

  const DeleteButton = useMemo(
    () => (
      <span>
        <Button
          disabled={trueSharedGroupsSelected}
          height={ButtonHeight.XSmall}
          id={styles.deleteButton}
          onClick={onBulkDeleteGroupsAndFolders}
          variant={ButtonVariant.Danger}
        >
          <Icon
            glyph={IconGlyph.DeleteAndCloseDelete}
            id={styles.deleteIcon}
            text="Delete"
          />
          <Text>Delete</Text>
        </Button>
      </span>
    ),
    [trueSharedGroupsSelected, onBulkDeleteGroupsAndFolders]
  );

  const MemoisedSearchBox = useMemo(
    () => (
      <>
        <FormBlock
          blockType={FormBlockType.Search}
          designvariant={FormBlockDesignVariant.Default}
        >
          <Input>
            <SearchBox
              debounceTimeMs={500}
              enableDebounce
              onChange={onSearch}
              placeholder="Search"
            />
          </Input>
        </FormBlock>
        {selectedFolderOrGroups.length === 0 && createFolderButton}
        {selectedFolderOrGroups.length !== 0 && DeleteButton}
      </>
    ),
    [onSearch, selectedFolderOrGroups.length, createFolderButton, DeleteButton]
  );

  return (
    <Group className={styles.sectionSearchCreate}>
      <Item halign={ItemHalign.Left}>
        <div className={styles.leftButtonGroup}>{MemoisedSearchBox}</div>
      </Item>
      <Item halign={ItemHalign.Right}>
        <Link to={getGroupCreatorPath(divisionName, hierarchyType)}>
          <Button height={ButtonHeight.XSmall} variant={ButtonVariant.Primary}>
            <Icon
              glyph={IconGlyph.AddAndPlusAddPlus}
              text={`Create a ${hierarchyType.toLowerCase()} group`}
            />
            <Text>{`Create a ${hierarchyType.toLowerCase()} group`}</Text>
          </Button>
        </Link>
      </Item>
    </Group>
  );
};
