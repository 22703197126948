import {
  Button,
  Dropdown,
  DropdownPositionX,
  DropdownPositionY,
  DropdownWidth,
  Group,
  Icon,
  IconGlyph,
  Item,
  ItemHalign,
  ItemValign,
  ItemWidth,
  Menu,
  MenuSection,
  Spinner,
  SpinnerSize,
  Text,
  MenuItemButton,
} from "@qbit/react";
import { type MenuSectionProps } from "@qbit/react/dist/menu";
import {
  type AttributeFocalItem,
  HierarchyItemType,
  type TransactionSource,
  getTransactionSourceFromEntitlements,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { type ReactElement, useRef, useState, useMemo } from "react";
import {
  type AvailableHierarchyItem,
  type AvailableHierarchyLevel,
} from "./AvailableHierarchyLevels";
import styles from "./HierarchyLevelSelector.module.css";
import { useGetAvailableHierarchyLevelsForAttribute } from "./useGetAvailableHierarchyLevelsForAttribute";

export type HierarchyLevelSelectorProps = {
  focalItem: AttributeFocalItem;
  initialSelection?: { code: string; shortName: string };
  onSelectionChanged?: (
    shortName: string,
    code: string,
    entitlements: TransactionSource[]
  ) => void;
};

const HierarchyLevelSelector = ({
  onSelectionChanged,
  initialSelection,
  focalItem,
}: HierarchyLevelSelectorProps) => {
  const { data: user } = useGetUserQuery();
  const { transactionSources: availableTransactionSources } = useDivision();

  const dropdownRef = useRef<Dropdown>();
  const closeDropdown = () => {
    // @ts-expect-error Qbit dropdown currently has no public method to close itself
    if (dropdownRef.current?.state.isOpen && dropdownRef.current.toggleState) {
      // @ts-expect-error Bug ticket to fix this: https://jira.quantium.com.au/browse/QDS-386
      dropdownRef.current.toggleState();
    }
  };

  const [hoveredItemCode, setHoveredItemCode] = useState<string | null>(null);

  const [selectedItem, setSelectedItem] = useState<
    | {
        code: string;
        name: string;
        shortName: string;
      }
    | undefined
  >();

  const displayEntitlements = useMemo(() => user?.isSupplier, [user]);

  const availableHierarchyLevels =
    useGetAvailableHierarchyLevelsForAttribute(focalItem);

  const onItemSelected = (
    level: AvailableHierarchyLevel,
    item: AvailableHierarchyItem
  ) => {
    closeDropdown();

    if (
      level.shortName !== selectedItem?.shortName ||
      item.code !== selectedItem.code
    ) {
      setSelectedItem({
        code: item.code,
        name: item.name,
        shortName: level.shortName,
      });
      if (onSelectionChanged) {
        onSelectionChanged(level.shortName, item.code, item.entitlements);
      }
    }
  };

  if (initialSelection) {
    const initialHierarchyLevel = availableHierarchyLevels?.find(
      (level) => level.shortName === initialSelection.shortName
    );
    const initialItem = initialHierarchyLevel?.hierarchyItems.find(
      (item) => item.code === initialSelection.code
    );
    if (!selectedItem && initialHierarchyLevel && initialItem) {
      setSelectedItem({
        code: initialItem.code,
        name: initialItem.name,
        shortName: initialHierarchyLevel.shortName,
      });
    }
  } else {
    const defaultHierarchyLevel =
      availableHierarchyLevels !== undefined &&
      availableHierarchyLevels.length > 0
        ? availableHierarchyLevels[0]
        : undefined;

    const defaultItem =
      defaultHierarchyLevel !== undefined &&
      defaultHierarchyLevel.hierarchyItems.length > 0
        ? defaultHierarchyLevel.hierarchyItems[0]
        : undefined;

    if (!selectedItem && defaultHierarchyLevel && defaultItem) {
      onItemSelected(defaultHierarchyLevel, defaultItem);
    }
  }

  if (!availableHierarchyLevels || !selectedItem) {
    if (availableHierarchyLevels === undefined) {
      return (
        <Button className={styles.hierarchyLevelSelectorButton} disabled>
          <Group>
            <Item
              className={styles.hierarchyLevelIcon}
              halign={ItemHalign.Left}
              valign={ItemValign.Middle}
              width={ItemWidth.Fit}
            >
              <Spinner size={SpinnerSize.Small} />
            </Item>
            <Item halign={ItemHalign.Left} valign={ItemValign.Middle}>
              Loading...
            </Item>
            <Item
              className={styles.dropdownIcon}
              halign={ItemHalign.Right}
              valign={ItemValign.Middle}
              width={ItemWidth.Fit}
            >
              <Icon glyph={IconGlyph.ArrowsChevronDown} text="expand" />
            </Item>
          </Group>
        </Button>
      );
    }

    return (
      <Button className={styles.hierarchyLevelSelectorButton} disabled>
        <Group>
          <Item halign={ItemHalign.Left} valign={ItemValign.Middle}>
            No options available
          </Item>
          <Item
            className={styles.dropdownIcon}
            halign={ItemHalign.Right}
            valign={ItemValign.Middle}
            width={ItemWidth.Fit}
          >
            <Icon glyph={IconGlyph.ArrowsChevronDown} text="expand" />
          </Item>
        </Group>
      </Button>
    );
  }

  return (
    <Dropdown
      className={styles.hierarchyLevelSelector}
      contentWidth={DropdownWidth.Medium}
      position={{
        x: DropdownPositionX.AlignRight,
        y: DropdownPositionY.AlignBottom,
      }}
      ref={dropdownRef as React.RefObject<Dropdown>}
      trigger={
        <Button
          className={styles.hierarchyLevelSelectorButton}
          data-testid="hierarchyLevelSelectorButton"
        >
          <Group>
            <Item
              className={styles.hierarchyLevelIcon}
              halign={ItemHalign.Left}
              valign={ItemValign.Middle}
              width={ItemWidth.Fit}
            >
              <HierarchyLevelIcon
                shortName={selectedItem.shortName}
                type={HierarchyItemType.Hierarchy}
              />
            </Item>
            <Item halign={ItemHalign.Left} valign={ItemValign.Middle}>
              <Text>{selectedItem.name}</Text>
            </Item>
            <Item
              className={styles.dropdownIcon}
              halign={ItemHalign.Right}
              valign={ItemValign.Middle}
              width={ItemWidth.Fit}
            >
              <Icon glyph={IconGlyph.ArrowsChevronDown} text="expand" />
            </Item>
          </Group>
        </Button>
      }
    >
      <Menu>
        {availableHierarchyLevels.map(
          (level) =>
            (
              <MenuSection heading={level.levelName} key={level.shortName}>
                {level.hierarchyItems.map((item) => (
                  <div
                    key={item.code}
                    onMouseEnter={() => {
                      setHoveredItemCode(item.code);
                    }}
                    onMouseLeave={() => {
                      setHoveredItemCode(null);
                    }}
                  >
                    <MenuItemButton
                      data-testid="hierarchy-item-name"
                      onClick={() => {
                        onItemSelected(level, item);
                      }}
                      selected={
                        level.shortName === selectedItem.shortName &&
                        item.code === selectedItem.code
                      }
                      text={
                        <Group>
                          <Item
                            className={styles.hierarchyLevelIcon}
                            halign={ItemHalign.Left}
                            width={ItemWidth.Fit}
                          >
                            <HierarchyLevelIcon
                              shortName={level.shortName}
                              type={HierarchyItemType.Hierarchy}
                            />
                          </Item>
                          <Item halign={ItemHalign.Left} width={ItemWidth.Fill}>
                            {item.name}
                          </Item>
                          {displayEntitlements && (
                            <Item
                              className={styles.transactionSourceIcon}
                              halign={ItemHalign.Right}
                              width={ItemWidth.Fit}
                            >
                              <TransactionSourceIcon
                                availableTransactionSources={
                                  availableTransactionSources
                                }
                                greyedOut={item.code !== hoveredItemCode}
                                transactionSource={getTransactionSourceFromEntitlements(
                                  item.entitlements
                                )}
                              />
                            </Item>
                          )}
                        </Group>
                      }
                    />
                  </div>
                ))}
              </MenuSection>
            ) as ReactElement<MenuSectionProps>
        )}
      </Menu>
    </Dropdown>
  );
};

export default HierarchyLevelSelector;
