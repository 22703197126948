import {
  Dropdown,
  DropdownWidth,
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  Menu,
  MenuSection,
  MenuItemButton,
  Text,
  Tooltip,
  TooltipVariant,
  TooltipPlacement,
  TooltipSpaceInside,
} from "@qbit/react";
import { type CustomerGroupDto } from "@quantium-enterprise/common-ui";
import {
  closeDropdown,
  CustomerGroupStatus,
  CustomerGroupType,
} from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { DownloadIconForSidePanel } from "components-ui/src/assets/icons/DownloadIconForSidePanel";
import { RemoveIcon } from "components-ui/src/assets/icons/RemoveIcon";
import { RenameIcon } from "components-ui/src/assets/icons/RenameIcon";
import { RerunIcon } from "components-ui/src/assets/icons/RerunIcon";
import { ShareIcon } from "components-ui/src/assets/icons/ShareIcon";
import { useRef } from "react";
import styles from "./ActionMenu.module.css";

type OptionProps = {
  dropdownRef: React.RefObject<Dropdown>;
  handleClick: () => void;
  icon?: JSX.Element;
  // eslint-disable-next-line react/no-unused-prop-types -- it IS used in MenuOption. linter issue.
  isDisabled?: boolean;
  text: string;
};

type RefreshOptionProps = OptionProps & {
  getCustomerGroup?: () => CustomerGroupDto | undefined;
};

type DeleteOptionProps = OptionProps & {
  getCustomerGroup?: () => CustomerGroupDto | undefined;
};

type RemoveOptionProps = OptionProps;

const MenuOption = ({
  dropdownRef,
  handleClick,
  icon,
  isDisabled = false,
  text,
}: OptionProps) => (
  <MenuItemButton
    className={styles.menuOption}
    disabled={isDisabled}
    onClick={() => {
      handleClick();
      closeDropdown(dropdownRef);
    }}
    text={
      <Text className={styles.menuOptionText}>
        <>
          {icon && <span className={styles.menuOptionIcon}>{icon}</span>}
          {text}
        </>
      </Text>
    }
  />
);

const RefreshOption = ({
  dropdownRef,
  getCustomerGroup,
  handleClick,
  icon,
  text,
}: RefreshOptionProps) => {
  const group = getCustomerGroup ? getCustomerGroup() : undefined;
  const isDisabled =
    group?.status === CustomerGroupStatus.InProgress ||
    (group?.status === CustomerGroupStatus.Invalid &&
      group.type === CustomerGroupType.CustomSegmentation);

  return (
    <MenuOption
      dropdownRef={dropdownRef}
      handleClick={handleClick}
      icon={icon}
      isDisabled={isDisabled}
      text={text}
    />
  );
};

const DeleteOption = ({
  dropdownRef,
  getCustomerGroup,
  handleClick,
  icon,
  text,
}: DeleteOptionProps) => {
  const group = getCustomerGroup ? getCustomerGroup() : undefined;
  const isDisabled = group?.segments.some(
    (segment) =>
      segment.activationDate !== undefined ||
      segment.stagedActivationDate !== undefined
  );

  const button = (
    <MenuItemButton
      className={styles.menuOption}
      disabled={isDisabled}
      onClick={() => {
        handleClick();
        closeDropdown(dropdownRef);
      }}
      text={
        <Text className={isDisabled ? styles.menuOptionText : styles.delete}>
          <>
            {icon && <span className={styles.menuOptionIcon}>{icon}</span>}
            {text}
          </>
        </Text>
      }
    />
  );

  return isDisabled ? (
    <Tooltip
      className={styles.tooltipContainer}
      placement={TooltipPlacement.BottomCentre}
      spaceInside={TooltipSpaceInside.Small}
      trigger={<span>{button}</span>}
      variant={TooltipVariant.ArrowDark}
    >
      <div className={styles.tooltipText}>
        Cannot delete a group with <br />
        segments in the Segment Library
      </div>
    </Tooltip>
  ) : (
    button
  );
};

const RemoveOption = ({
  dropdownRef,
  handleClick,
  icon,
  text,
}: RemoveOptionProps) => (
  <MenuItemButton
    className={styles.menuOption}
    onClick={() => {
      handleClick();
      closeDropdown(dropdownRef);
    }}
    text={
      <Text className={styles.delete}>
        <>
          {icon && <span className={styles.menuOptionIcon}>{icon}</span>}
          {text}
        </>
      </Text>
    }
  />
);

type ActionMenuProps = {
  className?: string;
  getCustomerGroup?: () => CustomerGroupDto | undefined;
  handleCopy?: () => void;
  handleDelete?: () => void;
  handleDownload?: () => void;
  handleMove?: () => void;
  handleRefresh?: () => void;
  handleRemove?: () => void;
  handleRename?: () => void;
  handleShare?: () => void;
};

export const ActionMenu = ({
  className,
  getCustomerGroup,
  handleCopy,
  handleDelete,
  handleDownload,
  handleMove,
  handleRefresh,
  handleRemove,
  handleRename,
  handleShare,
}: ActionMenuProps) => {
  const dropdownRef = useRef<Dropdown>(null);
  return (
    <Dropdown
      className={classNames(className, styles.actionMenuDropdown)}
      contentWidth={DropdownWidth.Small}
      ref={dropdownRef}
      trigger={
        <Button
          aria-label="Open quick actions menu"
          className={styles.menuToggle}
          height={ButtonHeight.Small}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            glyph={IconGlyph.MenuAndSettingsMoreHoriz}
            text="Quick actions"
          />
        </Button>
      }
    >
      <Menu className={styles.actionMenu}>
        <>
          <MenuSection className={styles.actionMenuSection}>
            <>
              {handleRename && (
                <MenuOption
                  dropdownRef={dropdownRef}
                  handleClick={handleRename}
                  icon={<RenameIcon />}
                  text="Rename"
                />
              )}
              {handleRefresh && (
                <RefreshOption
                  dropdownRef={dropdownRef}
                  getCustomerGroup={getCustomerGroup}
                  handleClick={handleRefresh}
                  icon={<RerunIcon />}
                  text="Refresh"
                />
              )}
              {handleMove && (
                <MenuOption
                  dropdownRef={dropdownRef}
                  handleClick={handleMove}
                  icon={
                    <Icon
                      glyph={IconGlyph.FilesAndFoldersFolderMove}
                      text="Move"
                    />
                  }
                  text="Move"
                />
              )}
              {handleDownload && (
                <MenuOption
                  dropdownRef={dropdownRef}
                  handleClick={handleDownload}
                  icon={<DownloadIconForSidePanel />}
                  text="Download"
                />
              )}
              {handleShare && (
                <MenuOption
                  dropdownRef={dropdownRef}
                  handleClick={handleShare}
                  icon={<ShareIcon />}
                  text="Share"
                />
              )}
              {handleCopy && (
                <MenuOption
                  dropdownRef={dropdownRef}
                  handleClick={handleCopy}
                  icon={
                    <Icon
                      glyph={IconGlyph.TextAndEditContentCopy}
                      text="Copy"
                    />
                  }
                  text="Copy"
                />
              )}
            </>
          </MenuSection>
          {(handleDelete || handleRemove) && (
            <MenuSection>
              <>
                {handleDelete ? (
                  <DeleteOption
                    dropdownRef={dropdownRef}
                    getCustomerGroup={getCustomerGroup}
                    handleClick={handleDelete}
                    icon={
                      <Icon
                        className={styles.menuOptionIcon}
                        glyph={IconGlyph.DeleteAndCloseDelete}
                        text="Delete"
                      />
                    }
                    text="Delete"
                  />
                ) : (
                  handleRemove && (
                    <RemoveOption
                      dropdownRef={dropdownRef}
                      handleClick={handleRemove}
                      icon={<RemoveIcon />}
                      text="Remove"
                    />
                  )
                )}
              </>
            </MenuSection>
          )}
        </>
      </Menu>
    </Dropdown>
  );
};
