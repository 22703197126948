import {
  FormBlock,
  FormBlockEditability,
  FormBlockType,
  Input,
  RadioButton,
  Spinner,
} from "@qbit/react";
import {
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  UnknownTrackingPropertyValue,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback } from "react";
import Zod from "zod";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { type ParameterOption } from "../../fast-report/api/parameterConfiguration";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import styles from "./PromotionFilterContent.module.css";

export const PromotionValueSchema = Zod.string();
export type PromotionValue = Zod.infer<typeof PromotionValueSchema>;

export const PromotionFilterContent = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.promotion,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.Promotion,
    PromotionValueSchema,
    (config) => config.globalParameters.promotion
  );

  const selectionChangedHandler = useCallback(
    (promotion: ParameterOption<string>) => {
      if (reportConfig.configuration) {
        const parameter = reportConfig.configuration.options.find(
          (option) => option.value === promotion.value
        );

        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingGlobal,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.PromotionSelected,
            parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
          )
        );
      }

      setSelection(promotion.value);
    },
    [reportConfig.configuration, eventTrackingService, setSelection]
  );

  return reportConfig.configuration ? (
    <div data-cy="PromotionGlobalParameter">
      {reportConfig.configuration.options.map((option) => (
        <FormBlock blockType={FormBlockType.Radio} key={option.value}>
          <Input>
            <RadioButton
              checked={option.value === selection}
              className={styles.radioButton}
              data-key={option.value}
              data-selected={option.value === selection}
              editability={
                reportConfig.configuration?.isReadOnly
                  ? FormBlockEditability.Disabled
                  : FormBlockEditability.Editable
              }
              id={option.value}
              label={option.displayName}
              name="PromotionFilterSelect"
              onChange={() => selectionChangedHandler(option)}
            />
          </Input>
        </FormBlock>
      ))}
    </div>
  ) : (
    <Spinner />
  );
};
