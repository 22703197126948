import { type TrackingComponent } from "./TrackingComponent";
import { type TrackingEvent } from "./TrackingEvent";
import { type TrackingProperties } from "./TrackingProperties";

export enum EventTrackingServiceType {
  // send tracking events directly to Amplitude
  Amplitude = "Amplitude",
  // do not send tracking events
  NoOp = "NoOp",
  // send tracking events to Segment, then possibly onward to Amplitude (configured in Segment destinations)
  Segment = "Segment",
}
export type EventTrackingService = {
  trackEvent: (
    component: TrackingComponent | TrackingComponent[],
    event: TrackingEvent,
    properties?: TrackingProperties
  ) => void;
};
