import { Toggle } from "@qbit/react";
import {
  HierarchyType,
  useHierarchyMetadataQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useEffect } from "react";
import { useReportTabState } from "report-tabs-ui";
import Zod from "zod";
import { Parameter } from "../../useFastReportingParameterState";
import {
  type LevelOfAnalysisValue,
  LevelOfAnalysisValueSchema,
} from "./LevelOfAnalysisDropdown";

export const ShowDeletedProductsValueSchema = Zod.boolean();
export type ShowDeletedProductsValue = Zod.infer<
  typeof ShowDeletedProductsValueSchema
>;

const ShowDeletedProductsToggle = () => {
  const division = useDivision();

  const hierarchyMetadata = useHierarchyMetadataQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      getAllAttributes: false,
    },
    { skip: !division.name }
  );

  const [levelOfAnalysis] = useReportTabState<LevelOfAnalysisValue>(
    Parameter.TopAndBottomLevelOfAnalysis,
    LevelOfAnalysisValueSchema
  );

  const [selection, setSelection] = useReportTabState<ShowDeletedProductsValue>(
    Parameter.TopAndBottomShowDeletedProducts,
    ShowDeletedProductsValueSchema
  );

  // Default to false
  if (selection === undefined) {
    setSelection(false);
  }

  const isLeafLevel =
    hierarchyMetadata.currentData?.find(
      (hm) => hm.shortName === levelOfAnalysis
    )?.isLeaf ?? false;

  useEffect(() => {
    if (!isLeafLevel) {
      setSelection(false);
    }
  }, [isLeafLevel, setSelection]);

  return (
    <Toggle
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- should always be set due to default above
      checked={selection!}
      disabled={!isLeafLevel}
      label="Show deleted products"
      onClick={() => setSelection(!selection)}
    />
  );
};

export default ShowDeletedProductsToggle;
