import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dialog,
  DialogWidth,
  FormBlock,
  FormInputHeight,
  FormInputWidth,
  FormMultiBlock,
  Icon,
  IconGlyph,
  IconSize,
  InlineIconGlyph,
  Input,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  StealthInlineIconButton,
  Text,
  Textarea,
  TypeaheadMulti,
} from "@qbit/react";
import { type GroupedOption, type Option } from "@qbit/react/dist/typeahead";
import {
  type SharedUserDto,
  useLazyShareReportQuery,
  useGetOrganizationUsersQuery,
  getUserColour,
  getUserInitials,
  AppContext,
  useGetUsersByIdQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useState, useRef, useMemo, useContext } from "react";
import { createPortal } from "react-dom";
import LargeBarChartIcon from "../../assets/common/large-bar-chart-icon.svg";
import MinusCircleIcon from "../../assets/common/minus-circle-icon.svg";
import { ShareIcon } from "../../assets/icons/ShareIcon";
import { StandAloneInfoBanner } from "../../error-banner/InfoBanner";
import { CircleIcon, CircleIconSize } from "../../icons";
import styles from "./ShareModal.module.scss";

export const convertToOptions = (users: SharedUserDto[]): GroupedOption[] => [
  {
    options: users.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: `${user.firstName} ${user.lastName} ${user.emailAddress}`,
    })),
  },
];

export const getSelectedUsers = (
  selectedOptions: Option[],
  users: SharedUserDto[]
): string[] =>
  users
    .filter((user) =>
      selectedOptions.some((option) =>
        option.value.toString().includes(user.emailAddress)
      )
    )
    .map((user) => user.salesforceUserId);

const ShareModalHeader = ({
  subtitle,
  title,
  onClose,
}: {
  onClose: () => void;
  subtitle: string;
  title: string;
}) => (
  <div>
    <div className={styles.modalHeader}>
      <img
        alt="Bar chart"
        className={styles.modalIcon}
        src={LargeBarChartIcon}
      />
      <span className={styles.titleWrapper}>
        <Text className={styles.subTitle}>{subtitle}</Text>
        <Text className={styles.title}>{title}</Text>
      </span>
    </div>
    <Button
      className={styles.closeDialogButton}
      height={ButtonHeight.Small}
      onClick={() => onClose()}
      variant={ButtonVariant.Stealth}
    >
      <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close selection" />
    </Button>
    <hr className={styles.modalSeparator} />
  </div>
);

const ManageAccessHeader = ({
  title,
  onClose,
  onBack,
}: {
  onBack?: () => void;
  onClose: () => void;
  title: string;
}) => (
  <div>
    <div className={styles.modalHeader}>
      {onBack && (
        <StealthInlineIconButton
          className={styles.backDialogButton}
          iconGlyph={InlineIconGlyph.ArrowsBack}
          iconSize={IconSize.Large}
          iconText="Back to share"
          onClick={() => onBack()}
        />
      )}
      <span className={styles.manageAccessIcon}>
        <Icon
          colour="var(--qbit-colour-text-primary)"
          glyph={IconGlyph.AccountAndUserAccountMultiple}
          text={title}
        />
      </span>
      <Text className={styles.manageAccessTitle}>{title}</Text>
    </div>
    <Button
      className={styles.closeDialogButton}
      height={ButtonHeight.Small}
      onClick={() => onClose()}
      variant={ButtonVariant.Stealth}
    >
      <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close selection" />
    </Button>
    <hr className={styles.modalSeparator} />
  </div>
);

const ShareModalFooter = ({
  selectedUsers,
  isSharingLoading,
  onClose,
  onShareSubmit,
  resourceType,
  textAreaValue,
}: {
  isSharingLoading: boolean;
  onClose: () => void;
  onShareSubmit: (
    salesforceUserIds: string[],
    sharingNotes: string
  ) => Promise<void>;
  resourceType: string;
  selectedUsers: string[];
  textAreaValue: string;
}) => (
  <div>
    <Button
      disabled={selectedUsers.length < 1}
      height={ButtonHeight.XSmall}
      loading={isSharingLoading}
      onClick={async () => await onShareSubmit(selectedUsers, textAreaValue)}
      text={`Share ${resourceType}`}
      variant={ButtonVariant.Primary}
    />
    <Button
      className={styles.marginLeft}
      height={ButtonHeight.XSmall}
      onClick={() => onClose()}
      text="Cancel"
      variant={ButtonVariant.Secondary}
    />
  </div>
);

const ShareModalStandardContent = ({
  organisationUsers,
  resourceType,
  setSelectedUsers,
  setTextAreaValue,
}: {
  organisationUsers: SharedUserDto[];
  resourceType: string;
  setSelectedUsers: (value: string[]) => void;
  setTextAreaValue: (value: string) => void;
}) => (
  <div className={styles.modalContent}>
    <FormMultiBlock
      label={
        <div className={styles.modalContentLabel}>
          <ShareIcon />
          <Text>{`Share this ${resourceType}`}</Text>
        </div>
      }
    >
      <FormBlock>
        <Input className={styles.shareTypeaheadWrapper}>
          <TypeaheadMulti
            id="share-typeahead"
            noOptionsMessage={() => "No results found"}
            onChange={(selectedOptions) =>
              setSelectedUsers(
                getSelectedUsers(selectedOptions, organisationUsers)
              )
            }
            options={convertToOptions(organisationUsers)}
            placeholder="Add people, groups or email addresses"
          />
        </Input>
      </FormBlock>
      <FormBlock>
        <Input>
          <Textarea
            height={FormInputHeight.XSmall}
            id="share-textarea"
            onChange={(event) => setTextAreaValue(event.target.value)}
            placeholder="Include a note for your recipients (optional)"
            rows={6}
            width={FormInputWidth.Fill}
          />
        </Input>
      </FormBlock>
    </FormMultiBlock>
  </div>
);

const Username = ({ user }: { user: SharedUserDto }) => (
  <Text
    className={styles.username}
  >{`${user.firstName} ${user.lastName}`}</Text>
);

export type ManageAccessContentProps = {
  owner: SharedUserDto | undefined;
  ownerView: boolean;
  sharedWith: SharedUserDto[];
  unshare?: (userId: string) => Promise<unknown>;
};

export const ManageAccessContent = ({
  owner,
  ownerView,
  sharedWith,
  unshare,
}: ManageAccessContentProps) => (
  <div className={styles.manageAccessContent}>
    <h5 className={styles.subtitle}>People with access</h5>
    <div className={styles.listContainer}>
      {owner && (
        <div className={styles.row}>
          <CircleIcon
            color={getUserColour(owner.salesforceUserId)}
            content={getUserInitials(owner.firstName, owner.lastName)}
            size={CircleIconSize.Small}
          />
          <Username user={owner} />
          <Text className={styles.ownerLabel}>Owner</Text>
        </div>
      )}
      {sharedWith.map((user) => (
        <div className={styles.row} key={user.salesforceUserId}>
          <CircleIcon
            color={getUserColour(user.salesforceUserId)}
            content={getUserInitials(user.firstName, user.lastName)}
            size={CircleIconSize.Small}
          />
          <Username user={user} />
          {ownerView && (
            <Button
              className={styles.removeButton}
              height={ButtonHeight.XSmall}
              onClick={
                unshare
                  ? async () => await unshare(user.salesforceUserId)
                  : undefined
              }
              variant={ButtonVariant.Stealth}
            >
              <img alt="Remove" src={MinusCircleIcon} />
              <Text>Remove</Text>
            </Button>
          )}
        </div>
      ))}
    </div>
  </div>
);

export type ShareModalProps = {
  disabled?: boolean;
  enableTrueShare?: boolean;
  onClose: () => void;
  organisationUsers: SharedUserDto[] | undefined;
  owner: SharedUserDto | undefined;
  resourceType: string;
  share: (
    divisionName: string,
    salesforceUserIds: string[],
    sharingNotes: string
  ) => Promise<unknown>;
  sharedWithUserIds: string[] | undefined;
  showModal: boolean;
  subtitle: string;
  title: string;
  unshare?: (salesforceUserId: string) => Promise<unknown>;
};

const emptyArray: SharedUserDto[] = [];

export const ShareModal = ({
  disabled,
  organisationUsers = emptyArray,
  owner,
  share,
  unshare,
  resourceType,
  title,
  subtitle,
  showModal,
  onClose,
  enableTrueShare,
  sharedWithUserIds,
}: ShareModalProps) => {
  const { userState } = useContext(AppContext);
  const division = useDivision();
  const triggerRef = useRef<HTMLDivElement>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [isSharingLoading, setIsSharingLoading] = useState(false);
  const [showManageAccess, setShowManageAccess] = useState(false);

  const sharedWithUsers = useMemo(
    () =>
      organisationUsers.filter((user) =>
        sharedWithUserIds?.includes(user.salesforceUserId)
      ),
    [sharedWithUserIds, organisationUsers]
  );
  const onCloseInternal = useCallback(() => {
    setShowManageAccess(false);
    onClose();
  }, [onClose, setShowManageAccess]);
  const onBack = useCallback(
    () => setShowManageAccess(false),
    [setShowManageAccess]
  );
  const onViewManageAccess = useCallback(
    () => setShowManageAccess(true),
    [setShowManageAccess]
  );
  const onShareSubmit = useCallback(
    async (salesforceUserIds: string[], sharingNotes: string) => {
      setIsSharingLoading(true);

      await share(division.name, salesforceUserIds, sharingNotes)
        .then(() => {
          QbitEmitToast(
            <QbitToastMessage
              content={<span />}
              heading={<span>1 {resourceType} shared</span>}
              showCloseButton
              showIcon
              variant={MessageVariant.Success}
            />
          );
          onCloseInternal();
        })
        .catch(() =>
          QbitEmitToast(
            <QbitToastMessage
              content={<span />}
              heading={<span>failed to share {resourceType}</span>}
              showCloseButton
              showIcon
              variant={MessageVariant.Danger}
            />
          )
        )
        .finally(() => setIsSharingLoading(false));
    },
    [division, share, resourceType, onCloseInternal]
  );

  return (
    <>
      {/* React portal used to have modal appear over the entire page and grey out everything */}
      {createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div aria-hidden="true" onClick={(event) => event.stopPropagation()}>
          <Dialog
            className={styles.modal}
            footer={
              showManageAccess ? undefined : (
                <ShareModalFooter
                  isSharingLoading={isSharingLoading}
                  onClose={onCloseInternal}
                  onShareSubmit={onShareSubmit}
                  resourceType={resourceType}
                  selectedUsers={selectedUsers}
                  textAreaValue={textAreaValue}
                />
              )
            }
            header={
              showManageAccess ? (
                <ManageAccessHeader
                  onBack={onBack}
                  onClose={onCloseInternal}
                  title="Manage access"
                />
              ) : (
                <ShareModalHeader
                  onClose={onCloseInternal}
                  subtitle={subtitle}
                  title={title}
                />
              )
            }
            onClose={onCloseInternal}
            show={showModal && !disabled}
            titleId="share-modal"
            triggeredBy={triggerRef}
            width={DialogWidth.Small}
          >
            {showManageAccess ? (
              <ManageAccessContent
                owner={owner}
                ownerView={
                  userState.currentUser?.salesForceId ===
                  owner?.salesforceUserId
                }
                sharedWith={sharedWithUsers}
                unshare={unshare}
              />
            ) : (
              <>
                <ShareModalStandardContent
                  organisationUsers={organisationUsers}
                  resourceType={resourceType}
                  setSelectedUsers={setSelectedUsers}
                  setTextAreaValue={setTextAreaValue}
                />
                {enableTrueShare && (
                  <Button
                    className={styles.manageAccessButton}
                    height={ButtonHeight.Small}
                    onClick={onViewManageAccess}
                    variant={ButtonVariant.Stealth}
                  >
                    <Icon
                      glyph={IconGlyph.AccountAndUserAccountMultiple}
                      text="Manage access"
                    />
                    <Text>Manage access</Text>
                  </Button>
                )}
              </>
            )}
          </Dialog>
        </div>,
        document.body
      )}
    </>
  );
};

export type ShareReportModalProps = Pick<
  ShareModalProps,
  "disabled" | "onClose" | "organisationUsers" | "showModal"
> & {
  reportId: string | undefined;
  reportName: string | undefined;
  triggerLazyReload?: Function;
};

export const ShareReportModal = ({
  disabled,
  onClose,
  organisationUsers = emptyArray,
  showModal,
  reportId,
  reportName,
  triggerLazyReload,
}: ShareReportModalProps) => {
  const [shareReport] = useLazyShareReportQuery();
  const shareReportCallback = useCallback(
    async (
      divisionName: string,
      salesforceUserIds: string[],
      sharingNotes: string
    ) => {
      await shareReport({
        divisionName,
        payload: {
          salesforceUserIds,
          sharingNotes,
          sourceReportId: reportId ?? "",
        },
      });
      await triggerLazyReload?.();
    },
    [shareReport, reportId, triggerLazyReload]
  );

  return (
    <ShareModal
      disabled={disabled}
      onClose={onClose}
      organisationUsers={organisationUsers}
      owner={undefined}
      resourceType="report"
      share={shareReportCallback}
      sharedWithUserIds={undefined}
      showModal={showModal}
      subtitle="Reports"
      title={reportName ?? ""}
      unshare={undefined}
    />
  );
};

export type ViewAccessModalInternalProps = {
  disabled?: boolean;
  onClose: () => void;
  organisationUsers: SharedUserDto[] | undefined;
  ownerAndRecipientUsers: SharedUserDto[] | undefined;
  ownerId: string | undefined;
  sharedWithUserIds: string[] | undefined;
  showModal: boolean;
};

export const ViewAccessModalInternal = ({
  disabled,
  ownerAndRecipientUsers,
  onClose,
  organisationUsers,
  ownerId,
  sharedWithUserIds,
  showModal,
}: ViewAccessModalInternalProps) => {
  const { userState } = useContext(AppContext);
  const triggerRef = useRef<HTMLDivElement>(null);

  const sharedWithUsers = useMemo(() => {
    const sharedUsers =
      organisationUsers?.filter((user) =>
        sharedWithUserIds?.includes(user.salesforceUserId)
      ) ?? [];
    const currentUser = ownerAndRecipientUsers?.find(
      (user) => user.salesforceUserId === userState.currentUser?.salesForceId
    );
    if (currentUser) {
      sharedUsers.splice(0, 0, currentUser);
    }

    return sharedUsers;
  }, [
    sharedWithUserIds,
    organisationUsers,
    ownerAndRecipientUsers,
    userState.currentUser,
  ]);
  const owner = useMemo(
    () =>
      ownerAndRecipientUsers?.find((user) => user.salesforceUserId === ownerId),
    [ownerId, ownerAndRecipientUsers]
  );

  return (
    <>
      {createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div aria-hidden="true" onClick={(event) => event.stopPropagation()}>
          <Dialog
            className={styles.modal}
            header={
              <ManageAccessHeader onClose={onClose} title="View access" />
            }
            onClose={onClose}
            show={showModal && !disabled}
            titleId="view-access-modal"
            triggeredBy={triggerRef}
            width={DialogWidth.Small}
          >
            <>
              <StandAloneInfoBanner className={styles.viewAccessInfoPanel}>
                Viewers can only see who else the group has been shared with and
                cannot manage access to the group.
              </StandAloneInfoBanner>
              <ManageAccessContent
                owner={owner}
                ownerView={false}
                sharedWith={sharedWithUsers}
              />
            </>
          </Dialog>
        </div>,
        document.body
      )}
    </>
  );
};

export type ViewAccessModalProps = {
  disabled?: boolean;
  onClose: () => void;
  ownerId: string | undefined;
  sharedWithUserIds: string[] | undefined;
  showModal: boolean;
};

export const ViewAccessModal = ({
  disabled,
  onClose,
  ownerId,
  sharedWithUserIds,
  showModal,
}: ViewAccessModalProps) => {
  const { userState } = useContext(AppContext);
  const division = useDivision();

  // Refetch original owner, just in case they have been deactivated.
  // Deactivated user's shares are still accessible to recipients so they have a chance to copy them.
  const salesforceUserIds = [];
  if (ownerId) {
    salesforceUserIds.push(ownerId);
  }

  if (
    userState.currentUser &&
    sharedWithUserIds?.includes(userState.currentUser.salesForceId)
  ) {
    salesforceUserIds.push(userState.currentUser.salesForceId);
  }

  const { data: organisationUsers } = useGetOrganizationUsersQuery({
    divisionName: division.name,
  });
  const { data: ownerAndRecipientUsers } = useGetUsersByIdQuery(
    {
      payload: {
        SalesforceUserIds: salesforceUserIds,
      },
    },
    {
      skip: salesforceUserIds.length === 0,
    }
  );
  return (
    <ViewAccessModalInternal
      disabled={disabled}
      onClose={onClose}
      organisationUsers={organisationUsers}
      ownerAndRecipientUsers={ownerAndRecipientUsers}
      ownerId={ownerId}
      sharedWithUserIds={sharedWithUserIds}
      showModal={showModal}
    />
  );
};

ShareModal.Reports = ShareReportModal;

export default ShareModal;
