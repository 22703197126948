import { Text } from "@qbit/react";
import { useMemo } from "react";
import StylisedSelect from "./StylisedSelect";
import {
  type ParameterOption,
  type SingleSelectParameterConfiguration,
} from "./api/parameterConfiguration";

export type ParameterSingleSelectProps<TOptionType> = {
  configuration: SingleSelectParameterConfiguration<TOptionType>;
  disabled?: boolean;
  onSelection: (selection: TOptionType) => void;
  selection: TOptionType;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint -- This is required for generic components
export const ParameterSingleSelect = <TOptionType extends unknown>({
  configuration,
  onSelection,
  selection,
  ...properties
}: ParameterSingleSelectProps<TOptionType>) => {
  const selectionOption = useMemo(
    () => configuration.options.find((opt) => opt.value === selection),
    [selection, configuration.options]
  );

  const currentDisplayName = useMemo(
    () => selectionOption?.displayName,
    [selectionOption]
  );

  // Callback when selection changed
  const selectionChangedHandler = (newSelection: TOptionType | undefined) => {
    if (newSelection !== undefined) {
      onSelection(newSelection);
    }
  };

  const itemDisplaySelector = (
    option: ParameterOption<TOptionType> | undefined
  ) => <Text>{option?.displayName ?? ""}</Text>;

  return (
    <StylisedSelect
      buttonText={currentDisplayName ?? ""}
      {...properties}
      disableLastSelected={false}
      disabled={properties.disabled}
      initialSelection={[selectionOption]}
      isMultiSelect={false}
      itemDisplaySelector={itemDisplaySelector}
      itemKeySelector={(item) => JSON.stringify(item?.value)}
      items={configuration.options}
      onSelectionChanged={(newSelection) =>
        selectionChangedHandler(newSelection[0]?.value)
      }
    />
  );
};
