import { Text } from "@qbit/react";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../FilterSummary.module.css";

export const LocationFilterSummary = () => {
  let text = <Text> None </Text>;
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.location,
        state,
      }),
      skip: !division.name,
    }
  );

  const [globalParameters] = useGlobalParameters();
  const locations = globalParameters.location;

  if (locations) {
    const level = reportConfig.configuration?.levels.find(
      (lvl) => lvl.value === (locations[0]?.level ?? null)
    );

    const selections = locations.map(
      (location) =>
        reportConfig.configuration?.options.find(
          (opt) =>
            opt.value?.level === location?.level &&
            opt.value?.code === location?.code
        )?.displayName
    );

    text = (
      <span>
        <strong>{level?.displayName}:</strong> {selections.join(", ")}
      </span>
    );
  }

  return <div className={styles.summaryText}>{text}</div>;
};
