import { type ReportParametersDto } from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ColorRankType } from "components-ui/src/tables/common/models/ColorRankType";
import { HeatMap } from "components-ui/src/tables/heat-map/HeatMap";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import {
  focalItemsHasDuplicateNames,
  getFocalItemLabels,
} from "../../common/utils/focal-item-labels-utils";
import { HEAT_MAP_COLOURS } from "../../common/utils/heat-map-colours";
import { type RootState } from "../../store";
import { TimeOfDayDayOfWeekFeatureFlags } from "../constants/time-of-day-day-of-week-feature-flags";
import { useGetTimeOfDayDayOfWeekHeatMapDataQuery } from "../services/time-of-day-day-of-week-heatmap-api-slice";
import { onHeatmapDataReceived } from "../services/time-of-day-day-of-week-slice";
import {
  selectFocalItemParents,
  selectFocalItems,
  selectLocalParametersInitialised,
  selectLocalParametersSelections,
} from "../services/time-of-day-day-of-week-slice-selectors";
import { formatHourId, formatDayId } from "../utils/chart-utils";
import getUserSelections from "../utils/getUserSelections";
import { csvTransformation } from "./HeatmapReportletCsvTransformation";
import { TimeOfDayDayOfWeekHeatmapFilters } from "./TimeOfDayDayOfWeekHeatmapFilters";
import styles from "./TimeOfDayDayOfWeekHeatmapReportlet.module.css";

export type TimeofDayReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

export const TimeOfDayDayOfWeekHeatmapReportlet = ({
  infoPanelSummary,
}: TimeofDayReportletProps) => {
  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);
  const localParameterSelections = useSelector(selectLocalParametersSelections);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const { metricFormatter } = getNumberFormat();
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();
  const { reportId, reportletData, heatmapDataRecieved, heatmapFilters } =
    useSelector((state: RootState) => ({
      localParametersInitialised:
        state.timeOfDayDayOfWeek.localParametersInitialised,
      reportId: state.timeOfDayDayOfWeek.metaData.reportId,
      reportletData: state.timeOfDayDayOfWeek.heatmapReportletData,
      heatmapDataRecieved: state.timeOfDayDayOfWeek.heatmapDataRecieved,
      heatmapFilters: state.timeOfDayDayOfWeek.heatmapFilters,
    }));

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[TimeOfDayDayOfWeekFeatureFlags.ReportExport] ?? false;

  const requestPayload = {
    focalItem: heatmapFilters.activeFocalItemOption?.code
      ? heatmapFilters.activeFocalItemOption
      : focalItems[0],
    localSelectedValues: getUserSelections(localParameterSelections),
    reportId,
  };

  const isDataLoaded =
    localParametersInitialised && reportId && focalItems.length;

  const { data: heatmapData, isFetching: isChartDataFetching } =
    useGetTimeOfDayDayOfWeekHeatMapDataQuery(
      {
        divisionName: activeDivisionName,
        requestPayload,
      },
      {
        skip: !isDataLoaded,
      }
    );

  useEffect(() => {
    if (heatmapData) {
      dispatch(onHeatmapDataReceived(heatmapData));
    }
  }, [dispatch, heatmapData]);
  const chartContainerRef = useRef<HTMLElement>();
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Time_of_day_day_of_week_heatmap_Chart_${localParameterSelections.TimePeriodLength}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );

  const focalItemLabel: string = useMemo(() => {
    const focalItem = focalItems.find(
      (item) =>
        item.nodeNumber === heatmapFilters.activeFocalItemOption?.nodeNumber
    );

    if (focalItem === undefined) {
      return "";
    }

    if (!focalItemsHasDuplicateNames(focalItems)) {
      return `(${hierarchyLevelDisplayLabel(focalItem.shortName)}) ${
        focalItem.name
      }`;
    }

    const labels = getFocalItemLabels(focalItems, focalItemParents);
    return `(${hierarchyLevelDisplayLabel(focalItem.shortName)}) ${
      labels[focalItem.nodeNumber] ?? focalItem.name
    }`;
  }, [
    focalItemParents,
    focalItems,
    heatmapFilters.activeFocalItemOption?.nodeNumber,
  ]);

  const chartCsvTransformationCallback = useCallback(
    () => csvTransformation(reportletData, currencySymbol, heatmapFilters),
    [reportletData, currencySymbol, heatmapFilters]
  );

  const parameterSummary = useMemo(
    () => [
      {
        name: "Focal Item",
        value: focalItemLabel,
      },
      { name: "Time", value: localParameterSelections.FocusPeriod as string },
      { name: "Metric", value: localParameterSelections.Metric.label },
      { name: "Promotion", value: localParameterSelections.Promotion.label },
      {
        name: "Segmentation",
        value: localParameterSelections.Segmentation.label,
      },
      {
        name: "Customer Segment",
        value:
          localParameterSelections.Segmentation.label === ""
            ? ""
            : heatmapFilters.segmentsDropdownOptions[
                heatmapFilters.activeSegmentIndex
              ],
      },
      { name: "Channel", value: localParameterSelections.Channel.label },
      {
        name: "Checkout type",
        value: localParameterSelections.CheckoutType?.label ?? "",
      },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localParameterSelections.LocationHierarchy.shortName
        )}) ${localParameterSelections.LocationHierarchy.name}`,
      },
    ],
    [
      focalItemLabel,
      localParameterSelections.FocusPeriod,
      localParameterSelections.Metric.label,
      localParameterSelections.Promotion.label,
      localParameterSelections.Segmentation.label,
      localParameterSelections.Channel.label,
      localParameterSelections.CheckoutType?.label,
      localParameterSelections.LocationHierarchy.shortName,
      localParameterSelections.LocationHierarchy.name,
      heatmapFilters.segmentsDropdownOptions,
      heatmapFilters.activeSegmentIndex,
    ]
  );

  return (
    <ReportletAccordion
      subtitle="Understand performance for each day of the week, in hourly increments for your selected item."
      title="Time of day, day of week heatmap"
    >
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={isChartDataFetching || !heatmapDataRecieved}
          minHeight="460px"
          noData={
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            reportletData.segmentedData === undefined ||
            reportletData.segmentedData.length === 0 ||
            heatmapFilters.activeFocalItemOption?.code === undefined ||
            focalItems.length === 0
          }
        >
          <div className={styles.reportletWrapper}>
            <div className={styles.chartOptionsContainer}>
              <TimeOfDayDayOfWeekHeatmapFilters />
              <div className={styles.exportOptionMarginTop}>
                <ChartOptions
                  filename={exportFilename}
                  getCSVData={chartCsvTransformationCallback}
                  getElementToExport={() => chartContainerRef.current}
                  isFeatureEnabled={isExportEnabled}
                  localParameters={parameterSummary}
                  reportParameters={infoPanelSummary}
                />
              </div>
              <div className={styles.driverTreeScrollableArea}>
                <div className={styles.driverTreeResizableContainer}>
                  <ChartFooterWrapper
                    height="360px"
                    parameters={parameterSummary}
                    ref={chartContainerRef}
                  >
                    <div className={styles.conditionalFlexAlignCenter}>
                      <div className={styles.conditionalMinContent}>
                        <HeatMap
                          colorRange={HEAT_MAP_COLOURS}
                          colorRankMethod={ColorRankType.LINEAR}
                          formatter={(value: number | string | null) =>
                            metricFormatter(reportletData.format, value)
                          }
                          leftColumnHeaders={reportletData.segmentedData[
                            heatmapFilters.activeSegmentIndex
                          ]?.dayData.map(
                            (ele) => formatDayId[ele.day.toUpperCase()]
                          )}
                          tableData={reportletData.segmentedData[
                            heatmapFilters.activeSegmentIndex
                          ]?.dayData.map((ele) => ele.values)}
                          topCornerHeader="Day"
                          topRowAlignRight
                          topRowHeaders={reportletData.hoursMetaData.map(
                            (hourId) => formatHourId(hourId)
                          )}
                        />
                      </div>
                    </div>
                  </ChartFooterWrapper>
                </div>
              </div>
            </div>
          </div>
        </NoDataChartWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};

export default TimeOfDayDayOfWeekHeatmapReportlet;
