import {
  useGetOrganizationUsersQuery,
  useGetUsersByIdQuery,
  useShareCustomerGroupMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { ShareModal } from "components-ui/src/modals";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShareCustomerGroup,
  setShareCustomerGroup,
} from "../../states/group-list-slice";

export const ShareCustomerGroupModal = () => {
  const dispatch = useDispatch();
  const division = useDivision();
  const customerGroupToShare = useSelector(selectShareCustomerGroup);

  const entityType = "Group";
  const groupId = customerGroupToShare?.id;
  const groupName = customerGroupToShare?.name;
  const ownerId = customerGroupToShare?.userId;
  const sharedWithUserIds = customerGroupToShare?.sharedWithUserIds;
  const { data: organisationUsers } = useGetOrganizationUsersQuery({
    divisionName: division.name,
  });
  const { data: owner } = useGetUsersByIdQuery(
    {
      payload: {
        SalesforceUserIds: ownerId ? [ownerId] : [],
      },
    },
    {
      skip: !ownerId,
    }
  );
  const [shareCustomerGroup] = useShareCustomerGroupMutation();
  const shareGroupCallback = useCallback(
    async (
      divisionName: string,
      salesforceUserIds: string[],
      sharingNotes: string
    ) =>
      await shareCustomerGroup({
        divisionName,
        payload: {
          entityType,
          userIds: salesforceUserIds,
          sharingNotes,
          sourceId: groupId ?? "",
        },
      }),
    [shareCustomerGroup, groupId, entityType]
  );
  return (
    <ShareModal
      onClose={() => dispatch(setShareCustomerGroup(undefined))}
      organisationUsers={organisationUsers}
      owner={owner ? owner[0] : undefined}
      resourceType={entityType.toLowerCase()}
      share={shareGroupCallback}
      sharedWithUserIds={sharedWithUserIds}
      showModal={Boolean(customerGroupToShare)}
      subtitle={entityType.toUpperCase()}
      title={groupName ?? ""}
    />
  );
};
