import { Spinner } from "@qbit/react";
import {
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  UnknownTrackingPropertyValue,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { differenceInWeeks } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import Zod from "zod";
import { ParameterSingleSelect } from "../../fast-report/ParameterSingleSelect";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";

const MAX_GROWTH_METRIC_WEEKS = 52;

export const MetricValueSchema = Zod.string();
export type MetricValue = Zod.infer<typeof MetricValueSchema>;

export const MetricDropdown = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();

  const [globalParameters] = useGlobalParameters();

  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.customerProfiling,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.CustomerProfilingMetrics,
    MetricValueSchema,
    (config) => config.reports.customerProfiling?.metrics
  );

  const focusPeriodWeeks = useMemo(() => {
    if (globalParameters.focusPeriod) {
      return (
        differenceInWeeks(
          new Date(globalParameters.focusPeriod.endDate),
          new Date(globalParameters.focusPeriod.startDate)
        ) + 1
      );
    }

    return 0;
  }, [globalParameters.focusPeriod]);

  useEffect(() => {
    if (focusPeriodWeeks > MAX_GROWTH_METRIC_WEEKS) {
      const selectedMetric = reportConfig.configuration?.metricMetadata.find(
        (option) => option.key === selection
      );
      if (selectedMetric?.isGrowthMetric) {
        setSelection(reportConfig.configuration?.metrics?.defaultOption ?? "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- causes infinite loop to include selections/setSelections
  }, [focusPeriodWeeks, reportConfig.configuration]);

  const metricOptions = useMemo(() => {
    let options = reportConfig.configuration?.metrics?.options;

    if (focusPeriodWeeks > MAX_GROWTH_METRIC_WEEKS) {
      options = options?.filter(
        (option) =>
          !reportConfig.configuration?.metricMetadata.find(
            (metadata) => metadata.key === option.value
          )?.isGrowthMetric
      );
    }

    return options;
  }, [focusPeriodWeeks, reportConfig.configuration]);

  const selectionChangedHandler = useCallback(
    (value: string) => {
      const parameter = reportConfig.configuration?.metrics?.options.find(
        (option) => option.value === value
      );
      eventTrackingService.trackEvent(
        TrackingComponent.FastReportingCustomerProfilingReport,
        TrackingEvent.Parameters,
        GenericTrackingProperties.single(
          ParametersTrackingProperty.MetricSelected,
          parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
        )
      );

      setSelection(value);
    },
    [
      eventTrackingService,
      reportConfig.configuration?.metrics?.options,
      setSelection,
    ]
  );

  return reportConfig.configuration?.metrics && selection ? (
    <ParameterSingleSelect
      configuration={{
        ...reportConfig.configuration.metrics,
        options: metricOptions ?? [],
      }}
      data-cy="MetricSelector"
      onSelection={selectionChangedHandler}
      selection={selection}
    />
  ) : (
    <Spinner />
  );
};
