import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import HighChartsWithPinnableTooltips from "components-ui/src/charts/highcharts-react/HighChartsWithPinnableTooltips";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import {
  TooltipHTML,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import type Highcharts from "highcharts";
import { useCallback } from "react";
import {
  getBlackColour,
  getSeriesColour,
} from "./PerformanceReportSeriesColours";

export type PerformanceChartSeries = {
  data: Array<number | null>;
  displayName: string;
  formatter: FormatterFunction;
  id: string;
  isBlackColour: boolean;
  showDataLabels: boolean;
  yAxisId: string | undefined;
};

export type PerformanceChartYAxisOptions = {
  displayName: string;
  format: string;
  formatter: FormatterFunction;
  id: string;
  isGrowth: boolean;
};

type PerformanceChartProps = {
  barSeriesId?: string;
  comparisonBarSeriesId?: string;
  dates: string[];
  id: string;
  series: PerformanceChartSeries[];
  yAxis: PerformanceChartYAxisOptions[];
};

const defaultSeriesOptions = (item: PerformanceChartSeries) =>
  ({
    connectNulls: true,
    data: Array.from(item.data),
    marker: { enabled: false, symbol: "circle" },
    name: item.displayName,
    tooltip: {
      headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
      pointFormatter(this) {
        return `<tr><td>${
          this.series.name
        }</td> <td style="text-align: right"><b>${item.formatter(
          this.y
        )}</b></td></tr>`;
      },
      footerFormat: "</table>",
    },
    yAxis: item.yAxisId,
    label: {
      enabled: false,
    },
    dataLabels: {
      enabled: item.showDataLabels,
      formatter(this) {
        return this.y === 0 ? null : item.formatter(this.y);
      },
    },
  } as Pick<
    Highcharts.SeriesLineOptions,
    | "connectNulls"
    | "data"
    | "dataLabels"
    | "marker"
    | "name"
    | "tooltip"
    | "yAxis"
  >);

const PerformanceChart = ({
  dates,
  series,
  yAxis,
  barSeriesId,
  comparisonBarSeriesId,
  id,
}: PerformanceChartProps) => {
  const mapToPerformanceTrendSeries =
    useCallback((): Highcharts.SeriesOptionsType[] => {
      let seriesColourIndex = 0;
      const highchartsSeries = series.map((item) => {
        switch (item.id) {
          case barSeriesId:
            return {
              ...defaultSeriesOptions(item),
              borderWidth: 0,
              color: "var(--qbit-colour-brand-500)",
              fillColor: "var(--qbit-colour-brand-500)",
              grouping: false,
              pointPadding: 0.2,
              type: "column",
              zIndex: 1,
            } as Highcharts.SeriesColumnOptions;
          case comparisonBarSeriesId:
            return {
              ...defaultSeriesOptions(item),
              borderWidth: 0,
              color: "var(--qbit-colour-shade-4)",
              fillColor: "var(--qbit-colour-shade-4)",
              grouping: false,
              pointPadding: -0.2,
              type: "column",
              zIndex: 0,
            } as Highcharts.SeriesColumnOptions;
          default:
            return {
              ...defaultSeriesOptions(item),
              color: item.isBlackColour
                ? getBlackColour()
                : getSeriesColour(seriesColourIndex++),
              dashStyle: "Solid",
              type: "line",
              zIndex: 2,
            } as Highcharts.SeriesLineOptions;
        }
      });

      return highchartsSeries;
    }, [series, barSeriesId, comparisonBarSeriesId]);

  const options: HighchartsReactProps = {
    ...defaultOptions,
    plotOptions: {
      line: {
        // white border around coloured series lines
        shadow: {
          color: "white",
          offsetX: 0,
          offsetY: 0,
          opacity: 100,
          width: 3,
        },
      },
    },
    series: mapToPerformanceTrendSeries(),
    tooltip: {
      ...defaultOptions.tooltip,
      ReactFormatter: (data) => {
        const tooltipData = data.points?.map((pt, index: number) => ({
          color: String(pt.color),
          name: pt.series.name,
          value: series[index]?.formatter(pt.y),
        }));
        return TooltipHTML(tooltipData, data.x);
      },
    },
    xAxis: {
      categories: dates,
      crosshair: {
        color: "var(--qbit-colour-secondary)",
        dashStyle: "Dash",
        width: 1,
        zIndex: 1,
      },
      labels: {
        rotation: 0,
      },
      tickInterval: Math.ceil(dates.length / 6),
    },
    yAxis: yAxis.map(
      (definition, index) =>
        ({
          id: definition.id,
          labels: {
            formatter({ value }) {
              return definition.formatter(value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
              fontSize: "0.75rem",
            },
          },
          opposite: index % 2 > 0,
          title: {
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontWeight: "var(--qbit-font-weight-medium)",
            },
            text: definition.displayName,
          },
          min: definition.isGrowth ? undefined : 0,
        } as Highcharts.YAxisOptions)
    ),
    chart: {
      zooming: {
        type: undefined,
      },
    },
  };

  return (
    <div id={id}>
      <HighChartsWithPinnableTooltips options={options} />
    </div>
  );
};

export default PerformanceChart;
