import { type DeleteItems } from "./DeleteDialog";

export const isSingleDelete = (itemIds: DeleteItems) => {
  const keys = Object.keys(itemIds);
  let totalItems = 0;
  for (const key of keys) {
    totalItems += itemIds[key as keyof DeleteItems]?.length ?? 0;
  }

  return totalItems === 1;
};

export const hasMultipleDeleteTypes = (itemIds: DeleteItems) =>
  Object.keys(itemIds).filter(
    (key) => itemIds[key as keyof DeleteItems]?.length ?? 0
  ).length > 1;

const singleDeletedMessage = (itemType: string, itemsLength: number) =>
  itemsLength === 1
    ? `${itemType}`
    : `${itemsLength} ${itemType.toLowerCase()}s`;

const multipleDeletedMessage = (itemType: string, itemsLength: number) =>
  itemsLength === 1
    ? `1 ${itemType.toLowerCase()}`
    : `${itemsLength} ${itemType.toLowerCase()}s`;

const getBaseMessage = (itemIds: DeleteItems) => {
  const messages = [];
  // If there's more than one type of item to delete, adjust the message format.
  if (hasMultipleDeleteTypes(itemIds)) {
    if (itemIds.folderIds?.length) {
      messages.push(multipleDeletedMessage("Folder", itemIds.folderIds.length));
    }

    if (itemIds.groupIds?.length) {
      messages.push(multipleDeletedMessage("Group", itemIds.groupIds.length));
    }

    if (itemIds.reportIds?.length) {
      messages.push(multipleDeletedMessage("Report", itemIds.reportIds.length));
    }

    if (itemIds.savedParametersIds?.length) {
      messages.push(
        multipleDeletedMessage(
          "Saved selection",
          itemIds.savedParametersIds.length
        )
      );
    }
  } else {
    if (itemIds.folderIds?.length) {
      messages.push(singleDeletedMessage("Folder", itemIds.folderIds.length));
    }

    if (itemIds.groupIds?.length) {
      messages.push(singleDeletedMessage("Group", itemIds.groupIds.length));
    }

    if (itemIds.reportIds?.length) {
      messages.push(singleDeletedMessage("Report", itemIds.reportIds.length));
    }

    if (itemIds.savedParametersIds?.length) {
      messages.push(
        singleDeletedMessage(
          "Saved selection",
          itemIds.savedParametersIds.length
        )
      );
    }
  }

  return messages.join(" and ");
};

export const getDeletedMessage = (itemIds: DeleteItems): string =>
  `${getBaseMessage(itemIds)} ${
    isSingleDelete(itemIds) ? "has" : "have"
  } been deleted.`;

export const getDeleteWarningMessage = (itemIds: DeleteItems): string =>
  `Are you sure you want to **delete ${
    isSingleDelete(itemIds) ? "this " : "these "
  }${getBaseMessage(itemIds).toLocaleLowerCase()}?**`;

export const getDeleteFailedMessage = (itemIds: DeleteItems): string =>
  `${getBaseMessage(itemIds)} ${
    isSingleDelete(itemIds) ? "has" : "have"
  } failed to be deleted.`;

export const getRemovedMessage = (itemIds: DeleteItems): string =>
  `${getBaseMessage(itemIds)} ${
    isSingleDelete(itemIds) ? "has" : "have"
  } been removed.`;

export const getRemoveWarningMessage = (itemIds: DeleteItems): string =>
  `Are you sure you want to **remove ${
    isSingleDelete(itemIds) ? "this " : "these "
  }${getBaseMessage(itemIds).toLocaleLowerCase()}?**
    \nRemoving this ${getBaseMessage(itemIds).toLowerCase()}
    will revoke your access and will require the ${getBaseMessage(
      itemIds
    ).toLowerCase()}
    owner to share it with you again.`;

export const getRemoveFailedMessage = (itemIds: DeleteItems): string =>
  `${getBaseMessage(itemIds)} ${
    isSingleDelete(itemIds) ? "has" : "have"
  } failed to be removed.`;
