import {
  type HierarchySliceNodeDto,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { EMPTY_HIERARCHY_SLICE_NODE } from "../../common/constants";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  buildNestedRows,
  insertChildrenIntoPosition,
} from "../../common/utils/data-table-utils";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type TimeOfDayDayOfWeekHeatmapFilters } from "../models/TimeOfDayDayOfWeekHeatmap";
import { type ChartSelections } from "../models/chart-selections";
import { type DayOfWeekResponseDto } from "../models/day-of-week-chart";
import { type HeatMapResponseDto } from "../models/reportlet-response";
import { type TimeOfDayResponseDto } from "../models/time-of-day-chart";
import {
  type FetchChildrenTableResponse,
  type InitialTableResponse,
  type RefetchTableResponse,
  type TimeOfDayDayOfWeekTableMetaData,
  type TimeOfDayDayOfWeekTableRow,
} from "../models/time-of-day-day-of-week-data-table-models";
import { getDefaultSelections } from "../utils/getDefaultSelections";
import { type LocalParametersResponse } from "./time-of-day-day-of-week-local-parameters-api-slice";

const FocalItemsView = "Focal item(s)";
const SegmentsView = "Customer segments";
const DaysView = "Days";

const getPersistenceKey = (reportId: string) =>
  `time-of-day-day-of-week-${reportId}`;

export type TimeOfDayDayOfWeekLocalSelections = {
  Channel: PanelOption;
  // making this optional to avoid this parameter not added in payload for all divisions
  CheckoutType?: PanelOption;
  FocusPeriod?: string;
  LocationHierarchy: LocalHierarchyNodeSelection;
  Metric: PanelOption;
  Promotion: PanelOption;
  Segmentation: PanelOption;
  TimePeriodLength?: string;
};

export type TimeOfDayDayOfWeekPersistedSelections = {
  focalItems: HierarchySliceNodeDto[];
  localParametersSelections: TimeOfDayDayOfWeekLocalSelections;
};

export type TimeOfDayDayOfWeekState = {
  chartSelections: ChartSelections;
  dayOfWeekChartData: DayOfWeekResponseDto;
  // FOCAL ITEM TABLE
  focalItemParents: HierarchySliceNodeDto[];
  focalItemSearchQuery: string;
  focalItemTableInitialised: boolean;
  focalItemTableMetaData: TimeOfDayDayOfWeekTableMetaData;
  focalItemTableRows: TimeOfDayDayOfWeekTableRow[];
  heatmapDataRecieved: boolean;
  heatmapFilters: TimeOfDayDayOfWeekHeatmapFilters;
  heatmapReportletData: HeatMapResponseDto;
  localParametersConfig: SidePanelParameter[];
  localParametersInitialised: boolean;
  metaData: {
    reportId: string;
    reportName: string;
  };
  persistedSelections: TimeOfDayDayOfWeekPersistedSelections;
  persistedSelectionsLoaded: boolean;
  showDayOfWeekChartDataLabels: boolean;
  showTimeOfDayChartDataLabels: boolean;
  timeOfDayChartData: TimeOfDayResponseDto;
};

export const initialState: TimeOfDayDayOfWeekState = {
  focalItemParents: [],
  focalItemTableInitialised: false,
  focalItemTableRows: [],
  focalItemSearchQuery: "",
  focalItemTableMetaData: {
    days: [],
    metric: {
      label: "",
      format: "",
    },
  },
  metaData: {
    reportId: "",
    reportName: "",
  },
  persistedSelections: {
    focalItems: [],
    localParametersSelections: {
      Channel: {
        value: "",
        label: "",
      },
      Metric: {
        value: "",
        label: "",
      },
      LocationHierarchy: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
        isDefault: false,
      },
      Promotion: {
        value: "",
        label: "",
      },
      Segmentation: {
        value: "",
        label: "",
      },
      FocusPeriod: "",
      TimePeriodLength: "",
      CheckoutType: {
        label: "",
        value: "",
      },
    },
  },
  persistedSelectionsLoaded: false,
  localParametersConfig: [],
  localParametersInitialised: false,
  heatmapDataRecieved: false,
  heatmapFilters: {
    activeFocalItemOption: EMPTY_HIERARCHY_SLICE_NODE,
    activeSegmentIndex: 0,
    focalItemDropdownOptions: [],
    isSegmentsDropdownDisabled: false,
    segmentsDropdownOptions: [],
  },
  heatmapReportletData: {
    focalItem: EMPTY_HIERARCHY_SLICE_NODE,
    format: "",
    hoursMetaData: [],
    metric: "",
    segmentedData: [
      {
        dayData: [],
        segmentName: "",
      },
    ],
    segmentsMetaData: [],
  },
  chartSelections: {
    viewBy: {
      dayOfWeekOptions: [FocalItemsView],
      timeOfDayOptions: [FocalItemsView, DaysView],
      selected: FocalItemsView,
    },
    benchmark: {
      options: [],
      selected: undefined,
      isEnabled: false,
    },
    segment: {
      options: [],
      selected: undefined,
      isEnabled: false,
    },
    focalItem: {
      selected: undefined,
      isEnabled: false,
    },
    dayOfWeek: {
      options: [],
      selected: undefined,
      isEnabled: false,
    },
    tabSelected: "Day of Week",
  },
  dayOfWeekChartData: {
    metric: "",
    format: "",
    daysMetadata: [],
    segmentsMetadata: [],
    focalItems: [],
    benchmarkItems: [],
    itemData: [],
  } as DayOfWeekResponseDto,
  timeOfDayChartData: {
    metric: "",
    format: "",
    daysMetadata: [],
    segmentsMetadata: [],
    focalItems: [],
    benchmarkItems: [],
    timeOfDayData: [],
    hoursMetadata: [],
  } as TimeOfDayResponseDto,
  showDayOfWeekChartDataLabels: false,
  showTimeOfDayChartDataLabels: false,
};

const isNodeRemoved = (
  nodes: HierarchySliceNodeDto[],
  node: number | undefined
) => nodes.findIndex((item) => item.nodeNumber === node) === -1;

export const TimeOfDayDayOfWeekSlice = createSlice({
  initialState,
  name: "time-of-day-day-of-week",
  reducers: {
    onMetadataSuccess: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<{ reportId: string; reportName: string }>
    ) => {
      state.metaData = payload;
    },
    onLocalParametersReceived: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<LocalParametersResponse>
    ) => {
      state.localParametersConfig = payload.localParameters;

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParametersSelections =
          getDefaultSelections(payload.localParameters);

        state.persistedSelections.focalItems = payload.defaultNodes;

        persistSelections(
          getPersistenceKey(state.metaData.reportId),
          state.persistedSelections
        );
      }

      state.localParametersInitialised = true;
    },
    onMetricChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.Metric = payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onChannelChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.Channel = {
        label: payload.label,
        value: payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onPromotionChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.Promotion = {
        label: payload.label,
        value: payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onCheckoutTypeChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.CheckoutType = {
        label: payload.label,
        value: payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localParametersSelections.LocationHierarchy =
        payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onSegmentationChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.Segmentation =
        payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onInitialTableDataReceived: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<InitialTableResponse>
    ) => {
      state.focalItemTableMetaData = payload.tableMetaData;
      state.focalItemTableRows = buildNestedRows(payload.tableRows);
      state.focalItemTableInitialised = true;
    },
    onTableChildrenDataRecieved: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<FetchChildrenTableResponse>
    ) => {
      state.focalItemTableRows = insertChildrenIntoPosition(
        state.focalItemTableRows,
        payload.tableRows,
        payload.parentNode
      );
    },
    onTableDataRecieved: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<RefetchTableResponse>
    ) => {
      state.focalItemTableMetaData = payload.tableMetaData;
      state.focalItemTableRows = payload.isSearching
        ? payload.tableRows
        : buildNestedRows(payload.tableRows);
    },
    onHeatmapDataReceived: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<HeatMapResponseDto>
    ) => {
      state.heatmapReportletData = payload;
      state.heatmapFilters.segmentsDropdownOptions = payload.segmentsMetaData;
      state.heatmapFilters.activeSegmentIndex = 0;
      state.heatmapFilters.activeFocalItemOption = payload.focalItem;
      state.heatmapDataRecieved = true;
    },
    onFocalItemHeatmapSelectionChange: (
      state: TimeOfDayDayOfWeekState,
      action: PayloadAction<number>
    ) => {
      state.heatmapFilters.activeFocalItemOption = {
        ...state.heatmapFilters.focalItemDropdownOptions.find(
          (item) => item.nodeNumber === action.payload
        ),
      } as HierarchySliceNodeDto | undefined;
    },
    onFocalItemChange: (
      state: TimeOfDayDayOfWeekState,
      action: PayloadAction<HierarchySliceNodeDto[]>
    ) => {
      state.persistedSelections.focalItems = action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
      if (action.payload.length === 0) {
        state.heatmapFilters.activeFocalItemOption = undefined;
      } else {
        if (state.heatmapFilters.activeFocalItemOption?.code === undefined) {
          state.heatmapFilters.activeFocalItemOption = action.payload[0];
        }

        if (
          action.payload.findIndex(
            (item) =>
              item.nodeNumber ===
              state.heatmapFilters.activeFocalItemOption?.nodeNumber
          ) === -1
        ) {
          const removedOptionIndex =
            state.heatmapFilters.focalItemDropdownOptions.findIndex(
              (item) =>
                item.nodeNumber ===
                state.heatmapFilters.activeFocalItemOption?.nodeNumber
            );

          state.heatmapFilters.activeFocalItemOption =
            removedOptionIndex > 0
              ? state.heatmapFilters.focalItemDropdownOptions[
                  removedOptionIndex - 1
                ]
              : action.payload[0];
        }
      }

      state.heatmapFilters.focalItemDropdownOptions = action.payload;
    },
    onSegmentationOptionSelectionChange: (
      state: TimeOfDayDayOfWeekState,
      action: PayloadAction<number>
    ) => {
      state.heatmapFilters.activeSegmentIndex = action.payload;
    },
    onSegmentationOptionsChange: (
      state: TimeOfDayDayOfWeekState,
      action: PayloadAction<string[]>
    ) => {
      state.heatmapFilters.activeSegmentIndex = 0;
      state.heatmapFilters.segmentsDropdownOptions = action.payload;
    },

    onChartBenchmarkChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<number>
    ) => {
      state.chartSelections.benchmark.selected = payload;
    },

    onChartCustomerSegmentChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<string>
    ) => {
      state.chartSelections.segment.selected = payload;
    },

    onChartFocalItemChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<number>
    ) => {
      state.chartSelections.focalItem.selected = payload;
    },

    onChartDayChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<string>
    ) => {
      state.chartSelections.dayOfWeek.selected = payload;
    },

    onDayOfWeekDataReceived: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<DayOfWeekResponseDto>
    ) => {
      state.dayOfWeekChartData = payload;

      const viewBy = state.chartSelections.viewBy;
      const benchmarks = payload.benchmarkItems ?? [];
      const benchmark = state.chartSelections.benchmark.selected;
      const segments = payload.segmentsMetadata;
      const focalItems = state.persistedSelections.focalItems;
      const focalItem = state.chartSelections.focalItem;

      if (segments.length > 1) {
        state.chartSelections.viewBy.dayOfWeekOptions = [
          FocalItemsView,
          SegmentsView,
        ];
      }

      const newBenchmark =
        benchmark === undefined || isNodeRemoved(benchmarks, benchmark)
          ? -1
          : benchmark;

      state.chartSelections.benchmark = {
        options: benchmarks,
        isEnabled: viewBy.selected === FocalItemsView && benchmarks.length > 0,
        selected: newBenchmark,
      };

      state.chartSelections.segment = {
        options: segments,
        isEnabled: viewBy.selected === FocalItemsView && segments.length > 1,
        selected: "All",
      };

      const isFocalRemoved = isNodeRemoved(focalItems, focalItem.selected);
      const selectedFocal =
        (!focalItem.selected || isFocalRemoved) && focalItems.length > 0
          ? focalItems[0].nodeNumber
          : focalItem.selected;
      state.chartSelections.focalItem = {
        isEnabled: viewBy.selected === SegmentsView && focalItems.length > 0,
        selected: selectedFocal,
      };
    },

    onChartViewByChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<string>
    ) => {
      state.chartSelections.viewBy.selected = payload;
      state.chartSelections.benchmark.isEnabled =
        payload === FocalItemsView &&
        state.chartSelections.benchmark.options.length > 0;
      state.chartSelections.segment.isEnabled =
        payload === FocalItemsView || payload === DaysView;
      state.chartSelections.focalItem.isEnabled =
        payload === SegmentsView || payload === DaysView;
      state.chartSelections.dayOfWeek.isEnabled =
        payload === FocalItemsView || payload === SegmentsView;
      state.chartSelections.segment.selected = "All";
      state.chartSelections.dayOfWeek.selected = "All";
    },

    onChartTabChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<string>
    ) => {
      state.chartSelections.tabSelected = payload;
      if (
        payload === "Day of Week" &&
        state.chartSelections.viewBy.selected === DaysView
      ) {
        state.chartSelections.viewBy.selected = FocalItemsView;
        state.chartSelections.benchmark.isEnabled =
          state.chartSelections.benchmark.options.length > 0;
        state.chartSelections.segment.isEnabled = true;
        state.chartSelections.focalItem.isEnabled = false;
        state.chartSelections.dayOfWeek.isEnabled = true;
        state.chartSelections.segment.selected = "All";
        state.chartSelections.dayOfWeek.selected = "All";
      }
    },

    onTimeOfDayDataReceived: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<TimeOfDayResponseDto>
    ) => {
      state.timeOfDayChartData = payload;

      const viewBy = state.chartSelections.viewBy;
      const benchmarks = payload.benchmarkItems;
      const benchmark = state.chartSelections.benchmark.selected;
      const segments = payload.segmentsMetadata;
      const focalItems = state.persistedSelections.focalItems;
      const focalItem = state.chartSelections.focalItem;
      const days = payload.daysMetadata;

      if (segments.length > 1) {
        state.chartSelections.viewBy.timeOfDayOptions = [
          FocalItemsView,
          SegmentsView,
          DaysView,
        ];
      }

      const newBenchmark =
        benchmark === undefined || isNodeRemoved(benchmarks, benchmark)
          ? -1
          : benchmark;
      state.chartSelections.benchmark = {
        options: benchmarks,
        isEnabled: viewBy.selected === FocalItemsView && benchmarks.length > 0,
        selected: newBenchmark,
      };

      state.chartSelections.segment = {
        options: segments,
        isEnabled:
          (viewBy.selected === FocalItemsView ||
            viewBy.selected === DaysView) &&
          segments.length > 1,
        selected: "All",
      };

      const isFocalRemoved = isNodeRemoved(focalItems, focalItem.selected);
      const selectedFocal =
        (!focalItem.selected || isFocalRemoved) && focalItems.length > 0
          ? focalItems[0].nodeNumber
          : focalItem.selected;
      state.chartSelections.focalItem = {
        isEnabled:
          (viewBy.selected === SegmentsView || viewBy.selected === DaysView) &&
          focalItems.length > 0,
        selected: selectedFocal,
      };

      state.chartSelections.dayOfWeek = {
        options: days,
        isEnabled:
          (viewBy.selected === FocalItemsView ||
            viewBy.selected === SegmentsView) &&
          days.length > 1,
        selected: "All",
      };
    },

    toggleDayOfWeekChartDataLabels: (state: TimeOfDayDayOfWeekState) => {
      state.showDayOfWeekChartDataLabels = !state.showDayOfWeekChartDataLabels;
    },
    toggleTimeOfDayChartDataLabels: (state: TimeOfDayDayOfWeekState) => {
      state.showTimeOfDayChartDataLabels = !state.showTimeOfDayChartDataLabels;
    },
    onSearchChange: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<string>
    ) => {
      state.focalItemSearchQuery = payload;
    },

    reset: () => initialState,
    onReportOpen: (
      state: TimeOfDayDayOfWeekState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
        };
      }

      const persistedSelections: TimeOfDayDayOfWeekPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
      };
    },
    onParentNodeDataReceived: (
      state: TimeOfDayDayOfWeekState,
      { payload }: PayloadAction<HierarchySliceNodeDto[]>
    ) => {
      state.focalItemParents = payload;
    },
  },
});

export const {
  onFocalItemChange,
  onMetadataSuccess,
  onLocalParametersReceived,
  onMetricChange,
  onChannelChange,
  onPromotionChange,
  onCheckoutTypeChange,
  onLocationChange,
  onSegmentationChange,
  onInitialTableDataReceived,
  onTableChildrenDataRecieved,
  onTableDataRecieved,
  onHeatmapDataReceived,
  onFocalItemHeatmapSelectionChange,
  onSegmentationOptionsChange,
  onSegmentationOptionSelectionChange,
  onChartBenchmarkChange,
  onChartCustomerSegmentChange,
  onChartDayChange,
  onDayOfWeekDataReceived,
  onChartFocalItemChange,
  onChartViewByChange,
  onChartTabChange,
  onTimeOfDayDataReceived,
  toggleDayOfWeekChartDataLabels,
  toggleTimeOfDayChartDataLabels,
  onSearchChange,
  reset,
  onReportOpen,
  onParentNodeDataReceived,
} = TimeOfDayDayOfWeekSlice.actions;

export default TimeOfDayDayOfWeekSlice.reducer;

export const selectIsTrendsChartFocalItemsView = (
  state: TimeOfDayDayOfWeekState
): boolean => state.chartSelections.viewBy.selected === FocalItemsView;

export const selectIsTrendsChartSegmentsView = (
  state: TimeOfDayDayOfWeekState
): boolean => state.chartSelections.viewBy.selected === SegmentsView;

export const selectIsTrendsChartDaysView = (
  state: TimeOfDayDayOfWeekState
): boolean => state.chartSelections.viewBy.selected === DaysView;
