import {
  Button,
  ButtonHeight,
  ButtonVariant,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import {
  type HierarchyType,
  type CustomerGroupWithSharingDto,
  CustomerGroupStatus,
  CustomerGroupType,
  EMIT_TOAST_DURATION,
  FeatureFlag,
  TrackingComponent,
  useCopyGroupMutation,
  useCreateCustomerGroupValidationTaskMutation,
  useDeleteCustomerGroupsMutation,
  useDeleteGroupsMutation,
  useLazyDownloadGroupQuery,
  useRenameGroupMutation,
  useRenameCustomerGroupMutation,
  type RenameCustomerGroupRequestDto,
  AppContext,
  useUnshareGroupMutation,
} from "@quantium-enterprise/common-ui";
import {
  type FolderOrGroupDto,
  type RenameGroupRequestDto,
} from "@quantium-enterprise/common-ui/src/models/group-dto";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { DownloadIconForSidePanel } from "components-ui/src/assets/icons/DownloadIconForSidePanel";
import { RerunIcon } from "components-ui/src/assets/icons/RerunIcon";
import { ShareIcon } from "components-ui/src/assets/icons/ShareIcon";
import {
  DeleteDialog,
  type DeleteItems,
} from "components-ui/src/delete-dialog/DeleteDialog";
import { EditableField } from "components-ui/src/editable-field/EditableField";
import {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GroupType } from "../../../enums/group-type";
import { setScrollToId } from "../../../states/group-list-slice";
import { isFolder } from "../../../utilities/folder-helper";
import {
  getEditGroupPath,
  getGroupListPath,
} from "../../../utilities/route-path-formats";
import { ActionMenu } from "../../action-menu/ActionMenu";
import styles from "./GroupInfoPanelHeader.module.css";

export type GroupInfoPanelHeaderProps = {
  focalGroup?: CustomerGroupWithSharingDto | FolderOrGroupDto;
  groupStatus?: CustomerGroupStatus;
  groupType: string;
  onClose: () => void;
  onMove?: () => void;
  onShare: () => void;
  templateType?: string;
};

export const GroupInfoPanelHeader = ({
  focalGroup,
  groupStatus,
  groupType,
  onClose,
  onMove,
  onShare,
  templateType,
}: GroupInfoPanelHeaderProps) => {
  const dispatch = useDispatch();
  const flags = useFlags();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { name: divisionName } = useDivision();
  const buttonRef = useRef(null);
  const [deleteCustomerGroups] = useDeleteCustomerGroupsMutation();
  const [deleteHierarchyGroups] = useDeleteGroupsMutation();
  const [unshareGroup] = useUnshareGroupMutation();
  const navigate = useNavigate();
  const deleteGroups =
    groupType === GroupType.Customer
      ? deleteCustomerGroups
      : deleteHierarchyGroups;

  const groupId = focalGroup?.id;
  const groupName = focalGroup?.name;
  const { userState } = useContext(AppContext);
  const isOwner = userState.currentUser?.salesForceId === focalGroup?.userId;

  const [createCustomerGroupValidationTask] =
    useCreateCustomerGroupValidationTaskMutation();

  const [downloadGroupTrigger] = useLazyDownloadGroupQuery();
  const [copyGroupTrigger] = useCopyGroupMutation();

  const editGroupPath =
    groupId && groupType
      ? getEditGroupPath(divisionName, groupType, groupId)
      : "";

  const handleDeleteDialogExit = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const handleDeleteGroup = useCallback(() => {
    onClose();
  }, [onClose]);

  const [renameHierarchyGroups] = useRenameGroupMutation();

  const renameHierarchyGroupTrigger = async (value: string) => {
    const hierarchyType = groupType as HierarchyType;
    const renameRequest: RenameGroupRequestDto = {
      groupId: groupId ?? "",
      hierarchyType,
      proposedName: value,
    };
    try {
      await renameHierarchyGroups({ divisionName, renameRequest }).unwrap();
    } catch (error) {
      // @ts-expect-error cant cast it into the right type to access the status
      const errorStatus = error?.status;
      const is409 = errorStatus === 409;
      const content = is409
        ? "Please use a group name that is unique."
        : "An unknown error has occurred";
      const heading = is409 ? "Rename error" : "Unknown error";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
        }
      );
      return false;
    }

    return true;
  };

  const [renameCustomerGroups] = useRenameCustomerGroupMutation();

  const renameCustomerGroupTrigger = async (value: string) => {
    const renameRequest: RenameCustomerGroupRequestDto = {
      groupId: groupId ?? "",
      proposedName: value,
    };
    try {
      await renameCustomerGroups({ divisionName, renameRequest }).unwrap();
    } catch (error) {
      // @ts-expect-error cant cast it into the right type to access the status
      const errorStatus = error?.status;
      const is409 = errorStatus === 409;
      const content = is409
        ? "Please use a group name that is unique."
        : "An unknown error has occurred";
      const heading = is409 ? "Rename error" : "Unknown error";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
        }
      );
      return false;
    }

    return true;
  };

  const renameGroupTrigger = async (value: string) => {
    if (groupType === GroupType.Customer) {
      return await renameCustomerGroupTrigger(value);
    } else {
      return await renameHierarchyGroupTrigger(value);
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const editableFieldContainerRef = useRef<HTMLDivElement>(null);

  /**
   * This hook focuses the textarea element in EditableField when the isEditing state changes
   * via handleRename in ActionMenu.
   *
   * The focus is applied after a slight delay using setTimeout to ensure that the DOM has been fully
   * updated before attempting to focus the element. This delay addresses rendering-timing issues.
   */
  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        const inputElement = editableFieldContainerRef.current?.querySelector(
          "textarea"
        ) as HTMLInputElement | HTMLTextAreaElement | undefined;
        if (inputElement) {
          inputElement.focus();
          inputElement.setSelectionRange(
            inputElement.value.length,
            inputElement.value.length
          );
        }
      }, 0);
    }
  }, [isEditing]);

  const [preventBlur, setPreventBlur] = useState(false);

  const handleRename = useCallback(() => {
    // Prevent early exit of EditableField after change of editing state
    setPreventBlur(true);
    setIsEditing(true);
    setTimeout(() => setPreventBlur(false), 100);
  }, []);

  const handleRefreshGroup = useCallback(async () => {
    if (groupId) {
      await createCustomerGroupValidationTask({ divisionName, groupId });
    }
  }, [createCustomerGroupValidationTask, divisionName, groupId]);

  const handleDownloadGroup = useCallback(async () => {
    if (groupId) {
      await downloadGroupTrigger({ divisionName, groupId });
    }
  }, [downloadGroupTrigger, divisionName, groupId]);

  const handleMove = useMemo(() => {
    if (groupId && groupType && groupType !== GroupType.Customer && onMove) {
      return onMove;
    }

    return undefined;
  }, [groupId, groupType, onMove]);

  const handleCopy = useCallback(async () => {
    if (groupId) {
      const newIds = await copyGroupTrigger({
        divisionName,
        payload: {
          sourceId: groupId,
          entityType: isFolder(focalGroup) ? "Folder" : "Group",
        },
      }).unwrap();
      const copiedGroupId = newIds.groups[newIds.groups.length - 1].groupId;
      dispatch(
        setScrollToId({
          groupType: groupType as GroupType,
          scrollToId: copiedGroupId,
        })
      );
      navigate(getGroupListPath(divisionName, groupType, copiedGroupId));
    }
  }, [
    groupId,
    copyGroupTrigger,
    divisionName,
    focalGroup,
    dispatch,
    groupType,
    navigate,
  ]);

  const disableRefresh =
    groupStatus === CustomerGroupStatus.InProgress ||
    (groupStatus === CustomerGroupStatus.Invalid &&
      templateType === CustomerGroupType.CustomSegmentation);

  const getButtonPanel = () => (
    <>
      {(groupType !== GroupType.Customer ||
        flags[FeatureFlag.EditCustomerGroupButtonInfoPanel]) && (
        <Tooltip
          placement={TooltipPlacement.TopCentre}
          spaceInside={TooltipSpaceInside.Small}
          trigger={
            <Link className={styles.editButton} to={editGroupPath}>
              <Button
                disabled={disableRefresh || !isOwner}
                height={ButtonHeight.XSmall}
                text="Edit"
                variant={ButtonVariant.Primary}
              />
            </Link>
          }
          variant={TooltipVariant.ArrowDark}
        >
          {isOwner ? "Edit" : "Only owner can edit"}
        </Tooltip>
      )}
      {groupType === GroupType.Customer && (
        <Tooltip
          placement={TooltipPlacement.TopCentre}
          spaceInside={TooltipSpaceInside.Small}
          trigger={
            <Button
              aria-label="Refresh"
              className={styles.refreshButton}
              disabled={disableRefresh}
              onClick={handleRefreshGroup}
              variant={ButtonVariant.Stealth}
            >
              <RerunIcon />
            </Button>
          }
          variant={TooltipVariant.ArrowDark}
        >
          Refresh
        </Tooltip>
      )}
      {flags[FeatureFlag.DownloadGroups] &&
        groupType !== GroupType.Customer && (
          <Tooltip
            placement={TooltipPlacement.TopCentre}
            spaceInside={TooltipSpaceInside.Small}
            trigger={
              <Button
                aria-label="Download"
                onClick={handleDownloadGroup}
                variant={ButtonVariant.Stealth}
              >
                <DownloadIconForSidePanel />
              </Button>
            }
            variant={TooltipVariant.ArrowDark}
          >
            Download
          </Tooltip>
        )}

      {flags[FeatureFlag.SharingGroups] && (
        <Tooltip
          placement={TooltipPlacement.TopCentre}
          spaceInside={TooltipSpaceInside.Small}
          trigger={
            <Button
              aria-label="Share"
              className={styles.refreshButton}
              disabled={!isOwner}
              onClick={onShare}
              variant={ButtonVariant.Stealth}
            >
              <ShareIcon />
            </Button>
          }
          variant={TooltipVariant.ArrowDark}
        >
          {isOwner ? "Share" : "Only owner can share"}
        </Tooltip>
      )}
      <ActionMenu
        getCustomerGroup={
          groupType === GroupType.Customer
            ? () => focalGroup as CustomerGroupWithSharingDto
            : undefined
        }
        handleCopy={
          flags[FeatureFlag.ProductGroupsTrueShare] &&
          groupType !== GroupType.Customer
            ? handleCopy
            : undefined
        }
        handleDelete={isOwner ? () => setShowDeleteDialog(true) : undefined}
        handleMove={isOwner ? handleMove : undefined}
        handleRemove={isOwner ? undefined : () => setShowDeleteDialog(true)}
        handleRename={isOwner ? handleRename : undefined}
      />
    </>
  );

  return (
    <>
      <div className={styles.infoPanelHeader}>
        <div
          className={styles.infoPanelTitleContainer}
          ref={editableFieldContainerRef}
        >
          {groupName ? (
            <EditableField
              editableFieldState={{
                isEditing,
                toggleEditing: (editing) => {
                  setIsEditing(editing);
                },
              }}
              // setting onlyExternalState to true will disable the
              // edit/rename by clicking in the field functionality
              onlyExternalState={!isOwner}
              preventBlur={preventBlur}
              rows={2}
              save={renameGroupTrigger}
              textStyle={styles.infoPanelTitle}
              value={groupName}
            />
          ) : (
            <h2 className={styles.infoPanelTitle} title={groupName}>
              {groupName}
            </h2>
          )}
        </div>
        <div className={styles.buttonPanel}>
          <div className={styles.infoPanelButtons}>{getButtonPanel()}</div>
        </div>
      </div>
      <DeleteDialog
        deleteItem={
          isOwner
            ? async (items: DeleteItems) =>
                await deleteGroups({
                  divisionName,
                  itemIds: items.groupIds ?? [],
                }).unwrap()
            : async () =>
                await unshareGroup({
                  divisionName,
                  payload: {
                    entityType: "Group",
                    sharingNotes: "",
                    sourceId: groupId ?? "",
                    userIds: [userState.currentUser?.salesForceId ?? ""],
                  },
                }).unwrap()
        }
        isRemove={!isOwner}
        itemIds={{ groupIds: groupId ? [groupId] : [] }}
        onClose={handleDeleteDialogExit}
        onDelete={handleDeleteGroup}
        ref={buttonRef}
        show={showDeleteDialog}
        trackingComponent={
          groupType === GroupType.Product
            ? TrackingComponent.ProductGroup
            : groupType === GroupType.Location
            ? TrackingComponent.LocationGroup
            : TrackingComponent.CustomerGroup
        }
      />
    </>
  );
};
