import { Spinner } from "@qbit/react";
import {
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  UnknownTrackingPropertyValue,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { differenceInCalendarWeeks, format, subWeeks } from "date-fns";
import { useCallback, useMemo } from "react";
import Zod from "zod";
import { ParameterSingleSelect } from "../../fast-report/ParameterSingleSelect";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";

const ComparisonPeriod = {
  lastYear: "lastYear",
  priorPeriod: "priorPeriod",
};

const getComparisonDates = (value: string, startDate: Date, endDate: Date) => {
  switch (value) {
    case ComparisonPeriod.priorPeriod: {
      const weeks = Math.abs(differenceInCalendarWeeks(startDate, endDate));

      const priorPeriodStartDate = subWeeks(startDate, weeks);
      const priorPeriodEndDate = subWeeks(endDate, weeks);

      return {
        startDate: priorPeriodStartDate,
        endDate: priorPeriodEndDate,
      };
    }

    case ComparisonPeriod.lastYear: {
      const lastYearStartDate = subWeeks(startDate, 52);
      const lastYearEndDate = subWeeks(endDate, 52);

      return {
        startDate: lastYearStartDate,
        endDate: lastYearEndDate,
      };
    }

    default: {
      return undefined;
    }
  }
};

export const ComparisonPeriodValueSchema = Zod.string();
export type ComparisonPeriodValue = Zod.infer<
  typeof ComparisonPeriodValueSchema
>;

export const ComparisonPeriodDropdown = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.keyDriverTree?.comparisonPeriod,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.KDTComparisonPeriod,
    ComparisonPeriodValueSchema,
    (config) => config.reports.keyDriverTree?.comparisonPeriod
  );

  const [globalParameters] = useGlobalParameters();

  const getDisplayNameWithDates = useCallback(
    (comparisonPeriod: string, baseDisplayName: string) => {
      if (!globalParameters.focusPeriod) {
        return baseDisplayName;
      }

      const focusStartDate = new Date(globalParameters.focusPeriod.startDate);
      const focusEndDate = new Date(globalParameters.focusPeriod.endDate);
      const comparisonDates = getComparisonDates(
        comparisonPeriod,
        focusStartDate,
        focusEndDate
      );

      if (!comparisonDates) {
        return baseDisplayName;
      }

      const dateFormat = "dd MMM yy";
      return (
        baseDisplayName +
        ` (${format(comparisonDates.startDate, dateFormat)} - ${format(
          comparisonDates.endDate,
          dateFormat
        )})`
      );
    },
    [globalParameters]
  );

  const optionsWithDates = useMemo(
    () =>
      reportConfig.configuration?.options.map((option) => ({
        ...option,
        displayName: getDisplayNameWithDates(option.value, option.displayName),
      })),
    [reportConfig.configuration, getDisplayNameWithDates]
  );

  const selectionChangedHandler = useCallback(
    (value: string) => {
      if (selection && value !== selection && reportConfig.configuration) {
        const parameter = reportConfig.configuration.options.find(
          (options) => options.value === value
        );
        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingKDTReport,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.ComparisonTimePeriodSelected,
            parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
          )
        );
      }

      setSelection(value);
    },
    [reportConfig.configuration, selection, eventTrackingService, setSelection]
  );

  return reportConfig.configuration && selection && optionsWithDates ? (
    <ParameterSingleSelect
      configuration={{
        ...reportConfig.configuration,
        options: optionsWithDates,
      }}
      data-cy="ComparisonPeriodSelector"
      onSelection={selectionChangedHandler}
      selection={selection}
    />
  ) : (
    <Spinner />
  );
};

export default ComparisonPeriodDropdown;
