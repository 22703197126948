import { type TransactionSource } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { TransactionSourceIcon } from "components-ui/src/icons";
import { format } from "date-fns";
import { useState, useMemo, useCallback, useEffect } from "react";
import { useReportTabState } from "report-tabs-ui";
import Zod from "zod";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import ReportLoadingWrapper from "../../fast-report/ReportLoadingWrapper";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { MetricNameSchema } from "../../fast-report-content/MetricsTypeahead";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../ReportPanel.module.css";
import { convertFocalItemToDto } from "../focalItemDto";
import {
  useDisplayEntitlements,
  useFilenameForExport,
  useGetFeaturesForFocalItem,
  useParameterValuesForCSVExport,
} from "../hooks";
import { PerformanceParametersHeader } from "./PerformanceParametersHeader";
import { type PerformanceReportMeasureResult } from "./PerformanceReport";
import PerformanceReport from "./PerformanceReport";
import { type PerformanceReportMetric } from "./PerformanceReportMetric";
import { usePerformanceReportMeasuresQuery } from "./api/performanceReportApi";

export const ShowDataLabelsValueSchema = Zod.boolean();
export type ShowDataLabelsValue = Zod.infer<typeof ShowDataLabelsValueSchema>;
export const PERFORMANCE_REPORT_FEATURE_NAME = "performance-report";

const PERFORMANCE_REPORT_ID = "FastReportingPerformanceChart";
const PerformanceReportPanel = () => {
  const [performanceTransactionSource, setPerformanceTransactionSource] =
    useState<TransactionSource | null>(null);

  const [performanceMetricsParameter] = useFastReportingParameterState(
    Parameter.PerformanceMetrics,
    MetricNameSchema,
    (config) => config.reports.performance?.metrics
  );

  const division = useDivision();
  const reportConfiguration = useReportConfigurationQuery(
    { division: division.name },
    { skip: !division.name }
  );
  const activeItem = useActiveItem();
  const displayEntitlements = useDisplayEntitlements();

  const performanceReportTitle = useMemo(
    () => (
      <span className={styles.reportTitle}>
        Trended performance
        {displayEntitlements && performanceTransactionSource && (
          <TransactionSourceIcon
            availableTransactionSources={division.transactionSources}
            transactionSource={performanceTransactionSource}
          />
        )}
      </span>
    ),
    [
      displayEntitlements,
      performanceTransactionSource,
      division.transactionSources,
    ]
  );

  const [globalParameters, areGlobalParametersSet] = useGlobalParameters();
  const [measure, setMeasure] = useState<PerformanceReportMeasureResult>();

  useEffect(() => {
    setMeasure(undefined);
  }, [activeItem, globalParameters]);

  const performanceMetrics = useMemo(() => {
    const mappedSelection = [] as PerformanceReportMetric[];
    if (performanceMetricsParameter) {
      for (const selected of performanceMetricsParameter) {
        const metricMetadata =
          reportConfiguration.data?.reports.performance?.metricMetadata.find(
            (mm) => mm.key === selected
          );

        mappedSelection.push({
          key: metricMetadata?.key,
          format: metricMetadata?.format,
          displayName: metricMetadata?.displayName,
          isCompare: metricMetadata?.isCompare,
          comparisonForKey: metricMetadata?.comparisonForKey,
          isBarMetric: metricMetadata?.isBarMetric,
          isBlackColour: metricMetadata?.isBlackColour,
          isGrowthMetric: metricMetadata?.isGrowthMetric,
        } as PerformanceReportMetric);
      }
    }

    return mappedSelection;
  }, [
    performanceMetricsParameter,
    reportConfiguration.data?.reports.performance?.metricMetadata,
  ]);

  const isMetricMeasureResultsAvailable = useMemo(
    () => performanceMetrics.every((sm) => measure?.measureResults[sm.key]),
    [performanceMetrics, measure]
  );

  const {
    currentData: apiMeasure,
    isFetching,
    isError,
    refetch,
  } = usePerformanceReportMeasuresQuery(
    {
      division: division.name,
      focusPeriod: globalParameters.focusPeriod,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- this request gets skipped if it is null / undefined
      item: activeItem ? convertFocalItemToDto(activeItem) : null!,
      location: globalParameters.location,
      channel: globalParameters.channel,
      promotion: globalParameters.promotion,
      banner: globalParameters.banner,
      metrics: performanceMetrics.map((sm) => sm.key),
      transactionSource: globalParameters.transactionSource,
      storeFormat: globalParameters.storeFormat,
      compStore: globalParameters.compStore,
    },
    {
      skip:
        !division.name ||
        performanceMetrics.length <= 0 ||
        isMetricMeasureResultsAvailable ||
        !activeItem ||
        !areGlobalParametersSet ||
        reportConfiguration.data?.reports.performance?.disabled,
    }
  );

  useEffect(() => {
    setPerformanceTransactionSource(measure?.transactionSource ?? null);
  }, [setPerformanceTransactionSource, measure?.transactionSource]);

  useEffect(() => {
    if (
      !isFetching &&
      apiMeasure &&
      performanceMetrics.length > 0 &&
      activeItem
    ) {
      setMeasure({
        measureResults: apiMeasure.measureResults,
        promotionWeekDates: apiMeasure.promotionWeekDates,
        transactionSource: apiMeasure.transactionSource,
      });
    }
  }, [activeItem, isFetching, apiMeasure, performanceMetrics]);

  const [globalParameterCsvValues] = useParameterValuesForCSVExport();

  const getDataForCsvExport = useCallback(() => {
    if (measure) {
      const parameters = [...globalParameterCsvValues];
      const header = [
        "Week ending",
        ...performanceMetrics.map((metric) => metric.displayName),
      ];
      const dataRows = measure.promotionWeekDates.map((date) => {
        const measureValues = performanceMetrics.map(
          (metric) =>
            measure.measureResults[metric.key]?.[date]?.toString() ?? ""
        );
        const formattedDate = format(new Date(date), "yyyy-MM-dd");
        return [formattedDate, ...measureValues];
      });
      return [parameters, header, ...dataRows];
    }

    throw new Error("Unable to get CSV data as report has not loaded");
  }, [globalParameterCsvValues, performanceMetrics, measure]);

  const filename = useFilenameForExport("TrendedPerformance");

  const [showDataLabels, setShowDataLabels] =
    useReportTabState<ShowDataLabelsValue>(
      Parameter.PerformanceDataLabels,
      ShowDataLabelsValueSchema
    );

  const features = useGetFeaturesForFocalItem(division, activeItem);

  const getPerformanceReport = useCallback(() => {
    if (!activeItem) {
      return null;
    }

    return (
      <ErrorBoundary>
        <div data-cy="PerformanceReportPanel">
          <div className={styles.reportHeader}>
            <PerformanceParametersHeader data-cy="ChartOptions" />
            <div className={styles.reportOptions}>
              {!reportConfiguration.currentData?.exportDisabled && (
                <ChartOptions
                  disabled={isFetching || !measure}
                  filename={filename}
                  getCSVData={getDataForCsvExport}
                  getElementToExport={() =>
                    document.querySelector<HTMLElement>(
                      `#${PERFORMANCE_REPORT_ID}`
                    ) ?? undefined
                  }
                  isDownloadPngEnabled={false}
                  isFeatureEnabled
                  toggleDataLabels={() => setShowDataLabels(!showDataLabels)}
                />
              )}
            </div>
          </div>
          <ReportLoadingWrapper
            data-cy="PerformanceReport"
            isError={isError}
            isLoading={isFetching}
            reportMinimumHeight={400}
            retry={refetch}
          >
            <PerformanceReport
              id={PERFORMANCE_REPORT_ID}
              measure={measure}
              selectedMetrics={performanceMetrics}
              showDataLabels={showDataLabels ?? false}
            />
          </ReportLoadingWrapper>
        </div>
      </ErrorBoundary>
    );
  }, [
    activeItem,
    performanceMetrics,
    isError,
    isFetching,
    refetch,
    measure,
    getDataForCsvExport,
    filename,
    reportConfiguration,
    showDataLabels,
    setShowDataLabels,
  ]);

  return (
    <div data-cy="PerformanceReportPanel">
      {reportConfiguration.data?.reports.performance?.disabled === false &&
        features?.find(
          (feature) => feature === PERFORMANCE_REPORT_FEATURE_NAME
        ) && (
          <Accordion
            className={styles.reportAccordion}
            subtitle="View your product performance over time in this chart."
            title={performanceReportTitle}
          >
            {getPerformanceReport()}
          </Accordion>
        )}
    </div>
  );
};

export default PerformanceReportPanel;
