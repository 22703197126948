import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import React from "react";
import {
  BandsParameter,
  BufferParameter,
  ChannelParameter,
  DropdownParameter,
  GroupsParameterQuery,
  LevelOfAnalysisParameterQuery,
  ProductHierarchyParameter,
  StructureParameter,
  ListParameter,
  ListParameterSelectionType,
  MeasureThresholdParameter,
  SegmentFilterParameter,
  SegmentationParameter,
  ComparisonPeriodParameter,
  FocusPeriodParameter,
  RollingPeriodParameter,
} from "../../../parameters";
import { LocationHierarchyParameter } from "../../../parameters/hierarchy/LocationHierarchyParameter";
import { LeadPeriodParameter } from "../../../parameters/time/LeadPeriodParameter";

export type ParameterBaseProps = {
  parameterDto: ParameterDto;
};

export const ParameterItem = ({ parameterDto }: ParameterBaseProps) => {
  const parameterProps = {
    parameterDto,
  };

  const ParameterMap: Record<NonNullable<string>, JSX.Element> = {
    [ParameterId.Channel]: <ChannelParameter {...parameterProps} />,
    [ParameterId.ComparisonPeriod]: (
      <ComparisonPeriodParameter {...parameterProps} />
    ),
    [ParameterId.CompStore]: (
      <ListParameter
        {...parameterProps}
        selectionType={ListParameterSelectionType.CheckboxWithDefault}
      />
    ),
    [ParameterId.Dataset]: (
      <ListParameter
        {...parameterProps}
        selectionType={ListParameterSelectionType.CheckboxWithDefault}
      />
    ),
    [ParameterId.FocusPeriod]: <FocusPeriodParameter {...parameterProps} />,
    [ParameterId.LeadPeriod]: <LeadPeriodParameter {...parameterProps} />,
    [ParameterId.LevelOfAnalysis]: (
      <LevelOfAnalysisParameterQuery {...parameterProps} />
    ),
    [ParameterId.LocationHierarchy]: <LocationHierarchyParameter />,
    [ParameterId.LocationStructure]: <StructureParameter {...parameterProps} />,
    [ParameterId.ProductHierarchy]: (
      <ProductHierarchyParameter {...parameterProps} />
    ),
    [ParameterId.ProductStructure]: <StructureParameter {...parameterProps} />,
    [ParameterId.Promotion]: (
      <ListParameter
        {...parameterProps}
        selectionType={ListParameterSelectionType.CheckboxWithDefault}
      />
    ),
    [ParameterId.RollingPeriod]: <RollingPeriodParameter {...parameterProps} />,
    [ParameterId.Segmentation]: <SegmentationParameter {...parameterProps} />,
    [ParameterId.SegmentFilter]: <SegmentFilterParameter {...parameterProps} />,
    [ParameterId.ProductGroups]: <GroupsParameterQuery {...parameterProps} />,
    [ParameterId.LocationGroups]: <GroupsParameterQuery {...parameterProps} />,
    [ParameterId.PurchasingBehaviour]: (
      <ListParameter
        {...parameterProps}
        selectionType={ListParameterSelectionType.Checkbox}
      />
    ),
    [ParameterId.PurchasingTrends]: (
      <ListParameter
        {...parameterProps}
        selectionType={ListParameterSelectionType.Checkbox}
        title="Select customers"
      />
    ),
    [ParameterId.NewLapsedContinuous]: (
      <ListParameter
        {...parameterProps}
        selectionType={ListParameterSelectionType.Checkbox}
      />
    ),
    [ParameterId.RetailerCustomers]: (
      <ListParameter
        {...parameterProps}
        selectionType={ListParameterSelectionType.RadioButton}
      />
    ),
    [ParameterId.Measure]: (
      <DropdownParameter {...parameterProps} headingText="Select a measure" />
    ),
    [ParameterId.MeasureThreshold]: (
      <MeasureThresholdParameter
        {...parameterProps}
        headingText="Select a measure"
      />
    ),
    [ParameterId.Bands]: (
      <BandsParameter {...parameterProps} headingText="Set range" />
    ),
    [ParameterId.Buffer]: (
      <BufferParameter {...parameterProps} headingText="Select a buffer" />
    ),
  };

  return (
    <React.Fragment key={parameterDto.id}>
      {Object.keys(ParameterMap).includes(parameterDto.id) ? (
        ParameterMap[parameterDto.id]
      ) : (
        <h2> Another parameter not yet handled </h2>
      )}
    </React.Fragment>
  );
};

export default ParameterItem;
