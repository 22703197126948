import { Group, Item, ItemHalign } from "@qbit/react";
import { HierarchyType } from "@quantium-enterprise/common-ui";
import { type UserModuleAccess } from "@quantium-enterprise/common-ui/src/models/division-dto";
import { useModuleAccessProtection } from "@quantium-enterprise/hooks-ui";
import { AppFooter } from "components-ui/src/footer/AppFooter";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { HierarchyGroupInfoPanel } from "../../components/group-info-panel/HierarchyGroupInfoPanel";
import { HierarchyGroupListTable } from "../../components/group-list-table/HierarchyGroupListTable/HierarchyGroupListTable";
import { DeleteHierarchyGroupModal } from "../../components/modal/DeleteHierarchyGroupModal";
import { MoveHierarchyGroupModal } from "../../components/modal/MoveHierarchyGroupModal";
import { ShareHierarchyGroupModal } from "../../components/modal/ShareHierarchyGroupModal";
import {
  resetAllScrollStates,
  resetDeleteFolderOrGroups,
  resetRenameState,
  resetSelectedFolderOrGroups,
} from "../../states/group-list-slice";
import styles from "./GroupManagement.module.css";
import { GroupManagementHeader } from "./GroupManagementHeader";
import { GroupManagementTabs } from "./GroupManagementTabs";
import { HierarchyGroupManagementSearchAndActions } from "./HierarchyGroupManagementSearchAndActions";

export type HierarchyGroupManagementProps = {
  hierarchyType: HierarchyType;
};

export const HierarchyGroupManagement = ({
  hierarchyType,
}: HierarchyGroupManagementProps) => {
  useModuleAccessProtection((moduleAccess: UserModuleAccess) =>
    hierarchyType === HierarchyType.Product
      ? moduleAccess.groups.productGroups
      : moduleAccess.groups.locationGroups
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetDeleteFolderOrGroups());
    dispatch(resetSelectedFolderOrGroups());
    dispatch(resetAllScrollStates());
    dispatch(resetRenameState());
  }, [hierarchyType, dispatch]);

  return (
    <div className={styles.groupListContainer}>
      <HierarchyGroupInfoPanel hierarchyType={hierarchyType}>
        <div className={styles.page}>
          <GroupManagementHeader />
          <GroupManagementTabs />
          <HierarchyGroupManagementSearchAndActions
            hierarchyType={hierarchyType}
          />
          <Group className={styles.tableContainer}>
            <Item
              className={styles.customerGroupTable}
              halign={ItemHalign.Left}
            >
              <ErrorBoundary>
                <HierarchyGroupListTable hierarchyType={hierarchyType} />
              </ErrorBoundary>
            </Item>
          </Group>
        </div>
      </HierarchyGroupInfoPanel>
      <AppFooter />
      <DeleteHierarchyGroupModal hierarchyType={hierarchyType} />
      <ShareHierarchyGroupModal />
      <MoveHierarchyGroupModal hierarchyType={hierarchyType} />
    </div>
  );
};
