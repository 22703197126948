type Props = {
  className?: string;
  coloured: boolean;
  text?: string;
};

export const RawDataIcon = ({ className, coloured, text }: Props) => {
  const colour1 = coloured
    ? "var(--report-wizard-icon-colour-one)"
    : "var(--icon-colour-one-disabled)";
  const colour2 = coloured
    ? "var(--report-wizard-icon-colour-two)"
    : "var(--icon-colour-two-disabled)";
  const colour3 = coloured
    ? "var(--report-wizard-icon-colour-three)"
    : "var(--icon-colour-three-disabled)";

  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{text ?? "Raw data report"}</title>
      <path d="M17 11H13V7H17V11Z" fill={colour1} />
      <path d="M11 11H7V7H11V11Z" fill={colour3} />
      <path d="M11 17H7V13H11V17Z" fill={colour1} />
      <path d="M17 17H13V13H17V17Z" fill={colour3} />
      <mask fill="white" id="path-2-inside-1">
        <path
          clipRule="evenodd"
          d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM19.5 4.5H4.5V19.5H19.5V4.5Z"
          fillRule="evenodd"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM19.5 4.5H4.5V19.5H19.5V4.5Z"
        fill={colour2}
        fillRule="evenodd"
      />
      <path
        d="M4.5 4.5V3H3V4.5H4.5ZM19.5 4.5H21V3H19.5V4.5ZM4.5 19.5H3V21H4.5V19.5ZM19.5 19.5V21H21V19.5H19.5ZM4.5 5C4.5 4.72386 4.72386 4.5 5 4.5V1.5C3.067 1.5 1.5 3.067 1.5 5H4.5ZM4.5 19V5H1.5V19H4.5ZM5 19.5C4.72386 19.5 4.5 19.2761 4.5 19H1.5C1.5 20.933 3.067 22.5 5 22.5V19.5ZM19 19.5H5V22.5H19V22.5ZM19.5 19C19.5 19.2761 19.2761 19.5 19 19.5V22.5C20.933 22.5 22.5 20.933 22.5 19H19.5ZM19.5 5V19H22.5V5H19.5ZM19 4.5C19.2761 4.5 19.5 4.72386 19.5 5H22.5C22.5 3.067 20.933 1.5 19 1.5V4.5ZM5 4.5H19V1.5H5V4.5ZM4.5 6H19.5V3H4.5V6ZM6 19.5V4.5H3V19.5H6ZM19.5 18H4.5V21H19.5V18ZM18 4.5V19.5H21V4.5H18Z"
        fill={colour2}
        mask="url(#path-2-inside-1)"
      />
    </svg>
  );
};

export default RawDataIcon;
