import {
  TrackingComponent,
  useDeleteCustomerGroupsMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { type DeleteItems } from "components-ui/src/delete-dialog/DeleteDialog";
import { DeleteDialog } from "components-ui/src/delete-dialog/DeleteDialog";
import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { type CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import {
  confirmDeleteCustomerGroupsSuccess,
  resetDeleteCustomerGroups,
  selectDeleteCustomerGroups,
} from "../../states/group-list-slice";
import { getDefaultGroupsPath } from "../../utilities/route-path-formats";

type DeleteCustomerGroupModalModalProps = {
  customerGroupTab: CustomerGroupTab;
};

export const DeleteCustomerGroupModalModal = ({
  customerGroupTab,
}: DeleteCustomerGroupModalModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const division = useDivision();
  const divisionName = division.name;

  const groupIdsToDelete = useSelector(selectDeleteCustomerGroups);
  const [deleteGroups] = useDeleteCustomerGroupsMutation();

  const deleteButtonRef = useRef(null);
  const showDeleteDialog = groupIdsToDelete.length > 0;

  // the actual deleting of items on clicking delete in the popup
  const deleteItems = async (items: DeleteItems) => {
    const promises: Array<Promise<unknown>> = [];

    if (items.groupIds?.length) {
      promises.push(
        deleteGroups({
          divisionName,
          itemIds: items.groupIds,
        }).unwrap()
      );
    }

    return await Promise.all(promises);
  };

  const handleDeleteDialogExit = useCallback(() => {
    dispatch(resetDeleteCustomerGroups());
  }, [dispatch]);

  const handleDeleteGroups = useCallback(() => {
    dispatch(confirmDeleteCustomerGroupsSuccess());
    navigate(getDefaultGroupsPath(divisionName, "Customer", customerGroupTab));
  }, [dispatch, customerGroupTab, divisionName, navigate]);

  const itemsToDelete = useMemo(
    () =>
      ({
        groupIds: groupIdsToDelete.map((grp) => grp.id),
      } as DeleteItems),
    [groupIdsToDelete]
  );

  return (
    <DeleteDialog
      deleteItem={deleteItems}
      itemIds={itemsToDelete}
      onClose={handleDeleteDialogExit}
      onDelete={handleDeleteGroups}
      ref={deleteButtonRef}
      show={showDeleteDialog}
      trackingComponent={TrackingComponent.CustomerGroup}
    />
  );
};
