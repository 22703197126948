import {
  Icon,
  IconColour,
  IconGlyph,
  Nav,
  NavButton,
  NavVariant,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  type SharingActivity,
  FeatureFlag,
  useGetSegmentActivationActivityQuery,
  useGetUsersByIdQuery,
  type CustomerGroupDtoWithDisplayName,
  useGetCustomerGroupQuery,
  useGetUserQuery,
  type Activity,
  ActivationGuidelineStatus,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InProgressSegmentCard } from "components-ui/src/customer-group-segments/InProgressSegmentCard";
import { InvalidSegmentCard } from "components-ui/src/customer-group-segments/InvalidSegmentCard";
import { ValidSegmentCard } from "components-ui/src/customer-group-segments/ValidSegmentCard";
import { CustomerGroupSummary } from "components-ui/src/customer-group-summary/CustomerGroupSummary";
import { InfoPanelActivity } from "components-ui/src/info-panel/info-panel-body/InfoPanelActivity";
import { SegmentGuidelinesModal } from "components-ui/src/modals/segment-guidelines-modal/SegmentGuidelinesModal";
import { PanelWithSideDrawer } from "components-ui/src/panel-with-side-drawer/PanelWithSideDrawer";
import { useMemo, useState, type PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import { GroupType } from "../../enums/group-type";
import {
  selectFocalSegment,
  setFocalSegment,
} from "../../states/group-list-slice";
import { getDefaultGroupsPath } from "../../utilities/route-path-formats";
import { type SegmentRow } from "../segment-library-table/SegmentLibraryTableCells";
import styles from "./GroupInfoPanel.module.css";
import { SegmentLibraryInfoPanelHeader } from "./group-info-panel-header/SegmentLibraryInfoPanelHeader";
import { SegmentSummary } from "./segment-summary/SegmentSummary";

const createUserIdList = (
  sharingActivities?: Array<Activity | SharingActivity>,
  focalSegment?: SegmentRow
) => {
  const userIds =
    sharingActivities?.flatMap(
      (sharingActivity) => sharingActivity.salesforceUserIds
    ) ?? [];
  return focalSegment?.ownerId ? userIds.concat(focalSegment.ownerId) : userIds;
};

export const SegmentLibraryInfoPanel = ({ children }: PropsWithChildren) => {
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const flags = useFlags();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const division = useDivision();
  const divisionName = division.name;
  const { groupId, segmentKey } = useParams();
  const [showSegmentGuidelinesModal, setShowSegmentGuidelinesModal] =
    useState(false);

  const focalSegment = useSelector(selectFocalSegment);
  const showDrawer = Boolean(divisionName && groupId && focalSegment);

  const { data: currentUser } = useGetUserQuery();
  const { data: parentCustomerGroup } = useGetCustomerGroupQuery(
    {
      divisionName,
      groupId: groupId ?? "",
    },
    {
      skip: !(Boolean(divisionName) && Boolean(groupId)),
    }
  );
  const { data: sharingActivities, isLoading: isActivityLoading } =
    useGetSegmentActivationActivityQuery(
      {
        divisionName,
        groupId: groupId ?? "",
        segmentKey: segmentKey ?? "",
      },
      {
        skip: !(
          Boolean(divisionName) &&
          Boolean(groupId) &&
          Boolean(segmentKey) &&
          flags[FeatureFlag.SharingGroups]
        ),
      }
    );
  const parentGroupWithTypeDisplayName = useMemo<
    CustomerGroupDtoWithDisplayName | undefined
  >(
    () =>
      parentCustomerGroup
        ? {
            ...parentCustomerGroup,
            typeDisplayName: focalSegment?.customerGroupTypeName ?? "",
          }
        : undefined,
    [parentCustomerGroup, focalSegment]
  );
  const { data: reportSharedUsers, isLoading: isReportSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds: createUserIdList(sharingActivities, focalSegment),
        },
      },
      {
        skip: !(
          Boolean(divisionName) &&
          Boolean(groupId) &&
          Boolean(sharingActivities) &&
          (Boolean(sharingActivities?.length) || Boolean(focalSegment)) &&
          flags[FeatureFlag.SharingGroups]
        ),
      }
    );

  const usersLoading = isActivityLoading || isReportSharedUsersLoading;

  const onClose = () => {
    dispatch(setFocalSegment(undefined));
    navigate(
      getDefaultGroupsPath(
        divisionName,
        "Customer",
        CustomerGroupTab.SegmentLibrary
      )
    );
  };

  const label = (
    <div className={styles.infoPanelLabel}>
      <Icon
        colour={IconColour.Notification}
        glyph={IconGlyph.AccountAndUserAccount}
        text="Customer group"
      />
      <Text>{`${GroupType.Customer} group`}</Text>
    </div>
  );

  const owner = useMemo(
    () =>
      reportSharedUsers?.find(
        (user) => user.salesforceUserId === focalSegment?.ownerId
      ),
    [reportSharedUsers, focalSegment?.ownerId]
  );

  const statusCard = useMemo(() => {
    const activationGuidelineStatus =
      parentCustomerGroup?.activationGuidelineStatus;

    if (activationGuidelineStatus === ActivationGuidelineStatus.Complete) {
      const invalidSegments =
        parentCustomerGroup?.segments
          .flatMap((segments) => segments.segmentGuidelines)
          .filter((guidelines) => guidelines?.valid === false).length ?? 0;

      if (invalidSegments > 0) {
        return (
          <InvalidSegmentCard
            onClick={() => setShowSegmentGuidelinesModal(true)}
          />
        );
      }

      return (
        <ValidSegmentCard
          activationGuidelinesRefreshDateUtc={
            parentCustomerGroup?.activationGuidelinesRefreshDateUtc
          }
          onClick={() => setShowSegmentGuidelinesModal(true)}
        />
      );
    } else if (
      activationGuidelineStatus === ActivationGuidelineStatus.InProgress
    ) {
      return (
        <InProgressSegmentCard
          onClick={() => setShowSegmentGuidelinesModal(true)}
        />
      );
    }

    return (
      <ValidSegmentCard onClick={() => setShowSegmentGuidelinesModal(true)} />
    );
  }, [
    parentCustomerGroup?.activationGuidelineStatus,
    parentCustomerGroup?.segments,
    parentCustomerGroup?.activationGuidelinesRefreshDateUtc,
  ]);

  const infoPanelTabs = [
    {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          <SegmentSummary
            owner={owner}
            ownerIsLoading={usersLoading}
            segment={focalSegment}
          />
        </div>
      ),
      title: "Segment",
    },
    {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          <CustomerGroupSummary
            group={parentGroupWithTypeDisplayName}
            title="Group info"
          />
        </div>
      ),
      title: "Group info",
    },
  ];

  if (flags[FeatureFlag.SharingGroups]) {
    const activityTab = {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          {usersLoading ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <InfoPanelActivity
              currentUser={currentUser}
              resourceType="group"
              sharedUsers={reportSharedUsers}
              sharingActivities={sharingActivities}
            />
          )}
        </div>
      ),
      title: "Activity",
    };
    infoPanelTabs.push(activityTab);
  }

  const header = (
    <>
      <SegmentLibraryInfoPanelHeader focalSegment={focalSegment} />
      {statusCard}
      <Nav activeIndex={currentNavIndex} variant={NavVariant.Tab}>
        {infoPanelTabs.map((tab, index) => (
          <NavButton key={tab.title} onClick={() => setCurrentNavIndex(index)}>
            {tab.title}
          </NavButton>
        ))}
      </Nav>
    </>
  );

  const body = infoPanelTabs[currentNavIndex].content;

  return (
    <>
      <PanelWithSideDrawer
        closeFunc={onClose}
        drawerContent={{
          body,
          header,
          label,
        }}
        isOverlay={false}
        showDrawer={showDrawer}
      >
        {children}
      </PanelWithSideDrawer>
      <SegmentGuidelinesModal
        inProgress={
          parentCustomerGroup?.activationGuidelineStatus ===
          ActivationGuidelineStatus.InProgress
        }
        onClose={() => setShowSegmentGuidelinesModal(false)}
        segments={parentCustomerGroup?.segments ?? []}
        show={showSegmentGuidelinesModal}
      />
    </>
  );
};
