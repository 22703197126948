import { Spinner } from "@qbit/react";
import { type FocalItem } from "@quantium-enterprise/common-ui";
import { HierarchyShortName } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback } from "react";
import Zod from "zod";
import { ParameterSingleSelect } from "../../fast-report/ParameterSingleSelect";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { type SingleSelectParameterConfiguration } from "../../fast-report/api/parameterConfiguration";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import styles from "./ShareOfDropdown.module.css";

export const ShareOfValueSchema = Zod.string();
export type ShareOfValue = Zod.infer<typeof ShareOfValueSchema>;

export type ShareOfDropdownProps = {
  activeItem?: FocalItem;
};

export const ShareOfDropdown = ({ activeItem }: ShareOfDropdownProps) => {
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.storeByStore?.shareOf,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState<
    ShareOfValue,
    SingleSelectParameterConfiguration<string>
  >(
    Parameter.StoreByStoreShareOf,
    ShareOfValueSchema,
    (config) => config.reports.storeByStore?.shareOf,
    reportConfig.configuration?.defaultOption,
    false
  );

  const selectionChangedHandler = useCallback(
    (value: ShareOfValue) => {
      setSelection(value);
    },
    [setSelection]
  );

  const getDropdown = () => {
    if (!reportConfig.configuration) {
      return <Spinner />;
    }

    return (
      <ParameterSingleSelect
        configuration={{ ...reportConfig.configuration }}
        data-cy="ShareOfSelector"
        disabled={activeItem?.type === HierarchyShortName.ProductGroup}
        onSelection={selectionChangedHandler}
        selection={selection ?? ""}
      />
    );
  };

  return (
    <div className={styles.shareOfDropdownContainer}>
      <label className={styles.shareOfTitle} htmlFor="ShareOfSelector">
        Share of
      </label>
      {getDropdown()}
    </div>
  );
};
