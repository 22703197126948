import {
  FeatureFlag,
  HierarchyItemType,
  HierarchyType,
  useHierarchyMetadataQuery,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import WarningBanner from "components-ui/src/error-banner/WarningBanner";
import { TransactionSourceIcon } from "components-ui/src/icons";
import { differenceInWeeks } from "date-fns";
import { useState, useMemo, useCallback, useEffect } from "react";
import { useReportTabState } from "report-tabs-ui";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import ReportLoadingWrapper from "../../fast-report/ReportLoadingWrapper";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { MetricNameSchema } from "../../fast-report-content/MetricsTypeahead";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../ReportPanel.module.css";
import { type FastReportingTableSorting } from "../common-table-utils/hooks";
import { isNewFocalItem } from "../focalItemDto";
import {
  useDisplayEntitlements,
  useFilenameForExport,
  useGetFeaturesForFocalItem,
  useParameterValuesForCSVExport,
} from "../hooks";
import { type LevelOfAnalysisValue } from "./LevelOfAnalysisDropdown";
import { LevelOfAnalysisValueSchema } from "./LevelOfAnalysisDropdown";
import { MetricBreakdownValueSchema } from "./MetricBreakdownParameter";
import {
  type ShowDeletedProductsValue,
  ShowDeletedProductsValueSchema,
} from "./ShowDeletedProductsToggle";
import { type ShowNewProductsOnlyValue } from "./ShowNewProductsOnlyToggle";
import { ShowNewProductsOnlyValueSchema } from "./ShowNewProductsOnlyToggle";
import { TopAndBottomParametersHeader } from "./TopAndBottomParametersHeader";
import { type TopAndBottomReportMetric } from "./TopAndBottomReportMetric";
import TopAndBottomTable from "./TopAndBottomTable";
import { type topAndBottomResponseItem } from "./api/topAndBottomDtos";
import { useGetTopAndBottomMeasures } from "./hooks";

export type TopAndBottomTableItem = topAndBottomResponseItem & {
  isNewLine: boolean;
};

export const TOP_AND_BOTTOM_REPORT_FEATURE_NAME = "top-and-bottom-report";

const TopAndBottomReportPanel = () => {
  const [topAndBottomTransactionSource, setTopAndBottomTransactionSource] =
    useState<TransactionSource | null>(null);

  const [topAndBottomMetricsParameter] = useFastReportingParameterState(
    Parameter.TopAndBottomMetrics,
    MetricNameSchema,
    (config) => config.reports.topAndBottom?.metrics
  );

  const flags = useFlags();
  const division = useDivision();
  const reportConfiguration = useReportConfigurationQuery(
    { division: division.name },
    { skip: !division.name }
  );
  const activeItem = useActiveItem();
  const displayEntitlements = useDisplayEntitlements();
  const [globalParameters] = useGlobalParameters();

  const [levelOfAnalysis] = useReportTabState<LevelOfAnalysisValue>(
    Parameter.TopAndBottomLevelOfAnalysis,
    LevelOfAnalysisValueSchema
  );
  const [metricBreakdown] = useReportTabState<string | null>(
    Parameter.TopAndBottomMetricBreakdown,
    MetricBreakdownValueSchema
  );

  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.topAndBottom,
        state,
      }),
      skip: !division.name,
    }
  );

  const hierarchyMetadata = useHierarchyMetadataQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      getAllAttributes: false,
    },
    { skip: !division.name }
  );

  const availableMetrics = useMemo(() => {
    if (reportConfig.configuration) {
      return reportConfig.configuration.metricMetadata.map(
        (metricMetadata) => ({
          displayName: metricMetadata.displayName,
          key: metricMetadata.key,
          format: metricMetadata.format,
        })
      );
    }

    return undefined;
  }, [reportConfig.configuration]);

  const topAndBottomMetrics = useMemo(() => {
    const mappedSelection = [] as TopAndBottomReportMetric[];
    if (topAndBottomMetricsParameter) {
      for (const selected of topAndBottomMetricsParameter) {
        const metricMetadata = reportConfig.configuration?.metricMetadata.find(
          (mm) => mm.key === selected
        );

        mappedSelection.push({
          key: metricMetadata?.key,
          format: metricMetadata?.format,
          displayName: metricMetadata?.displayName,
        } as TopAndBottomReportMetric);
      }
    }

    return mappedSelection;
  }, [
    topAndBottomMetricsParameter,
    reportConfig.configuration?.metricMetadata,
  ]);

  const measures = useGetTopAndBottomMeasures(
    activeItem,
    topAndBottomMetrics,
    levelOfAnalysis,
    reportConfig.configuration?.metricBreakdown ? metricBreakdown : null,
    division
  );

  useEffect(() => {
    setTopAndBottomTransactionSource(measures.data?.transactionSource ?? null);
  }, [setTopAndBottomTransactionSource, measures.data?.transactionSource]);

  const [sorting, setSorting] = useState<FastReportingTableSorting>();

  const levelOfAnalysisDisplayName =
    reportConfig.configuration?.levelOfAnalysis?.options.find(
      (option) => option.value === levelOfAnalysis
    )?.displayName;

  const [showNewProductsOnly] = useReportTabState<ShowNewProductsOnlyValue>(
    Parameter.TopAndBottomShowNewProductsOnly,
    ShowNewProductsOnlyValueSchema
  );

  const topAndBottomReportTitle = useMemo(
    () => (
      <span className={styles.reportTitle}>
        Top and bottom performers
        {displayEntitlements && topAndBottomTransactionSource && (
          <TransactionSourceIcon
            availableTransactionSources={division.transactionSources}
            transactionSource={topAndBottomTransactionSource}
          />
        )}
      </span>
    ),
    [
      displayEntitlements,
      division.transactionSources,
      topAndBottomTransactionSource,
    ]
  );

  const [globalParameterCsvValues, getParameterCsvValue] =
    useParameterValuesForCSVExport();

  const filename = useFilenameForExport("TopAndBottom");

  const isLeafLevel =
    hierarchyMetadata.currentData?.find(
      (hm) => hm.shortName === levelOfAnalysis
    )?.isLeaf ?? false;

  const [showDeletedProducts] = useReportTabState<ShowDeletedProductsValue>(
    Parameter.TopAndBottomShowDeletedProducts,
    ShowDeletedProductsValueSchema
  );

  let tableItems: TopAndBottomTableItem[] = useMemo(() => {
    const items = measures.data?.items;

    if (!items) {
      return [];
    }

    return items.map((item) => ({
      ...item,
      isNewLine: isNewFocalItem(item, globalParameters.focusPeriod?.startDate),
    }));
  }, [measures.data, globalParameters.focusPeriod]);

  if (
    flags[FeatureFlag.TopAndBottomImprovements] &&
    isLeafLevel &&
    !showDeletedProducts
  ) {
    tableItems = tableItems.filter((item) => !item.isDeletedLine);
  }

  const subColumns = useMemo(
    () => Object.keys(measures.data?.items[0]?.measureResults ?? {}),
    [measures]
  );

  const getDataForCsvExport = useCallback(() => {
    const isLeafLOA = hierarchyMetadata.currentData?.find(
      (hm) => hm.shortName === levelOfAnalysis
    )?.isLeaf;

    if (sorting) {
      const parameters = [
        ...globalParameterCsvValues,
        `Level of analysis: ${getParameterCsvValue(
          (config) => config.reports.topAndBottom?.levelOfAnalysis,
          levelOfAnalysis
        )}`,
      ];
      const isMetricSplit = subColumns.length > 1;

      let header = ["Rank"];

      if (isLeafLOA) {
        header.push("Product number");
      }

      header.push("Item name");

      if (isMetricSplit) {
        header = header.concat(
          topAndBottomMetrics.flatMap((metric) =>
            subColumns.map((splitName) => `${metric.displayName} ${splitName}`)
          )
        );
      } else {
        header = header.concat(
          topAndBottomMetrics.map((metric) => metric.displayName)
        );
      }

      let sortedSplit: string;
      let sortedMeasure: string;
      if (isMetricSplit) {
        const columnIdParts = sorting.columnId.split("_");
        sortedSplit = columnIdParts[1];
        sortedMeasure = columnIdParts[0];
      } else {
        sortedMeasure = sorting.columnId;
        sortedSplit = subColumns[0];
      }

      const sortedRows = [...tableItems].sort((a, b) => {
        const aValue = a.measureResults[sortedSplit]?.[sortedMeasure] ?? 0;
        const bValue = b.measureResults[sortedSplit]?.[sortedMeasure] ?? 0;
        return sorting.isDescending ? bValue - aValue : aValue - bValue;
      });

      const dataRows = sortedRows.map((item, index) => {
        const row = [(index + 1).toString()];

        if (isLeafLOA) {
          // To maintain the alignment of headers + cols it's better
          // to have empty cols if we end up in the scenario where items are missing numbers
          // (or the regex fails to strip them)
          row.push(item.number ?? "");
        }

        row.push(item.name);

        if (isMetricSplit) {
          for (const metric of topAndBottomMetrics) {
            for (const metricSplitName of subColumns) {
              row.push(
                item.measureResults[metricSplitName]?.[
                  metric.key
                ]?.toString() ?? ""
              );
            }
          }
        } else {
          for (const metric of topAndBottomMetrics) {
            row.push(
              item.measureResults[sortedSplit]?.[metric.key]?.toString() ?? ""
            );
          }
        }

        return row;
      });

      return [parameters, header, ...dataRows];
    }

    throw new Error("Unable to get CSV data as report has not loaded");
  }, [
    tableItems,
    sorting,
    globalParameterCsvValues,
    getParameterCsvValue,
    levelOfAnalysis,
    topAndBottomMetrics,
    subColumns,
    hierarchyMetadata.currentData,
  ]);

  if (isLeafLevel && showNewProductsOnly && globalParameters.focusPeriod) {
    tableItems = tableItems.filter((item) => item.isNewLine);
  }

  const features = useGetFeaturesForFocalItem(division, activeItem);

  const getTopAndBottomReport = useCallback(() => {
    if (!activeItem || !reportConfiguration.currentData?.reports.topAndBottom) {
      return null;
    }

    if (activeItem.type === HierarchyItemType.Leaf) {
      return (
        <WarningBanner
          data-cy="TopAndBottomReportLeafWarningBanner"
          text="This report is not available when viewing a product."
        />
      );
    }

    if (globalParameters.focusPeriod) {
      const focusPeriodWeeks =
        differenceInWeeks(
          new Date(globalParameters.focusPeriod.endDate),
          new Date(globalParameters.focusPeriod.startDate)
        ) + 1;

      if (
        focusPeriodWeeks >
        reportConfiguration.currentData.reports.topAndBottom.maxFocalWeeks
      ) {
        return (
          <WarningBanner
            data-cy="TopAndBottomReportFocusPeriodWarningBanner"
            text={`This report cannot be run on a ${focusPeriodWeeks} week focus period.`}
          />
        );
      }
    }

    return (
      <ErrorBoundary>
        <div data-cy="TopAndBottomReportPanel">
          <div className={styles.reportHeader}>
            <TopAndBottomParametersHeader />

            <div className={styles.reportOptions}>
              {!reportConfiguration.currentData.exportDisabled && (
                <DataTableOptions
                  disabled={!measures.data || !sorting || measures.isFetching}
                  filename={filename}
                  invokeCSVDownload={getDataForCsvExport}
                  isFeatureEnabled
                />
              )}
            </div>
          </div>

          <ReportLoadingWrapper
            data-cy="TopAndBottomReport"
            isError={measures.isError}
            isLoading={measures.isFetching}
            noData={!measures.data}
            reportMinimumHeight={560}
            retry={measures.refetch}
          >
            {availableMetrics && measures.data && (
              <TopAndBottomTable
                aggregateSplitValues={
                  measures.data.measureAggregateSplitResults
                }
                aggregateValues={measures.data.measureAggregateResults}
                availableMetrics={availableMetrics}
                items={tableItems}
                key={measures.requestId}
                levelOfAnalysis={levelOfAnalysisDisplayName ?? ""}
                onSortingChanged={setSorting}
                selectedMetrics={topAndBottomMetrics.map(
                  (metric) => metric.key
                )}
              />
            )}
          </ReportLoadingWrapper>
        </div>
      </ErrorBoundary>
    );
  }, [
    activeItem,
    reportConfiguration.currentData?.reports.topAndBottom,
    reportConfiguration.currentData?.exportDisabled,
    globalParameters.focusPeriod,
    measures.data,
    measures.isFetching,
    measures.isError,
    measures.refetch,
    measures.requestId,
    sorting,
    filename,
    getDataForCsvExport,
    availableMetrics,
    levelOfAnalysisDisplayName,
    tableItems,
    topAndBottomMetrics,
  ]);

  return (
    <div data-cy="TopAndBottomReportPanel">
      {reportConfiguration.data?.reports.topAndBottom?.disabled === false &&
        features?.find(
          (feature) => feature === TOP_AND_BOTTOM_REPORT_FEATURE_NAME
        ) && (
          <Accordion
            className={styles.reportAccordion}
            subtitle="Identify the top and bottom performers behind key metrics."
            title={topAndBottomReportTitle}
          >
            {getTopAndBottomReport()}
          </Accordion>
        )}
    </div>
  );
};

export default TopAndBottomReportPanel;
