import {
  type SearchRequestDto,
  type HierarchyItemDto,
  type HierarchyMetadataResponseDto,
} from "@quantium-enterprise/common-ui";
import {
  HierarchyItemType,
  HierarchyType,
  hierarchyServiceApi,
} from "@quantium-enterprise/common-ui";
import { FAST_REPORTING_FEATURE_NAME } from "../../FastReporting";
import { store } from "../../store";
import {
  getUniqueKey,
  isSelectableHierarchyItem,
  type SelectableHierarchyItem,
  type SelectableItem,
} from "../SelectableItem";
import { type SearchStrategy, SearchStrategyStatus } from "./SearchStrategy";

export class DefaultHierarchyStrategy implements SearchStrategy {
  private hierarchyMetadata: HierarchyMetadataResponseDto[] = [];

  private searchQuery: string = "";

  private expandedItems: SelectableHierarchyItem[] = [];

  private hierarchyFlatSearchItems: SelectableHierarchyItem[] = [];

  private hierarchyExpandSearchItems: SelectableHierarchyItem[] = [];

  private readonly division: string;

  private readonly pageSize: number;

  private readonly hierarchyStartLevel: string;

  private readonly isUserSupplier: boolean;

  private readonly scanFeatureEntitlementsEnabled: boolean;

  private status: SearchStrategyStatus = SearchStrategyStatus.Uninitialized;

  public onStatusChanged: ((status: SearchStrategyStatus) => void) | undefined;

  public onItemsChanged: ((status: SelectableItem[]) => void) | undefined;

  public onExpandedItemsChanged:
    | ((items: SelectableItem[]) => void)
    | undefined;

  public constructor(
    division: string,
    pageSize: number,
    hierarchyStartLevel: string,
    isUserSupplier: boolean,
    scanFeatureEntitlementsEnabled: boolean
  ) {
    this.division = division;
    this.pageSize = pageSize;
    this.hierarchyStartLevel = hierarchyStartLevel;
    this.isUserSupplier = isUserSupplier;
    this.scanFeatureEntitlementsEnabled = scanFeatureEntitlementsEnabled;
  }

  private setExpandedItems(items: SelectableHierarchyItem[]) {
    this.expandedItems = items;
    if (this.onExpandedItemsChanged) {
      this.onExpandedItemsChanged(items);
    }
  }

  public hierarchyLevelSelected(): void {
    // Do nothing since you can't select an additional hierarchy for a hierarchy item
  }

  public initialize() {
    if (this.status === SearchStrategyStatus.Uninitialized) {
      (async () => {
        await this.expandRootQuery();
      })();
    }
  }

  public setSearchQuery(query: string) {
    const previousSearch = this.searchQuery;
    this.searchQuery = query;

    const isSwitchingToExpandableView =
      previousSearch.length > 0 && query.length === 0;

    if (isSwitchingToExpandableView) {
      if (this.onItemsChanged) {
        this.onItemsChanged(this.hierarchyExpandSearchItems);
      }

      this.setStatus(SearchStrategyStatus.Success);
      return;
    }

    if (query.length > 0) {
      this.setStatus(SearchStrategyStatus.Loading);
      (async () => await this.search(0))();
    }
  }

  public loadMore(item: SelectableItem) {
    if (this.isFlatStructure()) {
      const currentPage = this.hierarchyFlatSearchItems.length / this.pageSize;
      (async () => await this.search(currentPage))();
    } else if (item.parent) {
      const subRowLength = item.parent.subRows?.length;
      const currentPage = subRowLength ? subRowLength / this.pageSize : 0;
      (async () => {
        if (isSelectableHierarchyItem(item)) {
          await this.loadSubRows(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            item.parent!,
            currentPage
          );
        }
      })();
    }
  }

  private setStatus(status: SearchStrategyStatus) {
    this.status = status;

    if (this.onStatusChanged) {
      this.onStatusChanged(status);
    }
  }

  public getStatus() {
    return this.status;
  }

  private setExpandableViewItems(items: SelectableHierarchyItem[]) {
    this.hierarchyExpandSearchItems = items;

    if (this.onItemsChanged && !this.isFlatStructure()) {
      this.onItemsChanged(items);
    }
  }

  private setSearchViewItems(items: SelectableHierarchyItem[]) {
    this.hierarchyFlatSearchItems = items;

    if (this.onItemsChanged && this.isFlatStructure()) {
      this.onItemsChanged(items);
    }
  }

  private isFlatStructure(): boolean {
    return this.searchQuery !== "";
  }

  public getExpandedItems(): SelectableHierarchyItem[] {
    return this.expandedItems;
  }

  public getItems(): SelectableHierarchyItem[] {
    return this.isFlatStructure()
      ? this.hierarchyFlatSearchItems
      : this.hierarchyExpandSearchItems;
  }

  private findItem(
    code: string,
    shortName: string,
    items: SelectableHierarchyItem[] | undefined
  ): SelectableHierarchyItem | undefined {
    if (items) {
      for (const item of items) {
        if (item.code === code && item.shortName === shortName) {
          return item;
        }

        const found = this.findItem(code, shortName, item.subRows);
        if (found) {
          return found;
        }
      }
    }

    return undefined;
  }

  private async ExpandUntilHierarchyLevel(
    hierarchyItem: SelectableHierarchyItem,
    untilHierarchyLevel: string
  ): Promise<void> {
    const response = await store.dispatch(
      hierarchyServiceApi.endpoints.expand.initiate({
        division: this.division,
        hierarchyType: HierarchyType.Product,
        payload: {
          page: 0,
          pageSize: this.pageSize,
          parent: {
            code: hierarchyItem.code,
            shortName: hierarchyItem.shortName,
          },
        },
      })
    );

    if (response.data) {
      for (const expandedItem of response.data.results) {
        const hierarchyLevel = this.hierarchyMetadata.find(
          (hm) => hm.shortName === expandedItem.shortName
        );

        const expandHierarchyItem = {
          code: expandedItem.code,
          hasLoadableChildRows: !hierarchyLevel?.isLeaf,
          name: expandedItem.name,
          shortName: expandedItem.shortName,
          type: hierarchyLevel?.isLeaf
            ? HierarchyItemType.Leaf
            : HierarchyItemType.Hierarchy,
          entitlements:
            expandedItem.transactionSourceAccess?.[expandedItem.shortName] ??
            [],
        } as SelectableHierarchyItem;

        if (!hierarchyItem.subRows) {
          hierarchyItem.subRows = [];
        }

        hierarchyItem.subRows.push(expandHierarchyItem);

        if (expandHierarchyItem.shortName !== untilHierarchyLevel) {
          await this.ExpandUntilHierarchyLevel(
            expandHierarchyItem,
            untilHierarchyLevel
          );
          this.setExpandedItems([...this.expandedItems, expandHierarchyItem]);
        }
      }
    }

    if (hierarchyItem.subRows) {
      for (const [index, subRow] of hierarchyItem.subRows.entries()) {
        subRow.parent = hierarchyItem;

        if (
          response.data?.hasNextPage &&
          index === hierarchyItem.subRows.length - 1
        ) {
          subRow.isMoreRow = true;
        } else {
          subRow.isMoreRow = false;
        }
      }
    }
  }

  private async expandRootQuery(): Promise<void> {
    const hierarchyMetadataResponse = await store.dispatch(
      hierarchyServiceApi.endpoints.hierarchyMetadata.initiate({
        division: this.division,
        hierarchyType: HierarchyType.Product,
      })
    );

    if (!hierarchyMetadataResponse.data) {
      this.setStatus(SearchStrategyStatus.Error);
      return;
    }

    this.hierarchyMetadata = hierarchyMetadataResponse.data;

    const response = await store.dispatch(
      hierarchyServiceApi.endpoints.getRootNodes.initiate({
        division: this.division,
        hierarchyType: HierarchyType.Product,
        payload: {
          page: 0,
          pageSize: this.pageSize,
        },
      })
    );

    const hierarchyItems: SelectableHierarchyItem[] = [];

    if (response.data) {
      const expandTasks: Array<Promise<void>> = [];
      const expandedItems: SelectableHierarchyItem[] = [];
      for (const result of response.data.results) {
        const hierarchyLevel = this.hierarchyMetadata.find(
          (hm) => hm.shortName === result.shortName
        );

        const rootNodeHierarchyItem = {
          code: result.code,
          hasLoadableChildRows: !hierarchyLevel?.isLeaf,
          name: result.name,
          shortName: result.shortName,
          type: hierarchyLevel?.isLeaf
            ? HierarchyItemType.Leaf
            : HierarchyItemType.Hierarchy,
          entitlements:
            result.transactionSourceAccess?.[result.shortName] ?? [],
        } as SelectableHierarchyItem;

        // Check if configured shortName is in the same hierarchy structure as the root items
        const canExpandToStartLevel =
          hierarchyLevel?.structureName ===
          this.hierarchyMetadata.find(
            (meta) => meta.shortName === this.hierarchyStartLevel
          )?.structureName;

        if (
          canExpandToStartLevel &&
          rootNodeHierarchyItem.shortName !== this.hierarchyStartLevel
        ) {
          expandedItems.push(rootNodeHierarchyItem);
          expandTasks.push(
            this.ExpandUntilHierarchyLevel(
              rootNodeHierarchyItem,
              this.hierarchyStartLevel
            )
          );
        }

        hierarchyItems.push(rootNodeHierarchyItem);
      }

      await Promise.all(expandTasks);
      this.setExpandedItems([...this.expandedItems, ...expandedItems]);
    }

    if (hierarchyItems.length > 0) {
      this.setExpandableViewItems(hierarchyItems);
      this.setStatus(SearchStrategyStatus.Success);
    } else {
      this.setStatus(SearchStrategyStatus.Error);
    }
  }

  public expandItem(item: SelectableItem) {
    if (item.type === HierarchyItemType.Hierarchy) {
      if ((item.subRows?.length ?? 0) === 0) {
        item.isExpanding = true;
        (async () => await this.loadSubRows(item))();
      }

      this.setExpandedItems([...this.expandedItems, item]);
    }
  }

  public unexpandItem(item: SelectableItem) {
    if (!isSelectableHierarchyItem(item)) {
      return;
    }

    this.setExpandedItems(
      this.expandedItems.filter(
        (existing) => getUniqueKey(existing) !== getUniqueKey(item)
      )
    );
  }

  private async loadSubRows(item: SelectableHierarchyItem, page: number = 0) {
    const response = await store.dispatch(
      hierarchyServiceApi.endpoints.expand.initiate({
        division: this.division,
        hierarchyType: HierarchyType.Product,
        payload: {
          page,
          pageSize: this.pageSize,
          parent: {
            code: item.code,
            shortName: item.shortName,
          },
        },
      })
    );

    if (response.data) {
      const mappedResults: SelectableHierarchyItem[] = [];

      for (const result of response.data.results) {
        const hierarchyLevel = this.hierarchyMetadata.find(
          (hm) => hm.shortName === result.shortName
        );

        const mappedResult = {
          code: result.code,
          hasLoadableChildRows: !hierarchyLevel?.isLeaf,
          name: result.name,
          shortName: result.shortName,
          type: hierarchyLevel?.isLeaf
            ? HierarchyItemType.Leaf
            : HierarchyItemType.Hierarchy,
          entitlements:
            result.transactionSourceAccess?.[result.shortName] ?? [],
        } as SelectableHierarchyItem;

        mappedResults.push(mappedResult);
      }

      if (item.subRows && item.subRows.length > 0) {
        item.subRows.push(...mappedResults);
      } else {
        item.subRows = mappedResults;
      }

      const newItems = [...this.hierarchyExpandSearchItems];

      const expandItem = this.findItem(item.code, item.shortName, newItems);

      if (expandItem) {
        expandItem.subRows = item.subRows;
        for (const subRow of expandItem.subRows) {
          subRow.parent = expandItem;
          subRow.isMoreRow = false;
        }

        expandItem.subRows[expandItem.subRows.length - 1].isMoreRow =
          response.data.hasNextPage;
      }

      this.setExpandableViewItems(newItems);
      this.setStatus(SearchStrategyStatus.Success);
    } else {
      this.setStatus(SearchStrategyStatus.Error);
    }
  }

  private async search(currentPage: number = 0) {
    const requestSearchQuery = this.searchQuery;
    let payload: SearchRequestDto = {
      page: currentPage,
      pageSize: this.pageSize,
      query: requestSearchQuery,
      includeCodes: true,
    };

    if (this.scanFeatureEntitlementsEnabled) {
      payload = {
        ...payload,
        featureModules: [FAST_REPORTING_FEATURE_NAME],
        featureFilter: { module: FAST_REPORTING_FEATURE_NAME },
      };
    }

    const response = await store.dispatch(
      hierarchyServiceApi.endpoints.search.initiate({
        division: this.division,
        hierarchyType: HierarchyType.Product,
        payload,
      })
    );

    if (requestSearchQuery !== this.searchQuery) {
      // Search query doesn't match what's in the state, ignore as it must be an old request.
      return;
    }

    if (response.data) {
      const mappedResults: SelectableHierarchyItem[] =
        this.convertFromHierarchyResponse(response.data.results, true);

      if (response.data.count > 0) {
        mappedResults[response.data.count - 1].isMoreRow =
          response.data.hasNextPage;
      }

      let flatSearchItems = [...this.hierarchyFlatSearchItems];

      if (flatSearchItems.length > 0 && currentPage > 0) {
        for (const flatSearchItem of flatSearchItems) {
          flatSearchItem.isMoreRow = false;
        }

        flatSearchItems.push(...mappedResults);
      } else {
        flatSearchItems = mappedResults;
      }

      this.setSearchViewItems(flatSearchItems);
      this.setStatus(SearchStrategyStatus.Success);
    } else {
      this.setStatus(SearchStrategyStatus.Error);
    }
  }

  public convertFromHierarchyResponse(
    response: HierarchyItemDto[],
    expandable: boolean
  ) {
    const mappedResults: SelectableHierarchyItem[] = [];

    for (const result of response) {
      const hierarchyLevel = this.hierarchyMetadata.find(
        (hm) => hm.shortName === result.shortName
      );

      const mappedResult = {
        code: result.code,
        hasLoadableChildRows: expandable && !hierarchyLevel?.isLeaf,
        name: result.name,
        shortName: result.shortName,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Model is wrong, can be null for root items
        suffix: result.parent ? `in ${result.parent.name}` : undefined,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Model is wrong, can be null for root items
        parent: result.parent
          ? {
              code: result.parent.code,
              entitlements:
                result.parent.transactionSourceAccess?.[
                  result.parent.shortName
                ] ?? [],
              hasLoadableChildRows: true,
              isExpanding: false,
              name: result.parent.name,
              shortName: result.parent.shortName,
              type: HierarchyItemType.Hierarchy,
            }
          : undefined,
        isExpanding: false,
        type: hierarchyLevel?.isLeaf
          ? HierarchyItemType.Leaf
          : HierarchyItemType.Hierarchy,
        entitlements: result.transactionSourceAccess?.[result.shortName] ?? [],
      } as SelectableHierarchyItem;

      mappedResults.push(mappedResult);
    }

    return mappedResults;
  }
}
