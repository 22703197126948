import {
  FormBlock,
  FormBlockType,
  Input,
  RadioButton,
  FormBlockEditability,
  Spinner,
} from "@qbit/react";
import {
  HierarchyItemType,
  useGetItemsQuery,
  HierarchyShortName,
  useGetValidTransactionSourcesQuery,
  HierarchyType,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback } from "react";
import Zod from "zod";
import { FAST_REPORTING_FEATURE_NAME } from "../../FastReporting";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";

export const TransactionSourceValueSchema = Zod.string();
export type TransactionSourceValue = Zod.infer<
  typeof TransactionSourceValueSchema
>;

export const TransactionSourceFilterContent = () => {
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.transactionSource,
        state,
      }),
      skip: !division.name,
    }
  );

  const activeItem = useActiveItem();

  // we need to retrieve transaction source levels for the selected item
  // either the item is hierachy or product group, depending on state of shortname and code and group id
  // specific RTK query will run based on their state
  const [shortName, code] =
    activeItem &&
    (activeItem.type === HierarchyItemType.Hierarchy ||
      activeItem.type === HierarchyItemType.Leaf)
      ? [activeItem.shortName, activeItem.code]
      : activeItem &&
        activeItem.type === HierarchyItemType.Attribute &&
        activeItem.additionalHierarchyFilter
      ? [
          activeItem.additionalHierarchyFilter.shortName,
          activeItem.additionalHierarchyFilter.code,
        ]
      : [undefined, undefined];

  const groupId =
    activeItem && activeItem.type === HierarchyShortName.ProductGroup
      ? activeItem.productGroupId
      : undefined;

  const { data: groupTransactionSources } = useGetValidTransactionSourcesQuery(
    {
      divisionName: division.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Skipped if undefined
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const { data: ItemsData } = useGetItemsQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      payload: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Skipped if undefined
        items: [{ code: code!, shortName: shortName! }],
        page: 0,
        pageSize: 1,
        featureModules: [FAST_REPORTING_FEATURE_NAME],
        featureFilter: { module: FAST_REPORTING_FEATURE_NAME },
      },
    },
    {
      skip: !shortName && !code,
    }
  );

  const transactionSources =
    ItemsData && shortName
      ? ItemsData.results[0].transactionSourceAccess?.[shortName]
      : groupTransactionSources;

  const validTransactionSources =
    transactionSources &&
    reportConfig.configuration?.options
      .map((option) => option.value)
      ?.filter((source) => transactionSources.includes(source));

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.TransactionSource,
    TransactionSourceValueSchema,
    (config) => config.globalParameters.transactionSource,
    validTransactionSources?.[0],
    false
  );

  const handleChange = useCallback(
    (value: string) => {
      setSelection(value);
    },
    [setSelection]
  );

  return reportConfig.configuration && transactionSources ? (
    <div data-cy="TransactionSourceGlobalParameter">
      {reportConfig.configuration.options.map((option) => (
        <FormBlock blockType={FormBlockType.Radio} key={option.value}>
          <Input>
            <RadioButton
              checked={option.value === selection}
              data-key={option.value}
              data-selected={option.value === selection}
              editability={
                reportConfig.configuration?.isReadOnly ||
                !transactionSources.includes(option.value)
                  ? FormBlockEditability.Disabled
                  : FormBlockEditability.Editable
              }
              id={option.value}
              label={option.displayName}
              name="TransactionSourceSelect"
              onChange={() => handleChange(option.value)}
            />
          </Input>
        </FormBlock>
      ))}
    </div>
  ) : (
    <Spinner />
  );
};
