import { Spinner } from "@qbit/react";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { type BasePanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { SidePanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useReportConfigurationQuery } from "../fast-report/api/fastReportConfigurationApi";
import { getBannerPanel } from "./BannerFilter/BannerFilterPanel";
import { getChannelPanel } from "./ChannelFilter/ChannelFilterPanel";
import { getCompStorePanel } from "./CompStoreFilter/CompStoreFilterPanel";
import { getLocationPanel } from "./LocationFilter/LocationFilterPanel";
import { getPromotionPanel } from "./PromotionFilter/PromotionFilterPanel";
import { getStoreFormatPanel } from "./StoreFormatFilter/StoreFormatFilterPanel";
import { getFocusPeriodPanel } from "./TimePeriodFilter/TimePeriodFilterPanel";
import { getTransactionSourcePanel } from "./TransactionSourceFilter/TransactionSourceFilterPanel";

export const FastReportingSidePanel = () => {
  const division = useDivision();
  const reportConfiguration = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: ({ data }) => ({
        globalParameters: data?.globalParameters,
      }),
      skip: !division.name,
    }
  );

  const generatePanels = () => {
    const panels: BasePanel[] = [];

    if (reportConfiguration.globalParameters?.transactionSource) {
      panels.push(
        ...getTransactionSourcePanel(
          reportConfiguration.globalParameters.transactionSource.displayName
        )
      );
    }

    if (reportConfiguration.globalParameters?.focusPeriod) {
      panels.push(...getFocusPeriodPanel());
    }

    if (reportConfiguration.globalParameters?.location) {
      panels.push(...getLocationPanel());
    }

    if (reportConfiguration.globalParameters?.channel) {
      panels.push(...getChannelPanel());
    }

    if (reportConfiguration.globalParameters?.storeFormat) {
      panels.push(...getStoreFormatPanel());
    }

    if (reportConfiguration.globalParameters?.promotion) {
      panels.push(...getPromotionPanel());
    }

    if (reportConfiguration.globalParameters?.banner) {
      panels.push(...getBannerPanel());
    }

    if (reportConfiguration.globalParameters?.compStore) {
      panels.push(...getCompStorePanel());
    }

    return panels;
  };

  return reportConfiguration.globalParameters ? (
    <SidePanel panels={generatePanels()} />
  ) : (
    <Spinner />
  );
};
