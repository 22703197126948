import { type TextPanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PanelType } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { LocationFilterContent } from "./LocationFilterContent";
import { LocationFilterSummary } from "./LocationFilterSummary";

export const getLocationPanel = () => {
  const panel = {
    content: <LocationFilterContent />,
    id: "FastReportingLocationSelect",
    label: "Location",
    panelType: PanelType.TEXT,
    summary: <LocationFilterSummary />,
  } as TextPanel;

  return [panel];
};
