import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type HypercubeExportParametersDto } from "../models/hypercube-export-parameters-dto";

export const exportHypercubeApi = createApi({
  baseQuery: getBaseQuery(`/`),

  endpoints: (builder) => ({
    exportHypercube: builder.query<
      Blob,
      {
        divisionName: string;
        payload: HypercubeExportParametersDto;
      }
    >({
      query: ({ divisionName, payload }) => ({
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/api/${payload.reportType}/Hypercube/Export/${divisionName}`,
        responseHandler: async (response: Response) =>
          await downloadFileFromResponse(
            response,
            `hypercube excel data for report ${payload.reportId}`
          ),
        cache: "no-cache",
      }),

      keepUnusedDataFor: 0,
    }),
  }),
  reducerPath: "exportHypercubeApi",
  tagTypes: ["ExportHypercube"],
});

export const { useLazyExportHypercubeQuery } = exportHypercubeApi;

export default exportHypercubeApi.reducer;
