import {
  type HierarchyGroupEvaluationType,
  type HierarchyType,
} from "../enums";

export type GroupFolderDto = {
  color?: string;
  creationDateUtc?: string;
  folders?: GroupFolderDto[];
  groups?: HierarchyGroupDto[];
  id?: string;
  name?: string;
  sharedWithUserIds?: string[];
  updateDateUtc?: string;
  userId?: string;
};

export type MoveFolderRequestDto = {
  folderId: string;
  hierarchyType: HierarchyType;
  targetParentFolderId?: string;
};

export type MoveFolderResponseDto = {
  folderId: string;
  parentFolderId?: string;
};

export type MoveGroupRequestDto = {
  groupId: string;
  hierarchyType: HierarchyType;
  targetFolderId?: string;
};

export type MoveGroupResponseDto = {
  folderId?: string;
  groupId: string;
};

export type DeleteFolderRequestDto = {
  folderId?: string;
};

export type RenameGroupRequestDto = {
  groupId: string;
  hierarchyType: HierarchyType;
  proposedName: string;
};

export type RenameGroupResponseDto = {
  groupId: string;
  groupName: string;
};

export type HierarchyGroupDto = {
  createDateUtc?: string;
  evaluationType: HierarchyGroupEvaluationType;
  hierarchyType: HierarchyType;
  id?: string;
  name: string;
  rules?: HierarchyGroupRuleDto[];
  updateDateUtc?: string;
  userId?: string;
};

export type HierarchyGroupWithSharingDto = HierarchyGroupDto & {
  sharedByUserId?: string;
  sharedWithUserIds?: string[];
};

export type GroupFolder = Omit<GroupFolderDto, "folders" | "groups">;

type WithDepth = {
  depth: number;
  hasChildren: boolean;
  isFolder: boolean;
  parentId?: string;
};

export type GroupFolderWithDepth = GroupFolder & WithDepth;
export type HierarchyGroupWithSharingDtoWithDepth =
  HierarchyGroupWithSharingDto & WithDepth;
export type FolderOrGroupDto =
  | GroupFolderWithDepth
  | HierarchyGroupWithSharingDtoWithDepth;

export type HierarchyGroupSummaryDto = {
  createDateUtc: string;
  evaluationType: HierarchyGroupEvaluationType;
  hierarchyType: HierarchyType;
  items: SimpleHierarchyItemDto[];
  name: string;
  rules: HierarchyGroupRuleSummaryDto[];
  totalItems: number;
  updateDateUtc: string;
};

export enum HierarchyGroupFieldNames {
  Name = "Name",
  Rules = "Rules",
}

export enum HierarchyGroupRuleFieldNames {
  Values = "Values",
}

export type HierarchyGroupRuleDto = {
  operator: HierarchyGroupRuleOperator;
  shortName: string;
  values: string[];
};

export type HierarchyGroupRuleWithId = HierarchyGroupRuleDto & {
  id: string;
};

export type HierarchyGroupRuleWithIdAndName = {
  fullShortName?: string;
  id: string;
  operator: HierarchyGroupRuleOperator;
  shortName: string;
  values: HierarchyItemName[];
};

export type HierarchyItemName = {
  code: string;
  name?: string;
};

export type HierarchyGroupRuleSummaryDto = {
  attributeName?: string;
  operator: HierarchyGroupRuleOperator;
  shortName: string;
  totalValues: number;
  values: SimpleHierarchyItemDto[];
};

export enum HierarchyGroupRuleOperator {
  Is = "Is",
  IsNot = "IsNot",
}

export type SimpleHierarchyItemDto = {
  code: string;
  name?: string;
  shortName: string;
};
