import {
  type TimePeriodSelectionDto,
  type ReportParametersDto,
  type SelectionDto,
  ddLog,
  isBandSelectionDto,
  isSegmentationSelectionDto,
  isListSelectionDto,
  isGroupSelectionDto,
  isHierarchySelectionDto,
  isOrderedSelectionDto,
  isTimePeriodSelectionDto,
} from "@quantium-enterprise/common-ui";
import { type TransactionSourceDto } from "@quantium-enterprise/common-ui/src/models/transaction-source-dto";
import { format } from "date-fns";
import Papa from "papaparse";
import { TransactionSourceDisplayNames } from "../icons/transaction-source-icon/TransactionSourceIcon";

// \p{P} is not used as it replaces () and some location names have ().
const punctuationOrWhitespaceRegex = /[\s$^+|<>’`~=!.,:;?@#%&*[\]{}\\/"'-]/gu;
export const cleanFilename = (filename: string) =>
  filename.replaceAll(punctuationOrWhitespaceRegex, "_");

// copy pasta from stack overflow of downloading file.
// https://stackoverflow.com/a/9834261
const downloadFileInternal = (response: Blob | string, filename: string) => {
  const link = document.createElement("a");
  try {
    link.setAttribute(
      "href",
      typeof response === "string" ? response : URL.createObjectURL(response)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    // Required to be in body for FireFox
    document.body.appendChild(link);
    link.click();
  } finally {
    document.body.removeChild(link);
  }
};

const getFileNameFromHttpResponse = (headers: Headers): string => {
  const contentDispositionHeader = headers.get("Content-Disposition");

  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const result = contentDispositionHeader!.split(";")[1].trim().split("=")[1];

  // eslint-disable-next-line require-unicode-regexp
  return result.replaceAll(/"/g, "");
};

export const downloadFile = (href: string, download: string) =>
  downloadFileInternal(href, download);

export const downloadFileFromResponse = async (
  response: Response,
  errorMessage: string
) => {
  try {
    const data = await response.blob();
    const filename = getFileNameFromHttpResponse(response.headers);
    downloadFileInternal(data, filename);
  } catch (error_) {
    let error: Error | undefined;
    if (error_ instanceof Error) {
      error = error_;
    }

    ddLog(`Error downloading ${errorMessage}`, undefined, "error", error);
  }

  // Not returning anything at this point resolves in
  // return of type Promise<void>.
  // It will be safe as RTK Query will not cache data.
};

const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);
  return format(date, "dd MMM yy");
};

const getFormattedTimePeriod = (selection: TimePeriodSelectionDto) => {
  const startDateString = formatTimestamp(selection.startDate);
  const endDateString = formatTimestamp(selection.endDate);
  return `${selection.label} (${startDateString} - ${endDateString})`;
};

const mapSelectionToDisplayValue = (selection: SelectionDto) => {
  if (isListSelectionDto(selection)) {
    // also covers isLoaSelectionDto since they are the practically same DTO
    return selection.label;
  } else if (isHierarchySelectionDto(selection)) {
    return selection.hierarchyItem.name;
  } else if (isGroupSelectionDto(selection)) {
    return selection.groupName;
  } else if (isOrderedSelectionDto(selection)) {
    return selection.name;
  } else if (isSegmentationSelectionDto(selection)) {
    return selection.name;
  } else if (isBandSelectionDto(selection)) {
    return `${selection.minimum}-${selection.maximum}`;
  } else if (isTimePeriodSelectionDto(selection)) {
    return getFormattedTimePeriod(selection);
  }

  throw new Error(`unknown SelectionDto type: ${JSON.stringify(selection)}`);
};

const createReportParametersSummary = (
  reportParameters: ReportParametersDto,
  availableTransactionSources: TransactionSourceDto[] | undefined
) =>
  Papa.unparse([
    ["Global parameters", "Details"],
    ...reportParameters.parameterGroupSelections.flatMap((group) =>
      group.parameterSelections.map((parameter) => [
        parameter.name,
        parameter.selections.map(mapSelectionToDisplayValue).join(", "),
      ])
    ),
    [
      "Dataset",
      reportParameters.dataSource.map((source) => {
        const transactionSourcedisplayName =
          availableTransactionSources?.find(
            (ts) => ts.transactionSource === source
          )?.displayName ?? TransactionSourceDisplayNames[source];

        return transactionSourcedisplayName;
      }),
    ],
  ]);
const createLocalParametersSummary = (
  localParameters: Array<{ name: string; value: string }>
) =>
  Papa.unparse([
    ["Local parameters", "Details"],
    ...localParameters.map((selection) => [selection.name, selection.value]),
  ]);

export const toCSV = (
  reportParameters: ReportParametersDto | undefined,
  localParameters: Array<{ name: string; value: string }> | undefined,
  availableTransactionSources: TransactionSourceDto[] | undefined,
  data: string[][]
) => {
  const globalParameterSummary = reportParameters
    ? createReportParametersSummary(
        reportParameters,
        availableTransactionSources
      ) + "\n\n"
    : "";
  const localParameterSummary = localParameters
    ? createLocalParametersSummary(localParameters) + "\n\n"
    : "";
  const viteTitle = import.meta.env.VITE_TITLE;
  return (
    "data:text/csv;charset=utf-8," +
    globalParameterSummary +
    localParameterSummary +
    // TODO: parameterise product name (e.g. Asda = "Quantium Checkout")
    "Source, " +
    viteTitle +
    "\n\n" +
    Papa.unparse(data)
  );
};
