import { differenceInWeeks, format } from "date-fns";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../FilterSummary.module.css";

type TimePeriodFilterSummaryProps = {
  displayAsNumberOfWeeks?: boolean;
};

export const TimePeriodFilterSummary = ({
  displayAsNumberOfWeeks,
}: TimePeriodFilterSummaryProps) => {
  let text = "";

  const [globalParameters] = useGlobalParameters();
  const focusPeriod = globalParameters.focusPeriod;
  if (focusPeriod) {
    if (displayAsNumberOfWeeks) {
      const focusPeriodWeeks =
        differenceInWeeks(
          new Date(focusPeriod.endDate),
          new Date(focusPeriod.startDate)
        ) + 1;
      text = focusPeriodWeeks + " week";
      if (focusPeriodWeeks > 1) {
        text += "s";
      }
    } else {
      const timePeriodFormat = "dd MMM yy";
      const startDate = format(
        new Date(focusPeriod.startDate),
        timePeriodFormat
      );
      const endDate = format(new Date(focusPeriod.endDate), timePeriodFormat);
      text = startDate + " - " + endDate;
    }
  }

  return <div className={styles.summaryText}>{text}</div>;
};
