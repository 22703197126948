import {
  Button,
  ButtonHeight,
  ButtonVariant,
  FormBlock,
  FormBlockDesignVariant,
  FormBlockType,
  Group,
  Icon,
  IconGlyph,
  Input,
  Item,
  ItemHalign,
  ItemValign,
  Text,
  Toggle,
  ToggleLabelPosition,
  ToggleLayout,
  ToggleSize,
  ToggleVariant,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import { formatDate } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { DownloadIcon } from "components-ui/src/assets/icons/DownloadIcon";
import {
  cleanFilename,
  downloadFile,
  toCSV,
} from "components-ui/src/export/export-functions";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SearchBox } from "../../../../components/src/search-box/SearchBox";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import {
  selectHaveSelectedCustomerGroups,
  selectHaveSelectedSegments,
  selectIsSegmentLibrarySegmentsSelected,
  selectSelectedSegments,
  selectShowActivatedSegments,
  setDeleteCustomerGroupsToCurrentSelection,
  setSearchText,
  setShowActiveSegments,
} from "../../states/group-list-slice";
import { getGroupCreatorPath } from "../../utilities/route-path-formats";
import { toCSVData } from "../../utilities/segment-library-csv-utils";
import styles from "./GroupManagement.module.css";

export type CustomerGroupManagementProps = {
  customerGroupTab: CustomerGroupTab;
};

export const CustomerGroupManagementSearchAndActions = ({
  customerGroupTab,
}: CustomerGroupManagementProps) => {
  const dispatch = useDispatch();
  const division = useDivision();
  const divisionName = division.name;

  const showActivatedSegments = useSelector(selectShowActivatedSegments);
  const segmentLibrarySegmentsSelected = useSelector(
    selectIsSegmentLibrarySegmentsSelected
  );
  const haveSelectedCustomerGroups = useSelector(
    selectHaveSelectedCustomerGroups
  );
  const selectedSegments = useSelector(selectSelectedSegments);
  const haveSelectedSegments = useSelector(selectHaveSelectedSegments);

  const onSearch = useCallback(
    (searchTerm: string) => {
      dispatch(setSearchText(searchTerm));
    },
    [dispatch]
  );

  const onBulkDeleteGroupsAndFolders = useCallback(() => {
    dispatch(setDeleteCustomerGroupsToCurrentSelection());
  }, [dispatch]);

  const DeleteButton = useMemo(
    () => (
      <span>
        <Button
          disabled={segmentLibrarySegmentsSelected}
          height={ButtonHeight.XSmall}
          id={styles.deleteButton}
          onClick={onBulkDeleteGroupsAndFolders}
          variant={ButtonVariant.Danger}
        >
          <Icon
            glyph={IconGlyph.DeleteAndCloseDelete}
            id={styles.deleteIcon}
            text="Delete"
          />
          <Text>Delete</Text>
        </Button>
      </span>
    ),
    [segmentLibrarySegmentsSelected, onBulkDeleteGroupsAndFolders]
  );

  const onBulkDownloadSegmentDetails = useCallback(() => {
    const data = toCSVData(selectedSegments);
    const csvContent = toCSV(undefined, undefined, undefined, data);
    const encodedUri = encodeURI(csvContent);
    const filename = cleanFilename(
      `Multi_segment_details_${formatDate(new Date())}`
    );
    downloadFile(encodedUri, `${filename}.csv`);
  }, [selectedSegments]);

  const bulkDownloadSegmentDetailsButton = useMemo(
    () => (
      <span>
        <Button
          data-testid="download-segment-details"
          height={ButtonHeight.XSmall}
          id="download-segment-details"
          onClick={onBulkDownloadSegmentDetails}
          variant={ButtonVariant.Secondary}
        >
          <DownloadIcon />
          <Text className={styles.bulkDownloadSegmentDetailsButtonText}>
            Download segment details
          </Text>
        </Button>
      </span>
    ),
    [onBulkDownloadSegmentDetails]
  );

  const MemoisedSearchBox = useMemo(
    () => (
      <>
        <FormBlock
          blockType={FormBlockType.Search}
          designvariant={FormBlockDesignVariant.Default}
        >
          <Input>
            <SearchBox
              debounceTimeMs={500}
              enableDebounce
              onChange={onSearch}
              placeholder="Search"
            />
          </Input>
        </FormBlock>
        {haveSelectedCustomerGroups &&
          (segmentLibrarySegmentsSelected ? (
            <Tooltip
              placement={TooltipPlacement.BottomCentre}
              spaceInside={TooltipSpaceInside.Small}
              trigger={DeleteButton}
              variant={TooltipVariant.ArrowDark}
            >
              <div className={styles.tooltipText}>
                Some selected groups can't be deleted
                <br />
                as they're used in the Segment Library
              </div>
            </Tooltip>
          ) : (
            DeleteButton
          ))}
        {haveSelectedSegments &&
          customerGroupTab === CustomerGroupTab.SegmentLibrary &&
          bulkDownloadSegmentDetailsButton}
      </>
    ),
    [
      onSearch,
      segmentLibrarySegmentsSelected,
      DeleteButton,
      haveSelectedSegments,
      haveSelectedCustomerGroups,
      customerGroupTab,
      bulkDownloadSegmentDetailsButton,
    ]
  );

  return (
    <Group className={styles.sectionSearchCreate}>
      <Item halign={ItemHalign.Left}>
        <div className={styles.leftButtonGroup}>{MemoisedSearchBox}</div>
      </Item>
      {customerGroupTab === CustomerGroupTab.SegmentLibrary ? (
        <div className={styles.activatedSegmentsToggle}>
          <Item halign={ItemHalign.Right} valign={ItemValign.Middle}>
            <Toggle
              checked={showActivatedSegments}
              label="Active segments only"
              labelPosition={ToggleLabelPosition.Start}
              layout={ToggleLayout.Together}
              onClick={() =>
                dispatch(setShowActiveSegments(!showActivatedSegments))
              }
              size={ToggleSize.Small}
              variant={ToggleVariant.TickAndCross}
            />
          </Item>
        </div>
      ) : (
        <Item halign={ItemHalign.Right}>
          <Link to={getGroupCreatorPath(divisionName, "Customer")}>
            <Button
              height={ButtonHeight.XSmall}
              variant={ButtonVariant.Primary}
            >
              <Icon
                glyph={IconGlyph.AddAndPlusAddPlus}
                text="Create a customer group"
              />
              <Text>Create a customer group</Text>
            </Button>
          </Link>
        </Item>
      )}
    </Group>
  );
};
