import { TypeaheadMulti, TypeaheadWidth } from "@qbit/react";
import { type Option } from "@qbit/react/dist/typeahead";
import { useCallback, useMemo } from "react";
import Zod from "zod";
import { joinArrayWithCharacterLimit } from "../utilities";
import styles from "./MetricsTypeahead.module.css";

export const MetricNameSchema = Zod.string();

export type MetricKeyDisplayName = {
  displayName: string;
  key: string;
};

export type MetricsTypeaheadProps = {
  metrics: MetricKeyDisplayName[];
  selectedMetrics: MetricKeyDisplayName[];
  setSelectedMetrics: (selectedMetrics: MetricKeyDisplayName[]) => void;
};

const areSelectedMetricsEqual = (
  oldSelection: MetricKeyDisplayName[],
  newSelection: MetricKeyDisplayName[]
) => {
  if (newSelection.length !== oldSelection.length) {
    return false;
  }

  for (const [index, element] of oldSelection.entries()) {
    if (element.key !== newSelection[index].key) {
      return false;
    }
  }

  return true;
};

export const MetricsTypeahead = ({
  metrics,
  selectedMetrics,
  setSelectedMetrics,
}: MetricsTypeaheadProps) => {
  const maxMetricNameCharacterLength = 35;

  const handleSelectionChange = useCallback(
    (selectionOptions: Option[]) => {
      const newSelectedMetrics = selectionOptions.map((option) => {
        const displayName = option.value.toString();
        return {
          key: metrics.find((metric) => metric.displayName === displayName)
            ?.key,
          displayName,
        } as MetricKeyDisplayName;
      });

      if (!areSelectedMetricsEqual(selectedMetrics, newSelectedMetrics)) {
        setSelectedMetrics(newSelectedMetrics);
      }
    },
    [metrics, selectedMetrics, setSelectedMetrics]
  );

  const metricOptions = metrics.map((metric) => ({
    value: metric.displayName,
  }));

  const selectedOptions = selectedMetrics.map((metric) => ({
    value: metric.displayName,
  }));

  const placeholder = useMemo(
    () =>
      joinArrayWithCharacterLimit(
        selectedMetrics.map((metric) => metric.displayName),
        maxMetricNameCharacterLength
      ),
    [selectedMetrics, maxMetricNameCharacterLength]
  );

  return (
    <div className={styles.typeaheadContainer} data-cy="MetricsSelector">
      <label className={styles.title} htmlFor="metrics-dropdown">
        Metrics
      </label>
      <TypeaheadMulti
        hideSelections
        id="fast-reporting-metric-typeahead"
        onChange={(selection) => handleSelectionChange(selection)}
        options={[
          {
            options: metricOptions,
          },
        ]}
        placeholder={placeholder}
        selectedItems={selectedOptions}
        width={TypeaheadWidth.Fill}
      />
    </div>
  );
};
