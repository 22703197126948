import { ddLog } from "@quantium-enterprise/common-ui";
import { type DeferredFormatFunction } from "@quantium-enterprise/hooks-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { type RootState } from "../../store";
import { type HierarchicalTableRow } from "../models/DataTableDto";
import {
  useLazyGetChildrenTableDataQuery,
  useLazyGetInitialTableQuery,
  useLazyGetTableDataQuery,
} from "../services/key-measure-trends-data-table-api-slice";
import {
  selectChannel,
  selectFocalItems,
  selectLocationHierarchy,
  selectMetrics,
  selectPromotion,
  selectDataset,
  selectCompStore,
} from "../services/key-measure-trends-slice";
import { getAllNodeNumbers } from "../utils/data-table-utils";
import { KeyMeasureTrendsTopDrawerProductTable } from "./KeyMeasureTrendsTopDrawerProductTable";

export type KeyMeasureTrendsTopDrawerProductTableQueryProps = {
  eventTrackingService: Function;
  formatter: DeferredFormatFunction;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const KeyMeasureTrendsTopDrawerProductTableQuery = ({
  reportType,
  reportName,
  renameReport,
  eventTrackingService,
  formatter,
}: KeyMeasureTrendsTopDrawerProductTableQueryProps) => {
  // Grab stuff from store
  const focalItems = useSelector(selectFocalItems);
  const channel = useSelector(selectChannel);
  const compStore = useSelector(selectCompStore);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const promotion = useSelector(selectPromotion);
  const metrics = useSelector(selectMetrics);
  const dataset = useSelector(selectDataset);

  const {
    focalItemTableRows,
    focalItemTableMetadata,
    focalItemTableInitialised,
    localParametersInitialised,
  } = useSelector((state: RootState) => ({
    focalItemTableRows: state.keyMeasureTrends.focalItemTableRows,
    focalItemTableMetadata: state.keyMeasureTrends.focalItemTableMetadata,
    focalItemTableInitialised: state.keyMeasureTrends.focalItemTableInitialised,
    localParametersInitialised:
      state.keyMeasureTrends.localParametersInitialised,
  }));

  // Fetch children support
  const [isRowFetchingChildren, setIsRowFetchingChildren] = useState<{
    [id: number]: boolean;
  }>({});

  // Retrieve info from URL parameters
  const reportId = useParams().id ?? "";
  const { name: divisionName } = useDivision();

  // Initial Table Query - gets called on initial load of report
  const [getInitialTable, { isFetching: isInitialTableFetching }] =
    useLazyGetInitialTableQuery();

  const fetchInitialTable = useCallback(async () => {
    await getInitialTable({
      divisionName,
      payload: {
        parameterSelections: {
          channel: channel.value.toString(),
          compStore: compStore.value.toString(),
          location: locationHierarchy.nodeNumber,
          promotion: promotion.value.toString(),
          dataset: dataset.value as string,
        },
        reportId,
      },
    });
  }, [
    reportId,
    divisionName,
    channel,
    compStore,
    locationHierarchy,
    promotion,
    dataset,
    getInitialTable,
  ]);

  useEffect(() => {
    if (!focalItemTableInitialised && localParametersInitialised) {
      fetchInitialTable().catch((error) => {
        ddLog(
          "Error retrieving key measure trends table data",
          {},
          "error",
          error
        );
      });
    }
  }, [
    fetchInitialTable,
    focalItemTableInitialised,
    localParametersInitialised,
  ]);

  // Lazy query for refetch of the full table
  const [refetchTableQueryTrigger, { isFetching: refetchTableFetching }] =
    useLazyGetTableDataQuery({
      refetchOnFocus: true,
    });

  const fetchTable = useCallback(async () => {
    // api slice will dispatch onTableDataRecieved to update state
    await refetchTableQueryTrigger({
      divisionName,
      payload: {
        reportId,
        nodeNumbers: getAllNodeNumbers(focalItemTableRows),
        parameterSelections: {
          channel: channel.value as string,
          compStore: compStore.value as string,
          location: locationHierarchy.nodeNumber,
          promotion: promotion.value as string,
          dataset: dataset.value as string,
        },
      },
    });
  }, [
    reportId,
    divisionName,
    focalItemTableRows,
    channel,
    compStore,
    locationHierarchy,
    promotion,
    dataset,
    refetchTableQueryTrigger,
  ]);

  // Only call this fetch for entire table when we pick up on local parameter change
  useEffect(() => {
    if (focalItemTableInitialised && localParametersInitialised) {
      fetchTable().catch((error) => {
        // FIXME throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, compStore, locationHierarchy, promotion, dataset]);

  // Lazy query for fetching children
  const [fetchChildrenQueryTrigger] = useLazyGetChildrenTableDataQuery({
    refetchOnFocus: false,
  });

  const fetchChildren = useCallback(
    async (parentNode: HierarchicalTableRow) => {
      setIsRowFetchingChildren({ [parentNode.hierarchyItem.nodeNumber]: true });

      await fetchChildrenQueryTrigger({
        divisionName,
        payload: {
          reportId,
          parentNodeNumber: parentNode.hierarchyItem.nodeNumber,
          parameterSelections: {
            channel: channel.value as string,
            compStore: compStore.value as string,
            location: locationHierarchy.nodeNumber,
            promotion: promotion.value as string,
            dataset: dataset.value as string,
          },
        },
      });

      setIsRowFetchingChildren({
        [parentNode.hierarchyItem.nodeNumber]: false,
      });
    },
    [
      reportId,
      divisionName,
      channel,
      compStore,
      locationHierarchy,
      promotion,
      dataset,
      fetchChildrenQueryTrigger,
    ]
  );

  return (
    <KeyMeasureTrendsTopDrawerProductTable
      data={focalItemTableRows}
      eventTrackingService={eventTrackingService}
      fetchChildren={fetchChildren}
      formatter={formatter}
      isRowFetchingChildren={isRowFetchingChildren}
      isSuccess={!isInitialTableFetching && !refetchTableFetching}
      metadata={focalItemTableMetadata}
      renameReport={renameReport}
      reportName={reportName}
      reportType={reportType}
      selectedItems={focalItems}
      selectedMetrics={Object.values(metrics).map((option) =>
        option.value.toString()
      )}
    />
  );
};
