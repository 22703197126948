import { Text } from "@qbit/react";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../FilterSummary.module.css";

export const ChannelFilterSummary = () => {
  let text = <Text> None </Text>;
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.channel,
        state,
      }),
      skip: !division.name,
    }
  );

  const [globalParameters] = useGlobalParameters();
  const channels = globalParameters.channel;

  if (channels) {
    const selections = channels.map(
      (channel) =>
        reportConfig.configuration?.options.find((opt) => opt.value === channel)
          ?.displayName
    );

    text = <span>{selections.join(", ")}</span>;
  }

  return <div className={styles.summaryText}>{text}</div>;
};
