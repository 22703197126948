import { MessageVariant, QbitEmitToast, QbitToastMessage } from "@qbit/react";
import {
  type HierarchyType,
  EMIT_TOAST_DURATION,
  type SharedUserDto,
  type HierarchyGroupWithSharingDto,
  FeatureFlag,
  useGetUsersByIdQuery,
  useGetGroupsAndFoldersQuery,
  useLazyDownloadGroupQuery,
  useRenameGroupMutation,
  type FolderOrGroupDto,
  useRenameGroupFolderMutation,
  type GroupFolder,
  AppContext,
  createRowNameMatchesSearchPredicate,
  ShareRootFolderId,
  useCopyGroupMutation,
} from "@quantium-enterprise/common-ui";
import { type RenameGroupRequestDto } from "@quantium-enterprise/common-ui/src/models/group-dto";
import { type RenameGroupFolderRequest } from "@quantium-enterprise/common-ui/src/models/rename-group-folder-dto";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { type CellContext, type ColumnDef } from "@tanstack/react-table";
import {
  FolderIcon,
  ShareRootFolderIcon,
} from "components-ui/src/assets/icons/FolderIcon";
import { EditableField } from "components-ui/src/editable-field/EditableField";
import { ExpandChevron, HierarchyGroupIcon } from "components-ui/src/icons";
import { SharedUserIconDisplay } from "components-ui/src/shared-user-icon-display/SharedUserIconDisplay";
import sortStyles from "components-ui/src/tables/common/column-sort/ColumnSort.module.css";
import { folderPadding } from "components-ui/src/tables/common/utils";
import { useCallback, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { type GroupType } from "../../../enums/group-type";
import {
  selectExpandedIds,
  setEditingState,
  setFolderExpandedState,
  selectRenameState,
  setRenameId,
  selectSearchText,
  setScrollToId,
  selectProductOrLocationFlatFoldersAndGroups,
  setFocalGroup,
  setMoveFolderOrGroup,
  setShareFolderOrGroup,
  toggleSelectedFolderOrGroups,
  setDeleteFolderOrGroups,
} from "../../../states/group-list-slice";
import { isFolder } from "../../../utilities/folder-helper";
import {
  getDefaultGroupsPath,
  getGroupListPath,
} from "../../../utilities/route-path-formats";
import {
  TimeElapsedCell,
  TypeCell,
  GroupListTable,
  QuickActionsCell,
} from "../GroupListTable";
import styles from "./HierarchyGroupListTable.module.css";

const timeElapsedCell = (info: CellContext<FolderOrGroupDto, unknown>) =>
  info.getValue() === "" ? (
    ""
  ) : (
    <TimeElapsedCell timestamp={info.getValue<string>()} />
  );

const typeCell = (info: CellContext<FolderOrGroupDto, unknown>) => (
  <TypeCell
    icon={<HierarchyGroupIcon evaluationType={info.getValue<string>()} />}
    text={info.getValue<string>()}
  />
);

const SharedCell = (
  allGroupSharedUsers: SharedUserDto[],
  info: CellContext<FolderOrGroupDto, unknown>
) =>
  info.row.original.isFolder ? (
    ""
  ) : (
    <SharedUserIconDisplay
      users={allGroupSharedUsers.filter((user) =>
        (
          info.row.original as HierarchyGroupWithSharingDto
        ).sharedWithUserIds?.includes(user.salesforceUserId)
      )}
    />
  );

const TrueSharedCell = (
  allFolderAndGroupUserAndRecipientIds: SharedUserDto[],
  info: CellContext<FolderOrGroupDto, unknown>
) => {
  const shouldShowSharedIcons = info.row.original.sharedWithUserIds?.length;

  return shouldShowSharedIcons ? (
    <SharedUserIconDisplay
      owner={allFolderAndGroupUserAndRecipientIds.find(
        (user) =>
          info.row.original.userId === user.salesforceUserId &&
          info.row.original.sharedWithUserIds?.length
      )}
      users={allFolderAndGroupUserAndRecipientIds.filter((user) =>
        info.row.original.sharedWithUserIds?.includes(user.salesforceUserId)
      )}
    />
  ) : (
    ""
  );
};

const NameHeader = () => (
  <span>
    Name
    <span className={`${sortStyles.sortIcon} ${sortStyles.sortIconDescending}`}>
      descending
    </span>
  </span>
);

export type HierarchyGroupListTableProps = {
  hierarchyType: HierarchyType;
};

export const HierarchyGroupListTable = ({
  hierarchyType,
}: HierarchyGroupListTableProps) => {
  const { userState } = useContext(AppContext);
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();
  const flags = useFlags();
  const expandedIds = useSelector(selectExpandedIds);
  const renameState = useSelector(selectRenameState);
  const searchTerm = useSelector(selectSearchText);
  const foldersAndGroups = useSelector(
    selectProductOrLocationFlatFoldersAndGroups
  );

  const filteredFoldersAndGroups = useMemo(() => {
    let filtered: FolderOrGroupDto[];
    if (searchTerm) {
      const rowNameMatchesSearch =
        createRowNameMatchesSearchPredicate(searchTerm);
      filtered = foldersAndGroups
        .filter(
          (group) => !group.isFolder && rowNameMatchesSearch(group.name ?? "")
        )
        .map((group) => ({
          ...group,
          depth: 0,
        }));
    } else {
      filtered = foldersAndGroups.filter(
        (row) => !row.parentId || expandedIds.includes(row.parentId)
      );
    }

    return filtered;
  }, [expandedIds, foldersAndGroups, searchTerm]);

  const { isFetching: isGroupListFetching, isUninitialized } =
    useGetGroupsAndFoldersQuery(
      {
        divisionName,
        hierarchyType,
        currentUserId: userState.currentUser?.salesForceId,
        trueShareFeatureFlag: flags[FeatureFlag.ProductGroupsTrueShare],
      },
      { skip: !divisionName }
    );

  const setIsEditingLocal = (editing: boolean) => {
    dispatch(setEditingState({ editing }));
  };

  const [downloadGroupTrigger] = useLazyDownloadGroupQuery();
  const [copyGroupTrigger] = useCopyGroupMutation();

  const [renameGroupFolderMutation] = useRenameGroupFolderMutation();
  const [renameGroupMutation] = useRenameGroupMutation();

  const renameFolderTrigger = async (value: string) => {
    const payload: RenameGroupFolderRequest = {
      folderId: renameState.id ?? "",
      hierarchyType,
      proposedName: value,
    };
    try {
      await renameGroupFolderMutation({ divisionName, payload }).unwrap();
    } catch (error) {
      // @ts-expect-error cant cast it into the right type to access the status
      const errorStatus = error?.status;
      const is409 = errorStatus === 409;
      const content = is409
        ? "A folder with the same name already exists."
        : "An unknown error has occurred";
      const heading = is409 ? "Folder creation failed." : "Unknown";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
        }
      );
    }

    dispatch(setRenameId({ id: undefined }));
    dispatch(setEditingState({ editing: false }));
    return true;
  };

  const renameGroupTrigger = async (value: string) => {
    const renameRequest: RenameGroupRequestDto = {
      groupId: renameState.id ?? "",
      hierarchyType,
      proposedName: value,
    };
    try {
      await renameGroupMutation({ divisionName, renameRequest }).unwrap();
    } catch (error) {
      // @ts-expect-error cant cast it into the right type to access the status
      const errorStatus = error?.status;
      const is409 = errorStatus === 409;
      const content = is409
        ? "Please use a group name that is unique."
        : "An unknown error has occurred";
      const heading = is409 ? "Rename error" : "Unknown error";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
        }
      );
    }

    dispatch(setRenameId({ id: undefined }));
    return true;
  };

  const allFolderAndGroupUserAndRecipientIds = useMemo((): string[] => {
    const userIds: Record<string, Boolean> = {};
    for (const folderOrGroup of foldersAndGroups) {
      if (folderOrGroup.userId) {
        userIds[folderOrGroup.userId] = true;
      }

      if (folderOrGroup.sharedWithUserIds) {
        for (const sharedWith of folderOrGroup.sharedWithUserIds) {
          userIds[sharedWith] = true;
        }
      }
    }

    return Object.keys(userIds);
  }, [foldersAndGroups]);
  const { data: allGroupSharedUsers, isLoading: isAllGroupSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds: allFolderAndGroupUserAndRecipientIds,
        },
      },
      {
        skip:
          foldersAndGroups.length === 0 || !flags[FeatureFlag.SharingGroups],
      }
    );

  const setFocalGroupById = useCallback(
    (id: string | undefined) => {
      const groupOrFolder = foldersAndGroups.find((row) => row.id === id);
      if (!groupOrFolder) return;
      dispatch(setFocalGroup(groupOrFolder));
    },
    [foldersAndGroups, dispatch]
  );

  type NameCellProps = {
    depth: number;
    folderColour?: string;
    hasChildren: boolean;
    id?: string;
    isAFolder: boolean;
    isShare: boolean;
    isShareRootFolder: boolean;
    name: string;
  };

  const useNameCell = (info: CellContext<FolderOrGroupDto, unknown>) => {
    const {
      id,
      depth,
      folderColour,
      isAFolder,
      isShare,
      isShareRootFolder,
      hasChildren,
      name,
    } = info.getValue<NameCellProps>();

    const toggleFolder = (): void => {
      if (!id) return;

      if (isAFolder) {
        dispatch(
          setFolderExpandedState({
            currentUserId: userState.currentUser?.salesForceId,
            id,
            isExpanded: !expandedIds.includes(id),
          })
        );
      }
    };

    const setFocalFolder = (): void => {
      if (!id) return;

      if (groupId === id) {
        navigate(getDefaultGroupsPath(divisionName, hierarchyType));
        setFocalGroupById(undefined);
      } else if (isAFolder) {
        setFocalGroupById(id);
      }
    };

    const stylePadding = { paddingLeft: folderPadding(isAFolder, depth) };
    const toggleEditing = (isEditing: boolean) => {
      setIsEditingLocal(isEditing);
      dispatch(setRenameId({ id }));
    };

    return (
      <div className={styles.row} style={stylePadding}>
        <div
          onClick={(event) => {
            if (renameState.id === id) {
              event.stopPropagation();
            } else {
              setFocalFolder();
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleFolder();
            }
          }}
          role="button"
          style={{ display: "flex", alignItems: "center" }}
          tabIndex={0}
        >
          {isAFolder ? (
            <>
              {hasChildren ? (
                <ExpandChevron
                  isCompact={false}
                  isExpanded={expandedIds.includes(id ?? "")}
                  isLoading={false}
                  onClick={toggleFolder}
                />
              ) : (
                <div className={styles.expandChevronSpacer} />
              )}
              {isShareRootFolder ? (
                <ShareRootFolderIcon />
              ) : (
                <FolderIcon
                  folderColour={
                    folderColour &&
                    folderColour.toLowerCase() !== "white" &&
                    folderColour.toLowerCase() !== "#ffffff"
                      ? folderColour
                      : undefined
                  }
                />
              )}
              <EditableField
                editableFieldState={{
                  isEditing: renameState.id === id,
                  toggleEditing,
                }}
                minCharacters={1}
                onlyExternalState
                save={renameFolderTrigger}
                stopEditing={() => dispatch(setRenameId({ id: undefined }))}
                value={name}
              />
            </>
          ) : (
            <>
              <HierarchyGroupIcon
                className={styles.nameIcon}
                hierarchyType={hierarchyType}
              />
              <EditableField
                editableFieldState={{
                  isEditing:
                    !isShareRootFolder && !isShare && renameState.id === id,
                  toggleEditing,
                }}
                onlyExternalState
                save={renameGroupTrigger}
                stopEditing={() => dispatch(setRenameId({ id: undefined }))}
                value={name}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const handleMove = useCallback(
    (id: string | undefined) => {
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      dispatch(setMoveFolderOrGroup(group));
    },
    [foldersAndGroups, dispatch]
  );

  const handleShare = useCallback(
    (id: string | undefined) => {
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      dispatch(setShareFolderOrGroup(group));
    },
    [foldersAndGroups, dispatch]
  );

  const handleRename = useCallback(
    (id: string | undefined) => {
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      dispatch(setRenameId({ id }));
      dispatch(setEditingState({ editing: true }));
    },
    [dispatch, foldersAndGroups]
  );

  const handleDownload = useCallback(
    async (id: string | undefined) => {
      if (id) {
        await downloadGroupTrigger({ divisionName, groupId: id });
      }
    },
    [downloadGroupTrigger, divisionName]
  );

  const handleDelete = useCallback(
    (id: string | undefined) => {
      if (!id) return;
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      dispatch(setDeleteFolderOrGroups([group]));
    },
    [dispatch, foldersAndGroups]
  );

  const handleCopy = useCallback(
    async (id: string | undefined) => {
      if (!id) return;
      const group = foldersAndGroups.find((row) => row.id === id);
      if (!group) return;
      const newIds = await copyGroupTrigger({
        divisionName,
        payload: {
          sourceId: group.id ?? "",
          entityType: isFolder(group) ? "Folder" : "Group",
        },
      }).unwrap();
      const copiedGroupId = isFolder(group)
        ? newIds.newFolderRootId
        : newIds.groups[newIds.groups.length - 1].groupId;

      dispatch(
        setScrollToId({
          groupType: hierarchyType as unknown as GroupType,
          scrollToId: copiedGroupId,
        })
      );
      navigate(getGroupListPath(divisionName, hierarchyType, copiedGroupId));
    },
    [
      copyGroupTrigger,
      dispatch,
      divisionName,
      foldersAndGroups,
      hierarchyType,
      navigate,
    ]
  );

  const quickActionsCell = useCallback(
    (info: CellContext<FolderOrGroupDto, unknown>) => {
      const group = filteredFoldersAndGroups.find(
        (row) => row.id === info.getValue<string>()
      );
      const ownerId = group?.userId;
      const isOwner = userState.currentUser?.salesForceId === ownerId;
      const isSharedWithMeFolder = group?.id === ShareRootFolderId;
      const isRowFolder = group?.isFolder;

      return (
        !isSharedWithMeFolder && (
          <QuickActionsCell
            groupId={info.getValue<string>()}
            handleCopy={
              flags[FeatureFlag.ProductGroupsTrueShare] ? handleCopy : undefined
            }
            handleDelete={isOwner ? handleDelete : undefined}
            handleDownload={
              flags[FeatureFlag.DownloadGroups] && !isRowFolder
                ? handleDownload
                : undefined
            }
            handleMove={
              flags[FeatureFlag.FoldersForGroups] && isOwner
                ? handleMove
                : undefined
            }
            handleRemove={isOwner ? undefined : handleDelete}
            handleRename={isOwner ? handleRename : undefined}
            handleShare={
              flags[FeatureFlag.ProductGroupsTrueShare] && !isOwner
                ? undefined
                : handleShare
            }
          />
        )
      );
    },
    [
      filteredFoldersAndGroups,
      userState.currentUser?.salesForceId,
      flags,
      handleCopy,
      handleDelete,
      handleDownload,
      handleMove,
      handleRename,
      handleShare,
    ]
  );

  const columns: Array<ColumnDef<FolderOrGroupDto>> = [
    {
      accessorFn: (row: FolderOrGroupDto): NameCellProps => ({
        id: row.id,
        depth: row.depth,
        folderColour: row.isFolder ? (row as GroupFolder).color : "",
        hasChildren: row.hasChildren,
        isAFolder: row.isFolder,
        isShare: row.userId !== userState.currentUser?.salesForceId,
        isShareRootFolder: row.id === ShareRootFolderId,
        name: row.name ?? "",
      }),
      cell: useNameCell,
      header: NameHeader,
      id: "name",
      minSize: 100,
      maxSize: 900,
      size: 300,
    },
    {
      accessorFn: (row: FolderOrGroupDto) => row.id,
      cell: quickActionsCell,
      header: "",
      id: "menu",
      minSize: 20,
      maxSize: 20,
      size: 20,
    },
    {
      accessorFn: (row: FolderOrGroupDto) => row.updateDateUtc,
      cell: timeElapsedCell,
      header: "Updated",
      id: "updated",
      minSize: 50,
      maxSize: 100,
      size: 70,
    },
    {
      accessorFn: (row: FolderOrGroupDto) =>
        row.isFolder
          ? ""
          : (row as HierarchyGroupWithSharingDto).evaluationType,
      cell: typeCell,
      header: "Type",
      id: "type",
      minSize: 50,
      maxSize: 100,
      size: 75,
    },
  ];

  if (flags[FeatureFlag.SharingGroups]) {
    columns.push({
      accessorFn: (row: FolderOrGroupDto) =>
        row.isFolder
          ? []
          : (row as HierarchyGroupWithSharingDto).sharedWithUserIds,
      cell: flags[FeatureFlag.ProductGroupsTrueShare]
        ? (info) => TrueSharedCell(allGroupSharedUsers ?? [], info)
        : (info) => SharedCell(allGroupSharedUsers ?? [], info),
      header: "Shared",
      id: "shared",
      minSize: 50,
      maxSize: 100,
      size: 75,
    });
  }

  return (
    <GroupListTable
      columns={columns as Array<ColumnDef<FolderOrGroupDto>>}
      groupType={hierarchyType}
      isFetchUnitialised={isUninitialized}
      isFetching={isGroupListFetching || isAllGroupSharedUsersLoading}
      onCheckboxSelect={(id) => dispatch(toggleSelectedFolderOrGroups(id))}
      rows={filteredFoldersAndGroups}
      setFocalGroup={setFocalGroupById}
      sortByColumnId=""
    />
  );
};
