import { type TextPanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PanelType } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { TransactionSourceFilterContent } from "./TransactionSourceFilterContent";
import { TransactionSourceFilterSummary } from "./TransactionSourceFilterSummary";

export const getTransactionSourcePanel = (displayName: string) => {
  const panel = {
    content: <TransactionSourceFilterContent />,
    id: "FastReportingTransactionSourceSelect",
    label: displayName,
    panelType: PanelType.TEXT,
    summary: <TransactionSourceFilterSummary />,
  } as TextPanel;

  return [panel];
};
