import {
  FormBlock,
  FormBlockType,
  Input,
  Select,
  SelectOption,
  Spinner,
} from "@qbit/react";
import {
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  UnknownTrackingPropertyValue,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { type DatePickerProps } from "components-ui/src/date-picker/DatePicker";
import { addDays, format, getDay } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import {
  FocusPeriodOptionWithCustomFlagSchema,
  type FocusPeriodOption,
} from "../../fast-report/api/globalParameterConfiguration";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import CustomDatePicker from "./CustomDatePicker";
import styles from "./TimePeriodFilterContent.module.css";
import { TimePeriodFilterSummary } from "./TimePeriodFilterSummary";

export const serializeFocusPeriod = (value: FocusPeriodOption) =>
  `${value.startDate}~${value.endDate}`;

const deserializeFocusPeriod = (value: string) => {
  const sections = value.split("~");

  return {
    startDate: sections[0],
    endDate: sections[1],
  } as FocusPeriodOption;
};

const customOptionKey = "custom";

export const TimePeriodFilterContent = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.focusPeriod,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.FocusPeriod,
    FocusPeriodOptionWithCustomFlagSchema,
    (config) => config.globalParameters.focusPeriod,
    undefined,
    undefined,
    true
  );

  // Revert selection to default if current selection is invalid
  useEffect(() => {
    if (selection && reportConfig.configuration) {
      const customSelectedWhenNotEnabled =
        selection.isCustom && !reportConfig.configuration.customOptions;
      const selectedNotInOptions =
        !selection.isCustom &&
        !reportConfig.configuration.options.some(
          (option) =>
            option.value.startDate === selection.startDate &&
            option.value.endDate === selection.endDate
        );

      if (customSelectedWhenNotEnabled || selectedNotInOptions) {
        setSelection(reportConfig.configuration.defaultOption);
      }
    }
  }, [selection, reportConfig.configuration, setSelection]);

  const selectionChangedHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (selection && event.target.value === customOptionKey) {
        setSelection({ ...selection, isCustom: true });

        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingGlobal,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.FocalTimePeriod,
            "Custom"
          )
        );
        return;
      }

      const focusPeriod = deserializeFocusPeriod(event.target.value);

      if (reportConfig.configuration) {
        const parameter = reportConfig.configuration.options.find(
          (option) =>
            option.value.startDate === focusPeriod.startDate &&
            option.value.endDate === focusPeriod.endDate
        );

        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingGlobal,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.FocalTimePeriod,
            parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
          )
        );
      }

      setSelection(focusPeriod);
    },
    [reportConfig.configuration, eventTrackingService, setSelection, selection]
  );

  const selectOptions = useMemo(() => {
    const configuredOptions = reportConfig.configuration?.options.map(
      (option) => (
        <SelectOption
          data-key={option.value}
          key={serializeFocusPeriod(option.value)}
          text={option.displayName}
          value={serializeFocusPeriod(option.value)}
        />
      )
    );

    if (reportConfig.configuration?.customOptions) {
      configuredOptions?.push(
        <SelectOption
          data-key={customOptionKey}
          key={customOptionKey}
          text="Custom"
          value={customOptionKey}
        />
      );
    }

    return configuredOptions;
  }, [reportConfig.configuration]);

  const selectElement =
    selectOptions && selection ? (
      <Select
        data-cy="TimePeriodGlobalParameterDropdown"
        disabled={reportConfig.configuration?.isReadOnly}
        id="time-period-dropdown"
        onChange={selectionChangedHandler}
        readOnly={reportConfig.configuration?.isReadOnly}
        value={
          selection.isCustom ? customOptionKey : serializeFocusPeriod(selection)
        }
      >
        {selectOptions}
      </Select>
    ) : (
      <Spinner />
    );

  const commonDatePickerProps:
    | Pick<DatePickerProps, "endDate" | "fromDate" | "startDate" | "toDate">
    | undefined = useMemo(() => {
    if (!selection || !reportConfig.configuration?.customOptions) {
      return undefined;
    }

    return {
      fromDate: new Date(
        reportConfig.configuration.customOptions.earliestWeek.startDate
      ),
      toDate: new Date(
        reportConfig.configuration.customOptions.latestWeek.endDate
      ),
      startDate: new Date(selection.startDate),
      endDate: new Date(selection.endDate),
    };
  }, [reportConfig.configuration, selection]);

  const setStartDate = useCallback(
    (date: Date | undefined) => {
      if (selection && date) {
        let endDate = new Date(selection.endDate);
        if (endDate < date) {
          endDate = addDays(date, 6);
        }

        setSelection({
          ...selection,
          startDate: format(date, "yyyy-MM-dd") + "T00:00:00+00:00",
          endDate: format(endDate, "yyyy-MM-dd") + "T00:00:00+00:00",
        });
      }
    },
    [selection, setSelection]
  );

  const setEndDate = useCallback(
    (date: Date | undefined) => {
      if (selection && date) {
        let startDate = new Date(selection.startDate);
        if (startDate > date) {
          startDate = addDays(date, -6);
        }

        setSelection({
          ...selection,
          startDate: format(startDate, "yyyy-MM-dd") + "T00:00:00+00:00",
          endDate: format(date, "yyyy-MM-dd") + "T00:00:00+00:00",
        });
      }
    },
    [selection, setSelection]
  );

  const isStartDateDisabled = useCallback(
    (date: Date) => {
      if (!reportConfig.configuration?.customOptions) {
        return true;
      }

      return (
        getDay(date) !==
        getDay(
          new Date(
            reportConfig.configuration.customOptions.earliestWeek.startDate
          )
        )
      );
    },
    [reportConfig.configuration]
  );

  const isEndDateDisabled = useCallback(
    (date: Date) => {
      if (!reportConfig.configuration?.customOptions) {
        return true;
      }

      return (
        getDay(date) !==
        getDay(
          new Date(
            reportConfig.configuration.customOptions.earliestWeek.endDate
          )
        )
      );
    },
    [reportConfig.configuration]
  );

  return (
    <FormBlock
      blockType={FormBlockType.Select}
      data-cy="TimePeriodGlobalParameter"
    >
      <Input>
        <span>Focus period:</span>
        {selectElement}
      </Input>

      {commonDatePickerProps &&
      reportConfig.configuration?.customOptions &&
      selection?.isCustom ? (
        <div className={styles.customFocusPeriodSelection}>
          <div className={styles.datePicker}>
            <span>Start date</span>
            <CustomDatePicker
              selectedDate={new Date(selection.startDate)}
              {...commonDatePickerProps}
              disabled={isStartDateDisabled}
              onSelect={setStartDate}
            />
          </div>

          <div className={styles.datePicker}>
            <span>End date</span>
            <CustomDatePicker
              selectedDate={new Date(selection.endDate)}
              {...commonDatePickerProps}
              disabled={isEndDateDisabled}
              onSelect={setEndDate}
            />
          </div>
        </div>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment -- Not useless, typescript throws a wobbly without it
        <></>
      )}

      <TimePeriodFilterSummary displayAsNumberOfWeeks={selection?.isCustom} />
    </FormBlock>
  );
};
