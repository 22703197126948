import { type TransactionSource } from "@quantium-enterprise/common-ui";
import Zod from "zod";
import {
  type MultiSelectParameterConfiguration,
  type ParameterOption,
  type SingleSelectParameterConfiguration,
} from "./parameterConfiguration";

export const FocusPeriodOptionSchema = Zod.object({
  endDate: Zod.string(),
  startDate: Zod.string(),
});
export const FocusPeriodOptionWithCustomFlagSchema =
  FocusPeriodOptionSchema.extend({ isCustom: Zod.boolean().optional() });

export type FocusPeriodOption = Zod.infer<typeof FocusPeriodOptionSchema>;
export type FocusPeriodOptionWithCustomFlag = Zod.infer<
  typeof FocusPeriodOptionWithCustomFlagSchema
>;

export const LocationOptionSchema = Zod.object({
  code: Zod.string(),
  level: Zod.string(),
}).nullable();
export type LocationOption = Zod.infer<typeof LocationOptionSchema>;
export type LocationParameterConfiguration =
  MultiSelectParameterConfiguration<LocationOption> & {
    levels: Array<ParameterOption<string | null>>;
  };

export type TransactionSourceConfiguration =
  SingleSelectParameterConfiguration<TransactionSource> & {
    displayName: string;
  };

export type CustomPeriodDateRange = {
  endDate: string;
  startDate: string;
};

export type FocusPeriodConfiguration =
  SingleSelectParameterConfiguration<FocusPeriodOption> & {
    customOptions?: {
      earliestWeek: CustomPeriodDateRange;
      latestWeek: CustomPeriodDateRange;
    };
  };

export type GlobalParameterConfiguration = {
  banner?: SingleSelectParameterConfiguration<string | null>;
  channel?: MultiSelectParameterConfiguration<string | null>;
  compStore?: SingleSelectParameterConfiguration<string | null>;
  focusPeriod?: FocusPeriodConfiguration;
  location?: LocationParameterConfiguration;
  promotion?: SingleSelectParameterConfiguration<string>;
  storeFormat?: MultiSelectParameterConfiguration<string | null>;
  transactionSource?: TransactionSourceConfiguration;
};
