import {
  HierarchyType,
  useHierarchyMetadataQuery,
  useLocateAttributeQuery,
} from "@quantium-enterprise/common-ui";
import { type AttributeFocalItem } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useSearchConfigurationQuery } from "../focal-item-search/services/SearchConfigurationApi";
import { type AvailableHierarchyLevel } from "./AvailableHierarchyLevels";

export const useGetAvailableHierarchyLevelsForAttribute = (
  focalItem: AttributeFocalItem
) => {
  const pageSize = 500;
  const hierarchyType = HierarchyType.Product;
  const { name: division } = useDivision();
  const { data: searchConfigData } = useSearchConfigurationQuery({ division });
  const { data: hierarchyMetadata } = useHierarchyMetadataQuery({
    division,
    hierarchyType,
  });
  let hierarchyLevelShortNames: string[] = [];

  if (hierarchyMetadata && searchConfigData) {
    const orderedProductHierarchyMetadata = hierarchyMetadata
      .filter((hm) => hm.structureName === HierarchyType.Product)
      .sort((a, b) => a.ordinal - b.ordinal);
    const skipHierarchyLevelsIndex = orderedProductHierarchyMetadata.findIndex(
      (hm) => hm.shortName === searchConfigData.attributeHierarchyStartLevel
    );

    hierarchyLevelShortNames = orderedProductHierarchyMetadata
      .slice(skipHierarchyLevelsIndex)
      .filter((hm) => hm.isLeaf === false)
      .map((hm) => hm.shortName);
  }

  const { data: hierarchyResponse } = useLocateAttributeQuery(
    {
      division,
      hierarchyType,
      payload: {
        attribute: {
          code: focalItem.code,
          shortName: focalItem.shortName,
        },
        levelShortNames: hierarchyLevelShortNames,
        page: 0,
        pageSize,
      },
    },
    { skip: hierarchyLevelShortNames.length === 0 }
  );

  let availableHierarchyLevels: AvailableHierarchyLevel[] | undefined;
  if (hierarchyResponse && hierarchyMetadata) {
    const groupedResults: { [key: string]: AvailableHierarchyLevel } = {};
    for (const result of hierarchyResponse.results) {
      // Exclude any results which we don't have any access to
      if (!result.transactionSourceAccess?.[result.shortName]?.length) {
        continue;
      }

      if (!(result.shortName in groupedResults)) {
        const hierarchyLevel = hierarchyMetadata.find(
          (hm) => hm.shortName === result.shortName
        );
        groupedResults[result.shortName] = {
          hierarchyItems: [],
          levelName: hierarchyLevel?.name ?? "Unknown",
          shortName: result.shortName,
        };
      }

      groupedResults[result.shortName].hierarchyItems.push({
        code: result.code,
        name: result.name,
        entitlements: result.transactionSourceAccess[result.shortName] ?? [],
      });
    }

    availableHierarchyLevels = Object.keys(groupedResults)
      .sort(
        (a, b) =>
          (hierarchyMetadata.find((hm) => hm.shortName === a)?.ordinal ?? 0) -
          (hierarchyMetadata.find((hm) => hm.shortName === b)?.ordinal ?? 0)
      )
      .map((key) => groupedResults[key]);
  }

  return availableHierarchyLevels;
};
