import {
  ddLog,
  HierarchyType,
  ParameterId,
  type SearchRequestDto,
  useHierarchyMetadataQuery,
  useLazyGetRootNodesQuery,
  useLazySearchQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onHierarchyMetadataReceived,
  onHierarchyRootNodeReceived,
  onProductSearchResultsReceived,
  selectHierarchyItems,
  selectLeafShortName,
  selectSearchString,
  selectTriggerSearchState,
  selectHierarchySupportedLevelShortNames,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { LOCATION_HIERARCHY_SEARCH_QUERY_PAGE_SIZE } from "./LocationHierarchyParameter";
import { LocationHierarchyParameterTable } from "./LocationHierarchyParameterTable";

const hierarchyType = HierarchyType.Location;
const parameterType = ParameterId.LocationHierarchy;

const curatedSearchText = (searchString: string) => {
  // matches string starting with digits followed by a hyphen and a word, whitespaces are optional
  const regex = /(\d+\s*-\s?)(\w+)/gu;
  return searchString.replaceAll(regex, "$2");
};

// only search barcodes when searching for 4+ digit numbers
const isSearchBarcodes = (searchString: string): boolean =>
  /^[\d\s,]\d{4}[\d\s,]$/u.test(searchString);

export const LocationHierarchyParameterQuery = () => {
  const dispatch = useDispatch();
  const { name: division } = useDivision();

  const { items, leafShortName } = useSelector((state: RootState) => ({
    items: selectHierarchyItems(parameterType, state),
    leafShortName: selectLeafShortName(parameterType, state),
  }));

  const supportedLevelShortNames = useSelector((state: RootState) =>
    selectHierarchySupportedLevelShortNames(parameterType, state)
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(parameterType, state)
  );

  const triggerSearch = useSelector((state: RootState) =>
    selectTriggerSearchState(parameterType, state)
  );

  const [
    triggerSearchQuery,
    { isLoading: isSearchQueryLoading, isFetching: isSearchQueryFetching },
  ] = useLazySearchQuery();

  const handleApplyFilter = useCallback(async () => {
    const searchRequest: SearchRequestDto = {
      page: 0,
      pageSize: LOCATION_HIERARCHY_SEARCH_QUERY_PAGE_SIZE,
    };

    if (searchString) {
      searchRequest.focalAttributes =
        supportedLevelShortNames.length > 0
          ? supportedLevelShortNames
          : undefined;
      searchRequest.query = curatedSearchText(searchString);
      searchRequest.includeCodes = true;
      searchRequest.includeBarcodes = isSearchBarcodes(searchString);
    }

    // retrieve flat product list data when non-hierarchy level filters are applied
    await triggerSearchQuery({
      division,
      hierarchyType,
      payload: searchRequest,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          onProductSearchResultsReceived({
            childNodes: data,
            parameterType,
          })
        );
      })
      .catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
  }, [
    division,
    dispatch,
    searchString,
    supportedLevelShortNames,
    triggerSearchQuery,
  ]);

  useEffect(() => {
    if (triggerSearch) {
      handleApplyFilter().catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [dispatch, handleApplyFilter, triggerSearch]);

  const [
    triggerLazyRootNodesQuery,
    { data: rootNodesData, isSuccess: isRootNodesSuccess },
  ] = useLazyGetRootNodesQuery();

  const fetchRootNode = useCallback(async () => {
    await triggerLazyRootNodesQuery({
      division,
      hierarchyType,
      payload: {
        page: 0,
      },
    });
  }, [division, triggerLazyRootNodesQuery]);

  useEffect(() => {
    if (items.length === 0) {
      fetchRootNode().catch((error) => {
        // FIXME throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [items.length, fetchRootNode]);

  useEffect(() => {
    if (isRootNodesSuccess && rootNodesData) {
      dispatch(
        onHierarchyRootNodeReceived({
          parameterType,
          response: rootNodesData,
        })
      );
    }
  }, [isRootNodesSuccess, rootNodesData, dispatch]);

  const { data: hierarchyMetadata, isSuccess: isHierarchyMetadataSuccess } =
    useHierarchyMetadataQuery(
      {
        division,
        hierarchyType,
        getAllAttributes: true,
      },
      {
        skip: leafShortName !== "",
      }
    );

  useEffect(() => {
    if (isHierarchyMetadataSuccess && hierarchyMetadata.length > 0) {
      dispatch(
        onHierarchyMetadataReceived({ hierarchyMetadata, parameterType })
      );
    }
  }, [hierarchyMetadata, isHierarchyMetadataSuccess, dispatch]);

  const isSuccess = useMemo(() => {
    if (isSearchQueryFetching || isSearchQueryLoading) {
      return false;
    }

    return true;
  }, [isSearchQueryFetching, isSearchQueryLoading]);

  return <LocationHierarchyParameterTable isSuccess={isSuccess} />;
};
