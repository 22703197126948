import {
  type MeasureThresholdSelectionDto,
  type TimePeriodSelectionDto,
  type OrderedSelectionDto,
  type SegmentationSelectionDto,
  type HierarchyGroupDto,
  type ListSelectionDto,
  type BandSelectionDto,
  type NumberSelectionDto,
  type HierarchySelectionDto,
  type ParameterGroupSelectionDto,
  type SelectionDto,
  type GroupSelectionDto,
  type ParameterConfigurationsDto,
  ParameterId,
  SegmentationType,
  NumberFormat,
} from "@quantium-enterprise/common-ui";
import {
  isBandsParameterState,
  isBufferParameterState,
  isDropdownParameterState,
  isGroupParameterState,
  isHierarchyState,
  isLevelOfAnalysisState,
  isStructureState,
  isListParameterState,
  isMeasureThresholdParameterState,
  isSegmentFilterParameterState,
  isSegmentationParameterState,
  isRollingPeriodState,
  isTimePeriodState,
} from "../../parameters";
import { type ParameterState } from "../../states/ParameterState";

const errorMessage = (parameterId: string) =>
  `Parameter ID '${parameterId}' does not have a valid parameter state`;

const buildBandSelections = (state: ParameterState): BandSelectionDto[] => {
  if (!isBandsParameterState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return state.bands.map((band, index) => ({
    ordinal: index + 1,
    minimum: band.minimum / 100,
    maximum: band.maximum / 100,
  }));
};

const buildBufferSelections = (state: ParameterState): NumberSelectionDto[] => {
  if (!isBufferParameterState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  if (state.buffer === undefined) {
    return [];
  }

  return [{ number: state.buffer / 100, format: NumberFormat.Percent }];
};

const buildDropdownSelections = (state: ParameterState): ListSelectionDto[] => {
  if (!isDropdownParameterState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  const selection = state.selection;
  if (selection === undefined) {
    return [];
  }

  return [{ label: selection.label, value: selection.value }];
};

const buildGroupSelections = (state: ParameterState): GroupSelectionDto[] => {
  if (!isGroupParameterState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return state.confirmedSelections.map((group: HierarchyGroupDto) => ({
    groupId: group.id as string,
    groupName: group.name,
    evaluationType: group.evaluationType,
    hierarchyType: group.hierarchyType,
  }));
};

const buildHierarchySelections = (
  state: ParameterState
): HierarchySelectionDto[] => {
  if (!isHierarchyState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return state.selectedRows
    .slice()
    .sort((a, b) => a.depth - b.depth)
    .map((hs) => ({
      hierarchyItem: {
        itemCode: hs.code,
        name: hs.name,
        shortName: hs.shortName,
      },
      children: [],
      parents: [],
    }));
};

const buildLevelOfAnalysisSelections = (
  state: ParameterState
): ListSelectionDto[] => {
  if (!isLevelOfAnalysisState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return state.selections.map((selection) => ({
    label: selection.label,
    value: selection.value,
  }));
};

const buildListSelections = (state: ParameterState): ListSelectionDto[] => {
  if (!isListParameterState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return state.selections
    .slice()
    .sort((a, b) => a.index - b.index)
    .map((selection) => ({
      label: selection.label,
      value: selection.value,
    }));
};

const buildOrderedSelections = (
  state: ParameterState
): OrderedSelectionDto[] => {
  if (!isStructureState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return state.droppableZones
    .filter((zone) => zone.item !== undefined)
    .map((zone, index) => ({
      name: zone.item?.name as string,
      position: index,
      shortName: zone.item?.shortName as string,
    }));
};

const buildMeasureThresholdSelections = (
  state: ParameterState
): MeasureThresholdSelectionDto[] => {
  if (!isMeasureThresholdParameterState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return state.selections
    .filter((selection) => selection.threshold !== undefined)
    .map((selection) => ({
      measure: selection.measure,
      comparator: selection.comparator,
      threshold: {
        number:
          selection.measure.thresholdFormat === NumberFormat.Percent
            ? (selection.threshold as number) / 100
            : (selection.threshold as number),
        format: selection.measure.thresholdFormat,
      },
    }));
};

const buildRollingPeriodSelections = (
  state: ParameterState
): ListSelectionDto[] => {
  if (!isRollingPeriodState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return [{ label: state.label, value: state.value }];
};

const buildSegmentationSelections = (
  state: ParameterState
): SegmentationSelectionDto[] => {
  if (!isSegmentationParameterState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return state.selections.map((selection) => ({
    ...selection,
    segments: selection.segments?.map((segment) => ({
      key: segment.key,
      name: segment.name,
      ordinal: segment.ordinal,
    })),
  }));
};

const buildSegmentFilterSelections = (
  state: ParameterState
): SegmentationSelectionDto[] => {
  if (!isSegmentFilterParameterState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  const selection = state.selection;
  if (!selection?.customerGroup || !selection.segment) {
    return [];
  }

  return [
    {
      name: selection.customerGroup.name,
      id: selection.customerGroup.id,
      segmentationType: SegmentationType.CustomerGroup,
      segments: [
        {
          key: selection.segment.key,
          name: selection.segment.name,
          ordinal: selection.segment.ordinal,
        },
      ],
    },
  ];
};

const buildTimePeriodSelections = (
  state: ParameterState
): TimePeriodSelectionDto[] => {
  if (!isTimePeriodState(state)) {
    throw new Error(errorMessage(state.parameterConfig.id));
  }

  return [
    {
      endDate: state.endDate,
      label: state.label,
      periodValue: state.value,
      startDate: state.startDate,
    },
  ];
};

export const buildSelectionDtos = (
  id: string,
  parameters: Record<string, ParameterState>
) => {
  const ParameterMap: Record<
    NonNullable<string>,
    (state: ParameterState) => SelectionDto[]
  > = {
    [ParameterId.Bands]: buildBandSelections,
    [ParameterId.Buffer]: buildBufferSelections,
    [ParameterId.Channel]: buildListSelections,
    [ParameterId.ComparisonPeriod]: buildTimePeriodSelections,
    [ParameterId.CompStore]: buildListSelections,
    [ParameterId.Dataset]: buildListSelections,
    [ParameterId.FocusPeriod]: buildTimePeriodSelections,
    [ParameterId.LeadPeriod]: buildTimePeriodSelections,
    [ParameterId.LevelOfAnalysis]: buildLevelOfAnalysisSelections,
    [ParameterId.LocationHierarchy]: buildHierarchySelections,
    [ParameterId.LocationGroups]: buildGroupSelections,
    [ParameterId.LocationStructure]: buildOrderedSelections,
    [ParameterId.ProductHierarchy]: buildHierarchySelections,
    [ParameterId.ProductGroups]: buildGroupSelections,
    [ParameterId.ProductStructure]: buildOrderedSelections,
    [ParameterId.Promotion]: buildListSelections,
    [ParameterId.RollingPeriod]: buildRollingPeriodSelections,
    [ParameterId.Segmentation]: buildSegmentationSelections,
    [ParameterId.SegmentFilter]: buildSegmentFilterSelections,
    [ParameterId.PurchasingBehaviour]: buildListSelections,
    [ParameterId.PurchasingTrends]: buildListSelections,
    [ParameterId.NewLapsedContinuous]: buildListSelections,
    [ParameterId.RetailerCustomers]: buildListSelections,
    [ParameterId.Measure]: buildDropdownSelections,
    [ParameterId.MeasureThreshold]: buildMeasureThresholdSelections,
  };

  if (Object.keys(ParameterMap).includes(id)) {
    return ParameterMap[id](parameters[id]);
  }

  throw new Error(`Parameter Id '${id}' is missing build function.`);
};

export const buildParameterGroupSelectionDtos = (
  parameters: Record<string, ParameterState>,
  parametersData: ParameterConfigurationsDto
): ParameterGroupSelectionDto[] => {
  const parameterGroupSelections = parametersData.parameterGroups?.map(
    (parameterGroup) => {
      const parameterSelections = parameterGroup.parameters.map((parameter) => {
        const selections = buildSelectionDtos(parameter.id, parameters);

        return {
          displayType: parameter.attributes.displayType,
          id: parameter.id,
          name: parameter.name,
          selections,
        };
      });

      return {
        name: parameterGroup.label,
        parameterSelections,
      };
    }
  );

  return parameterGroupSelections ?? [];
};
