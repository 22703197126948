import {
  HierarchyItemType,
  HierarchyShortName,
  useGetUserQuery,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import WarningBanner from "components-ui/src/error-banner/WarningBanner";
import { TransactionSourceIcon } from "components-ui/src/icons";
import { differenceInWeeks } from "date-fns";
import { useMemo, useState } from "react";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useActiveItem } from "../../useActiveItem";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../ReportPanel.module.css";
import { useDisplayEntitlements, useGetFeaturesForFocalItem } from "../hooks";
import { StoreByStoreReport } from "./StoreByStoreReport";

export const STORE_BY_STORE_REPORT_FEATURE_NAME = "store-by-store-report";

const StoreByStorePanel = () => {
  const division = useDivision();
  const activeItem = useActiveItem();
  const [globalParameters] = useGlobalParameters();
  const displayEntitlements = useDisplayEntitlements();
  const { data: user } = useGetUserQuery();
  const isSupplier = useMemo(() => user?.isSupplier, [user]);

  const [storeByStoreTransactionSource, setStoreByStoreTransactionSource] =
    useState<TransactionSource | null>(null);

  const storeByStoreTitle = useMemo(
    () => (
      <span className={styles.reportTitle}>
        Store by store
        {displayEntitlements && storeByStoreTransactionSource && (
          <TransactionSourceIcon
            availableTransactionSources={division.transactionSources}
            transactionSource={storeByStoreTransactionSource}
          />
        )}
      </span>
    ),
    [
      displayEntitlements,
      storeByStoreTransactionSource,
      division.transactionSources,
    ]
  );

  const reportConfiguration = useReportConfigurationQuery(
    { division: division.name },
    { skip: !division.name }
  );

  const sbsConfig = reportConfiguration.currentData?.reports.storeByStore;

  const features = useGetFeaturesForFocalItem(division, activeItem);

  const getStoreByStoreContent = () => {
    if (
      activeItem?.type !== HierarchyShortName.ProductGroup &&
      activeItem?.type !== HierarchyItemType.Leaf
    ) {
      return (
        <WarningBanner text="This report is only available when viewing a Product or Product group" />
      );
    }

    const supplierLatestWeekRestrictions =
      sbsConfig?.supplierLatestWeekRestrictions;
    if (isSupplier && supplierLatestWeekRestrictions?.length) {
      const customFocusPeriodOptions =
        reportConfiguration.currentData?.globalParameters.focusPeriod
          ?.customOptions;

      if (customFocusPeriodOptions && globalParameters.focusPeriod) {
        const isLatestPeriod =
          customFocusPeriodOptions.latestWeek.endDate ===
          globalParameters.focusPeriod.endDate;

        if (!isLatestPeriod) {
          return (
            <WarningBanner text="This report can only be run with the latest available end date" />
          );
        }

        const selectedRangeWeeks =
          differenceInWeeks(
            new Date(globalParameters.focusPeriod.endDate),
            new Date(globalParameters.focusPeriod.startDate)
          ) + 1;

        if (!supplierLatestWeekRestrictions.includes(selectedRangeWeeks)) {
          return (
            <WarningBanner
              text={`This report can only be run on the following focal periods: ${supplierLatestWeekRestrictions}`}
            />
          );
        }
      }
    }

    const disabledTransactionSources = sbsConfig?.disabledTransactionSources;

    if (
      disabledTransactionSources?.find(
        (x) => x === globalParameters.transactionSource
      )
    ) {
      return (
        <WarningBanner
          data-cy="StoreByStoreReportTransactionSourceWarningBanner"
          text={`This report cannot be run on ${globalParameters.transactionSource} data.`}
        />
      );
    }

    const disabledCompStoreValue = sbsConfig?.disabledCompStoreValue;

    if (globalParameters.compStore === disabledCompStoreValue) {
      return (
        <WarningBanner
          data-cy="StoreByStoreReportCompStoreWarningBanner"
          text={`This report is not currently available when ${globalParameters.compStore} is selected.`}
        />
      );
    }

    return (
      <ErrorBoundary>
        <StoreByStoreReport
          activeItem={activeItem}
          onTransactionSourceChanged={setStoreByStoreTransactionSource}
        />
      </ErrorBoundary>
    );
  };

  return (
    <>
      {sbsConfig &&
        !sbsConfig.disabled &&
        features?.find(
          (feature) => feature === STORE_BY_STORE_REPORT_FEATURE_NAME
        ) && (
          <div data-cy="StoreByStoreReportPanel">
            <Accordion
              className={styles.reportAccordion}
              subtitle="Understand performance at store level."
              title={storeByStoreTitle}
            >
              {getStoreByStoreContent()}
            </Accordion>
          </div>
        )}
    </>
  );
};

export default StoreByStorePanel;
