import { HierarchyShortName } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import DisabledParameterSingleSelect from "../../fast-report/DisabledParameterSingleSelect";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useActiveItem } from "../../useActiveItem";
import { BenchmarkDropdown } from "./BenchmarkDropdown";
import { ComparisonPeriodDropdown } from "./ComparisonPeriodDropdown";
import styles from "./KeyDriverTreeParametersHeader.module.css";

export const KeyDriverTreeParametersHeader = () => {
  const division = useDivision();
  const activeItem = useActiveItem();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.keyDriverTree,
        state,
      }),
      skip: !division.name,
    }
  );

  const isProductGroup = activeItem?.type === HierarchyShortName.ProductGroup;

  return (
    <div className={styles.parameterHeader}>
      {reportConfig.configuration?.comparisonPeriod && (
        <div className={classNames(styles.parameter, styles.comparisonPeriod)}>
          <label htmlFor="kdt-comparison-period-dropdown">
            Comparison period
          </label>
          <ComparisonPeriodDropdown />
        </div>
      )}
      {reportConfig.configuration?.benchmark && (
        <div className={classNames(styles.parameter, styles.benchmark)}>
          <label htmlFor="kdt-benchmark-dropdown">Benchmark</label>
          {isProductGroup ? (
            <DisabledParameterSingleSelect />
          ) : (
            <BenchmarkDropdown />
          )}
        </div>
      )}
    </div>
  );
};
