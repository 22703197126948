import {
  Button,
  ButtonVariant,
  Number,
  NumberChange,
  NumberChangePosition,
  NumberSentiment,
  NumberSize,
  Spinner,
  Text,
  TextSentiment,
} from "@qbit/react";
import { useNumberFormat } from "@quantium-enterprise/hooks-ui";
import styles from "./AggregateValue.module.css";

export type AggregateValueProps = {
  baseValue?: number;
  baseValueFormatter: (value?: number) => string;
  displayName: string;
  growthValue?: number;
  isError: boolean;
  isFetching: boolean;
  onRetry: () => void;
};

export const AggregateValue = ({
  displayName,
  baseValue,
  growthValue,
  baseValueFormatter,
  isFetching,
  isError,
  onRetry,
}: AggregateValueProps) => {
  const { percentFormatter } = useNumberFormat();

  let growthSentiment = NumberSentiment.None;
  let growthChange = NumberChange.NoChange;

  if (growthValue) {
    if (growthValue > 0) {
      growthSentiment = NumberSentiment.Good;
      growthChange = NumberChange.Increase;
    } else if (growthValue < 0) {
      growthSentiment = NumberSentiment.Bad;
      growthChange = NumberChange.Decrease;
    } else {
      growthSentiment = NumberSentiment.Neutral;
    }
  }

  return (
    <div className={styles.aggregateValue}>
      <div className={styles.column + " " + styles.base}>
        <div className={styles.title}>
          <Text>{displayName}</Text>
        </div>
        <div className={styles.value}>
          {!isFetching && !isError && (
            <Number size={NumberSize.Medium}>
              {baseValueFormatter(baseValue)}
            </Number>
          )}
          {isFetching && <Spinner />}
        </div>
      </div>
      <div className={styles.column + " " + styles.growth}>
        <div className={styles.title}>
          <Text>vs last year</Text>
        </div>
        <div className={styles.value}>
          {!isFetching && !isError && (
            <Number
              change={growthChange}
              changePosition={NumberChangePosition.After}
              sentiment={growthSentiment}
            >
              {growthValue ? percentFormatter(growthValue) : "N/A"}
            </Number>
          )}
        </div>
      </div>
      {isError && (
        <div className={styles.error}>
          <Text sentiment={TextSentiment.Bad}>Error retrieving data</Text>
          <Button onClick={onRetry} text="Retry" variant={ButtonVariant.Link} />
        </div>
      )}
    </div>
  );
};
