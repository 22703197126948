import * as amplitude from "@amplitude/analytics-browser";
import { type BrowserOptions } from "@amplitude/analytics-types";
import { type EventTrackingService } from "./EventTrackingService";
import { type TrackingComponent } from "./TrackingComponent";
import { type TrackingEvent } from "./TrackingEvent";
import { type TrackingProperties } from "./TrackingProperties";

// Amplitude - Event Tracking Service
// This is for directly sending events to Amplitude.
// Normally we would want to go via Segment, as Segment handles a single "Accept Cookies"
// popup, but this is a direct connection, if required.
export class AmplitudeEventTrackingService implements EventTrackingService {
  public constructor(
    amplitudeKey: string,
    amplitudeConfig: BrowserOptions,
    userId: string,
    divisionName: string
  ) {
    amplitude.init(amplitudeKey, userId, amplitudeConfig);
    amplitude.setUserId(userId);

    const identify = new amplitude.Identify();
    identify.set("VendorId", userId);
    identify.set("DivisionName", divisionName);

    amplitude.identify(identify);
  }

  public trackEvent(
    component: TrackingComponent | TrackingComponent[],
    event: TrackingEvent,
    properties?: TrackingProperties
  ) {
    // remove later
    const formattedComponent = Array.isArray(component)
      ? component.join(".")
      : component;
    // eslint-disable-next-line @typescript-eslint/return-await -- track.promise IS a Promise
    amplitude
      .track(`${formattedComponent}.${event}`, properties?.getProperties())
      .promise.catch(console.log); // eslint-disable-line no-console
  }
}
