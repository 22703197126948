export enum ParameterGroup {
  Associated = "Associated",
  Customer = "Customer",
  Focal = "Focal",
  LevelOfAnalysis = "Level of analysis",
  Location = "Location",
  Products = "Products",
  Rules = "Rules",
  Structure = "Structure",
  Time = "Time",
  Transaction = "Transaction",
}
