import { Group, Item, ItemValign, Text } from "@qbit/react";
import {
  type AttributeFocalItem,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import styles from "./AvailableHierarchyLevelDropdown.module.css";
import HierarchyLevelSelector from "./hierarchy-level-selector/HierarchyLevelSelector";

type AvailableHierarchyLevelDropdownProps = {
  focalItem: AttributeFocalItem;
  onSelectionChanged:
    | ((
        shortName: string,
        code: string,
        entitlements: TransactionSource[]
      ) => void)
    | undefined;
  selectedLevel?: { code: string; shortName: string };
};

export const AvailableHierarchyLevelDropdown = ({
  selectedLevel,
  focalItem,
  onSelectionChanged,
}: AvailableHierarchyLevelDropdownProps) => (
  <Group>
    <Item valign={ItemValign.Middle}>
      <Text className={styles.inLabel}>in</Text>
    </Item>
    <Item className={styles.selectorContainer}>
      <HierarchyLevelSelector
        focalItem={focalItem}
        initialSelection={selectedLevel}
        key={JSON.stringify(focalItem)}
        onSelectionChanged={onSelectionChanged}
      />
    </Item>
  </Group>
);
