import {
  Button,
  Dropdown,
  DropdownPositionX,
  DropdownPositionY,
  DropdownWidth,
  Group,
  Icon,
  IconGlyph,
  Item,
  ItemHalign,
  ItemValign,
  ItemWidth,
  Menu,
  MenuItemButton,
  Spinner,
} from "@qbit/react";
import {
  type TransactionSource,
  type FocalItem,
  useGetUserQuery,
  getTransactionSourceFromEntitlements,
  HierarchyShortName,
  useGetGroupsQuery,
  HierarchyItemType,
  HierarchyType,
  useExpandQuery,
  useGetAncestorsQuery,
  useGetRootNodesQuery,
} from "@quantium-enterprise/common-ui";
import {
  type ProductGroupFocalItem,
  type AttributeFocalItem,
  type HierarchyFocalItem,
} from "@quantium-enterprise/common-ui/src/models/FocalItem";
import {
  useDivision,
  useExternalClickListener,
} from "@quantium-enterprise/hooks-ui";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { useMemo, useRef, useState } from "react";
import { dispatchReplaceActiveTab } from "report-tabs-ui";
import { AvailableHierarchyLevelDropdown } from "./AvailableHierarchyLevelDropdown";
import styles from "./FocalItemButton.module.css";

export type AttributeItemButtonProps = {
  attribute?: {
    code: string;
    shortName: string;
  };
  item: AttributeFocalItem;
};
export const AttributeItemButton = ({
  item,
  attribute,
}: AttributeItemButtonProps) => {
  const onAttributeChanged = (
    shortName: string,
    code: string,
    entitlements: TransactionSource[]
  ): void => {
    const newItem = {
      ...item,
      additionalHierarchyFilter: {
        code,
        shortName,
        entitlements,
      },
    };
    dispatchReplaceActiveTab(newItem);
  };

  return (
    <button className={styles.focalButton} type="button">
      <div className={styles.focalButtonContent}>
        <HierarchyLevelIcon shortName={item.shortName} type={item.type} />
        <div className={styles.itemLabel}>{item.displayName}</div>
        <AvailableHierarchyLevelDropdown
          focalItem={item}
          onSelectionChanged={onAttributeChanged}
          selectedLevel={attribute}
        />
      </div>
    </button>
  );
};

export type HierarchyItemButtonProps = {
  item: HierarchyFocalItem;
};
export const HierarchyItemButton = ({ item }: HierarchyItemButtonProps) => {
  const dropdownRef = useRef<Dropdown>();
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonContentRef = useRef<HTMLDivElement>(null);
  const closeDropdown = () => {
    // @ts-expect-error Qbit dropdown currently has no public method to close itself
    if (dropdownRef.current?.state.isOpen && dropdownRef.current.toggleState) {
      // @ts-expect-error Bug ticket to fix this: https://jira.quantium.com.au/browse/QDS-386
      dropdownRef.current.toggleState();
    }
  };

  const handleExternalClick = (target: Element) => {
    // We don't want to close the dropdown when we've just clicked the button to open it
    if (!buttonContentRef.current?.parentElement?.contains(target)) {
      closeDropdown();
    }
  };

  useExternalClickListener(containerRef, document.body, handleExternalClick);

  const { data: user } = useGetUserQuery();

  const displayEntitlements = useMemo(() => user?.isSupplier, [user]);

  const [hoveredItemCode, setHoveredItemCode] = useState<string | null>(null);

  const division = useDivision();
  const ancestors = useGetAncestorsQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      payload: {
        filters: [{ shortName: item.shortName, codes: [item.code] }],
        page: 0,
      },
    },
    {
      selectFromResult: (result) => ({
        state: result,
        parent: result.data?.results.slice(-1)[0],
      }),
      skip: !division.name,
    }
  );

  const siblingQuery = useExpandQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Skipped if not null
      payload: { parent: ancestors.parent!, page: 0 },
    },
    { skip: !division.name || !ancestors.parent }
  );

  const rootNodesQuery = useGetRootNodesQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      payload: { page: 0 },
    },
    { skip: !division.name || Boolean(ancestors.parent) }
  );

  // If at the top level, get siblings via the rootNodes query instead
  const siblings = ancestors.parent ? siblingQuery : rootNodesQuery;

  return (
    <Dropdown
      className={styles.focalButton}
      contentWidth={DropdownWidth.XLarge}
      position={{
        x: DropdownPositionX.AlignLeft,
        y: DropdownPositionY.AlignBottom,
      }}
      ref={dropdownRef as React.RefObject<Dropdown>}
      trigger={
        <Button>
          <div className={styles.focalButtonContent} ref={buttonContentRef}>
            <HierarchyLevelIcon shortName={item.shortName} type={item.type} />
            <div className={styles.itemLabel}>{item.displayName}</div>
          </div>
          <Icon glyph={IconGlyph.ArrowsChevronDown} text="" />
        </Button>
      }
    >
      <div ref={containerRef}>
        {(siblings.isUninitialized || siblings.isLoading) && <Spinner />}
        <Menu>
          {siblings.data?.results
            .filter((x) => x.transactionSourceAccess?.[item.shortName]?.length)
            .map((sibling) => (
              <div
                key={sibling.code}
                onMouseEnter={() => {
                  setHoveredItemCode(sibling.code);
                }}
                onMouseLeave={() => {
                  setHoveredItemCode(null);
                }}
              >
                <MenuItemButton
                  key={sibling.code}
                  onClick={() => {
                    dispatchReplaceActiveTab({
                      shortName: sibling.shortName,
                      type: item.type,
                      code: sibling.code,
                      displayName: sibling.name,
                    });
                  }}
                  selected={
                    sibling.shortName === item.shortName &&
                    sibling.code === item.code
                  }
                  text={
                    <Group>
                      <Item
                        className={styles.hierarchyLevelIcon}
                        halign={ItemHalign.Left}
                        valign={ItemValign.Middle}
                        width={ItemWidth.Fit}
                      >
                        <HierarchyLevelIcon
                          shortName={sibling.shortName}
                          type={item.type}
                        />
                      </Item>
                      <Item
                        halign={ItemHalign.Left}
                        valign={ItemValign.Middle}
                        width={ItemWidth.Fill}
                      >
                        {sibling.name}
                      </Item>
                      {displayEntitlements && (
                        <Item
                          halign={ItemHalign.Right}
                          valign={ItemValign.Middle}
                          width={ItemWidth.Fit}
                        >
                          <TransactionSourceIcon
                            availableTransactionSources={
                              division.transactionSources
                            }
                            greyedOut={sibling.code !== hoveredItemCode}
                            transactionSource={getTransactionSourceFromEntitlements(
                              sibling.transactionSourceAccess?.[
                                sibling.shortName
                              ] ?? []
                            )}
                          />
                        </Item>
                      )}
                    </Group>
                  }
                />
              </div>
              // eslint-disable-next-line react/jsx-no-useless-fragment -- Signature of QBit means this is required
            )) ?? <></>}
        </Menu>
      </div>
    </Dropdown>
  );
};

type ProductGroupItemButtonProps = {
  item: ProductGroupFocalItem;
};

const ProductGroupItemButton = ({ item }: ProductGroupItemButtonProps) => {
  const dropdownRef = useRef<Dropdown>();
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonContentRef = useRef<HTMLDivElement>(null);

  const closeDropdown = () => {
    // @ts-expect-error Qbit dropdown currently has no public method to close itself
    if (dropdownRef.current?.state.isOpen && dropdownRef.current.toggleState) {
      // @ts-expect-error Bug ticket to fix this: https://jira.quantium.com.au/browse/QDS-386
      dropdownRef.current.toggleState();
    }
  };

  const handleExternalClick = (target: Element) => {
    // We don't want to close the dropdown when we've just clicked the button to open it
    if (!buttonContentRef.current?.parentElement?.contains(target)) {
      closeDropdown();
    }
  };

  useExternalClickListener(containerRef, document.body, handleExternalClick);

  const division = useDivision();

  const { data, isUninitialized, isLoading } = useGetGroupsQuery({
    divisionName: division.name,
    hierarchyType: HierarchyType.Product,
    includeRules: false,
  });

  return (
    <Dropdown
      className={styles.focalButton}
      contentWidth={DropdownWidth.Medium}
      position={{
        x: DropdownPositionX.AlignLeft,
        y: DropdownPositionY.AlignBottom,
      }}
      ref={dropdownRef as React.RefObject<Dropdown>}
      trigger={
        <Button>
          <div className={styles.focalButtonContent} ref={buttonContentRef}>
            <HierarchyGroupIcon
              evaluationType={item.evaluationType}
              hierarchyType={HierarchyType.Product}
            />
            <div className={styles.itemLabel}>{item.displayName}</div>
          </div>
          <Icon glyph={IconGlyph.ArrowsChevronDown} text="" />
        </Button>
      }
    >
      <div ref={containerRef}>
        {(isUninitialized || isLoading) && <Spinner />}
        <Menu>
          {data?.map((group) => (
            <div key={group.id}>
              <MenuItemButton
                key={group.id}
                onClick={() => {
                  dispatchReplaceActiveTab({
                    displayName: group.name,
                    evaluationType: group.evaluationType,
                    type: HierarchyShortName.ProductGroup,
                    productGroupId: group.id,
                  } as ProductGroupFocalItem);
                }}
                selected={group.id === item.productGroupId}
                text={
                  <Group>
                    <Item
                      className={styles.hierarchyLevelIcon}
                      halign={ItemHalign.Left}
                      valign={ItemValign.Middle}
                      width={ItemWidth.Fit}
                    >
                      <HierarchyGroupIcon
                        evaluationType={group.evaluationType}
                        hierarchyType={HierarchyType.Product}
                      />
                    </Item>
                    <Item
                      halign={ItemHalign.Left}
                      valign={ItemValign.Middle}
                      width={ItemWidth.Fill}
                    >
                      {group.name}
                    </Item>
                  </Group>
                }
              />
            </div>
            // eslint-disable-next-line react/jsx-no-useless-fragment -- Signature of QBit means this is required
          )) ?? <></>}
        </Menu>
      </div>
    </Dropdown>
  );
};

export type FocalItemButtonProps = {
  item: FocalItem;
};
export const FocalItemButton = ({ item }: FocalItemButtonProps) => (
  <div data-cy="FocalItemButton">
    {item.type === HierarchyItemType.Attribute && (
      <AttributeItemButton
        attribute={item.additionalHierarchyFilter}
        item={item}
      />
    )}

    {(item.type === HierarchyItemType.Hierarchy ||
      item.type === HierarchyItemType.Leaf) && (
      <HierarchyItemButton item={item} />
    )}

    {item.type === HierarchyShortName.ProductGroup && (
      <ProductGroupItemButton item={item} />
    )}
  </div>
);

export default FocalItemButton;
