import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import styles from "./CustomerProfilingParametersHeader.module.css";
import { MetricDropdown } from "./MetricDropdown";
import { SegmentationDropdown } from "./SegmentationDropdown";

export const CustomerProfilingParametersHeader = () => {
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.customerProfiling,
        state,
      }),
      skip: !division.name,
    }
  );

  return (
    <div className={styles.parametersContainer}>
      {reportConfig.configuration?.metrics && (
        <div className={classNames(styles.parameter, styles.metrics)}>
          <label htmlFor="customer-profiling-metrics-dropdown">Metrics</label>
          <MetricDropdown />
        </div>
      )}
      {reportConfig.configuration?.segmentations && (
        <div className={classNames(styles.parameter, styles.segmentation)}>
          <label htmlFor="customer-profiling-segmentation-dropdown">
            Segmentation
          </label>
          <SegmentationDropdown />
        </div>
      )}
    </div>
  );
};
