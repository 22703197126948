import { type TextPanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PanelType } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { ChannelFilterContent } from "./ChannelFilterContent";
import { ChannelFilterSummary } from "./ChannelFilterSummary";

export const getChannelPanel = () => {
  const panel = {
    content: <ChannelFilterContent />,
    id: "FastReportingChannelSelect",
    label: "Channel",
    panelType: PanelType.TEXT,
    summary: <ChannelFilterSummary />,
  } as TextPanel;

  return [panel];
};
