import { type TextPanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PanelType } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { CompStoreFilterContent } from "./CompStoreFilterContent";
import { CompStoreFilterSummary } from "./CompStoreFilterSummary";

export const getCompStorePanel = () => {
  const panel = {
    content: <CompStoreFilterContent />,
    id: "FastReportingCompStoreSelect",
    label: "Comp store",
    panelType: PanelType.TEXT,
    summary: <CompStoreFilterSummary />,
  } as TextPanel;

  return [panel];
};
