import { Text } from "@qbit/react";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../FilterSummary.module.css";

export const StoreFormatFilterSummary = () => {
  let text = <Text> None </Text>;
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.storeFormat,
        state,
      }),
      skip: !division.name,
    }
  );

  const [globalParameters] = useGlobalParameters();
  const storeFormats = globalParameters.storeFormat;

  if (storeFormats) {
    const selections = storeFormats.map(
      (storeFormat) =>
        reportConfig.configuration?.options.find(
          (opt) => opt.value === storeFormat
        )?.displayName
    );

    text = <span>{selections.join(", ")}</span>;
  }

  return <div className={styles.summaryText}>{text}</div>;
};
