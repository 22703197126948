import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import {
  type PanelOption,
  type Panel,
  SidePanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isLocationSelectionParameter,
  isPlainTextContentParameter,
  isSegmentationGroupParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildLocationHierarchyPanel,
  buildPlainTextContentPanel,
  buildSegmentationGroupPanel,
  buildSingleSelectPanel,
  buildTimePeriodPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { type RootState } from "../../store";
import {
  onChannelChange,
  onCheckoutTypeChange,
  onLocationChange,
  onMetricChange,
  onPromotionChange,
  onSegmentationChange,
} from "../services/time-of-day-day-of-week-slice";
import { selectLocalParametersSelections } from "../services/time-of-day-day-of-week-slice-selectors";

export const TimeOfDayDayOfWeekSidePanel = ({
  eventTrackingService,
}: {
  eventTrackingService: Function;
}) => {
  const dispatch = useDispatch();

  const localParametersSelections = useSelector(
    selectLocalParametersSelections
  );

  const { localParametersConfig } = useSelector((state: RootState) => ({
    localParametersConfig: state.timeOfDayDayOfWeek.localParametersConfig,
  }));

  const generatePanels = () => {
    const panels: Panel[] = [];

    for (const parameter of localParametersConfig) {
      switch (parameter.id) {
        case LocalParameters.Time:
          if (isTimePeriodParameter(parameter))
            panels.push(buildTimePeriodPanel(parameter));
          break;

        case LocalParameters.Metric:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onMetricChange(value));
                },
                localParametersSelections.Metric.value as string
              )
            );
          }

          break;

        case LocalParameters.Channel:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onChannelChange(value));
                },
                localParametersSelections.Channel.value as string
              )
            );
          }

          break;

        case LocalParameters.Promotion:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onPromotionChange(value));
                },
                localParametersSelections.Promotion.value as string
              )
            );
          }

          break;

        case LocalParameters.CheckoutType:
          if (isSingleSelectionParameter(parameter)) {
            panels.push(
              buildSingleSelectPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onCheckoutTypeChange(value));
                },
                localParametersSelections.CheckoutType?.value as string
              )
            );
          }

          break;

        case LocalParameters.Segmentation:
          if (isSegmentationGroupParameter(parameter)) {
            panels.push(
              buildSegmentationGroupPanel(
                parameter,
                (value: PanelOption) => {
                  dispatch(onSegmentationChange(value));
                },
                localParametersSelections.Segmentation.value as string
              )
            );
          } else if (isPlainTextContentParameter(parameter)) {
            panels.push(buildPlainTextContentPanel(parameter));
          }

          break;

        case LocalParameters.LocationHierarchy:
          if (isLocationSelectionParameter(parameter)) {
            const onLocationSelection = (
              selection: LocalHierarchyNodeSelection
            ) => {
              dispatch(onLocationChange(selection));
            };

            panels.push(
              buildLocationHierarchyPanel(
                parameter,
                onLocationSelection,
                localParametersSelections.LocationHierarchy
              )
            );
          }

          break;

        default: {
          break;
        }
      }
    }

    return panels;
  };

  return (
    <SidePanel
      eventTrackingService={eventTrackingService}
      panels={generatePanels()}
    />
  );
};

export default TimeOfDayDayOfWeekSidePanel;
