import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  IconSize,
  Text,
} from "@qbit/react";

export type ReportSummaryButtonProps = {
  onClick: () => void;
};
export const ReportSummaryButton = ({ onClick }: ReportSummaryButtonProps) => (
  <Button
    data-cy="ReportSummaryButton"
    data-testid="report-summary-button"
    height={ButtonHeight.Small}
    onClick={onClick}
    variant={ButtonVariant.Stealth}
  >
    <Text>View product attributes</Text>
    <Icon
      glyph={IconGlyph.AlertsAndNotificationsInform}
      size={IconSize.Large}
      text="Report Summary"
    />
  </Button>
);
