import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useEffect, useMemo, useState } from "react";
import { useActiveTab } from "report-tabs-ui";
import Zod from "zod";
import { ParameterSingleSelect } from "../../fast-report/ParameterSingleSelect";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { type GlobalParameterConfiguration } from "../../fast-report/api/globalParameterConfiguration";
import {
  type ParameterOption,
  type SingleSelectParameterConfiguration,
} from "../../fast-report/api/parameterConfiguration";
import { type GlobalParameterValues } from "../../fast-report/globalParameterValues";
import { areParameterValuesEquivalent } from "../../parameterUtilities";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";
import { joinArrayWithCharacterLimit } from "../../utilities";
import { useGetParameterDisplayNames } from "../hooks";

export const MetricBreakdownValueSchema = Zod.string().nullable();

export type MetricBreakdownParameterProps = {
  onNumberOfCurrentSplitsChanged?: (numberOfSplits: number) => void;
};

export const MetricBreakdownParameter = ({
  onNumberOfCurrentSplitsChanged,
}: MetricBreakdownParameterProps) => {
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.topAndBottom?.metricBreakdown,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState<
    string | null,
    SingleSelectParameterConfiguration<string | null>
  >(
    Parameter.TopAndBottomMetricBreakdown,
    MetricBreakdownValueSchema,
    (config) => config.reports.topAndBottom?.metricBreakdown,
    null,
    true
  );

  const [globalParameters, areGlobalParametersSet] = useGlobalParameters();
  const getDisplayNames = useGetParameterDisplayNames();

  const refinedOptions: Array<
    ParameterOption<string | null> & {
      splitCount: number;
    }
  > = useMemo(() => {
    if (reportConfig.configuration === undefined || !areGlobalParametersSet) {
      return [];
    }

    const newOptions = [];
    for (const option of reportConfig.configuration.options) {
      // The value of null is used as the Aggregate option that should always be available
      if (option.value === null) {
        newOptions.push({ ...option, splitCount: 1 });
        continue;
      }

      // Add options when the parameter matching the key is set to an array with at least 2 selections
      const parameterValue =
        globalParameters[option.value as keyof GlobalParameterValues];
      if (Array.isArray(parameterValue) && parameterValue.length > 1) {
        const displayNames = getDisplayNames(
          (config) =>
            config.globalParameters[
              option.value as keyof GlobalParameterConfiguration
            ],
          parameterValue
        );

        // Get display name with character limit
        const characterLimit = 25;
        const joinedDisplayNames = joinArrayWithCharacterLimit(
          displayNames,
          characterLimit - option.displayName.length
        );
        const newDisplayName = `${option.displayName} (${joinedDisplayNames})`;
        newOptions.push({
          ...option,
          displayName: newDisplayName,
          splitCount: displayNames?.length ?? 0,
        });
      }
    }

    return newOptions;
  }, [
    reportConfig.configuration,
    globalParameters,
    areGlobalParametersSet,
    getDisplayNames,
  ]);

  // Keep track of old options to set a new default when one becomes available
  const currentTab = useActiveTab();
  const [oldTab, setOldTab] = useState(currentTab);
  const [oldRefinedOptions, setOldRefinedOptions] = useState(refinedOptions);
  useEffect(() => {
    if (
      oldTab?.id === currentTab?.id &&
      oldRefinedOptions.length === 1 &&
      refinedOptions.length > 1
    ) {
      const newOptions = refinedOptions.filter(
        (option) =>
          !areParameterValuesEquivalent(
            option.value,
            oldRefinedOptions[0].value
          )
      );
      if (newOptions.length === 1) {
        setSelection(newOptions[0].value);
      }
    }

    setOldRefinedOptions(refinedOptions);
    setOldTab(currentTab);
  }, [
    refinedOptions,
    oldRefinedOptions,
    setOldRefinedOptions,
    setSelection,
    currentTab,
    oldTab,
    setOldTab,
  ]);

  // If the selected option gets removed, reset the selection
  useEffect(() => {
    if (
      selection !== undefined &&
      selection !== null &&
      refinedOptions.length > 0 &&
      !refinedOptions.some((option) => option.value === selection) &&
      areGlobalParametersSet
    ) {
      setSelection(null);
    }
  }, [selection, refinedOptions, setSelection, areGlobalParametersSet]);

  const numberOfCurrentSplits = useMemo(() => {
    const option = refinedOptions.find((opt) => opt.value === selection);
    if (!option) {
      return 0;
    }

    return option.splitCount;
  }, [refinedOptions, selection]);
  useEffect(() => {
    if (onNumberOfCurrentSplitsChanged) {
      onNumberOfCurrentSplitsChanged(numberOfCurrentSplits);
    }
  }, [numberOfCurrentSplits, onNumberOfCurrentSplitsChanged]);

  if (!reportConfig.configuration || selection === undefined) {
    return null;
  }

  return (
    <ParameterSingleSelect
      configuration={{
        ...reportConfig.configuration,
        options: refinedOptions,
      }}
      disabled={refinedOptions.length <= 1}
      onSelection={(value) => setSelection(value)}
      selection={selection}
    />
  );
};
