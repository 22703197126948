import tokens from "@qbit/core/dist/tokens.json";
import { IconGlyph } from "@qbit/react";
import { RenameIcon } from "../../assets/icons/RenameIcon";
import { RerunIcon } from "../../assets/icons/RerunIcon";
import { ShareIcon } from "../../assets/icons/ShareIcon";
import { type ButtonContent } from "../../button-list/ButtonList";

export const RenameButton: ButtonContent = {
  buttonIcon: <RenameIcon />,
  name: "rename",
  text: "Rename",
};

export const DeleteButton: ButtonContent = {
  buttonQbitIcon: IconGlyph.DeleteAndCloseDelete,
  buttonColour: tokens.colour["status-bad-text"],
  buttonTextColour: tokens.colour["status-bad-text"],
  name: "delete",
  text: "Delete",
};

export const MoveButton: ButtonContent = {
  buttonQbitIcon: IconGlyph.FilesAndFoldersFolderMove,
  buttonColour: tokens.colour["text-primary"],
  buttonTextColour: tokens.colour["text-primary"],
  name: "move",
  text: "Move",
};

export const ShareButton: ButtonContent = {
  buttonIcon: <ShareIcon />,
  name: "share",
  text: "Share",
};

export const RerunButton: ButtonContent = {
  buttonIcon: <RerunIcon text="Rerun" />,
  name: "rerun",
  text: "Rerun",
};

export const SaveParametersButton: ButtonContent = {
  buttonQbitIcon: IconGlyph.MenuAndSettingsBookmark,
  name: "saveParameters",
  text: "Save selection",
};

export const DownloadDataButton: ButtonContent = {
  buttonQbitIcon: IconGlyph.FilesAndFoldersDownload,
  name: "downloadData",
  text: "Download data",
};
