import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useMemo } from "react";
import { useReportTabState } from "report-tabs-ui";
import Zod from "zod";
import { useReportConfigurationQuery } from "./fast-report/api/fastReportConfigurationApi";
import {
  type FocusPeriodOptionWithCustomFlag,
  type FocusPeriodOption,
  FocusPeriodOptionWithCustomFlagSchema,
  type LocationOption,
  LocationOptionSchema,
} from "./fast-report/api/globalParameterConfiguration";
import { type GlobalParameterValues } from "./fast-report/globalParameterValues";
import { BannerValueSchema } from "./side-panel/BannerFilter/BannerFilterContent";
import { ChannelValueSchema } from "./side-panel/ChannelFilter/ChannelFilterContent";
import { CompStoreValueSchema } from "./side-panel/CompStoreFilter/CompStoreFilterContent";
import { PromotionValueSchema } from "./side-panel/PromotionFilter/PromotionFilterContent";
import { StoreFormatValueSchema } from "./side-panel/StoreFormatFilter/StoreFormatFilterContent";
import { TransactionSourceValueSchema } from "./side-panel/TransactionSourceFilter/TransactionSourceFilterContent";
import { Parameter } from "./useFastReportingParameterState";

export const useGlobalParameters = (): [GlobalParameterValues, boolean] => {
  const [banner] = useReportTabState<string | null>(
    Parameter.Banner,
    BannerValueSchema
  );

  const [channel] = useReportTabState<Array<string | null> | null>(
    Parameter.Channel,
    Zod.array(ChannelValueSchema)
  );

  const [focusPeriod] =
    useReportTabState<FocusPeriodOptionWithCustomFlag | null>(
      Parameter.FocusPeriod,
      FocusPeriodOptionWithCustomFlagSchema
    );

  const [location] = useReportTabState<Array<LocationOption | null> | null>(
    Parameter.Location,
    Zod.array(LocationOptionSchema)
  );

  const [promotion] = useReportTabState<string | null>(
    Parameter.Promotion,
    PromotionValueSchema
  );

  const [transactionSource] = useReportTabState<string | null>(
    Parameter.TransactionSource,
    TransactionSourceValueSchema
  );

  const [storeFormat] = useReportTabState<Array<string | null> | null>(
    Parameter.StoreFormat,
    Zod.array(StoreFormatValueSchema)
  );

  const [compStore] = useReportTabState<string | null>(
    Parameter.CompStore,
    CompStoreValueSchema
  );

  const cleanedFocusPeriod: FocusPeriodOption | null | undefined =
    useMemo(() => {
      if (!focusPeriod) {
        return focusPeriod;
      }

      return { startDate: focusPeriod.startDate, endDate: focusPeriod.endDate };
    }, [focusPeriod]);

  const globalParameters: GlobalParameterValues = useMemo(
    () => ({
      banner: banner ?? null,
      channel: channel ?? null,
      focusPeriod: cleanedFocusPeriod ?? null,
      location: location ?? null,
      promotion: promotion ?? null,
      transactionSource: transactionSource ?? null,
      storeFormat: storeFormat ?? null,
      compStore: compStore ?? null,
    }),
    // channel and location are arrays and react thinks there are always changes
    // as it is comparing the objects, stringifying it fixes this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      banner,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(channel),
      focusPeriod,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(location),
      promotion,
      transactionSource,
      compStore,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(storeFormat),
    ]
  );

  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: ({ data }) => ({
        globalParameters: data?.globalParameters,
      }),
      skip: !division.name,
    }
  );

  // Global parameters have all been set if they are enabled in the report config
  // and their value is not set to undefined (null means it has been set)
  const areGlobalParametersSet =
    Boolean(reportConfig.globalParameters) &&
    (!reportConfig.globalParameters?.banner || banner !== undefined) &&
    (!reportConfig.globalParameters?.channel || channel !== undefined) &&
    (!reportConfig.globalParameters?.focusPeriod ||
      focusPeriod !== undefined) &&
    (!reportConfig.globalParameters?.location || location !== undefined) &&
    (!reportConfig.globalParameters?.promotion || promotion !== undefined) &&
    (!reportConfig.globalParameters?.transactionSource ||
      transactionSource !== undefined) &&
    (!reportConfig.globalParameters?.compStore || compStore !== undefined);

  return [globalParameters, areGlobalParametersSet];
};
