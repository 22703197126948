import { Spinner, SpinnerSize } from "@qbit/react";
import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import SideDrawer from "components-ui/src/panel-with-side-drawer/SideDrawer";
import { format, parse } from "date-fns";
import { useCallback, useMemo } from "react";
import { convertFocalItemToDto } from "../report-panels/focalItemDto";
import { useActiveItem } from "../useActiveItem";
import useOpenFastReportingTabs from "../useOpenFastReportingTabs";
import styles from "./ReportSummary.module.css";
import { useReportSummaryLeafQuery } from "./api/reportSummaryApi";
import {
  type attributeReportSummary,
  ReportSummaryDisplayType,
  type hierarchyReportSummary,
} from "./api/reportSummaryDtos";

export type ReportSummaryProps = {
  onClose: () => void;
  show: boolean;
};
export const ReportSummary = ({ show, onClose }: ReportSummaryProps) => {
  const division = useDivision();
  const activeItem = useActiveItem();

  const { data, isSuccess, isError, isFetching } = useReportSummaryLeafQuery(
    {
      division: division.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- skipped if undefined
      item: activeItem ? convertFocalItemToDto(activeItem) : null!,
    },
    {
      skip:
        activeItem === undefined ||
        activeItem.type !== HierarchyItemType.Leaf ||
        !division.name,
    }
  );

  const openTabs = useOpenFastReportingTabs();
  const handleNavigation = useCallback(
    async (summary: attributeReportSummary) => {
      if (summary.navigation) {
        openTabs([
          {
            code: summary.code,
            shortName: summary.shortName,
            type: HierarchyItemType.Attribute,
            displayName: summary.name,
          },
        ]);
      }
    },
    [openTabs]
  );

  const createSummaryDisplayValue = useCallback(
    (value: string, displayType?: ReportSummaryDisplayType) => {
      if (!displayType) {
        return value;
      }

      try {
        switch (displayType) {
          case ReportSummaryDisplayType.Date:
            return format(parse(value, "yyyyMMdd", new Date()), "dd-MMM-yyyy");
          case ReportSummaryDisplayType.ShorthandYesNo:
            return value.toLocaleUpperCase() === "YES" ? "Y" : "N";
          default:
            return value;
        }
      } catch {
        return value;
      }
    },
    []
  );

  const createHierarchySummary = useCallback(
    (summary: hierarchyReportSummary) => (
      <div
        className={styles.summary}
        key={`${summary.shortName}_${summary.code}`}
      >
        <div className={styles.summaryDisplayIcon}>
          <HierarchyLevelIcon
            shortName={summary.shortName}
            type={HierarchyItemType.Hierarchy}
          />
        </div>

        <div className={classNames(styles.summaryDisplayValue)}>
          {createSummaryDisplayValue(summary.name)}
        </div>
      </div>
    ),
    [createSummaryDisplayValue]
  );

  const createAttributeSummary = useCallback(
    (summary: attributeReportSummary) => (
      <div
        className={styles.summary}
        key={`${summary.shortName}_${summary.code}`}
      >
        {summary.displayName && (
          <div className={styles.summaryDisplayName}>{summary.displayName}</div>
        )}
        <div
          className={classNames(styles.summaryDisplayValue, {
            [styles.navigation]: summary.navigation,
          })}
          onClick={async () => await handleNavigation(summary)}
          onKeyUp={async () => await handleNavigation(summary)}
          role="button"
          tabIndex={0}
        >
          {createSummaryDisplayValue(
            summary.displayValue,
            summary.displayFormat
          )}
        </div>
      </div>
    ),
    [handleNavigation, createSummaryDisplayValue]
  );

  const reportSummaryLabel = useMemo(
    () => (
      <div className={styles.reportSummaryLabel}>
        {!isFetching &&
          isSuccess &&
          createHierarchySummary(data.parentHierarchySummary)}
        {isError && <div>Error occurred</div>}
        {isFetching && <Spinner size={SpinnerSize.Small} />}
      </div>
    ),
    [data, isSuccess, isFetching, isError, createHierarchySummary]
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment -- header is required by component
  const reportSummaryHeader = useMemo(() => <></>, []);

  const reportSummaryBody = useMemo(
    () => (
      <div className={styles.reportSummaryBody}>
        {!isFetching &&
          isSuccess &&
          data.attributeSummaries.map((summary) =>
            createAttributeSummary(summary)
          )}
      </div>
    ),
    [data, isSuccess, isFetching, createAttributeSummary]
  );

  return (
    <div
      className={styles.reportSummary}
      data-testid="fast-reporting-report-summary"
    >
      <SideDrawer
        closeFunc={onClose}
        content={{
          label: reportSummaryLabel,
          body: reportSummaryBody,
          header: reportSummaryHeader,
        }}
        isOverlay
        showDrawer={show}
      />
    </div>
  );
};
