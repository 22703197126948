import { type TransactionSource } from "@quantium-enterprise/common-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import { GroupColumnIndividualTooltipChart } from "components-ui/src/charts/group-column-individual-tooltip-chart/GroupColumnIndividualTooltipChart";
import { TooltipHTML } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { type SeriesColumnOptions } from "highcharts";
import { useEffect, useMemo } from "react";
import ReportLoadingWrapper from "../../fast-report/ReportLoadingWrapper";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";
import { convertFocalItemToDto } from "../focalItemDto";
import styles from "./CustomerProfiling.module.css";
import { MetricValueSchema } from "./MetricDropdown";
import { SegmentationValueSchema } from "./SegmentationDropdown";
import { useCustomerProfilingMeasuresQuery } from "./api/customerProfilingApi";

export type CustomerProfilingProps = {
  onTransactionSourceChanged: (
    transactionSource: TransactionSource | null
  ) => void;
};

export const CustomerProfiling = ({
  onTransactionSourceChanged,
}: CustomerProfilingProps) => {
  const division = useDivision();
  const activeItem = useActiveItem();
  const [globalParameters, areGlobalParametersSet] = useGlobalParameters();
  const formatter = useFormatter();

  const [metric] = useFastReportingParameterState(
    Parameter.CustomerProfilingMetrics,
    MetricValueSchema,
    (config) => config.reports.customerProfiling?.metrics
  );

  const [segmentation] = useFastReportingParameterState(
    Parameter.CustomerProfilingSegmentation,
    SegmentationValueSchema,
    (config) => config.reports.customerProfiling?.segmentations
  );

  const measures = useCustomerProfilingMeasuresQuery(
    {
      division: division.name,
      focusPeriod: globalParameters.focusPeriod,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- skipped if undefined
      item: activeItem ? convertFocalItemToDto(activeItem) : null!,
      location: globalParameters.location,
      channel: globalParameters.channel,
      promotion: globalParameters.promotion,
      banner: globalParameters.banner,
      transactionSource: globalParameters.transactionSource,
      segmentation: segmentation ?? null,
      storeFormat: globalParameters.storeFormat,
      compStore: globalParameters.compStore,
    },
    {
      skip:
        activeItem === undefined ||
        !division.name ||
        segmentation === undefined ||
        !areGlobalParametersSet,
    }
  );

  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.customerProfiling,
        state,
      }),
      skip: !division.name,
    }
  );

  useEffect(() => {
    onTransactionSourceChanged(measures.currentData?.transactionSource ?? null);
  }, [onTransactionSourceChanged, measures.currentData?.transactionSource]);

  const chosenMeasure = metric && measures.currentData?.measureResults[metric];

  const chartData = useMemo((): SeriesColumnOptions[] => {
    if (!chosenMeasure || chosenMeasure.length === 0) {
      return [];
    }

    return Object.keys(chosenMeasure).map((key, index) => {
      // Pad out the data points with nulls to align with the categories
      const numberOfSegmentations = Object.keys(chosenMeasure).length;
      const data = Array.from({ length: numberOfSegmentations }).fill(null);
      data[index] = chosenMeasure[key];

      return {
        color: "",
        name: key,
        data,
        type: "column",
      } as SeriesColumnOptions;
    });
  }, [chosenMeasure]);

  const metricMetadata = reportConfig.configuration?.metricMetadata.find(
    (x) => x.key === metric
  );
  const threshold = metricMetadata?.threshold ?? 0;

  return (
    <div className={styles.container}>
      <ReportLoadingWrapper
        data-cy="CustomerProfilingReport"
        isError={measures.isError}
        isLoading={measures.isFetching}
        noData={!measures.data}
        reportMinimumHeight={560}
        retry={measures.refetch}
      >
        {chosenMeasure && metricMetadata && (
          <GroupColumnIndividualTooltipChart
            categories={Object.keys(chosenMeasure)}
            centerInCategory
            data={chartData}
            dataLabelFormatter={formatter(metricMetadata.format)}
            grouping={false}
            legendClickable={false}
            plotLines={[
              {
                value: threshold,
                width: 2,
                zIndex: 1,
                color: "var(--qbit-colour-shade-6)",
              },
            ]}
            threshold={threshold}
            tooltipFormatter={formatter(metricMetadata.format)}
            tooltipOverride={(metrics) =>
              TooltipHTML(metrics, metricMetadata.displayName)
            }
            yAxisLabel={metricMetadata.displayName}
            yAxisTickFormatter={formatter(metricMetadata.format)}
          />
        )}
      </ReportLoadingWrapper>
    </div>
  );
};
