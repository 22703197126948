import {
  Button,
  ButtonHeight,
  ButtonVariant,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  QbitToastPosition,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  ddLog,
  FeatureFlag,
  TrackingComponent,
  useMoveReportMutation,
  useCreateSavedParametersMutation,
  type SharedUserDto,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReportIcon } from "../../assets/icons/ReportIcon";
import { type ButtonContent } from "../../button-list/ButtonList";
import { ButtonList } from "../../button-list/ButtonList";
import {
  DeleteDialog,
  type DeleteItems,
} from "../../delete-dialog/DeleteDialog";
import { EditableField } from "../../editable-field/EditableField";
import { MoveItemModal, RenameModal } from "../../modals";
import {
  type Folder,
  type MoveItemModalSubmitProps,
} from "../../modals/move-item-modal/MoveItemModal";
import { ShareModal } from "../../modals/share-modal/ShareModal";
import { RenameItem } from "../../rename-item/RenameItem";
import {
  RenameButton,
  DeleteButton,
  ShareButton,
  RerunButton,
  MoveButton,
  SaveParametersButton,
} from "./InfoPanelButtons";
import styles from "./InfoPanelHeader.module.css";

export enum InfoPanelType {
  MyReports = "My Reports",
  ReportViewer = "Report Viewer",
}

export const InfoPanelHeaderLayout = ({
  handleRename,
  id,
  title,
  buttonPanel,
  editableFieldState,
}: {
  buttonPanel?: JSX.Element;
  editableFieldState?: {
    isEditing: boolean;
    toggleEditing: (editing: boolean) => void;
  };
  handleRename: (value: string, id: string) => Promise<unknown>;
  id?: string;

  title?: string;
}) => (
  <div className={styles.infoPanelHeader}>
    <div className={styles.infoPanelTitleContainer}>
      {title ? (
        <EditableField
          editableFieldState={editableFieldState}
          resizeMode="vertical"
          rows={2}
          save={async (value: string) => {
            const data = await handleRename(value, id ?? "").then(
              () => true,
              () => false
            );
            return data;
          }}
          textStyle={styles.infoPanelTitle}
          value={title}
        />
      ) : (
        <h2 className={styles.infoPanelTitle} title={title}>
          {title}
        </h2>
      )}
    </div>
    {buttonPanel && <div className={styles.buttonPanel}>{buttonPanel} </div>}
  </div>
);

export type InfoPanelHeaderProps = {
  deleteItem?: (itemIds: string[]) => Promise<void>;
  folders: Folder[];
  infoPanelType: InfoPanelType;
  isReportDisabled?: boolean;
  isRerunLoading?: boolean;
  onDelete: () => void;
  onRerun?: () => void;
  organisationUsers: SharedUserDto[] | undefined;
  renameItem: (newItemName: string, itemId: string) => Promise<unknown>;
  reportId: string | undefined;
  reportName: string | undefined;
  reportPath?: string;
  trackButtonClick: () => void;
  triggerLazyReload?: () => Promise<void>;
};

export const InfoPanelHeader = ({
  deleteItem,
  infoPanelType,
  isReportDisabled,
  isRerunLoading,
  organisationUsers,
  renameItem,
  reportName,
  reportId,
  reportPath,
  trackButtonClick,
  triggerLazyReload,
  onDelete,
  onRerun,
  folders,
}: InfoPanelHeaderProps) => {
  const flags = useFlags();
  const division = useDivision();
  const [editingReportName, setEditingReportName] = useState(false);
  const editableFieldState = {
    isEditing: editingReportName,
    toggleEditing: setEditingReportName,
  };
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const deleteButtonRef = useRef(null);
  const handleDeleteDialogExit = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const handleMoveDialogExit = useCallback(() => {
    setShowMoveDialog(false);
  }, []);

  const [moveReport] = useMoveReportMutation();

  const handleDeleteReport = useCallback(() => {
    setShowDeleteDialog(false);
    onDelete();
  }, [onDelete]);

  const [showShareDialog, setShowShareDialog] = useState(false);
  const handleShareDialogExit = useCallback(() => {
    setShowShareDialog(false);
  }, []);

  const { handleRename } = RenameItem({
    itemType: "Report",
    renameItem,
    triggerLazyReload,
  });

  const moveReportCallback = useCallback(
    async ({ itemId, newParentFolderId }: MoveItemModalSubmitProps) => {
      const response = await moveReport({
        divisionName: division.name,
        payload: {
          newFolderId: newParentFolderId,
          reportId: itemId,
        },
      }).unwrap();
      return response;
    },
    [division, moveReport]
  );

  const [createSavedParametersTrigger] = useCreateSavedParametersMutation();

  const handleCreateSavedParameters = useCallback(async () => {
    try {
      await createSavedParametersTrigger({
        divisionName: division.name,
        payload: { reportParametersId: reportId ?? "" },
      }).unwrap();
      const heading = "Success";
      const content = "Selections for 1 report saved";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showCloseButton
          showIcon
          variant={MessageVariant.Success}
        />,
        {
          autoClose: 3_000,
          position: QbitToastPosition.BottomRight,
        }
      );
    } catch (error) {
      const content = "An unknown error has occurred";
      const heading = "Unknown error";
      ddLog("ERROR", {}, "error", error as Error);
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showCloseButton
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: 3_000,
          position: QbitToastPosition.BottomRight,
        }
      );
    }
  }, [createSavedParametersTrigger, division.name, reportId]);

  const buttonPanel = useMemo(() => {
    const isDisabled =
      infoPanelType === InfoPanelType.MyReports && reportPath === undefined;
    let availableButtons: ButtonContent[][];
    let visibleButtons: ButtonContent[] = [];

    // feature flag condition can be added here
    if (
      infoPanelType === InfoPanelType.MyReports &&
      !isReportDisabled &&
      !isRerunLoading &&
      onRerun &&
      flags[FeatureFlag.Rerun]
    ) {
      visibleButtons = [
        ...visibleButtons,
        {
          ...RerunButton,
          handleClick: onRerun,
        },
      ];
    }

    if (flags[FeatureFlag.SharingReports]) {
      visibleButtons = [
        ...visibleButtons,
        {
          ...ShareButton,
          disabled: isDisabled,
          handleClick: () => setShowShareDialog(true),
        },
      ];
    }

    visibleButtons = [
      ...visibleButtons,
      {
        ...RenameButton,
        handleClick: () => setEditingReportName(true),
      },
    ];

    let dropdownButtons: ButtonContent[] = [];
    if (reportPath) {
      dropdownButtons = [
        ...dropdownButtons,
        {
          ...SaveParametersButton,
          handleClick: async () => await handleCreateSavedParameters(),
        },
      ];
    }

    switch (infoPanelType) {
      case InfoPanelType.MyReports:
        availableButtons = [
          visibleButtons,
          [
            ...dropdownButtons,
            ...(flags[FeatureFlag.Folders]
              ? [{ ...MoveButton, handleClick: () => setShowMoveDialog(true) }]
              : []),
          ],
          [{ ...DeleteButton, handleClick: () => setShowDeleteDialog(true) }],
        ];

        return (
          <div className={styles.infoPanelButtons}>
            {reportPath ? (
              <Link to={reportPath}>
                <Button
                  height={ButtonHeight.XSmall}
                  onClick={() => trackButtonClick()}
                  text="View report"
                  variant={ButtonVariant.Primary}
                />
              </Link>
            ) : (
              <Button
                disabled
                height={ButtonHeight.XSmall}
                onClick={() => trackButtonClick()}
                text="View report"
                variant={ButtonVariant.Primary}
              />
            )}
            {isRerunLoading && (
              <div className={styles.loading}>
                <Spinner size={SpinnerSize.Medium} text="Loading" />
              </div>
            )}
            <ButtonList buttons={availableButtons} />
            <DeleteDialog
              deleteItem={async (items: DeleteItems) =>
                deleteItem ? await deleteItem(items.reportIds ?? []) : () => {}
              }
              itemIds={{ reportIds: reportId ? [reportId] : [] }}
              onClose={handleDeleteDialogExit}
              onDelete={handleDeleteReport}
              ref={deleteButtonRef}
              show={showDeleteDialog}
              trackingComponent={TrackingComponent.Report}
            />
            <ShareModal.Reports
              onClose={handleShareDialogExit}
              organisationUsers={organisationUsers}
              reportId={reportId}
              reportName={reportName}
              showModal={showShareDialog}
              triggerLazyReload={triggerLazyReload}
            />
            <RenameModal
              currentName={reportName ?? ""}
              itemType="Report"
              onClose={() => {
                setShowRenameDialog(false);
              }}
              onSubmit={async (value: string) =>
                await renameItem(value, reportId ?? "")
              }
              show={showRenameDialog}
            />

            <MoveItemModal
              folders={folders}
              onClose={handleMoveDialogExit}
              onSubmit={moveReportCallback}
              selectedItemId={reportId ?? ""}
              show={showMoveDialog}
            />
          </div>
        );
      case InfoPanelType.ReportViewer:
        availableButtons = [visibleButtons];

        return (
          <div className={styles.infoPanelButtons}>
            <ButtonList buttons={availableButtons} />
            <ShareModal.Reports
              onClose={handleShareDialogExit}
              organisationUsers={organisationUsers}
              reportId={reportId}
              reportName={reportName}
              showModal={showShareDialog}
              triggerLazyReload={triggerLazyReload}
            />
          </div>
        );
      default:
        return undefined;
    }
  }, [
    infoPanelType,
    reportPath,
    isReportDisabled,
    isRerunLoading,
    onRerun,
    flags,
    reportId,
    handleDeleteDialogExit,
    handleDeleteReport,
    showDeleteDialog,
    handleShareDialogExit,
    organisationUsers,
    reportName,
    showShareDialog,
    handleCreateSavedParameters,
    trackButtonClick,
    deleteItem,
    handleMoveDialogExit,
    showMoveDialog,
    showRenameDialog,
    folders,
    moveReportCallback,
    renameItem,
    triggerLazyReload,
  ]);

  return (
    <InfoPanelHeaderLayout
      buttonPanel={buttonPanel}
      editableFieldState={editableFieldState}
      handleRename={handleRename}
      id={reportId}
      title={reportName}
    />
  );
};

export type InfoPanelLabelProps = {
  reportType?: string;
};

export const InfoPanelLabel = ({ reportType }: InfoPanelLabelProps) => (
  <div className={styles.infoPanelLabel}>
    <ReportIcon reportFailed={false} />
    <Text>{reportType ?? ""}</Text>
  </div>
);
