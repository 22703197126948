import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useReportTabState } from "report-tabs-ui";
import Zod from "zod";
import { useReportConfigurationQuery } from "./fast-report/api/fastReportConfigurationApi";
import {
  type MultiSelectParameterConfiguration,
  type SingleSelectParameterConfiguration,
} from "./fast-report/api/parameterConfiguration";
import { type ReportingConfigurationDto } from "./fast-report/api/reportConfigurationDto";
import { useAppDispatch } from "./hooks";
import {
  newParameterValueSet,
  updateCurrentParameterValue,
} from "./parameterSlice";
import { areParameterValuesEquivalent } from "./parameterUtilities";

export enum Parameter {
  Banner = "Global_Banner",
  Channel = "Global_Channel",
  CompStore = "Global_CompStore",
  CustomerProfilingMetrics = "CustomerProfiling_Metrics",
  CustomerProfilingSegmentation = "CustomerProfiling_Segmentation",
  FocusPeriod = "Global_FocusPeriod",
  KDTBenchmark = "KDT_Benchmark",
  KDTComparisonPeriod = "KDT_ComparisonPeriod",
  Location = "Global_Location",
  PerformanceDataLabels = "Performance_DataLabels",
  PerformanceMetrics = "Performance_Metrics",
  Promotion = "Global_Promotion",
  RangeActiveTab = "Range_ActiveTab",
  StoreByStoreMetrics = "StoreByStore_Metrics",
  StoreByStoreShareOf = "StoreByStore_ShareOf",
  StoreFormat = "Global_StoreFormat",
  TopAndBottomLevelOfAnalysis = "TopAndBottom_LevelOfAnalysis",
  TopAndBottomMetricBreakdown = "TopAndBottom_MetricBreakdown",
  TopAndBottomMetrics = "TopAndBottom_Metrics",
  TopAndBottomShowDeletedProducts = "TopAndBottom_ShowDeletedProducts",
  TopAndBottomShowNewProductsOnly = "TopAndBottom_ShowNewProductsOnly",
  TransactionSource = "Global_TransactionSource",
}

type ParameterConfiguration<T> =
  | MultiSelectParameterConfiguration<T>
  | SingleSelectParameterConfiguration<T>;

type ParameterState<
  T,
  TConfig extends ParameterConfiguration<T>
> = TConfig extends MultiSelectParameterConfiguration<T> ? T[] : T;

export const useFastReportingParameterState =
  /**
   * Manages state for a fast-reporting parameter. It stores the state against the report tab,
   * loads default values from config, tracks current parameter values and when they are set
   * so that new defaults can be set for new tabs, and also validates that the selection is a
   * valid option returned from the parameter config
   *
   * @template T The type of the value for the parameter. Does not need to be set as can be inferred from
   * schema and configSelector parameters
   * @template {ParameterConfiguration<T>} TConfig The type of parameter configuration. This does not need
   * to be set as it can be inferred from the configSelector parameter
   * @param {Parameter} parameterName The parameter this state is for. Should be unique for each parameter
   * @param {Zod.ZodType<T>} schema A Zod schema describing the shape of a parameter value
   * @param {(config: ReportingConfigurationDto) => TConfig | undefined} configSelector A function that returns
   * the configuration for the parameter
   * @param {T | undefined} overrideDefault Supply a different default to use instead of the one from report config
   * @param {boolean} copyToNewTabs If false, the parameter value will not be copied to new tabs
   * @returns {[
   *   ParameterState<T, TConfig> | undefined,
   *   (value: ParameterState<T, TConfig>) => void
   * ]} An array where the first item is the value of the parameter, and the second is a function to call to set the
   * parameter state
   */
  <T, TConfig extends ParameterConfiguration<T>>(
    parameterName: Parameter,
    schema: Zod.ZodType<T>,
    configSelector: (config: ReportingConfigurationDto) => TConfig | undefined,
    overrideDefault?: T,
    copyToNewTabs?: boolean,
    allowUnlistedValues?: boolean
  ): [
    ParameterState<T, TConfig> | undefined,
    (value: ParameterState<T, TConfig>) => void
  ] => {
    type TState = ParameterState<T, TConfig>;
    const dispatch = useAppDispatch();

    const division = useDivision();
    const reportConfig = useReportConfigurationQuery(
      { division: division.name },
      {
        skip: !division.name,
      }
    );

    const parameterConfiguration = useMemo(() => {
      if (!reportConfig.data) {
        return undefined;
      }

      return configSelector(reportConfig.data);
    }, [reportConfig.data, configSelector]);

    const stateSchema = useMemo((): Zod.ZodType<TState> | undefined => {
      if (!parameterConfiguration) {
        return undefined;
      }

      return Array.isArray(parameterConfiguration.defaultOption)
        ? (Zod.array(schema) as unknown as Zod.ZodType<TState>)
        : (schema as Zod.ZodType<TState>);
    }, [parameterConfiguration, schema]);

    const [tabState, setTabState] = useReportTabState<TState>(
      parameterName,
      stateSchema
    );

    // Set the default option when configuration is loaded
    useEffect(() => {
      if (tabState === undefined && parameterConfiguration) {
        const defaultValue =
          overrideDefault === undefined
            ? parameterConfiguration.defaultOption
            : overrideDefault;
        setTabState(defaultValue as TState);
      }
    }, [
      tabState,
      setTabState,
      parameterConfiguration,
      configSelector,
      overrideDefault,
    ]);

    // Set the current parameter value when it changes, so that new tabs can get the latest value
    useEffect(() => {
      if (tabState !== undefined) {
        dispatch(
          updateCurrentParameterValue({
            key: parameterName,
            value: tabState,
            copyToNewTabs: copyToNewTabs ?? true,
          })
        );
      }
    }, [tabState, dispatch, parameterName, copyToNewTabs]);

    const validatedValue = useMemo(() => {
      if (!parameterConfiguration || tabState === undefined) {
        return undefined;
      }

      if (allowUnlistedValues) {
        return tabState;
      }

      if (Array.isArray(parameterConfiguration.defaultOption)) {
        for (const arrayItem of tabState as T[]) {
          const matchingOption = parameterConfiguration.options.find((option) =>
            areParameterValuesEquivalent(option.value, arrayItem)
          );

          if (matchingOption === undefined) {
            return undefined;
          }
        }

        return tabState;
      } else {
        const matchingOption = parameterConfiguration.options.find((option) =>
          areParameterValuesEquivalent(option.value, tabState)
        );

        if (matchingOption === undefined) {
          return undefined;
        }

        return tabState;
      }
    }, [tabState, parameterConfiguration, allowUnlistedValues]);

    useEffect(() => {
      if (
        parameterConfiguration &&
        validatedValue === undefined &&
        tabState !== undefined
      ) {
        setTabState(parameterConfiguration.defaultOption as TState);
      }
    });

    const updateState = useCallback(
      (newValue: TState) => {
        setTabState(newValue);
        dispatch(
          updateCurrentParameterValue({
            key: parameterName,
            value: newValue,
            copyToNewTabs: copyToNewTabs ?? true,
          })
        );
        dispatch(newParameterValueSet());
      },
      [setTabState, dispatch, parameterName, copyToNewTabs]
    );

    return [validatedValue, updateState];
  };

export default useFastReportingParameterState;
