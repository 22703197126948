import { type RouteWithLabel } from "@quantium-enterprise/common-ui";
import { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { type RouteObject } from "react-router-dom";
import ErrorBoundary from "../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import {
  route as gsdRoute,
  routeWithId as gsdRouteWithId,
} from "./customer-groups/GrowingStableDeclining";
import {
  route as hmlRoute,
  routeWithId as hmlRouteWithId,
} from "./customer-groups/HeavyMediumLight";
import {
  route as mtRoute,
  routeWithId as mtRouteWithId,
} from "./customer-groups/MeasureThreshold";
import {
  route as nlcRoute,
  routeWithId as nlcRouteWithId,
} from "./customer-groups/NewLapsedContinuous";
import {
  route as pnpRoute,
  routeWithId as pnpRouteWithId,
} from "./customer-groups/PurchaserNonPurchaser";
import { route as arRoute } from "./reports/AggregateRank";
import { route as baRoute } from "./reports/BasketAffinities";
import { route as blRoute } from "./reports/BasketLimits";
import { route as bqRoute } from "./reports/BasketQuantities";
import { route as cmRoute } from "./reports/CompareMetrics";
import { route as ccsRoute } from "./reports/CustomerCrossShop";
import { route as clRoute } from "./reports/CustomerLoyalty";
import { route as cpRoute } from "./reports/CustomerProfiling";
import { route as glRoute } from "./reports/GainsAndLoss";
import { route as kdtRoute } from "./reports/KeyDriverTree";
import { route as kdotRoute } from "./reports/KeyDriversOverTime";
import { route as kmtRoute } from "./reports/KeyMeasureTrends";
import { route as npbRoute } from "./reports/NewProductBenchmarking";
import { route as plRoute } from "./reports/PricingLadders";
import { route as psRoute } from "./reports/ProductSubstitutability";
import { route as rdRoute } from "./reports/RawData";
import { route as rptRoute } from "./reports/Repertoire";
import { route as toddowRoute } from "./reports/TimeOfDayDayOfWeek";
import { route as tarRoute } from "./reports/TrialAndRepeat";
import { store } from "./store";

// eslint-disable-next-line @typescript-eslint/promise-function-async -- this is as per React docs
const ReportParameters = lazy(
  async () => await import("./common/wizard/parameters/ReportParameters")
);

const CustomerGroupParameters = lazy(
  async () => await import("./common/wizard/parameters/CustomerGroupParameters")
);

const reportRoutes: RouteObject[] = [
  kmtRoute,
  glRoute,
  kdtRoute,
  cpRoute,
  kdotRoute,
  cmRoute,
  toddowRoute,
  clRoute,
  ccsRoute,
  baRoute,
  blRoute,
  bqRoute,
  arRoute,
  psRoute,
  npbRoute,
  plRoute,
  rptRoute,
  tarRoute,
  rdRoute,
];

const customerGroupRoutes: RouteObject[] = [
  gsdRoute,
  gsdRouteWithId,
  hmlRoute,
  hmlRouteWithId,
  mtRoute,
  mtRouteWithId,
  nlcRoute,
  nlcRouteWithId,
  pnpRoute,
  pnpRouteWithId,
];

export const ReportWizard = (): RouteWithLabel => ({
  children: reportRoutes,
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <ReportParameters />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Report Wizard",
  path: `report-wizard`,
});

export const CustomerGroupWizard = (): RouteWithLabel => ({
  children: customerGroupRoutes,
  element: (
    <ErrorBoundary>
      <Suspense fallback={<>loading...</>}>
        <Provider store={store}>
          <CustomerGroupParameters />
        </Provider>
      </Suspense>
    </ErrorBoundary>
  ),
  label: "Customer Group",
  path: `customer-group-wizard`,
});
