import { Toggle } from "@qbit/react";
import {
  HierarchyType,
  useHierarchyMetadataQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useEffect } from "react";
import { useReportTabState } from "report-tabs-ui";
import Zod from "zod";
import { Parameter } from "../../useFastReportingParameterState";
import { type LevelOfAnalysisValue } from "./LevelOfAnalysisDropdown";
import { LevelOfAnalysisValueSchema } from "./LevelOfAnalysisDropdown";

export const ShowNewProductsOnlyValueSchema = Zod.boolean();
export type ShowNewProductsOnlyValue = Zod.infer<
  typeof ShowNewProductsOnlyValueSchema
>;

export const ShowNewProductsOnlyToggle = () => {
  const [showNewProductsOnly, setNewProductsOnly] =
    useReportTabState<ShowNewProductsOnlyValue>(
      Parameter.TopAndBottomShowNewProductsOnly,
      ShowNewProductsOnlyValueSchema
    );

  if (showNewProductsOnly === undefined) {
    setNewProductsOnly(false);
  }

  const division = useDivision();

  const hierarchyMetadata = useHierarchyMetadataQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      getAllAttributes: false,
    },
    { skip: !division.name }
  );

  const [levelOfAnalysis] = useReportTabState<LevelOfAnalysisValue>(
    Parameter.TopAndBottomLevelOfAnalysis,
    LevelOfAnalysisValueSchema
  );

  const isLeafLevel =
    hierarchyMetadata.currentData?.find(
      (hm) => hm.shortName === levelOfAnalysis
    )?.isLeaf ?? false;

  useEffect(() => {
    if (!isLeafLevel) {
      setNewProductsOnly(false);
    }
  }, [isLeafLevel, setNewProductsOnly]);

  const label = (
    <span title="See products launched within your selected time period.">
      Show new products only
    </span>
  );

  return (
    <Toggle
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- should always be set due to default above
      checked={showNewProductsOnly!}
      disabled={!isLeafLevel}
      label={label}
      onClick={() => setNewProductsOnly(!showNewProductsOnly)}
    />
  );
};
