import { type TransactionSource } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import WarningBanner from "components-ui/src/error-banner/WarningBanner";
import { TransactionSourceIcon } from "components-ui/src/icons";
import { differenceInWeeks } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useActiveItem } from "../../useActiveItem";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../ReportPanel.module.css";
import { useDisplayEntitlements, useGetFeaturesForFocalItem } from "../hooks";
import { KeyDriverTree } from "./KeyDriverTree";
import { KeyDriverTreeParametersHeader } from "./KeyDriverTreeParametersHeader";

export const KEY_DRIVER_TREE_REPORT_FEATURE_NAME = "key-driver-tree-report";

const KeyDriverTreeReportPanel = () => {
  const activeItem = useActiveItem();
  const [keyDriverTreeTransactionSource, setKeyDriverTreeTransactionSource] =
    useState<TransactionSource | null>(null);

  const division = useDivision();
  const reportConfiguration = useReportConfigurationQuery(
    { division: division.name },
    { skip: !division.name }
  );
  const displayEntitlements = useDisplayEntitlements();
  const [globalParameters] = useGlobalParameters();

  const keyDriverTreeReportTitle = useMemo(
    () => (
      <span className={styles.reportTitle}>
        Key driver tree
        {displayEntitlements && keyDriverTreeTransactionSource && (
          <TransactionSourceIcon
            availableTransactionSources={division.transactionSources}
            transactionSource={keyDriverTreeTransactionSource}
          />
        )}
      </span>
    ),
    [
      displayEntitlements,
      keyDriverTreeTransactionSource,
      division.transactionSources,
    ]
  );

  const features = useGetFeaturesForFocalItem(division, activeItem);

  const getKeyDriverTreeReport = useCallback(() => {
    if (
      !activeItem ||
      !reportConfiguration.currentData?.reports.keyDriverTree
    ) {
      return null;
    }

    const disabledTransactionSources =
      reportConfiguration.currentData.reports.keyDriverTree
        .disabledTransactionSources;
    if (
      disabledTransactionSources?.find(
        (x) => x === globalParameters.transactionSource
      )
    ) {
      return (
        <WarningBanner
          data-cy="KeyDriverTreeReportTransactionSourceWarningBanner"
          text={`This report cannot be run on ${globalParameters.transactionSource} data.`}
        />
      );
    }

    if (globalParameters.focusPeriod) {
      const focusPeriodWeeks =
        differenceInWeeks(
          new Date(globalParameters.focusPeriod.endDate),
          new Date(globalParameters.focusPeriod.startDate)
        ) + 1;

      if (
        focusPeriodWeeks >
        reportConfiguration.currentData.reports.keyDriverTree.maxFocalWeeks
      ) {
        return (
          <WarningBanner
            data-cy="KeyDriverTreeReportFocusPeriodWarningBanner"
            text={`This report cannot be run on a ${focusPeriodWeeks} week focus period.`}
          />
        );
      }

      const customFocusPeriodOptions =
        reportConfiguration.currentData.globalParameters.focusPeriod
          ?.customOptions;
      if (customFocusPeriodOptions) {
        const maxStartDateAgeWeeks =
          reportConfiguration.currentData.reports.keyDriverTree
            .maxStartDateAgeWeeks;
        const selectedStartDateAgeWeeks =
          differenceInWeeks(
            new Date(customFocusPeriodOptions.latestWeek.endDate),
            new Date(globalParameters.focusPeriod.startDate)
          ) + 1;

        if (selectedStartDateAgeWeeks > maxStartDateAgeWeeks) {
          return (
            <WarningBanner
              data-cy="KeyDriverTreeReportFocusPeriodWarningBanner"
              text={`This report cannot be run on a date range greater than ${maxStartDateAgeWeeks} weeks in the past.`}
            />
          );
        }
      }
    }

    return (
      <ErrorBoundary>
        <div data-cy="KeyDriverTreeReportPanel">
          <KeyDriverTreeParametersHeader />
          <KeyDriverTree
            onTransactionSourceChanged={setKeyDriverTreeTransactionSource}
          />
        </div>
      </ErrorBoundary>
    );
  }, [
    activeItem,
    globalParameters.focusPeriod,
    globalParameters.transactionSource,
    reportConfiguration.currentData?.globalParameters.focusPeriod
      ?.customOptions,
    reportConfiguration.currentData?.reports.keyDriverTree,
  ]);

  return (
    <div data-cy="KeyDriverTreeReportPanel">
      {reportConfiguration.data?.reports.keyDriverTree?.disabled === false &&
        features?.find(
          (feature) => feature === KEY_DRIVER_TREE_REPORT_FEATURE_NAME
        ) && (
          <Accordion
            className={styles.reportAccordion}
            subtitle="Identify key drivers of sales performance in your chosen time period."
            title={keyDriverTreeReportTitle}
          >
            {getKeyDriverTreeReport()}
          </Accordion>
        )}
    </div>
  );
};

export default KeyDriverTreeReportPanel;
