import { type TransactionSource } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import WarningBanner from "components-ui/src/error-banner/WarningBanner";
import { TransactionSourceIcon } from "components-ui/src/icons";
import { useCallback, useMemo, useState } from "react";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useActiveItem } from "../../useActiveItem";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../ReportPanel.module.css";
import { useDisplayEntitlements, useGetFeaturesForFocalItem } from "../hooks";
import { CustomerProfiling } from "./CustomerProfiling";
import { CustomerProfilingParametersHeader } from "./CustomerProfilingParametersHeader";

export const CUSTOMER_PROFILING_REPORT_FEATURE_NAME =
  "customer-profiling-report";

const CustomerProfilingReportPanel = () => {
  const [
    customerProfilingTransactionSource,
    setCustomerProfilingTransactionSource,
  ] = useState<TransactionSource | null>(null);

  const division = useDivision();
  const reportConfiguration = useReportConfigurationQuery(
    { division: division.name },
    { skip: !division.name }
  );
  const activeItem = useActiveItem();
  const displayEntitlements = useDisplayEntitlements();
  const [globalParameters] = useGlobalParameters();

  const customerProfilingReportTitle = useMemo(
    () => (
      <span className={styles.reportTitle}>
        Customer profiling
        {displayEntitlements && customerProfilingTransactionSource && (
          <TransactionSourceIcon
            availableTransactionSources={division.transactionSources}
            transactionSource={customerProfilingTransactionSource}
          />
        )}
      </span>
    ),
    [
      displayEntitlements,
      customerProfilingTransactionSource,
      division.transactionSources,
    ]
  );

  const features = useGetFeaturesForFocalItem(division, activeItem);

  const getCustomerProfilingReport = useCallback(() => {
    if (
      !activeItem ||
      !reportConfiguration.currentData?.reports.customerProfiling
    ) {
      return null;
    }

    const disabledTransactionSources =
      reportConfiguration.currentData.reports.customerProfiling
        .disabledTransactionSources;
    if (
      disabledTransactionSources?.find(
        (x) => x === globalParameters.transactionSource
      )
    ) {
      return (
        <WarningBanner
          data-cy="CustomerProfilingReportTransactionSourceWarningBanner"
          text={`This report cannot be run on ${globalParameters.transactionSource} data.`}
        />
      );
    }

    const disabledCompStoreValue =
      reportConfiguration.currentData.reports.customerProfiling
        .disabledCompStoreValue;
    if (globalParameters.compStore === disabledCompStoreValue) {
      return (
        <WarningBanner
          data-cy="CustomerProfilingReportTransactionSourceWarningBanner"
          text={`This report is not currently available when ${globalParameters.compStore} is selected.`}
        />
      );
    }

    return (
      <ErrorBoundary>
        <div>
          <CustomerProfilingParametersHeader />
          <CustomerProfiling
            onTransactionSourceChanged={setCustomerProfilingTransactionSource}
          />
        </div>
      </ErrorBoundary>
    );
  }, [
    activeItem,
    globalParameters.compStore,
    globalParameters.transactionSource,
    reportConfiguration.currentData?.reports.customerProfiling,
  ]);

  return (
    <div data-cy="CustomerProfilingReportPanel">
      {reportConfiguration.data?.reports.customerProfiling?.disabled ===
        false &&
        features?.find(
          (feature) => feature === CUSTOMER_PROFILING_REPORT_FEATURE_NAME
        ) && (
          <Accordion
            className={styles.reportAccordion}
            subtitle="See which customers your selected items resonate with most."
            title={customerProfilingReportTitle}
          >
            {getCustomerProfilingReport()}
          </Accordion>
        )}
    </div>
  );
};

export default CustomerProfilingReportPanel;
