type RemoveIconProps = {
  isDisabled?: boolean;
};

export const RemoveIcon = ({ isDisabled = false }: RemoveIconProps) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 16 16"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00016 1.33325C4.32016 1.33325 1.3335 4.31992 1.3335 7.99992C1.3335 11.6799 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.6799 14.6668 7.99992C14.6668 4.31992 11.6802 1.33325 8.00016 1.33325ZM11.3335 8.66658H4.66683V7.33325H11.3335V8.66658Z"
      fill={isDisabled ? "#C3C3C3" : "#B10200"}
    />
  </svg>
);
