import tokens from "@qbit/core/dist/tokens.json";
import {
  Button,
  ButtonVariant,
  IconSize,
  InlineIcon,
  InlineIconGlyph,
} from "@qbit/react";
import {
  HierarchyItemType,
  useHierarchyMetadataQuery,
  HierarchyType,
  useGetRootNodesQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type Column,
  type Table,
  type VisibilityState,
  type HeaderContext,
  type CellContext,
} from "@tanstack/react-table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { notUndefined, useVirtualizer } from "@tanstack/react-virtual";
import React, {
  useState,
  useCallback,
  useMemo,
  useLayoutEffect,
  memo,
  useEffect,
} from "react";
import { useReportTabState } from "report-tabs-ui";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { type GlobalParameterValues } from "../../fast-report/globalParameterValues";
import { useActiveItem } from "../../useActiveItem";
import { Parameter } from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";
import useOpenFastReportingTabs from "../../useOpenFastReportingTabs";
import { FocalItemIcon } from "../FocalItemIcon";
import { type FastReportingTableSorting } from "../common-table-utils/hooks";
import { useFastReportingTableSorting } from "../common-table-utils/hooks";
import { useGetParameterValues } from "../hooks";
import { MetricBreakdownValueSchema } from "./MetricBreakdownParameter";
import { type TopAndBottomReportMetric } from "./TopAndBottomReportMetric";
import { type TopAndBottomTableItem } from "./TopAndBottomReportPanel";
import styles from "./TopAndBottomTable.module.css";

type ColumnMeta = {
  isNumeric: boolean;
  isRankColumn: boolean;
  isRowHeader: boolean;
  isSticky: boolean;
  stickyWidth: number;
};

const getSplitMetricKey = (key: string, splitValue: string) =>
  `${key}_${splitValue}`;

const getCellStyling = (
  column: Column<TopAndBottomTableItem, unknown>,
  table: Table<TopAndBottomTableItem>,
  isHeader: boolean,
  isAggregate: boolean
): React.CSSProperties => {
  const columnMeta = column.columnDef.meta as ColumnMeta | undefined;

  let currentStyling: React.CSSProperties = {};

  // If the column is sticky, set the position of each cell to be the combined width of previous columns
  if (columnMeta?.isSticky) {
    const columns = table.getVisibleLeafColumns();
    const selectedColumnPosition = columns.indexOf(column);

    // Sum the sticky width of all previous columns
    const stickyPosition = columns
      .slice(0, selectedColumnPosition)
      .map(
        (previousColumn) =>
          (previousColumn.columnDef.meta as ColumnMeta | undefined)?.stickyWidth
      )
      .reduce((previous, current) => (previous ?? 0) + (current ?? 0), 0);

    currentStyling = {
      ...currentStyling,
      position: "sticky",
      left: stickyPosition,
      zIndex: isHeader || isAggregate ? 2 : 1,
      width: columnMeta.stickyWidth,
      minWidth: columnMeta.stickyWidth,
      maxWidth: columnMeta.stickyWidth,
    };
  }

  // If column is numeric and in table body or aggregate, align right
  if (
    (columnMeta?.isNumeric && !isHeader) ||
    (columnMeta?.isNumeric && isAggregate)
  ) {
    currentStyling = {
      ...currentStyling,
      textAlign: "end",
    };
  }

  // If sorted by column, make header a different shade
  if (isHeader && column.getIsSorted()) {
    currentStyling = {
      ...currentStyling,
      background: tokens.colour["shade-2"],
    };
  }

  return currentStyling;
};

export type TopAndBottomTableProps = {
  aggregateSplitValues: Record<
    string,
    Record<string, number | undefined> | undefined
  >;
  aggregateValues: Record<string, number | undefined>;
  availableMetrics: TopAndBottomReportMetric[];
  items: TopAndBottomTableItem[];
  levelOfAnalysis: string;
  onSortingChanged: (sorting: FastReportingTableSorting) => void;
  selectedMetrics: string[];
};

type NameCellItemProps = {
  handleClick: (shortName: string, code: string, name: string) => void;
  item: TopAndBottomTableItem;
};

// This has to be memo'd. It looks like the virtualisation is causing the SVG icons to be
// rerendered on scroll causing react to crash, this is worse when running in strict mode.
// Likewise, we've taken and made static, the svg's from HierarchyLevelIcon.tsx as they are horribly slow
// and are calculated inline.
const DelTag = memo(() => (
  <div
    className={styles.tagContainer}
    title="This product is no longer in the range."
  >
    <svg
      className="hierarchy-level-icon del"
      data-testid="hierarchy-level-icon"
      fill="none"
      height="16"
      shapeRendering="optimizeSpeed"
      viewBox="0 0 28 16"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="hierarchy-level-icon-badge"
        data-testid="child-badge"
        height="16"
        rx="3"
        width="28"
      />
      <text
        className="hierarchy-level-icon-text"
        textRendering="optimizeSpeed"
        x="14"
        y="11.65"
      >
        DEL
      </text>
    </svg>
  </div>
));

const NewTag = memo(() => (
  <div
    className={styles.tagContainer}
    title="This product has been launched within your selected time period."
  >
    <svg
      className="hierarchy-level-icon new"
      data-testid="hierarchy-level-icon"
      fill="none"
      height="16"
      shapeRendering="optimizeSpeed"
      viewBox="0 0 28 16"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="hierarchy-level-icon-badge"
        data-testid="child-badge"
        height="16"
        rx="3"
        width="28"
      />
      <text
        className="hierarchy-level-icon-text"
        textRendering="optimizeSpeed"
        x="14"
        y="11.65"
      >
        NEW
      </text>
    </svg>
  </div>
));

const NameCellItem = memo(({ item, handleClick }: NameCellItemProps) => {
  // Hide the SVG tags until the cell has rendered into view.
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => setIsLoading(false), []);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={styles.navigationButton}
      data-cy="ItemName"
      onClick={() => handleClick(item.shortName, item.code, item.name)}
      role="presentation"
    >
      <div className={styles.nameCellContainer}>
        <div className={styles.nameContainer} title={item.name}>
          {item.name}
        </div>
        {!isLoading && item.isNewLine && <NewTag />}
        {!isLoading && item.isDeletedLine && <DelTag />}
      </div>
    </a>
  );
});

export const TopAndBottomTable = ({
  items,
  availableMetrics,
  selectedMetrics,
  aggregateSplitValues,
  aggregateValues,
  onSortingChanged,
  levelOfAnalysis,
}: TopAndBottomTableProps) => {
  // Default all metrics to be visible when selected
  const [visibility, setVisibility] = useState<VisibilityState>({});
  const [globalParameters] = useGlobalParameters();
  const division = useDivision();
  const reportConfiguration = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: ({ data }) => ({
        globalParameters: data?.globalParameters,
        metricBreakdown: data?.reports.topAndBottom?.metricBreakdown,
      }),
      skip: !division.name,
    }
  );

  const [metricBreakdown] = useReportTabState<string | null>(
    Parameter.TopAndBottomMetricBreakdown,
    MetricBreakdownValueSchema
  );

  const getValues = useGetParameterValues();
  const subColumns = useMemo(
    () =>
      getValues(
        (config) =>
          config.globalParameters[
            metricBreakdown as keyof GlobalParameterValues
          ],
        globalParameters[metricBreakdown as keyof GlobalParameterValues]
      ),
    [getValues, globalParameters, metricBreakdown]
  );

  const selectedMetricIds = useMemo(
    () =>
      subColumns
        ? selectedMetrics.flatMap((selectedMetric) =>
            subColumns.map((column) =>
              getSplitMetricKey(selectedMetric, column)
            )
          )
        : selectedMetrics,
    [selectedMetrics, subColumns]
  );

  useLayoutEffect(() => {
    setVisibility(() => {
      const availableMetricIds = subColumns
        ? availableMetrics.flatMap((availableMetric) =>
            subColumns.map((column) => ({
              ...availableMetric,
              key: getSplitMetricKey(availableMetric.key, column),
            }))
          )
        : availableMetrics;

      return Object.assign(
        {
          productNumber: items.some((item) => item.number),
        },
        ...availableMetricIds.map((metric) => ({
          [metric.key]: selectedMetricIds.includes(metric.key),
        }))
      );
    });
  }, [availableMetrics, items, selectedMetricIds, setVisibility, subColumns]);

  const { toggleSorting, reactTableSortingState } =
    useFastReportingTableSorting(
      "TopAndBottom_Sorting",
      onSortingChanged,
      selectedMetricIds
    );

  const formatter = useFormatter();

  const columnHelper = useMemo(
    () => createColumnHelper<TopAndBottomTableItem>(),
    []
  );

  const metricColumns = useMemo(() => {
    const getMetricColumn = (
      metric: {
        displayName: string;
        format: string;
        key: string;
      },
      subColumn?: string
    ) => ({
      id: subColumn ? getSplitMetricKey(metric.key, subColumn) : metric.key,
      // eslint-disable-next-line react/no-unstable-nested-components
      header: (properties: HeaderContext<TopAndBottomTableItem, unknown>) => {
        const sortDirection = properties.column.getIsSorted();
        const sortIcon =
          sortDirection === "desc"
            ? InlineIconGlyph.ArrowsSortingDown
            : InlineIconGlyph.ArrowsSortingUp;
        const metricBreakdownParameterOptions =
          reportConfiguration.globalParameters?.[
            metricBreakdown as keyof GlobalParameterValues
          ]?.options;
        // Have to iterate in a loop as find is failing typecheck due to the complexity of the ParameterOptions object
        let title = metric.displayName;
        if (subColumn && Array.isArray(metricBreakdownParameterOptions)) {
          for (const metricBreakdownOption of metricBreakdownParameterOptions) {
            if (metricBreakdownOption.value === subColumn) {
              title = metricBreakdownOption.displayName;
              break;
            }
          }
        }

        return (
          <span className={styles.sortedHeader}>
            <Button
              data-cy="MetricHeader"
              data-key={metric.key}
              data-sorting={sortDirection}
              onClick={() => toggleSorting(properties.header.column.id)}
              text={title}
              variant={ButtonVariant.Link}
            />
            {sortDirection && (
              <InlineIcon
                glyph={sortIcon}
                size={IconSize.Small}
                text={`sorted ${sortDirection}`}
              />
            )}
          </span>
        );
      },
      cell: (
        properties: CellContext<TopAndBottomTableItem, number | undefined>
      ) => formatter(metric.format)(properties.getValue()),
      footer: () =>
        formatter(metric.format)(
          subColumn
            ? aggregateSplitValues[subColumn]?.[metric.key]
            : aggregateValues[metric.key]
        ),
      meta: { isNumeric: true } as ColumnMeta,
    });

    return availableMetrics.map((metric) =>
      subColumns
        ? columnHelper.group({
            // eslint-disable-next-line react/no-unstable-nested-components
            header: () => (
              <div className={styles.splitHeader}>
                <div>{metric.displayName}</div>
                <div>
                  {formatter(metric.format)(aggregateValues[metric.key])}
                </div>
              </div>
            ),
            id: metric.key,
            columns: subColumns.map((column) =>
              columnHelper.accessor(
                (item) => item.measureResults[column]?.[metric.key] ?? 0,
                getMetricColumn(metric, column)
              )
            ),
          })
        : columnHelper.accessor(
            (item) => item.measureResults.AGGREGATED?.[metric.key],
            getMetricColumn(metric)
          )
    );
  }, [
    availableMetrics,
    reportConfiguration.globalParameters,
    metricBreakdown,
    toggleSorting,
    formatter,
    aggregateSplitValues,
    aggregateValues,
    subColumns,
    columnHelper,
  ]);

  const openTabs = useOpenFastReportingTabs();
  const activeItem = useActiveItem();
  const hierarchyRoot = useGetRootNodesQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
      payload: { page: 0 },
    },
    { skip: !division.name }
  );
  const hierarchyMetadata = useHierarchyMetadataQuery(
    {
      division: division.name,
      hierarchyType: HierarchyType.Product,
    },
    { skip: !division.name }
  );

  const openTabForChild = useCallback(
    (shortName: string, code: string, name: string) => {
      if (hierarchyMetadata.data && hierarchyRoot.data) {
        const matchingHierarchyLevel = hierarchyMetadata.data.find(
          (level) => level.shortName === shortName
        );
        const rootStructureName = hierarchyMetadata.data.find(
          (meta) => meta.shortName === hierarchyRoot.data?.results[0].shortName
        )?.structureName;
        const isAttribute =
          matchingHierarchyLevel?.structureName !== rootStructureName;
        const itemType = isAttribute
          ? HierarchyItemType.Attribute
          : matchingHierarchyLevel?.isLeaf
          ? HierarchyItemType.Leaf
          : HierarchyItemType.Hierarchy;

        if (
          itemType === HierarchyItemType.Attribute &&
          activeItem?.type === HierarchyItemType.Hierarchy
        ) {
          openTabs([
            {
              type: itemType,
              displayName: name,
              shortName,
              code,
              additionalHierarchyFilter: {
                code: activeItem.code,
                shortName: activeItem.shortName,
              },
            },
          ]);
        } else {
          openTabs([
            {
              type: itemType,
              displayName: name,
              shortName,
              code,
            },
          ]);
        }
      }
    },
    [hierarchyMetadata.data, hierarchyRoot.data, openTabs, activeItem]
  );

  const allColumns = useMemo(
    () => [
      columnHelper.display({
        id: "rank",
        cell: (properties) => properties.row.index + 1,
        header: "Rank",
        meta: {
          isRowHeader: true,
          isRankColumn: true,
          isNumeric: true,
          isSticky: true,
          stickyWidth: 50,
        } as ColumnMeta,
      }),
      columnHelper.display({
        id: "productNumber",
        cell: (properties) => properties.row.original.number,
        header: "Product number",
        meta: {
          isRowHeader: true,
          isSticky: true,
          stickyWidth: 150,
        } as ColumnMeta,
      }),
      columnHelper.display({
        id: "productName",
        // eslint-disable-next-line react/no-unstable-nested-components -- This isn't a component
        cell: (properties) => (
          <NameCellItem
            handleClick={() =>
              openTabForChild(
                properties.row.original.shortName,
                properties.row.original.code,
                properties.row.original.name
              )
            }
            item={properties.row.original}
          />
        ),
        header: () => (levelOfAnalysis ? `${levelOfAnalysis} name` : null),
        // eslint-disable-next-line react/no-unstable-nested-components -- This isn't a component
        footer: () => (
          <div className={styles.hierarchyName}>
            {activeItem && (
              <>
                <FocalItemIcon focalItem={activeItem} />
                <div>{activeItem.displayName}</div>
              </>
            )}
          </div>
        ),
        meta: {
          isRowHeader: true,
          isSticky: true,
          stickyWidth: 350,
        } as ColumnMeta,
      }),
      ...metricColumns,
    ],
    [columnHelper, activeItem, metricColumns, levelOfAnalysis, openTabForChild]
  );

  const table = useReactTable<TopAndBottomTableItem>({
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: reactTableSortingState,
      columnVisibility: visibility,
    },
    columns: allColumns,
  });

  const { rows } = table.getRowModel();
  const parentRef = React.useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => 35, []),
    measureElement:
      typeof window !== "undefined" && !navigator.userAgent.includes("Firefox")
        ? (element) => element.getBoundingClientRect().height
        : undefined,
    overscan: 0,
  });

  const virtualItems = virtualizer.getVirtualItems();

  const [paddingTop, paddingBottom] =
    virtualItems.length > 0
      ? [
          notUndefined(virtualItems[0]).start -
            virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() -
            notUndefined(virtualItems[virtualItems.length - 1]).end,
        ]
      : [0, 0];

  return (
    <div className={styles.container} ref={parentRef}>
      <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
        <table className={styles.topAndBottomTable} data-cy="TopAndBottomTable">
          <colgroup>
            {table.getVisibleLeafColumns().map((column) => {
              const columnMeta = column.columnDef.meta as ColumnMeta;
              let width: number | undefined;
              if (columnMeta.isSticky) {
                width = columnMeta.stickyWidth;
              } else if (columnMeta.isNumeric) {
                width = 120;
              }

              return (
                <col
                  key={column.id}
                  style={{
                    width,
                  }}
                />
              );
            })}
          </colgroup>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    colSpan={header.colSpan}
                    key={header.id}
                    style={getCellStyling(header.column, table, true, false)}
                  >
                    <span>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* React table is generating 2 footers when the table is split, we only ever want the first one */}
            <tr key={table.getFooterGroups()[0].id}>
              {table.getFooterGroups()[0].headers.map((header) => (
                <th
                  colSpan={header.colSpan}
                  key={header.id}
                  style={getCellStyling(header.column, table, true, true)}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paddingTop > 0 ? (
              <tr>
                <td style={{ height: paddingTop }} />
              </tr>
            ) : null}
            {virtualizer.getVirtualItems().map((virtualRow, index) => (
              <tr
                data-index={index}
                key={rows[virtualRow.index].id}
                ref={(node) => virtualizer.measureElement(node)}
                style={{
                  height: `${virtualRow.size}px`,
                }}
              >
                {rows[virtualRow.index].getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={getCellStyling(cell.column, table, false, false)}
                  >
                    {(cell.column.columnDef.meta as ColumnMeta | undefined)
                      ?.isRankColumn
                      ? virtualRow.index + 1
                      : flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                  </td>
                ))}
              </tr>
            ))}
            {paddingBottom > 0 ? (
              <tr>
                <td style={{ height: paddingBottom }} />
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopAndBottomTable;
