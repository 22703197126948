import {
  FeatureFlag,
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
  formatNumberDate,
} from "@quantium-enterprise/common-ui";
import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui/src/models/hierarchy-slice-dto";
import {
  useFlags,
  type DeferredFormatFunction,
} from "@quantium-enterprise/hooks-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  type ExpandedState,
  type RowSelectionState,
  type VisibilityState,
  type GroupColumnDef,
  type CellContext,
  createColumnHelper,
  type Row,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { KeyMeasureTrendsFeatureFlags } from "../constants/key-measure-trends-feature-flags";
import {
  type KeyMeasureTrendsMetadata,
  type HierarchicalTableRow,
  type SearchTableRequest,
} from "../models/DataTableDto";
import { useGetSearchTableQuery } from "../services/key-measure-trends-data-table-api-slice";
import {
  useLazyDownloadTableExcelQuery,
  useLazyDownloadTableQuery,
} from "../services/key-measure-trends-export-api-slice";
import {
  onFocalItemsChange,
  selectChannel,
  selectLocationHierarchy,
  selectPromotion,
  resetHiddenSelections,
  selectTimePeriodLength,
  selectTime,
  selectDataset,
  selectCompStore,
} from "../services/key-measure-trends-slice";
import { getFocalItemTableLocalSelections } from "../utils/getDefaultSelections";
import styles from "./KeyMeasureTrendsTopDrawerProductTable.module.css";

export type KeyMeasureTrendsTopDrawerProductTableProps = {
  data: HierarchicalTableRow[];
  eventTrackingService: Function;
  fetchChildren: (item: HierarchicalTableRow) => void;
  formatter: DeferredFormatFunction;
  isRowFetchingChildren: {
    [nodeNumber: number]: boolean;
  };
  isSuccess: boolean;
  metadata: KeyMeasureTrendsMetadata;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
  selectedItems: HierarchySliceNodeDto[];
  selectedMetrics: string[];
};

export const KeyMeasureTrendsTopDrawerProductTable = ({
  data,
  metadata,
  fetchChildren,
  formatter,
  isRowFetchingChildren,
  isSuccess,
  renameReport,
  reportType,
  reportName,
  selectedItems,
  selectedMetrics,
  eventTrackingService,
}: KeyMeasureTrendsTopDrawerProductTableProps) => {
  const dispatch = useDispatch();

  const columnHelper = createColumnHelper<HierarchicalTableRow>();

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[KeyMeasureTrendsFeatureFlags.ReportExport] ?? false;
  const isDownloadAsExcelEnabled =
    featureFlags[FeatureFlag.DownloadAsExcel] ?? false;

  // search of the top table
  const [search, setSearch] = useState("");
  const reportId = useParams().id ?? "";
  const { name: divisionName } = useDivision();

  const channel = useSelector(selectChannel);
  const compStore = useSelector(selectCompStore);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const promotion = useSelector(selectPromotion);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const dataset = useSelector(selectDataset);
  // called when searching
  const { data: searchResult, isFetching: isSearchFetching } =
    useGetSearchTableQuery(
      {
        divisionName,
        payload: {
          reportId,
          searchText: search,
          parameterSelections: {
            channel: channel.value as string,
            location: locationHierarchy.nodeNumber,
            promotion: promotion.value as string,
            dataset: dataset.value as string,
          },
        } as SearchTableRequest,
      },
      { skip: !divisionName || !search.length }
    );

  const { columnVisibilityDefaultState, promoDateList, seriesColumns } =
    useMemo((): {
      columnVisibilityDefaultState: VisibilityState;
      promoDateList: number[];
      seriesColumns: Array<GroupColumnDef<HierarchicalTableRow>>;
    } => {
      const measures = metadata.metricMetadata;
      const measuresColumnDefs = (promoDate: number, promoDateIndex: number) =>
        measures.map((measure, index) => ({
          accessorFn: (row: HierarchicalTableRow) =>
            row.promoWeeks[promoDateIndex].metrics[index]?.metricValue,
          cell: ({ getValue }: CellContext<HierarchicalTableRow, number>) =>
            ValueCell({
              formatter: formatter(measure.format),
              value: getValue(),
            }),
          header: measure.metricLabel,
          id: `${promoDate}-${measure.metricKey}`,
        }));

      const promoDates = metadata.promoWeeks;
      const promoDateColumnDefs = promoDates.map(
        (promoDate, promoDateIndex) => ({
          columns: measuresColumnDefs(promoDate, promoDateIndex),
          header: formatNumberDate(promoDate),
          id: `${promoDate}`,
        })
      );

      const defaultColumnVisibilityState: VisibilityState = {};

      for (const measure of measures) {
        for (const promoDate of promoDates) {
          defaultColumnVisibilityState[`${promoDate}-${measure.metricKey}`] =
            false;
        }
      }

      return {
        columnVisibilityDefaultState: defaultColumnVisibilityState,
        promoDateList: promoDates,
        seriesColumns: promoDateColumnDefs,
      };
    }, [formatter, metadata.metricMetadata, metadata.promoWeeks]);

  const onSearchChange = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const matchesFound = useMemo(() => {
    if (!search) return undefined;
    return searchResult?.tableRows.length ?? 0;
  }, [searchResult, search]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchbox}>
        <SearchBox
          debounceTimeMs={500}
          enableDebounce
          onChange={onSearchChange}
          placeholder="Type to search"
          resultCount={matchesFound}
          searchQuery={search}
        />
      </div>
    ),
    [onSearchChange, matchesFound, search]
  );

  const columnVisibility: VisibilityState = useMemo(() => {
    const columnVisibilityState = { ...columnVisibilityDefaultState };
    for (const selectedMetric of selectedMetrics) {
      for (const promoDate of promoDateList) {
        columnVisibilityState[`${promoDate}-${selectedMetric}`] = true;
      }
    }

    return columnVisibilityState;
  }, [columnVisibilityDefaultState, promoDateList, selectedMetrics]);

  const getRowId = (row: HierarchicalTableRow) =>
    row.hierarchyItem.nodeNumber.toString();

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [expandState, setExpandState] = useState<ExpandedState>(true);

  useEffect(() => {
    for (const key in rowSelection) {
      if ({}.hasOwnProperty.call(rowSelection, key)) {
        rowSelection[key] = false;
      }
    }

    for (const item of selectedItems) {
      rowSelection[item.nodeNumber] = true;
    }
  }, [selectedItems, rowSelection]);

  const tableData = useMemo(
    () => (search ? searchResult?.tableRows ?? [] : data),
    [data, search, searchResult?.tableRows]
  );

  const success = Boolean(searchResult);

  const displayEmptySearch: boolean =
    success && search.length > 0 && tableData.length === 0;

  const toggleSelected = useCallback(
    (row: Row<HierarchicalTableRow>) => (event: unknown) => {
      row.getToggleSelectedHandler()(event);
      const newFocalItemsState = row.getIsSelected()
        ? selectedItems.filter(
            (value) =>
              value.nodeNumber !== row.original.hierarchyItem.nodeNumber
          )
        : [...selectedItems, row.original.hierarchyItem];
      dispatch(onFocalItemsChange(newFocalItemsState));
      dispatch(resetHiddenSelections());

      eventTrackingService(
        [
          TrackingComponent.MyReports,
          TrackingComponent.Report,
          TrackingComponent.FocalItemTableRow,
        ],
        row.getIsSelected() ? TrackingEvent.Unselected : TrackingEvent.Selected,
        {
          selection: newFocalItemsState.map(
            ({ shortName, name }) => `${shortName}:${name}`
          ),
        }
      );
    },
    [dispatch, eventTrackingService, selectedItems]
  );

  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<HierarchicalTableRow, string>) => (
      <ExpandableNameCell
        canExpand={
          search
            ? false
            : row.subRows.length >= 0 && !row.original.hierarchyItem.isLeaf
        }
        depth={search ? 0 : row.depth}
        handleToggleExpanded={() => {
          if (row.subRows.length === 0) {
            fetchChildren(row.original);
          }

          row.getToggleExpandedHandler()();
        }}
        handleToggleSelected={toggleSelected(row)}
        // TODO: not yet implemented
        // isDeletedLine={row.original.hierarchyItem.isDeletedLine}
        // isNewLine={row.original.item.isNewLine}
        isExpanded={row.subRows.length > 0 && row.getIsExpanded()}
        isExpanding={
          isRowFetchingChildren[row.original.hierarchyItem.nodeNumber] &&
          row.original.subRows.length === 0
        }
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        shortName={row.original.hierarchyItem.shortName}
        // FIXME: do we need this still? hardcoding to Hierarchy type here
        type={HierarchyItemType.Hierarchy}
        value={getValue()}
      />
    ),
    [search, toggleSelected, isRowFetchingChildren, fetchChildren]
  );

  const columns = useMemo(
    () => [
      {
        columns: [
          columnHelper.accessor((row) => row.hierarchyItem.name, {
            cell: createNameCell,
            enableHiding: false,
            enableResizing: false,
            enableSorting: false,
            header: "Item description",
          }),
        ],
        header: memoizedSearchBox,
        enableResizing: false,
        enableSorting: false,
        id: "Search",
      },
      ...seriesColumns,
    ],
    [columnHelper, createNameCell, memoizedSearchBox, seriesColumns]
  );
  const [downloadTableTrigger] = useLazyDownloadTableQuery();
  const [downloadTableExcelTrigger] = useLazyDownloadTableExcelQuery();
  const fileName = useMemo(
    () =>
      cleanFilename(
        `Key_Measure_Trends_${timePeriodLength}_${locationHierarchy.name}`
      ),
    [locationHierarchy, timePeriodLength]
  );

  const parameterSummary = useMemo(
    () => [
      {
        name: "Time",
        value: time,
      },
      ...(dataset.label
        ? [{ name: "Transaction set", value: dataset.label }]
        : []),
      ...(channel.label ? [{ name: "Channel", value: channel.label }] : []),
      { name: "Promotion", value: promotion.label },
      ...(compStore.label
        ? [{ name: "Comp store", value: compStore.label }]
        : []),
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(locationHierarchy.shortName)}) ${
          locationHierarchy.name
        }`,
      },
      { name: "Metrics", value: selectedMetrics.join(", ") },
    ],
    [
      channel,
      compStore,
      dataset,
      promotion,
      selectedMetrics,
      time,
      locationHierarchy,
    ]
  );

  const downloadPayload = useMemo(
    () => ({
      localParameters: parameterSummary,
      localParameterSelections: getFocalItemTableLocalSelections(
        locationHierarchy.nodeNumber,
        channel.value as string,
        compStore.value as string,
        promotion.value as string,
        dataset.value as string
      ),
      fileName,
      reportId,
      metrics: selectedMetrics,
    }),
    [
      locationHierarchy.nodeNumber,
      channel.value,
      compStore.value,
      promotion.value,
      dataset.value,
      fileName,
      selectedMetrics,
      parameterSummary,
      reportId,
    ]
  );

  const handleDownloadExcelButtonClick = useCallback(async () => {
    await downloadTableExcelTrigger({
      divisionName,
      payload: downloadPayload,
    });
  }, [downloadTableExcelTrigger, downloadPayload, divisionName]);

  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: downloadPayload,
    });
  }, [downloadTableTrigger, downloadPayload, divisionName]);

  return (
    <ReportTopDrawer
      controls={[
        <DataTableOptions
          filename={fileName}
          invokeCSVDownload={handleDownloadButtonClick}
          invokeExcelDownload={
            isDownloadAsExcelEnabled
              ? handleDownloadExcelButtonClick
              : undefined
          }
          isFeatureEnabled={isExportEnabled}
          isInFocalItemHeader
          key={reportId}
        />,
      ]}
      items={selectedItems.map((item) => ({
        code: item.shortName,
        displayName: item.name,
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportHierarchyTableWrapper isSuccess={isSuccess && !isSearchFetching}>
          <VirtuosoTableComponent
            columnResizeMode="onChange"
            columnVisibility={columnVisibility}
            columns={columns as Array<GroupColumnDef<HierarchicalTableRow>>}
            data={tableData}
            getRowId={getRowId}
            getSubRows={(row) => row.subRows}
            onExpandedChange={setExpandState}
            onRowSelectionChange={setRowSelection}
            pinFirstColumn
            rowExpandedState={expandState}
            rowSelectionState={rowSelection}
            showCheckboxesOnlyOnHover
          />
          {displayEmptySearch && (
            <div className={styles.emptySearch}>
              <EmptySearch />
            </div>
          )}
        </ReportHierarchyTableWrapper>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};
