import {
  InlineIconGlyph,
  ButtonVariant,
  IconSize,
  Button,
  InlineIcon,
} from "@qbit/react";
import styles from "./RangeReport.module.css";

export const renderHeader =
  (
    headerName: string,
    key: string,
    sorting: { columnId: string; isDescending: boolean } | undefined,
    toggleSorting: (columnId: string, initiallySortAscending?: boolean) => void,
    initiallySortAscending: boolean = false
  ) =>
  (properties: { header: { column: { id: string } } }) => {
    const isSortedColumn = sorting?.columnId === properties.header.column.id;
    const sortDirection = sorting?.isDescending ? "desc" : "asc";
    const sortIcon =
      sortDirection === "desc"
        ? InlineIconGlyph.ArrowsSortingDown
        : InlineIconGlyph.ArrowsSortingUp;

    return (
      <span className={styles.sortedHeader}>
        <Button
          data-cy="MetricHeader"
          data-key={key}
          data-sorting={sortDirection}
          onClick={() =>
            toggleSorting(properties.header.column.id, initiallySortAscending)
          }
          text={headerName}
          variant={ButtonVariant.Link}
        />
        {isSortedColumn && (
          <InlineIcon
            glyph={sortIcon}
            size={IconSize.Small}
            text={`sorted ${sortDirection}`}
          />
        )}
      </span>
    );
  };
