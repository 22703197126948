import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import { useEffect, useRef, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../../common/components/NoDataChartWrapper";
import { type RootState } from "../../../../store";
import { TimeOfDayDayOfWeekFeatureFlags } from "../../../constants/time-of-day-day-of-week-feature-flags";
import {
  onTimeOfDayDataReceived,
  toggleTimeOfDayChartDataLabels,
} from "../../../services/time-of-day-day-of-week-slice";
import {
  selectFocalItemParents,
  selectFocalItems,
  selectLocalParametersSelections,
} from "../../../services/time-of-day-day-of-week-slice-selectors";
import { useGetTimeOfDayChartDataQuery } from "../../../services/time-of-day-day-of-week-time-of-day-api-slice";
import getLocalParametersSummary from "../../../utils/getLocalParametersSummary";
import getUserSelections from "../../../utils/getUserSelections";
import { ChartSelectors } from "../ChartSelectors";
import styles from "./TimeOfDayReporlet.module.css";
import TimeOfDayReportletChart from "./TimeOfDayReportletChart";
import { csvTransformation } from "./csvTransformation";

export type TimeofDayReportletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

export const TimeOfDayReportlet = ({
  infoPanelSummary,
}: TimeofDayReportletProps) => {
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();

  const focalItems = useSelector(selectFocalItems);
  const focalItemParents = useSelector(selectFocalItemParents);
  const localParameterSelections = useSelector(selectLocalParametersSelections);

  const {
    reportId,
    timeOfDayChartData,
    localParametersInitialised,
    chartSelections,
    showTimeOfDayChartDataLabels,
    reportName,
  } = useSelector((state: RootState) => ({
    localParametersInitialised:
      state.timeOfDayDayOfWeek.localParametersInitialised,
    reportId: state.timeOfDayDayOfWeek.metaData.reportId,
    timeOfDayChartData: state.timeOfDayDayOfWeek.timeOfDayChartData,
    chartSelections: state.timeOfDayDayOfWeek.chartSelections,
    showTimeOfDayChartDataLabels:
      state.timeOfDayDayOfWeek.showTimeOfDayChartDataLabels,
    reportName: state.timeOfDayDayOfWeek.metaData.reportName,
  }));

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[TimeOfDayDayOfWeekFeatureFlags.ReportExport] ?? false;

  const isDataLoaded = localParametersInitialised && reportId && focalItems;
  const { data: timeOfDayData, isFetching: isDataFetching } =
    useGetTimeOfDayChartDataQuery(
      {
        divisionName: activeDivisionName,
        requestPayload: {
          focalItems,
          localSelectedValues: getUserSelections(localParameterSelections),
          reportId,
        },
      },
      {
        skip: !isDataLoaded,
      }
    );

  useEffect(() => {
    if (timeOfDayData) {
      dispatch(onTimeOfDayDataReceived(timeOfDayData));
    }
  }, [dispatch, timeOfDayData]);

  const chartContainerRef = useRef<HTMLElement>();

  const { locale, currency } = useDivision();

  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const parameterSummary = useMemo(
    () =>
      getLocalParametersSummary(
        localParameterSelections,
        chartSelections,
        focalItems,
        focalItemParents
      ),
    [localParameterSelections, chartSelections, focalItems, focalItemParents]
  );

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Time_of_day_day_of_week_trend_Chart_${localParameterSelections.TimePeriodLength}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );

  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        timeOfDayChartData,
        chartSelections,
        currencySymbol,
        focalItemParents
      ),
    [timeOfDayChartData, chartSelections, currencySymbol, focalItemParents]
  );

  const dataLabelOptions: DataLabelsOptions = {
    isSelected: showTimeOfDayChartDataLabels,
    value: "",
  };

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  return (
    <ErrorBoundary>
      <NoDataChartWrapper
        isLoading={isDataFetching || !isDataLoaded || !timeOfDayData}
        minHeight="460px"
        noData={
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          !timeOfDayChartData.timeOfDayData ||
          timeOfDayChartData.timeOfDayData.length === 0
        }
      >
        <div className={styles.chartSelectorsAndOptionsRow}>
          <ChartSelectors />
          <div className={styles.chartOptionsContainer}>
            <ChartOptions
              dataLabelsOptions={[dataLabelOptions]}
              downloadWizardOptions={
                currentOptions
                  ? {
                      chartOptions: currentOptions,
                      reportIcon: (
                        <ReportIcon type={ReportType.TimeOfDayDayOfWeek} />
                      ),
                      chartTitle: `Time of day, day of week trends - ${reportName}`,
                      reportTitle: reportName,
                    }
                  : undefined
              }
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={infoPanelSummary}
              toggleDataLabels={() => {
                dispatch(toggleTimeOfDayChartDataLabels());
              }}
            />
          </div>
        </div>
        <ChartFooterWrapper
          height="400px"
          parameters={parameterSummary}
          ref={chartContainerRef}
        >
          <TimeOfDayReportletChart
            onOptionsChanged={setCurrentOptions}
            reportletData={timeOfDayChartData}
          />
        </ChartFooterWrapper>
      </NoDataChartWrapper>
    </ErrorBoundary>
  );
};

export default TimeOfDayReportlet;
