import { AnalyticsBrowser } from "@segment/analytics-next";
import { ENVIRONMENT } from "../../config/environment-config";
import { ddLog } from "../../utilities";
import { type EventTrackingService } from "./EventTrackingService";
import { type TrackingComponent } from "./TrackingComponent";
import { type TrackingEvent } from "./TrackingEvent";
import { type TrackingProperties } from "./TrackingProperties";

// Segment - Event Tracking Service
// app.segment.com
// Segment is a service that collects data from various sources and sends it to various destinations, for example Amplitude.
export class SegmentEventTrackingService implements EventTrackingService {
  private readonly analytics: AnalyticsBrowser | undefined;

  private constructor(analytics: AnalyticsBrowser | undefined) {
    this.analytics = analytics;
  }

  public static async create(
    userId: string,
    divisionName: string
  ): Promise<SegmentEventTrackingService> {
    if (!ENVIRONMENT.VITE_SEGMENT_API_KEY) {
      throw new Error("Segment API key not found");
    }

    try {
      const analytics = new AnalyticsBrowser();

      await analytics
        .load({
          writeKey: ENVIRONMENT.VITE_SEGMENT_API_KEY.toString(),
        })
        .catch((error) => {
          ddLog(
            `Failed to load SegmentEventTrackingService (service blocked?): ${error}`
          );
          // fall back to parent error handler
          throw error;
        });

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- can be undefined if segment blocked by ad blocker
      if (!analytics) {
        return new SegmentEventTrackingService(undefined);
      }

      await analytics.identify(userId, {
        VendorId: userId,
        DivisionName: divisionName,
      });

      return new SegmentEventTrackingService(analytics);
    } catch (error) {
      ddLog(
        `Failed to create SegmentEventTrackingService (service blocked?): ${error}`
      );
      return new SegmentEventTrackingService(undefined);
    }
  }

  public trackEvent(
    component: TrackingComponent | TrackingComponent[],
    event: TrackingEvent,
    properties?: TrackingProperties
  ): void {
    if (!this.analytics) {
      ddLog("Respecting tracking choices, not tracking event");
      return;
    }

    const formattedComponent = Array.isArray(component)
      ? component.join(".")
      : component;

    void this.analytics
      .track(`${formattedComponent}.${event}`, properties?.getProperties())
      .catch((error) => {
        ddLog(`Failed to track (service blocked?): ${error}`);
      });
  }
}
