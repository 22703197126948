import { Spinner } from "@qbit/react";
import {
  FeatureFlag,
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import {
  MetricNameSchema,
  type MetricKeyDisplayName,
} from "../../fast-report-content/MetricsTypeahead";
import { MetricsTypeahead } from "../../fast-report-content/MetricsTypeahead";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useFilterCustomerMetrics } from "../hooks";
import LevelOfAnalysisDropdown from "./LevelOfAnalysisDropdown";
import { MetricBreakdownParameter } from "./MetricBreakdownParameter";
import ShowDeletedProductsToggle from "./ShowDeletedProductsToggle";
import { ShowNewProductsOnlyToggle } from "./ShowNewProductsOnlyToggle";
import styles from "./TopAndBottomParametersHeader.module.css";

export const TopAndBottomParametersHeader = () => {
  const eventTrackingService = useEventTrackingServiceContext();

  const flags = useFlags();
  const division = useDivision();
  const activeItem = useActiveItem();

  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.topAndBottom,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selections, setSelections] = useFastReportingParameterState(
    Parameter.TopAndBottomMetrics,
    MetricNameSchema,
    (config) => config.reports.topAndBottom?.metrics
  );

  const filterCustomerMetrics = useFilterCustomerMetrics();

  const availableSelections = useMemo(() => {
    const config = reportConfig.configuration;
    if (config) {
      const metrics = config.metrics.options.map(
        (metric) =>
          ({
            key: metric.value,
            displayName: metric.displayName,
          } as MetricKeyDisplayName)
      );

      if (filterCustomerMetrics) {
        const customerMetrics = config.metricMetadata
          .filter((metadata) => metadata.isCustomerMetric)
          .map((metadata) => metadata.key);
        return metrics.filter(
          (metric) => !customerMetrics.includes(metric.key)
        );
      } else {
        return metrics;
      }
    }

    return undefined;
  }, [filterCustomerMetrics, reportConfig.configuration]);

  const selectedSelections = useMemo(() => {
    if (!selections) {
      return [];
    }

    const newSelection = [] as MetricKeyDisplayName[];
    for (const selection of selections) {
      newSelection.push({
        key: selection,
        displayName:
          availableSelections?.find((item) => item.key === selection)
            ?.displayName ?? "Unknown",
      });
    }

    return newSelection;
  }, [availableSelections, selections]);

  const selectionChangedHandler = useCallback(
    (selection: MetricKeyDisplayName[]) => {
      if (selection.length > selectedSelections.length) {
        const existingMetricKeys = selectedSelections.map(
          (metric) => metric.key
        );

        const selectedMetrics = selection.filter(
          (metric) => !existingMetricKeys.includes(metric.key)
        );

        for (const selectedMetric of selectedMetrics) {
          eventTrackingService.trackEvent(
            TrackingComponent.FastReportingTopAndBottomReport,
            TrackingEvent.Parameters,
            GenericTrackingProperties.single(
              ParametersTrackingProperty.MetricSelected,
              selectedMetric.displayName
            )
          );
        }
      } else {
        const selectedMetricKeys = selection.map((metric) => metric.key);

        const deselectedMetrics = selectedSelections.filter(
          (metric) => !selectedMetricKeys.includes(metric.key)
        );

        for (const deselectedMetric of deselectedMetrics) {
          eventTrackingService.trackEvent(
            TrackingComponent.FastReportingTopAndBottomReport,
            TrackingEvent.Parameters,
            GenericTrackingProperties.single(
              ParametersTrackingProperty.MetricDeselected,
              deselectedMetric.displayName
            )
          );
        }
      }

      // We need to make sure to we order against availableSelections which mimics the order in which columns are displayed
      if (availableSelections) {
        const orderedSelection = [] as MetricKeyDisplayName[];
        for (const availableSelection of availableSelections) {
          const selectedMetric = selection.find(
            (metric) => metric.key === availableSelection.key
          );
          if (selectedMetric) {
            orderedSelection.push(selectedMetric);
          }
        }

        setSelections(orderedSelection.map((metric) => metric.key));
      }
    },
    [
      availableSelections,
      selectedSelections,
      setSelections,
      eventTrackingService,
    ]
  );

  useEffect(() => {
    if (selections && availableSelections) {
      const availableSelectionsKeys = availableSelections.map(
        (selection) => selection.key
      );
      if (
        selections.some(
          (selection) => !availableSelectionsKeys.includes(selection)
        )
      ) {
        setSelections(
          selections.filter((selection) =>
            availableSelectionsKeys.includes(selection)
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run this when available selection changes due to filtering customer metrics and we have to modify selection
  }, [availableSelections]);

  const [numberOfMetricBreakdownSplits, setNumberOfMetricBreakdownSplits] =
    useState(0);

  const metricsParameterElement =
    availableSelections && activeItem ? (
      <>
        <MetricsTypeahead
          metrics={availableSelections}
          selectedMetrics={selectedSelections}
          setSelectedMetrics={selectionChangedHandler}
        />
        <div className={classNames(styles.parameter, styles.levelOfAnalysis)}>
          <label
            className={styles.title}
            htmlFor="top-and-bottom-level-of-analysis-dropdown"
          >
            Level of analysis
          </label>
          <LevelOfAnalysisDropdown />
        </div>

        {reportConfig.configuration?.metricBreakdown && (
          <div className={classNames(styles.parameter, styles.metricBreakdown)}>
            <label className={styles.title} htmlFor="metric-breakdown-dropdown">
              Metrics breakdown ({numberOfMetricBreakdownSplits})
            </label>
            <MetricBreakdownParameter
              onNumberOfCurrentSplitsChanged={setNumberOfMetricBreakdownSplits}
            />
          </div>
        )}
      </>
    ) : (
      <Spinner />
    );

  return (
    <div>
      <div className={styles.parametersContainer}>
        {metricsParameterElement}
      </div>
      {flags[FeatureFlag.TopAndBottomImprovements] && (
        <div className={styles.togglesContainer}>
          <ShowDeletedProductsToggle />
          <ShowNewProductsOnlyToggle />
        </div>
      )}
    </div>
  );
};
