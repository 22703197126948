import { type FocalItem } from "@quantium-enterprise/common-ui";
import {
  HierarchyItemType,
  HierarchyShortName,
} from "@quantium-enterprise/common-ui";

export type FocalItemDto = {
  attribute?: {
    code: string;
    shortName: string;
  };
  code?: string;
  productGroupId?: string;
  shortName?: string;
};

export const convertFocalItemToDto = (item: FocalItem): FocalItemDto => {
  switch (item.type) {
    case HierarchyItemType.Hierarchy:
    case HierarchyItemType.Leaf:
      return {
        shortName: item.shortName,
        code: item.code,
      };
    case HierarchyItemType.Attribute:
      return {
        shortName: item.shortName,
        code: item.code,
        attribute: {
          shortName: item.additionalHierarchyFilter?.shortName ?? "",
          code: item.additionalHierarchyFilter?.code ?? "",
        },
      };
    case HierarchyShortName.ProductGroup:
      return {
        productGroupId: item.productGroupId,
      };
    default:
      throw new Error("Unknown focal item type");
  }
};

export const isNewFocalItem = (
  item: { launchWeek?: Date },
  focusPeriodStartDate?: string
) => {
  if (!focusPeriodStartDate) {
    return false;
  }

  const itemLaunchWeek = item.launchWeek ?? new Date(0);
  const focalStartDate = new Date(focusPeriodStartDate);

  return new Date(itemLaunchWeek) > focalStartDate;
};
