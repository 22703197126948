import {
  Nav,
  NavButton,
  NavVariant,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  type HierarchyType,
  useGetGroupSummaryQuery,
  FeatureFlag,
  useGetGroupSharingActivitiesQuery,
  useGetUsersByIdQuery,
  AppContext,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { HierarchyGroupSummary } from "components-ui/src/hierarchy-group-summary/HierarchyGroupSummary";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { InfoPanelActivity } from "components-ui/src/info-panel/info-panel-body/InfoPanelActivity";
import { ViewAccessModal } from "components-ui/src/modals";
import { PanelWithSideDrawer } from "components-ui/src/panel-with-side-drawer/PanelWithSideDrawer";
import { useContext, useState, type PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectFocalGroup,
  setFocalGroup,
  setMoveFolderOrGroup,
  setShareFolderOrGroup,
} from "../../states/group-list-slice";
import { isFolder } from "../../utilities/folder-helper";
import { getDefaultGroupsPath } from "../../utilities/route-path-formats";
import styles from "./GroupInfoPanel.module.css";
import { GroupInfoPanelHeader } from "./group-info-panel-header/GroupInfoPanelHeader";

export type HierarchyGroupInfoPanelProps = {
  hierarchyType: HierarchyType;
};

export const HierarchyGroupInfoPanel = ({
  children,
  hierarchyType,
}: PropsWithChildren<HierarchyGroupInfoPanelProps>) => {
  const { userState } = useContext(AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const [viewAccessModal, setViewAccessModal] = useState(false);
  const flags = useFlags();
  const { name: divisionName } = useDivision();
  const { groupId } = useParams();
  const focalGroup = useSelector(selectFocalGroup);

  const onClose = () => {
    navigate(getDefaultGroupsPath(divisionName, hierarchyType));
    dispatch(setFocalGroup(undefined));
  };

  const commonConditions = Boolean(divisionName) && Boolean(groupId);
  const { currentData: summary, isFetching: isSummaryFetching } =
    useGetGroupSummaryQuery(
      {
        divisionName,
        groupId: groupId ?? "",
        maxItemsInSubset: 32,
      },
      {
        skip: !commonConditions || focalGroup?.isFolder,
      }
    );
  const { data: sharingActivities, isLoading: isActivityLoading } =
    useGetGroupSharingActivitiesQuery(
      {
        divisionName,
        groupId: groupId ?? "",
      },
      {
        skip:
          !(commonConditions && flags[FeatureFlag.SharingGroups]) ||
          focalGroup?.isFolder,
      }
    );
  const { data: reportSharedUsers, isLoading: isReportSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds:
            sharingActivities?.flatMap(
              (sharingActivity) => sharingActivity.salesforceUserIds
            ) ?? [],
        },
      },
      {
        skip: !(
          commonConditions &&
          Boolean(sharingActivities) &&
          Boolean(sharingActivities?.length) &&
          flags[FeatureFlag.SharingGroups]
        ),
      }
    );

  const label = (
    <div className={styles.infoPanelLabel}>
      <HierarchyGroupIcon hierarchyType={hierarchyType} />
      <Text>{`${hierarchyType} group`}</Text>
    </div>
  );

  const infoPanelTabs = [
    {
      content: (
        <>
          {isSummaryFetching ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <div className={styles.groupInfoPanelBodyContent}>
              <HierarchyGroupSummary group={summary} title="Group info" />
            </div>
          )}
        </>
      ),
      title: "Info",
    },
  ];
  const showViewAccess =
    flags[FeatureFlag.ProductGroupsTrueShare] &&
    userState.currentUser?.salesForceId !== focalGroup?.userId
      ? () => setViewAccessModal(true)
      : undefined;
  if (flags[FeatureFlag.SharingGroups]) {
    const activityTab = {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          {isActivityLoading || isReportSharedUsersLoading ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <InfoPanelActivity
              header="Group activity"
              resourceType="group"
              sharedUsers={reportSharedUsers}
              sharingActivities={sharingActivities}
              showViewAccess={showViewAccess}
            />
          )}
        </div>
      ),
      title: "Activity",
    };
    infoPanelTabs.push(activityTab);
  }

  const header = (
    <>
      <GroupInfoPanelHeader
        focalGroup={focalGroup}
        groupType={hierarchyType}
        onClose={onClose}
        onMove={() => dispatch(setMoveFolderOrGroup(focalGroup))}
        onShare={() => dispatch(setShareFolderOrGroup(focalGroup))}
      />
      <Nav activeIndex={currentNavIndex} variant={NavVariant.Tab}>
        {infoPanelTabs.map((tab, index) => (
          <NavButton key={tab.title} onClick={() => setCurrentNavIndex(index)}>
            {tab.title}
          </NavButton>
        ))}
      </Nav>
    </>
  );

  const body = infoPanelTabs[currentNavIndex].content;

  return (
    <>
      {showViewAccess && (
        <ViewAccessModal
          onClose={() => setViewAccessModal(false)}
          ownerId={focalGroup?.userId}
          sharedWithUserIds={focalGroup?.sharedWithUserIds}
          showModal={viewAccessModal}
        />
      )}
      <PanelWithSideDrawer
        closeFunc={onClose}
        drawerContent={{
          body,
          header,
          label,
        }}
        isOverlay={false}
        showDrawer={
          Boolean(focalGroup) && Boolean(groupId) && !isFolder(focalGroup)
        }
      >
        {children}
      </PanelWithSideDrawer>
    </>
  );
};
