/* eslint-disable @typescript-eslint/dot-notation */
import tokens from "@qbit/core/dist/tokens.json";

const colourPalettes = [
  tokens.colour["brand-cyan-palette"]["800"],
  // Not added to qbit yet
  // brand-turquoise-new
  "#12A35A",
  // brand-yellow-new
  "#C48221",
  tokens.colour["brand-orange-palette"]["900"],
  tokens.colour["brand-coral-palette"]["900"],
  tokens.colour["brand-burgundy-palette"]["500"],
  tokens.colour["brand-violet-palette"]["400"],
  tokens.colour["brand-blue-palette"]["A400"],
  tokens.colour["brand-burgundy-palette"]["900"],
  tokens.colour["brand-violet-palette"]["900"],
  tokens.colour["brand-blue-palette"]["900"],
  tokens.colour["brand-secondary"]["dark-warm-grey"],
];

// Modulus ensures that the index is always within the bounds of the array, even if it exceeds the length of the array.
export const getSeriesColour = (index: number) =>
  colourPalettes[index % colourPalettes.length];

export const getBlackColour = () => tokens.colour["brand-black"];
