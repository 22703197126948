export enum LocalParameters {
  BasketLimitThreshold = "BasketLimitThreshold",
  BasketsWithBothThreshold = "BasketsWithBothThreshold",
  Breakdown = "Breakdown",
  ChangeOn = "ChangeOn",
  Channel = "Channel",
  CheckoutType = "CheckoutType",
  CompStore = "CompStore",
  Dataset = "Dataset",
  KeyDriver = "KeyDriver",
  LevelOfAnalysis = "LevelOfAnalysis",
  LocationGroups = "LocationGroups",
  LocationHierarchy = "LocationHierarchy",
  Metric = "Metric",
  MetricSet = "MetricSet",
  Metrics = "Metrics",
  ProductHierarchy = "ProductHierarchy",
  ProductInteraction = "ProductInteraction",
  Promotion = "Promotion",
  Segmentation = "Segmentation",
  Time = "Time",
  UpliftThreshold = "UpliftThreshold",
  ViewAs = "ViewAs",
  ViewBy = "ViewBy",
}
