import { HierarchyType } from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Navigate, Outlet } from "react-router-dom";
import { CustomerGroupTab } from "./enums/customer-group-tab-ids";
import { GroupType } from "./enums/group-type";
import GroupCreator from "./pages/group-creator/GroupCreator";
import { CustomerGroupManagement } from "./pages/group-list/CustomerGroupManagement";
import { HierarchyGroupManagement } from "./pages/group-list/HierarchyGroupManagement";
import { GroupWizard } from "./pages/group-wizard/GroupWizard";

const DefaultGroupManagement = () => {
  const division = useDivision();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Causes crashes if not checked
  if (division.moduleAccess) {
    // order determines which is the first management tab to be shown.
    if (division.moduleAccess.groups.productGroups.hasAccess) {
      return <Navigate to={GroupType.Product} />;
    }

    if (division.moduleAccess.groups.locationGroups.hasAccess) {
      return <Navigate to={GroupType.Location} />;
    }

    if (division.moduleAccess.groups.customerGroups.hasAccess) {
      return <Navigate to={GroupType.Customer} />;
    }
  }

  return null;
};

// Order matters for path matching
export const groupsRoutes = [
  {
    element: <GroupWizard />,
    label: "Group creation wizard",
    path: "create/:groupType/:templateType",
  },
  {
    element: <GroupWizard />,
    label: "Group edit wizard",
    path: "edit/:groupType/:groupId",
  },
  {
    element: <GroupCreator />,
    label: "Group creator",
    path: "create/:groupType",
  },
  {
    element: (
      <CustomerGroupManagement
        customerGroupTab={CustomerGroupTab.SegmentLibrary}
      />
    ),
    label: "Segment library with info panel",
    path: `${GroupType.Customer}/segment-library/:groupId/:segmentKey`,
  },
  {
    element: (
      <CustomerGroupManagement
        customerGroupTab={CustomerGroupTab.SegmentLibrary}
      />
    ),
    label: "Segment library",
    path: `${GroupType.Customer}/segment-library`,
  },
  {
    element: (
      <CustomerGroupManagement
        customerGroupTab={CustomerGroupTab.CustomerGroups}
      />
    ),
    label: "Customer groups list with info panel",
    path: `${GroupType.Customer}/:groupId`,
  },
  {
    element: (
      <CustomerGroupManagement
        customerGroupTab={CustomerGroupTab.CustomerGroups}
      />
    ),
    label: "Customer groups list",
    path: GroupType.Customer,
  },
  {
    element: <HierarchyGroupManagement hierarchyType={HierarchyType.Product} />,
    label: "Product groups list with info panel",
    path: `${GroupType.Product}/:groupId`,
  },
  {
    element: <HierarchyGroupManagement hierarchyType={HierarchyType.Product} />,
    label: "Product groups list",
    path: GroupType.Product,
  },
  {
    element: (
      <HierarchyGroupManagement hierarchyType={HierarchyType.Location} />
    ),
    label: "Location groups list with info panel",
    path: `${GroupType.Location}/:groupId`,
  },
  {
    element: (
      <HierarchyGroupManagement hierarchyType={HierarchyType.Location} />
    ),
    label: "Location groups list",
    path: GroupType.Location,
  },
  {
    element: <DefaultGroupManagement />,
    label: "Groups list",
    path: "",
  },
];

// the component matching each of the routes matched by the url will be output here
export default () => <Outlet />;
