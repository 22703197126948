import { useActiveTab, isFastReportingTabMetadata } from "report-tabs-ui";
import { type FastReportingTabMetadata } from "report-tabs-ui/src/models/ReportTab";

export const useActiveItem = () => {
  const tab = useActiveTab();

  if (tab && isFastReportingTabMetadata(tab.metadata)) {
    return (tab.metadata as FastReportingTabMetadata).item;
  }

  return undefined;
};
