import { type LogLevel, type BrowserOptions } from "@amplitude/analytics-types";
import { ddLog, ENVIRONMENT } from "@quantium-enterprise/common-ui";
import { useState, useEffect } from "react";
import { type Division } from "../../../models/division";
import { type UserDto } from "../../../models/user-dto";
import { AmplitudeEventTrackingService } from "../AmplitudeEventTrackingService";
import {
  type EventTrackingService,
  EventTrackingServiceType,
} from "../EventTrackingService";
import { NoOpEventTrackingService } from "../NoOpEventTrackingService";
import { SegmentEventTrackingService } from "../SegmentEventTrackingService";

type UseEventTrackerProps = {
  activeDivision: Division;
  currentUser: UserDto | undefined;
  trackerType: EventTrackingServiceType;
};

export const useEventTracker = ({
  currentUser,
  activeDivision,
  trackerType,
}: UseEventTrackerProps): EventTrackingService | undefined => {
  const [trackingService, setTrackingService] = useState<
    EventTrackingService | undefined
  >(undefined);

  useEffect(() => {
    const initializeTracker = async () => {
      if (!currentUser) {
        return;
      }

      try {
        switch (trackerType) {
          case EventTrackingServiceType.Amplitude: {
            const amplitudeConfig: BrowserOptions = {
              flushIntervalMillis: Number(
                ENVIRONMENT.VITE_AMPLITUDE_EVENT_UPLOAD_PERIOD_MILLIS
              ),
              flushQueueSize: Number(
                ENVIRONMENT.VITE_AMPLITUDE_EVENT_UPLOAD_THRESHOLD
              ),
              logLevel: ENVIRONMENT.VITE_AMPLITUDE_LOG_LEVEL as unknown as
                | LogLevel
                | undefined,
              serverZone: ENVIRONMENT.VITE_AMPLITUDE_SERVER_ZONE as
                | "EU"
                | "US"
                | undefined,
              useBatch: Boolean(
                ENVIRONMENT.VITE_AMPLITUDE_EVENT_BATCHING_ENABLED
              ),
            };

            setTrackingService(
              new AmplitudeEventTrackingService(
                String(ENVIRONMENT.VITE_AMPLITUDE_API_KEY),
                amplitudeConfig,
                currentUser.salesForceId,
                activeDivision.displayName
              )
            );
            break;
          }

          case EventTrackingServiceType.Segment: {
            const segmentService = await SegmentEventTrackingService.create(
              currentUser.salesForceId,
              activeDivision.displayName
            );
            setTrackingService(segmentService);
            break;
          }

          default: {
            setTrackingService(new NoOpEventTrackingService());
          }
        }
      } catch (error) {
        ddLog(
          `Failed to initialize ${trackerType} tracking service`,
          {
            userId: currentUser.salesForceId,
            division: activeDivision.displayName,
            trackerType,
            error,
          },
          "error"
        );
        setTrackingService(new NoOpEventTrackingService());
      }
    };

    void initializeTracker();
  }, [currentUser, activeDivision.displayName, trackerType]);

  return trackingService;
};
