import { uniqueId } from "@qbit/react/dist/common";
import {
  HierarchyItemType,
  ddLog,
  TrackingEvent,
  TrackingComponent,
} from "@quantium-enterprise/common-ui";
import {
  useFlags,
  type DeferredFormatFunction,
} from "@quantium-enterprise/hooks-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type ColumnDef,
  type ExpandedState,
  type CellContext,
  createColumnHelper,
  type RowSelectionState,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import {
  getDescendantRows,
  getParentRows,
} from "../../../common/utils/data-table-utils";
import { TimeOfDayDayOfWeekFeatureFlags } from "../../constants/time-of-day-day-of-week-feature-flags";
import {
  type FetchChildrenTableRequest,
  type RefetchTableRequest,
  type TimeOfDayDayOfWeekTableMetaData,
  type TimeOfDayDayOfWeekTableRow,
} from "../../models/time-of-day-day-of-week-data-table-models";
import {
  useGetInitialTableQuery,
  useLazyGetChildrenTableDataQuery,
  useLazyGetExportDataQuery,
  useLazyGetTableDataQuery,
} from "../../services/time-of-day-day-of-week-data-table-api-slice";
import {
  onFocalItemChange,
  onInitialTableDataReceived,
  onSearchChange,
} from "../../services/time-of-day-day-of-week-slice";
import {
  selectFocalItems,
  selectInitialDataRecieved,
  selectLocalParametersInitialised,
  selectLocalParametersSelections,
  selectSearchQuery,
  selectTableMetaData,
  selectTableRows,
} from "../../services/time-of-day-day-of-week-slice-selectors";
import { formatDayId } from "../../utils/chart-utils";
import getUserSelections from "../../utils/getUserSelections";
import styles from "./TimeOfDayDayOfWeekTopDrawer.module.css";

// Export to enable testing
export const measuresColumnDefs = (
  metaData: TimeOfDayDayOfWeekTableMetaData,
  formatter: DeferredFormatFunction
) =>
  metaData.days.map((day) => ({
    accessorFn: (row: TimeOfDayDayOfWeekTableRow) => {
      const data = row.data.find((item) => day === item.day);
      if (data) {
        return data.value;
      }

      return "";
    },
    cell: ({ getValue }: CellContext<TimeOfDayDayOfWeekTableRow, number>) =>
      ValueCell({
        formatter: formatter(metaData.metric.format),
        value: getValue(),
      }),
    header: formatDayId[day.toUpperCase()],
  }));

export type TimeOfDayDayOfWeekTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const TimeOfDayDayOfWeekTopDrawer = ({
  eventTrackingService,
  renameReport,
  reportType,
  reportName,
}: TimeOfDayDayOfWeekTopDrawerProps) => {
  const { name: divisionName } = useDivision();
  const { id: reportId } = useParams();
  const dispatch = useDispatch();
  const formatter = useFormatter();

  // Store
  const searchQuery = useSelector(selectSearchQuery);
  const focalItems = useSelector(selectFocalItems);
  const initialDataRecieved = useSelector(selectInitialDataRecieved);
  const localParameterSelections = useSelector(selectLocalParametersSelections);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const tableMetaData = useSelector(selectTableMetaData);
  const tableRows = useSelector(selectTableRows);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[TimeOfDayDayOfWeekFeatureFlags.ReportExport] ?? false;

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    for (const key in rowSelection) {
      if ({}.hasOwnProperty.call(rowSelection, key)) {
        rowSelection[key] = false;
      }
    }

    for (const item of focalItems) {
      rowSelection[item.nodeNumber.toString()] = true;
    }
  }, [focalItems, rowSelection]);

  const [itemsToRefetch, setItemsToRefetch] = useState<number[]>([]);
  const [expandState, setExpandState] = useState<ExpandedState>(true);

  const expandRow = useCallback(
    (row: TimeOfDayDayOfWeekTableRow) => {
      setItemsToRefetch([...itemsToRefetch, row.hierarchyItem.nodeNumber]);
    },
    [itemsToRefetch]
  );

  const collapseRow = useCallback(
    (row: TimeOfDayDayOfWeekTableRow) => {
      const rowsToCollapse = getDescendantRows(row);
      rowsToCollapse.push(row.hierarchyItem.nodeNumber);
      const filtered = itemsToRefetch.filter(
        (item) => !rowsToCollapse.includes(item)
      );
      setItemsToRefetch(filtered);
    },
    [itemsToRefetch]
  );
  // Export Focal Item
  const [downloadTableTrigger] = useLazyGetExportDataQuery();
  const fileName = useMemo(
    () =>
      cleanFilename(
        `Time_Of_Day_Day_Of_Week_${localParameterSelections.TimePeriodLength}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );
  const parameterSummary = useMemo(
    () => [
      { name: "Time", value: localParameterSelections.FocusPeriod as string },
      { name: "Metrics", value: localParameterSelections.Metric.label },
      { name: "Channel", value: localParameterSelections.Channel.label },
      { name: "Promotion", value: localParameterSelections.Promotion.label },
      {
        name: "Segmentation",
        value: localParameterSelections.Segmentation.label,
      },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localParameterSelections.LocationHierarchy.shortName
        )}) ${localParameterSelections.LocationHierarchy.name}`,
      },
    ],
    [localParameterSelections]
  );
  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: {
        localSelectedValues: getUserSelections(localParameterSelections),
        localSelectedLabels: parameterSummary,
        fileName,
        reportId: reportId ?? "",
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    fileName,
    reportId,
    localParameterSelections,
    parameterSummary,
  ]);

  // Initial Table
  const { data: initialTableData, isFetching: isInitialTableFetching } =
    useGetInitialTableQuery(
      {
        divisionName,
        payload: {
          localSelectedValues: getUserSelections({
            Channel: localParameterSelections.Channel,
            Metric: localParameterSelections.Metric,
            Promotion: localParameterSelections.Promotion,
            Segmentation: localParameterSelections.Segmentation,
            LocationHierarchy: localParameterSelections.LocationHierarchy,
            CheckoutType: localParameterSelections.CheckoutType ?? undefined,
          }),
          reportId: reportId ?? "",
          defaultNodes: focalItems,
        },
      },
      { skip: !localParametersInitialised || initialDataRecieved }
    );

  // Set the initial displayed nodes
  useEffect(() => {
    if (initialTableData) {
      dispatch(onInitialTableDataReceived(initialTableData));
      setItemsToRefetch(getParentRows(initialTableData.tableRows));
    }
  }, [dispatch, initialTableData]);

  // Refetch Table
  const [refetchTableQueryTrigger, { isFetching: refetchTableFetching }] =
    useLazyGetTableDataQuery({
      refetchOnFocus: true,
    });

  const fetchTable = useCallback(async () => {
    const payload: RefetchTableRequest = {
      localSelectedValues: getUserSelections({
        Channel: localParameterSelections.Channel,
        Metric: localParameterSelections.Metric,
        Promotion: localParameterSelections.Promotion,
        Segmentation: localParameterSelections.Segmentation,
        LocationHierarchy: localParameterSelections.LocationHierarchy,
      }),
      parentNodes: itemsToRefetch,
      reportId: reportId ?? "",
      searchQuery,
    };

    await refetchTableQueryTrigger({
      divisionName,
      payload,
    });
  }, [
    itemsToRefetch,
    divisionName,
    localParameterSelections,
    reportId,
    refetchTableQueryTrigger,
    searchQuery,
  ]);

  // changing localParameterSelections.CheckoutType should not call focaltable api
  useEffect(() => {
    if (initialDataRecieved && localParametersInitialised) {
      fetchTable().catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localParameterSelections.Channel,
    localParameterSelections.Metric,
    localParameterSelections.Promotion,
    localParameterSelections.Segmentation,
    localParameterSelections.LocationHierarchy,
    searchQuery,
  ]);

  // Fetch Children
  const [isRowFetchingChildren, setIsRowFetchingChildren] = useState<{
    [id: number]: boolean;
  }>({});

  const [fetchChildrenQueryTrigger] = useLazyGetChildrenTableDataQuery({
    refetchOnFocus: false,
  });

  const fetchChildren = useCallback(
    async (parentNode: TimeOfDayDayOfWeekTableRow) => {
      const payload: FetchChildrenTableRequest = {
        localSelectedValues: getUserSelections({
          Channel: localParameterSelections.Channel,
          Metric: localParameterSelections.Metric,
          Promotion: localParameterSelections.Promotion,
          Segmentation: localParameterSelections.Segmentation,
          LocationHierarchy: localParameterSelections.LocationHierarchy,
        }),
        parentNode: parentNode.hierarchyItem.nodeNumber,
        reportId: reportId ?? "",
      };

      setIsRowFetchingChildren({ [parentNode.hierarchyItem.nodeNumber]: true });

      await fetchChildrenQueryTrigger({
        divisionName,
        payload,
      });

      setIsRowFetchingChildren({
        [parentNode.hierarchyItem.nodeNumber]: false,
      });
    },
    [
      localParameterSelections,
      reportId,
      fetchChildrenQueryTrigger,
      divisionName,
    ]
  );

  // Should be moved to common if all focal item tables rowID will be their node number
  const getRowId = (row: TimeOfDayDayOfWeekTableRow) =>
    row.hierarchyItem.nodeNumber.toString();

  const columnHelper = createColumnHelper<TimeOfDayDayOfWeekTableRow>();

  const isSearchMode = useMemo(
    () => searchQuery.length > 0 && !refetchTableFetching,
    [refetchTableFetching, searchQuery.length]
  );

  // Create the expandable cells for the first columns
  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<TimeOfDayDayOfWeekTableRow, string>) => (
      <ExpandableNameCell
        canExpand={!row.original.hierarchyItem.isLeaf && !isSearchMode}
        depth={row.depth}
        handleToggleExpanded={(event) => {
          event.stopPropagation();
          if (itemsToRefetch.includes(row.original.hierarchyItem.nodeNumber)) {
            collapseRow(row.original);
          } else {
            if (row.subRows.length === 0) {
              fetchChildren(row.original).catch((error) => {
                ddLog("ERROR", {}, "error", error);
              });
              // when fetching children, the row doesn't expand on first click so
              // force it to open manually
              setExpandState((previousState) =>
                typeof previousState === "boolean"
                  ? true
                  : {
                      ...previousState,
                      [row.original.hierarchyItem.nodeNumber]: true,
                    }
              );
            }

            expandRow(row.original);
          }

          row.getToggleExpandedHandler()();
        }}
        handleToggleSelected={(event) => {
          row.getToggleSelectedHandler()(event);

          const currentFocalItems = row.getIsSelected()
            ? focalItems.filter(
                (value) =>
                  value.nodeNumber !== row.original.hierarchyItem.nodeNumber
              )
            : [...focalItems, row.original.hierarchyItem];
          eventTrackingService(
            [
              TrackingComponent.MyReports,
              TrackingComponent.Report,
              TrackingComponent.FocalItemTableRow,
            ],
            row.getIsSelected()
              ? TrackingEvent.Unselected
              : TrackingEvent.Selected,
            {
              selection: currentFocalItems.map(
                ({ shortName, name }) => `${shortName}:${name}`
              ),
            }
          );

          if (row.getIsSelected()) {
            dispatch(
              onFocalItemChange(
                focalItems.filter(
                  (value) =>
                    value.nodeNumber !== row.original.hierarchyItem.nodeNumber
                )
              )
            );
          } else {
            dispatch(
              onFocalItemChange([...focalItems, row.original.hierarchyItem])
            );
          }
        }}
        isExpanded={row.getIsExpanded() && row.original.subRows.length > 0}
        isExpanding={
          isRowFetchingChildren[row.original.hierarchyItem.nodeNumber] &&
          row.original.subRows.length === 0
        }
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={getValue()}
      />
    ),
    [
      eventTrackingService,
      collapseRow,
      dispatch,
      expandRow,
      itemsToRefetch,
      fetchChildren,
      focalItems,
      isRowFetchingChildren,
      isSearchMode,
    ]
  );

  const displayEmptySearch = isSearchMode && tableRows.length === 0;

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchbox}>
        <SearchBox
          enableDebounce
          onChange={(value: string) => dispatch(onSearchChange(value))}
          placeholder="Type to search"
          resultCount={isSearchMode ? tableRows.length : undefined}
          searchQuery={searchQuery}
        />
      </div>
    ),
    [isSearchMode, tableRows.length, dispatch, searchQuery]
  );

  const dataColumns = useMemo(
    () =>
      tableMetaData.days.length > 0
        ? [
            {
              columns: measuresColumnDefs(tableMetaData, formatter),
              header: tableMetaData.metric.label,
              id: tableMetaData.metric.label,
            },
          ]
        : [],
    [formatter, tableMetaData]
  );

  // @ts-expect-error typecheck issue with memoizedSearchBox as header
  const columnDefs: Array<ColumnDef<TimeOfDayDayOfWeekTableRow>> = useMemo(
    () => [
      {
        columns: [
          columnHelper.accessor((row) => row.hierarchyItem.name, {
            cell: createNameCell,
            enableHiding: false,
            enableResizing: true,
            enableSorting: false,
            header: "Item description",
          }),
        ],
        header: memoizedSearchBox,
        id: uniqueId(),
      },
      ...dataColumns,
    ],
    [columnHelper, createNameCell, dataColumns, memoizedSearchBox]
  );

  return (
    <ReportTopDrawer
      controls={[
        <div key={reportId} style={{ width: "30px", overflow: "hidden" }}>
          <DataTableOptions
            filename={fileName}
            invokeCSVDownload={handleDownloadButtonClick}
            isFeatureEnabled={isExportEnabled}
            isInFocalItemHeader
            key={reportId}
          />
        </div>,
      ]}
      items={focalItems.map((row) => ({
        code: row.shortName,
        displayName: row.name,
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportHierarchyTableWrapper isSuccess={!isInitialTableFetching}>
          <VirtuosoTableComponent
            columns={columnDefs}
            data={tableRows}
            getRowId={getRowId}
            getSubRows={(row) => row.subRows}
            onExpandedChange={setExpandState}
            onRowSelectionChange={setRowSelection}
            pinFirstColumn
            refreshingData={refetchTableFetching}
            rowExpandedState={expandState}
            rowSelectionState={rowSelection}
            showCheckboxesOnlyOnHover
          />
          {displayEmptySearch && (
            <div className={styles.emptySearch}>
              <EmptySearch />
            </div>
          )}
        </ReportHierarchyTableWrapper>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default TimeOfDayDayOfWeekTopDrawer;
