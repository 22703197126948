import { type SortingState } from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";
import { useReportTabState } from "report-tabs-ui";
import Zod from "zod";

export const FastReportingTableSortingSchema = Zod.object({
  isDescending: Zod.boolean(),
  columnId: Zod.string(),
});

export type FastReportingTableSorting = Zod.infer<
  typeof FastReportingTableSortingSchema
>;

const firstColumnDefaultSortingSelector = (
  columnIds: string[]
): FastReportingTableSorting => ({
  columnId: columnIds[0],
  isDescending: true,
});

export const useFastReportingTableSorting = (
  reportTabStateKey: string,
  onSortingChanged: (sorting: FastReportingTableSorting) => void,
  columnIds: string[],
  defaultSortingSelectorOverride?: (
    columnIds: string[]
  ) => FastReportingTableSorting
) => {
  const [reactTableSortingState, setReactTableSortingState] =
    useState<SortingState>([]);

  const [sortedMetric, setSortedMetric] = useReportTabState(
    reportTabStateKey,
    FastReportingTableSortingSchema
  );

  // Set default value
  useEffect(() => {
    if (!sortedMetric) {
      const defaultSortingFunction =
        defaultSortingSelectorOverride ?? firstColumnDefaultSortingSelector;
      setSortedMetric(defaultSortingFunction(columnIds));
    }
  }, [
    defaultSortingSelectorOverride,
    sortedMetric,
    setSortedMetric,
    columnIds,
  ]);

  // Update react table state when the sorted metric is changed
  useEffect(() => {
    if (sortedMetric) {
      setReactTableSortingState([
        { id: sortedMetric.columnId, desc: sortedMetric.isDescending },
      ]);
      onSortingChanged(sortedMetric);
    }
  }, [sortedMetric, setReactTableSortingState, onSortingChanged]);

  const toggleSorting = useCallback(
    (metricKey: string, initiallySortAscending: boolean = false) => {
      if (sortedMetric && sortedMetric.columnId === metricKey) {
        setSortedMetric({
          columnId: metricKey,
          isDescending: !sortedMetric.isDescending,
        });
      } else {
        setSortedMetric({
          columnId: metricKey,
          isDescending: !initiallySortAscending,
        });
      }
    },
    [setSortedMetric, sortedMetric]
  );

  useEffect(() => {
    // When a user removes the sort by metric from the selection, the table is then reverted to default
    if (
      sortedMetric &&
      columnIds.length > 0 &&
      !columnIds.includes(sortedMetric.columnId)
    ) {
      const defaultSortingFunction =
        defaultSortingSelectorOverride ?? firstColumnDefaultSortingSelector;
      const newSortedMetric = defaultSortingFunction(columnIds);
      setSortedMetric(newSortedMetric);
      onSortingChanged(newSortedMetric);
    }
    // After removing all metrics the first newly selected metric will be the ‘sort by metric’
    else if (
      sortedMetric &&
      columnIds.length === 1 &&
      !columnIds.includes(sortedMetric.columnId)
    ) {
      const newSortedMetric = {
        columnId: columnIds[0],
        isDescending: sortedMetric.isDescending,
      };
      setSortedMetric(newSortedMetric);
      onSortingChanged(newSortedMetric);
    }
  }, [
    columnIds,
    sortedMetric,
    setSortedMetric,
    onSortingChanged,
    defaultSortingSelectorOverride,
  ]);

  return { toggleSorting, reactTableSortingState };
};
