import {
  Page,
  PageVariant,
  QbitToastContainer,
  Spinner,
  SpinnerSize,
} from "@qbit/react";
import { admin } from "@quantium-enterprise/admin-ui";
import { availableReports } from "@quantium-enterprise/available-reports-ui";
import {
  type Division,
  MY_DASHBOARD_PATH,
  AppContext,
  useGetUserQuery,
  EventTrackingServiceContext,
  ddLog,
  EventTrackingServiceType,
  useEventTracker,
} from "@quantium-enterprise/common-ui";
import { ENVIRONMENT } from "@quantium-enterprise/common-ui";
import { dashboardRoute as dashboard } from "@quantium-enterprise/dashboard-ui";
import {
  FastReportingSearchModal,
  fastReporting,
} from "@quantium-enterprise/fast-reporting-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { rangePerformance } from "@quantium-enterprise/range-performance-ui";
import { type SerializedError } from "@reduxjs/toolkit";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { groups } from "groups-ui";
import { myReports } from "my-reports-ui";
import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  type RouteObject,
  useRoutes,
  Outlet,
  Navigate,
  Await,
} from "react-router-dom";
import { CustomerGroupWizard, ReportWizard } from "report-parameters-ui";
import { reportView } from "reports-ui";
import styles from "./App.module.css";
import { AppMeta } from "./AppMeta";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import { FullPageError } from "./components/error-boundary/FullPageError";
import AppHeader from "./components/header/AppHeader";
import { NotFoundPage } from "./components/not-found-page/NotFoundPage";
import AppSideBar from "./components/sidebar/AppSideBar";

// move this out to prevent a chicken and egg problem
// needed within <App /> component and nameless exported wrapper
const myReportsRoute = myReports();
const groupsRoute = groups();
const reportCreatorRoute = availableReports();
const reportWizardRoute = ReportWizard();
const customerGroupWizardRoute = CustomerGroupWizard();
const reportRoute = reportView();
const dashboardRoute = dashboard();
const fastReportingRoute = fastReporting();
const adminRoute = admin();
const rangePerformanceRoute = rangePerformance();

const notLoggedInRedirectIfError = (
  error: Error | FetchBaseQueryError | SerializedError | object | undefined
) => {
  if (
    error &&
    (("status" in error && error.status === 401) ||
      ("originalStatus" in error && error.originalStatus === 401))
  ) {
    window.location.assign(
      `/sso-proxy/challenge-with-fragment?redirectUri=${encodeURIComponent(
        window.location.href
      )}`
    );
  }
};

const App = (): JSX.Element => {
  const { userState } = useContext(AppContext);

  const {
    displayName: activeDivisionDisplayName,
    name: activeDivisionName,
    uiAssetsPath,
  } = useDivision();
  const activeDivision = {
    displayName: activeDivisionDisplayName,
    name: activeDivisionName,
    uiAssetsPath,
  } as Division;

  // This tracks user events through the site.
  const trackerType = ENVIRONMENT.IS_PRODUCTION
    ? EventTrackingServiceType.Segment
    : EventTrackingServiceType.NoOp;

  const eventTrackingService = useEventTracker({
    currentUser: userState.currentUser,
    activeDivision,
    trackerType,
  });

  // importing dynamically here so that theme.css will be added into the bundle.
  useEffect(() => {
    const themeModules = import.meta.glob("./retailer/*/theme.css", {
      query: "?inline",
    });
    const themePath = `./retailer/${uiAssetsPath}/theme.css`;

    if (uiAssetsPath) {
      themeModules[themePath]()
        .then((module) => {
          // @ts-expect-error module is of type unknown
          const cssContent = module.default;
          const styleTag = document.createElement("style");
          styleTag.innerHTML = cssContent;
          document.head.appendChild(styleTag);
        })
        .catch((error_) => {
          ddLog("ERROR", {}, "error", error_ as Error);
        });
    }
  }, [uiAssetsPath]);

  useEffect(() => {
    notLoggedInRedirectIfError(userState.error);
  }, [userState.error, userState.isError]);

  const render = useMemo(
    () => eventTrackingService !== undefined,
    [eventTrackingService]
  );

  return render ? (
    <AppMeta>
      <ErrorBoundary fallback={<FullPageError />}>
        <EventTrackingServiceContext.Provider value={eventTrackingService}>
          <Page
            className={styles.appPage}
            // eslint-disable-next-line react/jsx-no-useless-fragment -- header is required by qbit
            header={<></>}
            variant={PageVariant.Custom}
          >
            <div className={styles.appPageContent}>
              {userState.isLoading && <Spinner size={SpinnerSize.Large} />}
              {userState.error && <span> An error has occurred </span>}
              {userState.currentUser && (
                <AppSideBar
                  activeDivision={activeDivision}
                  dashboardRoute={dashboardRoute}
                  groupsRoute={groupsRoute}
                  myReportsRoute={myReportsRoute}
                  reportCreatorRoute={reportCreatorRoute}
                  user={userState.currentUser}
                />
              )}
              <div className={styles.appMainContent}>
                <QbitToastContainer />
                {userState.currentUser && (
                  <AppHeader
                    activeDivision={activeDivision}
                    user={userState.currentUser}
                  />
                )}
                <div className={styles.appMainContentInner}>
                  <ErrorBoundary>
                    <Outlet />
                    <FastReportingSearchModal />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </Page>
        </EventTrackingServiceContext.Provider>
      </ErrorBoundary>
    </AppMeta>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

const DefaultRoute = () => {
  const { userState } = useContext(AppContext);
  const { name: divisionName } = useDivision();
  const [defaultPath, setDefaultPath] = useState("");

  useEffect(() => {
    notLoggedInRedirectIfError(userState.error);
  }, [userState.error, userState.isError]);

  const resolver = useMemo(async () => {
    const promise = new Promise((resolve) => {
      if (divisionName) {
        setDefaultPath(divisionName);
        resolve(true);
      }
    });

    return await promise;
  }, [divisionName]);

  const getPath = () => {
    if (!defaultPath.length) {
      return "/404";
    }

    return `/${defaultPath}/${MY_DASHBOARD_PATH}`;
  };

  return (
    <React.Suspense fallback={<p>loading</p>}>
      <Await errorElement={<p>Error</p>} resolve={resolver}>
        <Navigate to={getPath()} />
      </Await>
    </React.Suspense>
  );
};

export default () => {
  const { data, error, isLoading, isError } = useGetUserQuery();
  const value = useMemo(
    () => ({ userState: { currentUser: data, error, isLoading, isError } }),
    [data, error, isLoading, isError]
  );

  // set the site title
  const siteTitle = document.querySelector("head")?.querySelector("title");
  if (siteTitle) {
    siteTitle.text = String(ENVIRONMENT.VITE_TITLE);
  }

  const routes = useRoutes([
    {
      element: <NotFoundPage />,
      path: "/404",
    },
    {
      children: [
        myReportsRoute as RouteObject,
        groupsRoute as RouteObject,
        reportCreatorRoute as RouteObject,
        reportWizardRoute as RouteObject,
        customerGroupWizardRoute as RouteObject,
        reportRoute as RouteObject,
        dashboardRoute as RouteObject,
        {
          element: <DefaultRoute />,
          index: true,
        },
        fastReportingRoute as RouteObject,
        rangePerformanceRoute as RouteObject,
      ],
      element: <App />,
      path: "/:division",
    },
    {
      element: <DefaultRoute />,
      path: "/",
    },
    adminRoute as RouteObject,
    {
      element: <NotFoundPage />,
      path: "*",
    },
  ]);
  return <AppContext.Provider value={value}>{routes}</AppContext.Provider>;
};

// trigger ui release (try again)
