import { type SearchRequestDto } from "@quantium-enterprise/common-ui";
import {
  HierarchyType,
  hierarchyServiceApi,
} from "@quantium-enterprise/common-ui";
import { FAST_REPORTING_FEATURE_NAME } from "../../FastReporting";
import { store } from "../../store";
import {
  isSelectableAttributeItem,
  type SelectableAttributeItem,
  type SelectableItem,
} from "../SelectableItem";
import { getUniqueKey } from "../SelectableItem";
import { type AttributeStrategy } from "./AttributeStrategy";
import { type DefaultHierarchyStrategy } from "./DefaultHierarchyStrategy";
import { SearchStrategyStatus } from "./SearchStrategy";
import { SearchStrategyBase } from "./SearchStrategyBase";
import { getAttributeSubRows } from "./search-strategy-utils";

export class AllSearchStrategy extends SearchStrategyBase {
  private searchQuery: string = "";

  private currentHierarchyPage: number = 0;

  private currentAttributePage: number = 0;

  private hierarchyResultsComplete: boolean = false;

  private attributeResultsComplete: boolean = false;

  private searchResultErrored: boolean = false;

  private readonly pageSize: number;

  private readonly hierarchyStrategy: DefaultHierarchyStrategy;

  private readonly attributeStrategies: Record<string, AttributeStrategy>;

  private readonly division: string;

  private readonly attributePriority: string[];

  private readonly scanFeatureEntitlementsEnabled: boolean;

  public constructor(
    hierarchyStrategy: DefaultHierarchyStrategy,
    attributeStrategies: AttributeStrategy[],
    division: string,
    pageSize: number,
    scanFeatureEntitlementsEnabled: boolean
  ) {
    super();
    this.division = division;
    this.pageSize = pageSize;
    this.hierarchyStrategy = hierarchyStrategy;
    this.scanFeatureEntitlementsEnabled = scanFeatureEntitlementsEnabled;

    const attributeStrategyDictionary: Record<string, AttributeStrategy> = {};
    for (const strategy of attributeStrategies) {
      attributeStrategyDictionary[strategy.shortName] = strategy;
    }

    this.attributeStrategies = attributeStrategyDictionary;

    this.attributePriority = attributeStrategies.map(
      (strategy) => strategy.shortName
    );
  }

  public setSearchQuery(query: string): void {
    if (query !== this.searchQuery) {
      if (this.getStatus() !== SearchStrategyStatus.Uninitialized) {
        this.setStatus(SearchStrategyStatus.Loading);
      }

      this.currentHierarchyPage = 0;
      this.currentAttributePage = 0;
      this.hierarchyResultsComplete = false;
      this.attributeResultsComplete = false;
      this.searchResultErrored = false;
      this.searchQuery = query;
      this.search();
      this.setExpandedItems([]);
    }
  }

  public loadMore(): void {
    if (this.hierarchyResultsComplete) {
      this.currentAttributePage++;
    } else {
      this.currentHierarchyPage++;
    }

    this.search();
  }

  public async expandItem(item: SelectableItem) {
    if (isSelectableAttributeItem(item)) {
      if ((item.subRows?.length ?? 0) === 0) {
        item.isExpanding = true;
        await this.loadAttributeSubRows(item);
      }

      this.setExpandedItems([...this.getExpandedItems(), item]);
    } else {
      throw new Error(`Method not implemented for ${item.type} type.`);
    }
  }

  public unexpandItem(item: SelectableItem): void {
    if (isSelectableAttributeItem(item)) {
      this.setExpandedItems(
        this.getExpandedItems().filter(
          (existing) => getUniqueKey(existing) !== getUniqueKey(item)
        )
      );
    } else {
      throw new Error(`Method not implemented for ${item.type} type.`);
    }
  }

  private async loadAttributeSubRows(item: SelectableAttributeItem) {
    const subRows = await getAttributeSubRows(item, this.division);

    if (subRows) {
      const items = this.getItems() as SelectableAttributeItem[];
      const expandItem = this.findAttributeItem(
        item.code,
        item.shortName,
        items
      );

      if (expandItem) {
        expandItem.subRows = subRows;

        if (this.onItemsChanged) {
          this.onItemsChanged(items);
        }
      }

      this.setStatus(SearchStrategyStatus.Success);
    } else {
      this.setStatus(SearchStrategyStatus.Error);
    }
  }

  private findAttributeItem(
    code: string,
    shortName: string,
    items: SelectableAttributeItem[] | undefined
  ): SelectableAttributeItem | undefined {
    if (items) {
      for (const item of items) {
        if (item.code === code && item.shortName === shortName) {
          return item;
        }
      }
    }

    return undefined;
  }

  public initialize(): void {
    if (this.getStatus() === SearchStrategyStatus.Uninitialized) {
      this.search();
    }
  }

  private search() {
    const requestSearchQuery = this.searchQuery;
    (async () => {
      const isInitialSearch =
        !this.hierarchyResultsComplete && this.currentHierarchyPage === 0;
      const results: SelectableItem[] = [];

      if (!this.hierarchyResultsComplete) {
        results.push(...(await this.hierarchySearch(requestSearchQuery)));
      }

      if (this.hierarchyResultsComplete && !this.attributeResultsComplete) {
        results.push(...(await this.attributeSearch(requestSearchQuery)));
      }

      if (requestSearchQuery !== this.searchQuery) {
        // Search query doesn't match what's in the state, ignore as it must be an old request.
        return;
      }

      if (this.searchResultErrored) {
        this.setStatus(SearchStrategyStatus.Error);
      } else {
        const existingItems = this.getItems();
        for (const item of existingItems) {
          item.isMoreRow = false;
        }

        if (!this.attributeResultsComplete) {
          results[results.length - 1].isMoreRow = true;
        }

        if (isInitialSearch) {
          this.setItems(results);
        } else {
          const newItems = [...existingItems, ...results];
          this.setItems(newItems);
        }

        this.setStatus(SearchStrategyStatus.Success);
      }
    })();
  }

  private async hierarchySearch(
    requestSearchQuery: string
  ): Promise<SelectableItem[]> {
    let payload: SearchRequestDto = {
      includeCodes: true,
      page: this.currentHierarchyPage,
      pageSize: this.pageSize,
      query: requestSearchQuery,
    };

    if (this.scanFeatureEntitlementsEnabled) {
      payload = {
        ...payload,
        featureModules: [FAST_REPORTING_FEATURE_NAME],
        featureFilter: { module: FAST_REPORTING_FEATURE_NAME },
      };
    }

    const response = await store.dispatch(
      hierarchyServiceApi.endpoints.search.initiate({
        division: this.division,
        hierarchyType: HierarchyType.Product,
        payload,
      })
    );

    if (response.data) {
      if (!response.data.hasNextPage) {
        this.hierarchyResultsComplete = true;
      }

      return this.hierarchyStrategy.convertFromHierarchyResponse(
        response.data.results,
        false
      );
    }

    if (response.error) {
      this.searchResultErrored = true;
    }

    return [];
  }

  private async attributeSearch(
    requestSearchQuery: string
  ): Promise<SelectableAttributeItem[]> {
    let payload: SearchRequestDto = {
      focalAttributes: this.attributePriority,
      includeCodes: true,
      page: this.currentAttributePage,
      pageSize: this.pageSize,
      query: requestSearchQuery,
    };

    if (this.scanFeatureEntitlementsEnabled) {
      payload = {
        ...payload,
        featureModules: [FAST_REPORTING_FEATURE_NAME],
        featureFilter: { module: FAST_REPORTING_FEATURE_NAME },
      };
    }

    const response = await store.dispatch(
      hierarchyServiceApi.endpoints.search.initiate({
        division: this.division,
        hierarchyType: HierarchyType.Product,
        payload,
      })
    );

    if (response.data) {
      if (!response.data.hasNextPage) {
        this.attributeResultsComplete = true;
      }

      return response.data.results.flatMap((result) => {
        const strategy = this.attributeStrategies[result.shortName];
        return strategy.convertFromHierarchyResponse([result]);
      });
    }

    if (response.error) {
      this.searchResultErrored = true;
    }

    return [];
  }

  public hierarchyLevelSelected(
    item: SelectableItem,
    shortName: string,
    code: string
  ): void {
    if (isSelectableAttributeItem(item)) {
      const strategy = this.attributeStrategies[item.shortName];
      strategy.hierarchyLevelSelected(item, shortName, code);

      const existingItems = this.getItems();
      const existingItem = existingItems.find(
        (index) => getUniqueKey(index) === getUniqueKey(item)
      ) as SelectableAttributeItem | undefined;
      const entitlements = item.additionalHierarchyFilter?.entitlements ?? [];
      if (existingItem) {
        existingItem.additionalHierarchyFilter = {
          code,
          entitlements,
          shortName,
        };
      }

      this.setItems(existingItems);
    }
  }
}
