import {
  IconGlyph,
  Input,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  RadioButton,
  Text,
} from "@qbit/react";
import {
  type CustomerGroupDto,
  type GroupSegmentSelectionDto,
  type ListSelectionDto,
  type ParameterGroupSelectionDto,
  CustomerGroupType,
  GenericTrackingProperties,
  TrackingComponent,
  TrackingEvent,
  useCreateCustomerGroupMutation,
  useEventTrackingServiceContext,
  useUpdateCustomerGroupMutation,
  GroupsTrackingProperty,
  ParameterId,
  useGetSegmentsQuery,
  type CombinedSegmentDto,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { CustomerGroupIcon } from "components-ui/src/icons";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { GroupType } from "../../enums/group-type";
import {
  getGroupCreatorPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import { type CombinedGroupSegmentRow } from "../combined-group-segments-table/CombinedGroupSegmentsTable";
import { CombinedGroupSegmentsTable } from "../combined-group-segments-table/CombinedGroupSegmentsTable";
import { GroupEditor } from "../group-editor/GroupEditor";
import { TwoPanelForm } from "../two-panel-form/TwoPanelForm";
import styles from "./CombinedGroupEditor.module.css";

const { Header, BackButton, Title, Content, Footer, SaveButton } = GroupEditor;
const { Heading, Details, Divider, LeftPanel, RightPanel } = TwoPanelForm;

// checkbox IDs are of format groupID|segmentKey|(RHS?) to create unique IDs
const getLHSCorrespondingIDs = (rhsIds: string[]) =>
  rhsIds.map((cg) => {
    const match = /(.*)\|/u.exec(cg);
    return match ? match[1] : cg;
  });

export const createUniqueGroupSegmentId = (
  cg: CombinedGroupSegmentRow,
  isRHS: boolean
) =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  (cg.groupId ?? cg.standardSegmentationId) +
  "|" +
  cg.segmentKey +
  (isRHS ? "|RHS" : "");

const sortByRefreshDate = (a: CombinedSegmentDto, b: CombinedSegmentDto) => {
  if (a.refreshDate && b.refreshDate) {
    return (
      new Date(b.refreshDate).getTime() - new Date(a.refreshDate).getTime()
    );
  }

  return 0;
};

const mapGroupSegment: (
  segment: CombinedSegmentDto
) => CombinedGroupSegmentRow = (segment: CombinedSegmentDto) =>
  ({
    groupId: segment.groupId,
    name: `${segment.groupName} (${segment.segmentName})`,
    segmentKey: segment.segmentKey,
    type: segment.groupType,
    standardSegmentationId: segment.segmentationId,
  } as CombinedGroupSegmentRow);

const getExistingGroupRule = (existingGroup: CustomerGroupDto) =>
  (
    existingGroup.parameters.find((parameter) => parameter.name === "Rule")
      ?.parameterSelections[0].selections[0] as ListSelectionDto
  ).value;

const getExistingGroupSegments = (existingGroup: CustomerGroupDto) =>
  existingGroup.parameters.find((parameter) => parameter.name === "Segments")
    ?.parameterSelections[0].selections as GroupSegmentSelectionDto[];

type CombinedGroupProps = {
  existingGroup?: CustomerGroupDto;
};

export const CombinedGroupEditor = ({ existingGroup }: CombinedGroupProps) => {
  const { name: divisionName } = useDivision();
  const navigate = useNavigate();
  const eventTrackingService = useEventTrackingServiceContext();

  const [isGroupRuleAnd, setIsGroupRuleAnd] = useState(true);
  const [selectedGroupSegmentsLHS, setSelectedGroupSegmentsLHS] = useState<
    string[]
  >([]);
  const [groupSegmentsRHS, setGroupSegmentsRHS] = useState<
    CombinedGroupSegmentRow[]
  >([]);
  const [selectedGroupSegmentsRHS, setSelectedGroupSegmentsRHS] = useState<
    string[]
  >([]);

  const icon = useMemo(
    () => <CustomerGroupIcon type={CustomerGroupType.Combined} />,
    []
  );

  const [createParameters] = useCreateCustomerGroupMutation();
  const [updateParameters] = useUpdateCustomerGroupMutation();

  const [lhsSearchText, setLhsSearchText] = useState("");
  const [rhsSearchText, setRhsSearchText] = useState("");

  const onLhsSearch = useCallback((searchText: string) => {
    setLhsSearchText(searchText);
  }, []);

  const onRhsSearch = useCallback((searchText: string) => {
    setRhsSearchText(searchText);
  }, []);

  const {
    data: serverCustomerSegmentList,
    isFetching: isCustomerSegmentListFetching,
    isSuccess: isCustomerSegmentListSuccess,
  } = useGetSegmentsQuery(
    {
      divisionName,
    },
    { skip: !divisionName }
  );

  const customerGroupSegmentsList = useMemo(() => {
    if (!serverCustomerSegmentList) {
      return undefined;
    }

    const standardSegments = serverCustomerSegmentList
      .filter((segment) => Boolean(segment.segmentationId))
      .map(mapGroupSegment);
    const userSegments = serverCustomerSegmentList
      .filter(
        (segment) =>
          Boolean(segment.groupId) &&
          (!existingGroup || segment.groupId !== existingGroup.id)
      )
      .sort(sortByRefreshDate)
      .map(mapGroupSegment);

    // standard segments always at top, followed by custom segments ordered by refresh date
    const combinedSegments = [];
    combinedSegments.push(...standardSegments);
    combinedSegments.push(...userSegments);

    return combinedSegments;
  }, [serverCustomerSegmentList, existingGroup]);

  useEffect(() => {
    if (!existingGroup || !customerGroupSegmentsList) {
      return;
    }

    const groupRule = getExistingGroupRule(existingGroup);
    const segments = getExistingGroupSegments(existingGroup);

    setIsGroupRuleAnd(groupRule === "AND");

    if (segments.length > 0) {
      const selectedSegments = customerGroupSegmentsList.filter(
        (customerGroupSegment) =>
          segments.some(
            (segment) =>
              (segment.customerGroupId === customerGroupSegment.groupId &&
                segment.segmentKey === customerGroupSegment.segmentKey) ||
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              (customerGroupSegment.groupId === undefined &&
                segment.standardSegmentationKey ===
                  customerGroupSegment.standardSegmentationId &&
                segment.segmentKey === customerGroupSegment.segmentKey)
          )
      );

      setGroupSegmentsRHS(selectedSegments);
      setSelectedGroupSegmentsLHS(
        selectedSegments.map((segment) =>
          createUniqueGroupSegmentId(segment, false)
        )
      );
    }
  }, [customerGroupSegmentsList, existingGroup]);

  const handleAddItems = useCallback(() => {
    const RHSSegments = customerGroupSegmentsList?.filter((cg) =>
      selectedGroupSegmentsLHS.includes(createUniqueGroupSegmentId(cg, false))
    );
    setGroupSegmentsRHS(RHSSegments ?? []);
  }, [customerGroupSegmentsList, selectedGroupSegmentsLHS]);

  const handleRemoveItems = useCallback(() => {
    setSelectedGroupSegmentsLHS(
      selectedGroupSegmentsLHS.filter(
        (cg) => !getLHSCorrespondingIDs(selectedGroupSegmentsRHS).includes(cg)
      )
    );
    setGroupSegmentsRHS(
      groupSegmentsRHS.filter(
        (cg) =>
          !selectedGroupSegmentsRHS.includes(
            createUniqueGroupSegmentId(cg, true)
          )
      )
    );
    setSelectedGroupSegmentsRHS([]);
  }, [groupSegmentsRHS, selectedGroupSegmentsLHS, selectedGroupSegmentsRHS]);

  const buildParameters = useCallback(() => {
    const groupRuleParameter: ParameterGroupSelectionDto = {
      name: "Rule",
      parameterSelections: [
        {
          id: ParameterId.CombinedGroupRule,
          name: "Group rule",
          selections: [
            {
              label: isGroupRuleAnd ? "AND" : "OR",
              value: isGroupRuleAnd ? "AND" : "OR",
            } as ListSelectionDto,
          ],
        },
      ],
    };

    const groupSegmentsParameter: ParameterGroupSelectionDto = {
      name: "Segments",
      parameterSelections: [
        {
          id: ParameterId.GroupSegments,
          name: "Group segments",
          selections: groupSegmentsRHS.map(
            (cg) =>
              ({
                customerGroupId: cg.groupId,
                segmentDisplayName: cg.name,
                segmentKey: cg.segmentKey,
                standardSegmentationKey: cg.standardSegmentationId,
              } as GroupSegmentSelectionDto)
          ),
        },
      ],
    };

    return [groupRuleParameter, groupSegmentsParameter];
  }, [groupSegmentsRHS, isGroupRuleAnd]);

  const requestHandler = useCallback(
    async (request: Promise<CustomerGroupDto>, successMessage: string) => {
      let isSuccess = false;
      try {
        const customerGroupDto = await request;

        if (!customerGroupDto.id) {
          throw new Error("Created group ID cannot be undefined or empty.");
        }

        isSuccess = true;
        navigate(
          getGroupListPath(
            divisionName,
            GroupType.Customer,
            customerGroupDto.id
          )
        );
        QbitEmitToast(
          <QbitToastMessage
            content={<p>{successMessage}</p>}
            heading={<h5>Customer group</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Notification}
          />
        );
      } finally {
        eventTrackingService.trackEvent(
          [TrackingComponent.Groups, TrackingComponent.CustomerGroup],
          TrackingEvent.Submitted,
          new GenericTrackingProperties({
            [GroupsTrackingProperty.GroupStatus]: isSuccess
              ? "Success"
              : "Failure",
            [GroupsTrackingProperty.GroupType]: CustomerGroupType.Combined,
          })
        );
      }
    },
    [divisionName, eventTrackingService, navigate]
  );

  const handleCreateGroup = useCallback(
    async (name: string) => {
      const parameters = buildParameters();
      const request = createParameters({
        divisionName,
        customerGroupType: CustomerGroupType.Combined,
        groupName: name,
        parameterGroupSelections: parameters,
      }).unwrap();
      await requestHandler(request, "Customer group creation in progress.");
    },
    [buildParameters, createParameters, requestHandler, divisionName]
  );

  const handleEditGroup = useCallback(
    async (name: string) => {
      if (!existingGroup) {
        throw new Error("Tried to edit a group that doesn't exist!");
      }

      const parameters = buildParameters();
      const request = updateParameters({
        groupId: existingGroup.id,
        divisionName,
        customerGroupType: CustomerGroupType.Combined,
        groupName: name,
        parameterGroupSelections: parameters,
      }).unwrap();
      await requestHandler(request, "Customer group refresh in progress.");
    },
    [
      buildParameters,
      updateParameters,
      requestHandler,
      divisionName,
      existingGroup,
    ]
  );

  const dividerButtons = useMemo(
    () => [
      {
        disabled: selectedGroupSegmentsLHS.every((cgLHS) =>
          groupSegmentsRHS
            .map((cgRHS) => createUniqueGroupSegmentId(cgRHS, false))
            .includes(cgLHS)
        ),
        handleClick: handleAddItems,
        icon: IconGlyph.ArrowsNext,
        text: "Add",
      },
      {
        disabled: groupSegmentsRHS.length === 0,
        handleClick: handleRemoveItems,
        icon: IconGlyph.ArrowsBack,
        text: "Remove",
      },
    ],
    [
      groupSegmentsRHS,
      handleAddItems,
      handleRemoveItems,
      selectedGroupSegmentsLHS,
    ]
  );

  return (
    <GroupEditor>
      <Header>
        <BackButton
          returnPath={
            existingGroup
              ? getGroupListPath(
                  divisionName,
                  GroupType.Customer,
                  existingGroup.id
                )
              : getGroupCreatorPath(divisionName, GroupType.Customer)
          }
          showExitDialog
        />
        <Title
          icon={icon}
          subtitle="Combined"
          title="Create a customer group"
        />
      </Header>
      <Content>
        <TwoPanelForm>
          <Heading
            subText="Select and move your existing customer group segments to be combined."
            text="Existing customer group segments"
          />
          <Details>
            <Text>
              <strong>Group rule</strong>
            </Text>
            <div className={styles.groupRule}>
              <Input>
                <RadioButton
                  checked={isGroupRuleAnd}
                  id="radioButtonAnd"
                  label="And"
                  name="radioButton"
                  onChange={() => setIsGroupRuleAnd(true)}
                />
              </Input>
            </div>
            <div className={styles.groupRule}>
              <Input>
                <RadioButton
                  checked={!isGroupRuleAnd}
                  id="radioButtonOr"
                  label="Or"
                  name="radioButton"
                  onChange={() => setIsGroupRuleAnd(false)}
                />
              </Input>
            </div>
          </Details>
          <LeftPanel>
            <div className={styles.groupEditorSearch} id={styles.lhsSearch}>
              <SearchBox
                adjustableWidth
                debounceTimeMs={500}
                enableDebounce
                onChange={onLhsSearch}
                placeholder="Search customer groups"
              />
            </div>
            <CombinedGroupSegmentsTable
              data={customerGroupSegmentsList ?? []}
              disableCheckboxes={selectedGroupSegmentsLHS.length >= 5}
              isProcessing={isCustomerSegmentListFetching}
              isSuccess={isCustomerSegmentListSuccess}
              movedGroupIds={groupSegmentsRHS.map((cg) =>
                createUniqueGroupSegmentId(cg, false)
              )}
              searchText={lhsSearchText}
              selectedGroupIds={selectedGroupSegmentsLHS}
              setSelectedGroupIds={setSelectedGroupSegmentsLHS}
            />
          </LeftPanel>
          <Divider
            buttons={dividerButtons}
            className={styles.combinedGroupDivider}
            showBar
          />
          <RightPanel>
            <div className={styles.groupEditorSearch} id={styles.rhsSearch}>
              <SearchBox
                adjustableWidth
                debounceTimeMs={500}
                enableDebounce
                onChange={onRhsSearch}
                placeholder="Search customer groups"
              />
            </div>
            <CombinedGroupSegmentsTable
              data={groupSegmentsRHS}
              isProcessing={isCustomerSegmentListFetching}
              isRHS
              isSuccess={isCustomerSegmentListSuccess}
              searchText={rhsSearchText}
              selectedGroupIds={selectedGroupSegmentsRHS}
              setSelectedGroupIds={setSelectedGroupSegmentsRHS}
            />
          </RightPanel>
        </TwoPanelForm>
      </Content>
      <Footer>
        <SaveButton
          disabled={groupSegmentsRHS.length < 2}
          groupTypeIcon={icon}
          groupTypeName="Combined"
          handleCreateGroup={handleCreateGroup}
          handleEditGroup={existingGroup ? handleEditGroup : undefined}
          initialGroupName={existingGroup?.name}
        />
      </Footer>
    </GroupEditor>
  );
};
