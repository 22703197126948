import { type GlobalParameterConfiguration } from "./globalParameterConfiguration";
import {
  type SingleSelectParameterConfiguration,
  type MultiSelectParameterConfiguration,
} from "./parameterConfiguration";

export type ReportMetricMetadataDto = {
  displayName: string;
  format: string;
  key: string;
};

export type PerformanceReportMetricMetadataDto = ReportMetricMetadataDto & {
  comparisonForKey?: string;
  isBarMetric: boolean;
  isBlackColour: boolean;
  isCompare: boolean;
  isCustomerMetric: boolean;
  isGrowthMetric: boolean;
};

export type TopAndBottomReportMetricMetadataDto = ReportMetricMetadataDto & {
  isCustomerMetric: boolean;
};

export type CustomerProfilingReportMetricMetadataDto =
  ReportMetricMetadataDto & {
    isGrowthMetric: boolean;
    threshold: number;
  };

export type PerformanceAggregateMetricMapping = {
  aggregates: string[];
  selectedMetric: string | null;
};

export type RangeReportLocationMetadata = {
  displayName: string;
  key: string;
};

export type StoreByStoreMetricMetadataDto = ReportMetricMetadataDto & {
  isGrowthMetric: boolean;
  metricName: string;
};

export type ReportConfigurationDtoBase = {
  disabled: boolean;
  disabledReason?: string;
};

export type LevelOfAnalysisLevel = {
  availableLevels: string[];
  shortName: string;
};

export type LevelOfAnalysisConfiguration = SingleSelectParameterConfiguration<
  string | null
> & {
  levels: LevelOfAnalysisLevel[];
};

export type PerformanceReportConfigurationDto = ReportConfigurationDtoBase & {
  aggregateMapping: PerformanceAggregateMetricMapping[];
  metricMetadata: PerformanceReportMetricMetadataDto[];
  metrics?: MultiSelectParameterConfiguration<string>;
};

export type KeyDriverTreeReportConfigurationDto = ReportConfigurationDtoBase & {
  benchmark?: SingleSelectParameterConfiguration<string>;
  comparisonPeriod?: SingleSelectParameterConfiguration<string>;
  disabledTransactionSources?: string[];
  maxFocalWeeks: number;
  maxStartDateAgeWeeks: number;
};

export type TopAndBottomReportConfigurationDto = ReportConfigurationDtoBase & {
  levelOfAnalysis?: LevelOfAnalysisConfiguration;
  maxFocalWeeks: number;
  metricBreakdown?: SingleSelectParameterConfiguration<string | null>;
  metricMetadata: TopAndBottomReportMetricMetadataDto[];
  metrics: MultiSelectParameterConfiguration<string>;
};

export type CustomerProfilingReportConfigurationDto =
  ReportConfigurationDtoBase & {
    disabledCompStoreValue?: string;
    disabledTransactionSources?: string[];
    metricMetadata: CustomerProfilingReportMetricMetadataDto[];
    metrics?: SingleSelectParameterConfiguration<string>;
    segmentations?: SingleSelectParameterConfiguration<string>;
  };

type RangeSorting = { column: string; direction: string };

export type RangeReportConfigurationDto = ReportConfigurationDtoBase & {
  defaultProductSorting: RangeSorting;
  defaultStoresSorting: RangeSorting;
  disabledHierarchyLevels: string[];
  disabledTransactionSources: string[];
  locationGroupingMetadata: RangeReportLocationMetadata[];
  metricMetadata: ReportMetricMetadataDto[];
};

export enum StoreByStoreSorting {
  Ascending = "asc",
  Descending = "desc",
}

export type StoreByStoreReportConfigurationDto = ReportConfigurationDtoBase & {
  defaultSorting: {
    column: string;
    direction: StoreByStoreSorting.Ascending | StoreByStoreSorting.Descending;
  };
  disabledCompStoreValue?: string;
  disabledTransactionSources?: string[];
  metricMetadata: StoreByStoreMetricMetadataDto[];
  metrics: MultiSelectParameterConfiguration<string>;
  metricsExcludedForGroups: string[];
  shareOf: SingleSelectParameterConfiguration<string>;
  supplierLatestWeekRestrictions: number[];
};

export type ReportConfigurationDto = {
  customerProfiling?: CustomerProfilingReportConfigurationDto;
  keyDriverTree?: KeyDriverTreeReportConfigurationDto;
  performance?: PerformanceReportConfigurationDto;
  range?: RangeReportConfigurationDto;
  storeByStore?: StoreByStoreReportConfigurationDto;
  topAndBottom?: TopAndBottomReportConfigurationDto;
};

export type ReportingConfigurationDto = {
  exportDisabled: boolean;
  globalParameters: GlobalParameterConfiguration;
  reports: ReportConfigurationDto;
};
