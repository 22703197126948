import {
  HierarchyItemType,
  HierarchyShortName,
  LocationHierarchy,
  ProductHierarchy,
} from "@quantium-enterprise/common-ui";
import classNames from "classnames";

// eslint-disable-next-line no-warning-comments
// TODO remove this once we have the uiShortName passed from the backend
export const HierarchyLevelToDisplayMap: Record<string, string> = {
  ARE: "ARE",
  BAN: "BAN",
  BAPS: "BPS",
  BR: "BRD",
  CMAREA: "CM",
  CT: "CAT",
  DGCAT: "CAT",
  DIV: "DIV",
  ADIV: "DIV",
  DGLDIV: "DIV",
  DL: "DEL",
  DT: "DEP",
  GMAREA: "GM",
  GRP: "GRP",
  HOTAREA: "HOT",
  NL: "NEW",
  PD: "PRD",
  PSWU: "PSU",
  PVD: "PVD",
  REG: "REG",
  SBBR: "SBR",
  SC: "SUB",
  SE: "SEG",
  SFM: "SFM",
  SM: "ALL",
  SPBR: "SPB",
  SSWU: "SSU",
  STA: "STA",
  STT: "STA",
  STR: "STR",
  TSWU: "TSU",
  VAR: "VAR",
};

// eslint-disable-next-line no-warning-comments
// TODO remove this once we have the uiShortName passed from the backend
export const hierarchyLevelDisplayLabel = (shortName: string) =>
  HierarchyLevelToDisplayMap[shortName]
    ? HierarchyLevelToDisplayMap[shortName]
    : shortName;

export type HierarchyLevelIconProps = {
  disabled?: boolean;
  isChild?: boolean;
  shortName: string;
  type:
    | HierarchyItemType
    | HierarchyShortName
    | LocationHierarchy
    | ProductHierarchy;
};

export const leafTypes = [
  HierarchyItemType.Attribute,
  HierarchyItemType.Leaf,
  HierarchyShortName.DeletedLine,
  HierarchyShortName.NewLine,
  ProductHierarchy.Product,
  LocationHierarchy.Store,
  LocationHierarchy.Metro,
];

export const isLeaf = (
  type:
    | HierarchyItemType
    | HierarchyShortName
    | LocationHierarchy
    | ProductHierarchy
) => leafTypes.includes(type);

export const HierarchyLevelIcon = ({
  disabled = false,
  isChild = false,
  type,
  shortName,
}: HierarchyLevelIconProps) => {
  const displayCode = hierarchyLevelDisplayLabel(shortName);
  const isChildBadge = isChild || isLeaf(type);

  return (
    <svg
      className={classNames("hierarchy-level-icon", displayCode.toLowerCase(), {
        "hierarchy-level-icon-disabled": disabled,
        "is-attribute": type === HierarchyItemType.Attribute,
      })}
      data-testid="hierarchy-level-icon"
      fill="none"
      height="16"
      viewBox="0 0 28 16"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isChildBadge ? (
        <rect
          className="hierarchy-level-icon-badge"
          data-testid="child-badge"
          height="16"
          rx="3"
          width="28"
        />
      ) : (
        <path
          className="hierarchy-level-icon-badge"
          d="M11.2 0L14 2H25.2C25.9426 2 26.6548 2.09436 27.1799 2.44969C27.705 2.80502 28 3.28696 28 3.78947V14.1053C28 14.6078 27.705 15.0897 27.1799 15.445C26.6548 15.8004 25.9426 16 25.2 16H2.8C1.246 16 1.90735e-06 15.1474 1.90735e-06 14.1053V1.89474C1.90735e-06 0.843158 1.246 0 2.8 0H11.2Z"
          data-testid="parent-badge"
        />
      )}

      <text className="hierarchy-level-icon-text" x="14" y="11.65">
        {displayCode}
      </text>
    </svg>
  );
};
