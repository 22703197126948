import {
  Nav,
  NavButton,
  NavFontWeight,
  NavSize,
  NavVariant,
} from "@qbit/react";
import { type TransactionSource } from "@quantium-enterprise/common-ui";
import {
  HierarchyItemType,
  HierarchyShortName,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import WarningBanner from "components-ui/src/error-banner/WarningBanner";
import { TransactionSourceIcon } from "components-ui/src/icons";
import { useEffect, useMemo, useState } from "react";
import { useReportTabState } from "report-tabs-ui";
import Zod from "zod";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { type RangeReportConfigurationDto } from "../../fast-report/api/reportConfigurationDto";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../ReportPanel.module.css";
import { useDisplayEntitlements, useGetFeaturesForFocalItem } from "../hooks";
import ProductsRangeReport from "./ProductsRangeReport";
import StoresRangeReport from "./StoresRangeReport";

export const RANGE_REPORT_FEATURE_NAME = "range-report";

enum Tabs {
  Products = 0,
  Stores = 1,
}

const TabsValueSchema = Zod.number();
type TabsValue = Zod.infer<typeof TabsValueSchema>;

const RangeReportPanel = () => {
  const division = useDivision();
  const activeItem = useActiveItem();
  const [globalParameters] = useGlobalParameters();

  const [, setActiveTab] = useFastReportingParameterState(
    Parameter.RangeActiveTab,
    TabsValueSchema,
    () => undefined
  );

  const [activeTab] = useReportTabState<TabsValue>(
    Parameter.RangeActiveTab,
    TabsValueSchema
  );

  useEffect(() => {
    if (activeTab === undefined) {
      if (activeItem?.type === HierarchyItemType.Leaf) {
        setActiveTab(Tabs.Stores);
      } else {
        setActiveTab(Tabs.Products);
      }
    }
  }, [activeItem, activeTab, setActiveTab]);

  const { configuration } = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.range,
        state,
      }),
      skip: !division.name,
    }
  );

  const reportEnabled = configuration && !configuration.disabled && activeItem;

  const hierarchyLevelIsValid = () => {
    if (!configuration || !activeItem) {
      return false;
    }

    if (activeItem.type === HierarchyShortName.ProductGroup) {
      return true;
    }

    return !configuration.disabledHierarchyLevels.includes(
      activeItem.shortName
    );
  };

  const displayEntitlements = useDisplayEntitlements();
  const [transactionSource, setTransactionSource] =
    useState<TransactionSource | null>(null);

  const rangeReportTitle = useMemo(
    () => (
      <span className={styles.reportTitle}>
        Range
        {displayEntitlements && transactionSource && (
          <TransactionSourceIcon
            availableTransactionSources={division.transactionSources}
            transactionSource={transactionSource}
          />
        )}
      </span>
    ),
    [displayEntitlements, transactionSource, division.transactionSources]
  );

  const features = useGetFeaturesForFocalItem(division, activeItem);

  const getRangeReportContent = (config: RangeReportConfigurationDto) => {
    if (!hierarchyLevelIsValid()) {
      return (
        <div style={{ paddingTop: "var(--qbit-space-xsmall)" }}>
          <WarningBanner text="This report is only available when viewing category or lower." />
        </div>
      );
    }

    if (
      globalParameters.transactionSource &&
      config.disabledTransactionSources.includes(
        globalParameters.transactionSource
      )
    ) {
      return (
        <WarningBanner
          data-cy="RangeReportTransactionSourceWarningBanner"
          text={`This report cannot be run on ${globalParameters.transactionSource} data.`}
        />
      );
    }

    return (
      <>
        <div style={{ paddingBottom: "var(--qbit-space-medium)" }}>
          <Nav
            activeIndex={activeTab}
            fontWeight={NavFontWeight.Regular}
            size={NavSize.Small}
            variant={NavVariant.Tab}
          >
            <NavButton onClick={() => setActiveTab(Tabs.Products)}>
              Products
            </NavButton>
            <NavButton onClick={() => setActiveTab(Tabs.Stores)}>
              Stores
            </NavButton>
          </Nav>
        </div>
        <ErrorBoundary>
          {activeTab === Tabs.Products ? (
            <ProductsRangeReport
              configuration={config}
              onTransactionSourceChanged={setTransactionSource}
            />
          ) : (
            <StoresRangeReport
              configuration={config}
              onTransactionSourceChanged={setTransactionSource}
            />
          )}
        </ErrorBoundary>
      </>
    );
  };

  return (
    <>
      {reportEnabled &&
        features?.find((feature) => feature === RANGE_REPORT_FEATURE_NAME) && (
          <div data-cy="RangeReportPanel">
            <Accordion
              className={styles.reportAccordion}
              subtitle="Identify products that are ranged in stores but haven't been scanned (RNS)."
              title={rangeReportTitle}
            >
              {getRangeReportContent(configuration)}
            </Accordion>
          </div>
        )}
    </>
  );
};

export default RangeReportPanel;
