import {
  FormBlock,
  FormBlockType,
  FormInputHeight,
  Input,
  Select,
  SelectOption,
} from "@qbit/react";
import { ReportType, TransactionSource } from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import { type ToggleButton } from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { ButtonToggleGroup } from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import {
  defaultOptions,
  HighchartsReact,
  TooltipHTML,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { type ChartProps, type Series } from "components-ui/src/charts/models";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { type SeriesColumnOptions, type SeriesOptionsType } from "highcharts";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "reports-ui/src/store";
import {
  onShowSegmentationChartDataLabelsChange,
  selectChannel,
  selectLocationHierarchy,
  selectPromotion,
  selectSegmentation,
  selectTime,
  selectTimePeriodLength,
  selectDataset,
  selectCompStore,
} from "../../services/key-measure-trends-slice";
import styles from "./SegmentationBreakdownChart.module.css";
import { SegmentationBreakdownFeatureFlags } from "./constants";
import { csvTransformation } from "./csvTransformation";
import { mapToSegmentationBreakdownSeries } from "./utils";

type SegmentationBreakdownChartProps = ChartProps & {
  primaryMetricUIName?: string;
  reportName: string;
  secondaryMetricUIName?: string;
};

const DEFAULT_VALUE_STRING: string[] = [];
const DEFAULT_VALUE_SERIES: Series[] = [];

const SegmentationBreakdownChart = ({
  dates = DEFAULT_VALUE_STRING,
  primaryMetric,
  primaryMetricUIName,
  secondaryMetric,
  secondaryMetricUIName,
  series = DEFAULT_VALUE_SERIES,
  reportName,
}: SegmentationBreakdownChartProps) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState<string[]>();
  const [selectedMetricIndex, setSelectedMetricIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState<string>();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[SegmentationBreakdownFeatureFlags.ReportExport] ?? false;

  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const channel = useSelector(selectChannel);
  const compStore = useSelector(selectCompStore);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const dataset = useSelector(selectDataset);

  const {
    segmentationBreakdownResponse,
    reportParameters,
    showSegmentationChartDataLabels,
  } = useSelector((state: RootState) => ({
    segmentationBreakdownResponse:
      state.keyMeasureTrends.segmentationBreakdownResponse,
    reportParameters: state.keyMeasureTrends.reportParameters,
    showSegmentationChartDataLabels:
      state.keyMeasureTrends.showSegmentationChartDataLabels,
  }));

  const metrics = [
    {
      displayName: primaryMetricUIName,
      metric: primaryMetric,
    },
    {
      displayName: secondaryMetricUIName,
      metric: secondaryMetric,
    },
  ];
  const selectedMetric = metrics[selectedMetricIndex];
  const dataLabelsOptions = [
    { isSelected: showSegmentationChartDataLabels, value: "segmentation" },
  ];

  const toggleButtons: Array<ToggleButton<number>> = metrics.map(
    (x, index) => ({
      displayText: x.displayName ?? x.metric?.name,
      id: index,
    })
  );

  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Segmentation_Breakdown_Chart_${timePeriodLength}_${locationHierarchy.name}`
      ),
    [timePeriodLength, locationHierarchy]
  );

  const isSegmentationDisabled = useMemo(
    () => dataset.value === TransactionSource.Total && segmentation.label,
    [dataset, segmentation]
  );

  const parameterSummary = useMemo(
    () => [
      { name: "Focal item", value: selectedItem ?? "" },
      {
        name: "Time",
        value: time,
      },
      ...(dataset.label
        ? [{ name: "Transaction set", value: dataset.label }]
        : []),
      {
        name: "Metric",
        value: selectedMetric.displayName ?? "",
      },
      { name: "Channel", value: channel.label },
      {
        name: "Promotion",
        value: promotion.label,
      },
      {
        name: "Segmentation",
        value: isSegmentationDisabled ? "N/A" : segmentation.label,
      },
      { name: "Comp store", value: compStore.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(locationHierarchy.shortName)}) ${
          locationHierarchy.name
        }`,
      },
    ],
    [
      time,
      dataset,
      channel,
      compStore,
      promotion,
      segmentation,
      locationHierarchy,
      selectedItem,
      selectedMetric,
      isSegmentationDisabled,
    ]
  );
  const chartCsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        segmentationBreakdownResponse,
        currencySymbol,
        selectedMetric.metric?.name,
        selectedItem
      ),
    [
      segmentationBreakdownResponse,
      currencySymbol,
      selectedMetric,
      selectedItem,
    ]
  );

  useEffect(() => {
    const localItems = [...new Set(series.map((item) => item.name))];
    setItems(localItems);
    setSelectedItem(localItems[0]);
  }, [series]);

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const seriesData = mapToSegmentationBreakdownSeries(
    series,
    selectedMetric.metric,
    selectedItem
  );

  const options: HighchartsReactProps = {
    ...defaultOptions,
    legend: {
      ...defaultOptions.legend,
      symbolRadius: 0,
      title: {
        style: {
          color: "var(--qbit-colour-text-secondary)",
          fontSize: "0.875rem",
        },
        text: `Customer segments - (${selectedItem})`,
      },
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        stacking: "normal",
        events: {
          legendItemClick: (event) => legendItemClick(event.target.index),
        },
      },
    },
    series: seriesData.map(
      (
        seriesOption: SeriesColumnOptions,
        index: number
      ): SeriesOptionsType => ({
        ...seriesOption,
        visible: !hiddenSeries.includes(index),
      })
    ),
    tooltip: {
      ...defaultOptions.tooltip,
      positioner(labelWidth, _, point) {
        return {
          x:
            point.plotX - 0.7 * labelWidth > 0
              ? point.plotX - 0.7 * labelWidth
              : point.plotX + 0.85 * labelWidth,
          y: 100,
        };
      },
      ReactFormatter: (data) => {
        const tooltipData = data.points?.map((pt) => ({
          color: String(pt.color),
          name: pt.series.name,
          value: selectedMetric.metric?.formatter(pt.y),
        }));
        return TooltipHTML(tooltipData, data.x, selectedItem);
      },
    },
    xAxis: {
      categories: dates,
      crosshair: {
        color: "var(--qbit-colour-chrome-background)",
        zIndex: 0,
      },
      labels: {
        style: {
          color: "var(--qbit-colour-text-secondary)",
        },
      },
      title: {
        style: {
          color: "var(--qbit-colour-text-primary)",
        },
      },
      tickmarkPlacement: "on",
      tickWidth: 1,
    },
    yAxis: {
      ...defaultOptions.yAxis,
      labels: {
        formatter({ value }) {
          return selectedMetric.metric?.formatter(value, false, "", true) ?? "";
        },
        style: {
          color: "var(--qbit-colour-text-secondary)",
          fontSize: "0.75rem",
        },
      },
      title: {
        style: {
          color: "var(--qbit-colour-text-primary)",
          fontWeight: "var(--qbit-font-weight-medium)",
        },
        text: selectedMetric.displayName ?? selectedMetric.metric?.name,
      },
    },
  };

  return (
    <>
      <div className={styles.chartOptionsContainer}>
        {items && items.length > 1 && (
          <FormBlock blockType={FormBlockType.Select} className={styles.margin}>
            <Input>
              <Select
                className={styles.dropdown}
                height={FormInputHeight.XSmall}
                id="items"
                onChange={(event) => setSelectedItem(event.target.value)}
              >
                {items.map((item) => (
                  <SelectOption key={item} text={item} value={item} />
                ))}
              </Select>
            </Input>
          </FormBlock>
        )}
        {secondaryMetric && (
          <ButtonToggleGroup
            buttonSelected={selectedMetricIndex}
            buttons={toggleButtons}
            setButtonSelected={setSelectedMetricIndex}
          />
        )}

        {primaryMetric && (
          <div className={styles.container}>
            <ChartOptions
              dataLabelsOptions={dataLabelsOptions}
              downloadWizardOptions={{
                chartOptions: options,
                chartTitle: `Segmentation breakdown - ${reportName}`,
                reportIcon: <ReportIcon type={ReportType.KeyMeasureTrends} />,
                reportTitle: reportName,
              }}
              filename={exportFilename}
              getCSVData={chartCsvTransformationCallback}
              getElementToExport={() => chartContainerRef.current}
              isFeatureEnabled={isExportEnabled}
              localParameters={parameterSummary}
              reportParameters={reportParameters}
              toggleDataLabels={() =>
                dispatch(onShowSegmentationChartDataLabelsChange())
              }
            />
          </div>
        )}
      </div>

      <ChartFooterWrapper
        height="400px"
        parameters={parameterSummary}
        ref={chartContainerRef}
      >
        <HighchartsReact options={options} />
      </ChartFooterWrapper>
    </>
  );
};

export { SegmentationBreakdownChart };
