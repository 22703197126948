import { Group, Item, ItemHalign } from "@qbit/react";
import { FeatureFlag } from "@quantium-enterprise/common-ui";
import {
  useDivision,
  useFlags,
  useModuleAccessProtection,
} from "@quantium-enterprise/hooks-ui";
import { AppFooter } from "components-ui/src/footer/AppFooter";
import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { GroupInfoPanel } from "../../components/group-info-panel/GroupInfoPanel";
import { CustomerGroupListTable } from "../../components/group-list-table/CustomerGroupListTable/CustomerGroupListTable";
import { DeleteCustomerGroupModalModal } from "../../components/modal/DeleteCustomerGroupModal";
import { ShareCustomerGroupModal } from "../../components/modal/ShareCustomerGroupModal";
import { SegmentLibraryTable } from "../../components/segment-library-table/SegmentLibraryTable";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import {
  clearSelectedSegments,
  resetAllScrollStates,
  resetDeleteCustomerGroups,
  resetRenameState,
  resetSelectedCustomerGroups,
  setFocalCustomerGroup,
  setFocalSegment,
} from "../../states/group-list-slice";
import { CustomerGroupManagementSearchAndActions } from "./CustomerGroupManagementSearchAndActions";
import { CustomerGroupManagementSegmentLibraryTabs } from "./CustomerGroupManagementSegmentLibraryTabs";
import styles from "./GroupManagement.module.css";
import { GroupManagementHeader } from "./GroupManagementHeader";
import { GroupManagementTabs } from "./GroupManagementTabs";

type CustomerGroupManagementProps = {
  customerGroupTab: CustomerGroupTab;
};

export const CustomerGroupManagement = ({
  customerGroupTab,
}: CustomerGroupManagementProps) => {
  useModuleAccessProtection(
    (moduleAccess) => moduleAccess.groups.customerGroups
  );

  const dispatch = useDispatch();
  const division = useDivision();
  const flags = useFlags();

  const showSegmentBuilder =
    flags[FeatureFlag.SegmentBuilder] &&
    division.moduleAccess.groups.customerGroups.segmentBuilder.hasAccess;

  const Table = useCallback(() => {
    switch (customerGroupTab) {
      case CustomerGroupTab.CustomerGroups:
        return <CustomerGroupListTable />;
      case CustomerGroupTab.SegmentLibrary:
        return <SegmentLibraryTable />;
      default:
        return <> </>;
    }
  }, [customerGroupTab]);

  useEffect(() => {
    dispatch(setFocalCustomerGroup(undefined));
    dispatch(setFocalSegment(undefined));
    dispatch(clearSelectedSegments());
    dispatch(resetDeleteCustomerGroups());
    dispatch(resetSelectedCustomerGroups());
    dispatch(resetAllScrollStates());
    dispatch(resetRenameState());
  }, [customerGroupTab, dispatch]);

  return (
    <div className={styles.groupListContainer}>
      <GroupInfoPanel customerGroupTab={customerGroupTab}>
        <div
          className={
            showSegmentBuilder ? styles.customerGroupsPage : styles.page
          }
        >
          <GroupManagementHeader />
          <GroupManagementTabs />
          <CustomerGroupManagementSearchAndActions
            customerGroupTab={customerGroupTab}
          />
          {showSegmentBuilder && (
            <CustomerGroupManagementSegmentLibraryTabs
              customerGroupTab={customerGroupTab}
            />
          )}
          <Group className={styles.tableContainer}>
            <Item
              className={styles.customerGroupTable}
              halign={ItemHalign.Left}
            >
              <ErrorBoundary>
                <Table />
              </ErrorBoundary>
            </Item>
          </Group>
        </div>
      </GroupInfoPanel>
      <AppFooter />
      <DeleteCustomerGroupModalModal customerGroupTab={customerGroupTab} />
      <ShareCustomerGroupModal />
    </div>
  );
};
