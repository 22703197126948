import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isSegmentationGroupParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationGroupSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type TimeOfDayDayOfWeekLocalSelections } from "../services/time-of-day-day-of-week-slice";

export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): TimeOfDayDayOfWeekLocalSelections => {
  const selections: TimeOfDayDayOfWeekLocalSelections = {
    Channel: {
      value: "",
      label: "",
    },
    Metric: {
      value: "",
      label: "",
    },
    LocationHierarchy: {
      code: "",
      depth: -1,
      isBenchmark: false,
      isLeaf: false,
      name: "",
      nodeNumber: -1,
      shortName: "",
      isDefault: false,
    },
    Promotion: {
      value: "",
      label: "",
    },
    Segmentation: {
      value: "",
      label: "",
    },
    FocusPeriod: "",
    TimePeriodLength: "",
    CheckoutType: {
      label: "",
      value: "",
    },
  };

  const handleTimeParameter = (parameter: SidePanelParameter) => {
    if (isTimePeriodParameter(parameter)) {
      const { time, timePeriodLength } = getTimeDefaultSelection(parameter);
      selections.FocusPeriod = time;
      selections.TimePeriodLength = timePeriodLength;
    }
  };

  const handleMetricParameter = (parameter: SidePanelParameter) => {
    if (isSingleSelectionParameter(parameter)) {
      const selection = getSingleSelectDefaultSelection(parameter);
      if (selection) {
        selections.Metric.label = selection.label;
        selections.Metric.value = selection.value;
      }
    }
  };

  const handleChannelParameter = (parameter: SidePanelParameter) => {
    if (isSingleSelectionParameter(parameter)) {
      const selection = getSingleSelectDefaultSelection(parameter);
      if (selection) {
        selections.Channel.label = selection.label;
        selections.Channel.value = selection.value;
      }
    }
  };

  const handlePromotionParameter = (parameter: SidePanelParameter) => {
    if (isSingleSelectionParameter(parameter)) {
      const selection = getSingleSelectDefaultSelection(parameter);
      if (selection) {
        selections.Promotion.label = selection.label;
        selections.Promotion.value = selection.value;
      }
    }
  };

  const handleCheckoutTypeParameter = (parameter: SidePanelParameter) => {
    if (isSingleSelectionParameter(parameter)) {
      const selection = getSingleSelectDefaultSelection(parameter);
      if (selection && selections.CheckoutType) {
        selections.CheckoutType.label = selection.label;
        selections.CheckoutType.value = selection.value;
      }
    }
  };

  const handleSegmentationParameter = (parameter: SidePanelParameter) => {
    if (isSegmentationGroupParameter(parameter)) {
      const selection = getSegmentationGroupSelectDefaultSelection(parameter);
      if (selection) {
        selections.Segmentation.label = selection.name;
        selections.Segmentation.value = selection.id;
      }
    }
  };

  const handleLocationHierarchyParameter = (parameter: SidePanelParameter) => {
    if (isLocationSelectionParameter(parameter)) {
      selections.LocationHierarchy = getLocationSelectDefault(parameter);
    }
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        handleTimeParameter(parameter);
        break;
      case LocalParameters.Metric:
        handleMetricParameter(parameter);
        break;
      case LocalParameters.Channel:
        handleChannelParameter(parameter);
        break;
      case LocalParameters.Promotion:
        handlePromotionParameter(parameter);
        break;
      case LocalParameters.CheckoutType:
        handleCheckoutTypeParameter(parameter);
        break;
      case LocalParameters.Segmentation:
        handleSegmentationParameter(parameter);
        break;
      case LocalParameters.LocationHierarchy:
        handleLocationHierarchyParameter(parameter);
        break;
      default:
        break;
    }
  }

  return selections;
};
