import { type TransactionSource } from "@quantium-enterprise/common-ui";
import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { format } from "date-fns";
import {
  type PerformanceChartSeries,
  type PerformanceChartYAxisOptions,
} from "./PerformanceChart";
import PerformanceChart from "./PerformanceChart";
import styles from "./PerformanceReport.module.css";
import { type PerformanceReportMetric } from "./PerformanceReportMetric";

const PROMO_WEEK_DATE_FORMAT = "dd MMM yy";
const MAX_Y_AXIS = 3;

export type PerformanceReportMeasureResult = {
  measureResults: {
    [measureKey: string]:
      | { [promotionWeek: string]: number | undefined }
      | undefined;
  };
  promotionWeekDates: string[];
  transactionSource: TransactionSource;
};

export type PerformanceReportProps = {
  id: string;
  measure?: PerformanceReportMeasureResult;
  selectedMetrics: PerformanceReportMetric[];
  showDataLabels: boolean;
};

export const getYAxes = (
  selectedMetrics: PerformanceReportMetric[],
  yAxis: PerformanceChartYAxisOptions[],
  formatter: ReturnType<typeof useFormatter>
) => {
  // Map yaxis, we need to iterate this multiple times because if a comparison metric is first in the list
  // we would not properly map it to its compare metric when mapping the series
  for (const metric of selectedMetrics) {
    // Ensure we exclude the comparison metric from yAxis if metric its compared against is selected
    const isComparisonMetricSelected =
      metric.comparisonForKey &&
      selectedMetrics.some((sm) => sm.key === metric.comparisonForKey);

    // Exclude additional growth metrics if a growth metric is already in the yAxis
    const isGrowthMetricInYAxis =
      metric.isGrowthMetric && yAxis.some((ya) => ya.isGrowth);

    if (isComparisonMetricSelected || isGrowthMetricInYAxis) {
      continue;
    }

    if (yAxis.length < MAX_Y_AXIS) {
      yAxis.push({
        displayName: metric.displayName,
        format: metric.format,
        formatter: formatter(metric.format),
        id: metric.key,
        isGrowth: metric.isGrowthMetric,
      });
    } else if (metric.isGrowthMetric && !yAxis.some((ya) => ya.isGrowth)) {
      // Ensure there is a growth axis if a growth metric is selected
      yAxis[MAX_Y_AXIS - 1] = {
        displayName: metric.displayName,
        format: metric.format,
        formatter: formatter(metric.format),
        id: metric.key,
        isGrowth: metric.isGrowthMetric,
      };
      break;
    }
  }
};

// eslint-disable-next-line complexity -- Feels like the arrow function complexity rule shouldn't apply to components
export const PerformanceReport = ({
  selectedMetrics,
  measure,
  id,
  showDataLabels,
}: PerformanceReportProps) => {
  const formatter = useFormatter();

  let barSeriesMetricId: string | undefined;
  let comparisonBarSeriesMetricId: string | undefined;
  const yAxis: PerformanceChartYAxisOptions[] = [];
  const series: PerformanceChartSeries[] = [];
  const dates: string[] = [];

  const setBarSeriesAndComparisonBarSeriesMetricIds = () => {
    // Find a bar metric with a comparison metric (eg. sales)
    barSeriesMetricId = selectedMetrics.find(
      (sm) => sm.isBarMetric && sm.isCompare
    )?.key;

    // if we have one, the comparison metric can only be the one related to the main metric
    // e.g sales LY
    // if we don't have a main compare metric we can set the comparison metric to the first possible one.
    // e.g Units LY
    if (barSeriesMetricId) {
      comparisonBarSeriesMetricId = selectedMetrics.find(
        (sm) => sm.isBarMetric && sm.comparisonForKey === barSeriesMetricId
      )?.key;
    } else {
      comparisonBarSeriesMetricId = selectedMetrics.find(
        (sm) => sm.isBarMetric && sm.comparisonForKey
      )?.key;
    }
  };

  const getYAxisId = (metric: PerformanceReportMetric) => {
    const comparedMetric = selectedMetrics.find(
      (sm) => sm.key === metric.comparisonForKey
    );

    // Check if compared metric has been selected and is in the yaxis
    if (comparedMetric && yAxis.some((ya) => ya.id === comparedMetric.key)) {
      return comparedMetric.key;
    }

    if (metric.isGrowthMetric && yAxis.some((ya) => ya.isGrowth)) {
      return yAxis.find((ya) => ya.isGrowth)?.id;
    }

    const nonGrowthAxes = yAxis.filter((ya) => !ya.isGrowth);

    // Fallback if the metric is in the yaxis,
    // if not try and find yaxis with same format
    // else return undefined
    // series cannot have a yaxis that does not exist
    return (
      yAxis.find((ya) => ya.id === metric.key)?.id ??
      nonGrowthAxes.find((ya) => ya.format === metric.format)?.id ??
      nonGrowthAxes[0].id
    );
  };

  if (measure) {
    setBarSeriesAndComparisonBarSeriesMetricIds();

    getYAxes(selectedMetrics, yAxis, formatter);

    // Map series
    for (const metric of selectedMetrics) {
      series.push({
        data: measure.promotionWeekDates.map(
          (promoWeek) => measure.measureResults[metric.key]?.[promoWeek] ?? null
        ),
        displayName: metric.displayName,
        formatter: formatter(metric.format),
        id: metric.key,
        isBlackColour: metric.isBlackColour,
        yAxisId: getYAxisId(metric),
        showDataLabels,
      } as PerformanceChartSeries);
    }

    // Map dates
    for (const promotionWeekDate of measure.promotionWeekDates) {
      dates.push(format(new Date(promotionWeekDate), PROMO_WEEK_DATE_FORMAT));
    }
  }

  return (
    <div className={styles.container}>
      <PerformanceChart
        barSeriesId={barSeriesMetricId}
        comparisonBarSeriesId={comparisonBarSeriesMetricId}
        dates={dates}
        id={id}
        series={series}
        yAxis={yAxis}
      />
    </div>
  );
};

export default PerformanceReport;
