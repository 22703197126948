import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isLocationSelectionParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
  isTimePeriodParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type GainsAndLossLocalSelections } from "../services/gains-and-loss-slice";
import { initialState } from "../services/gains-and-loss-slice";

export const getLocalSelections = (
  localParameters: SidePanelParameter[]
): GainsAndLossLocalSelections => {
  let localSelections: GainsAndLossLocalSelections =
    initialState.persistedSelections.localSelections;

  for (const parameter of localParameters) {
    if (
      parameter.id === LocalParameters.Channel &&
      isSingleSelectionParameter(parameter)
    ) {
      const defaultSelection =
        getSingleSelectDefaultSelection(parameter) ?? ({} as PanelOption);

      localSelections = {
        ...localSelections,
        channel: {
          label: defaultSelection.label.toString(),
          value: defaultSelection.value.toString(),
        },
      };
    }

    if (
      parameter.id === LocalParameters.CompStore &&
      isSingleSelectionParameter(parameter)
    ) {
      const defaultSelection =
        getSingleSelectDefaultSelection(parameter) ?? ({} as PanelOption);

      localSelections = {
        ...localSelections,
        compStore: {
          label: defaultSelection.label.toString(),
          value: defaultSelection.value.toString(),
        },
      };
    } else if (
      parameter.id === LocalParameters.LocationHierarchy &&
      isLocationSelectionParameter(parameter)
    ) {
      localSelections = {
        ...localSelections,
        location: {
          ...getLocationSelectDefault(parameter),
        },
      };
    } else if (
      parameter.id === LocalParameters.MetricSet &&
      isSingleSelectionParameter(parameter)
    ) {
      const defaultSelection =
        getSingleSelectDefaultSelection(parameter) ?? ({} as PanelOption);

      localSelections = {
        ...localSelections,
        metricSet: {
          label: defaultSelection.label.toString(),
          value: defaultSelection.value.toString(),
        },
      };
    } else if (
      parameter.id === LocalParameters.Segmentation &&
      isSegmentationParameter(parameter)
    ) {
      const defaultSelection =
        getSingleSelectDefaultSelection(parameter) ?? ({} as PanelOption);

      localSelections = {
        ...localSelections,
        segmentation: {
          label: defaultSelection.label.toString(),
          value: defaultSelection.value.toString(),
        },
        segment: {
          value: "All",
          label: "All",
        },
      };
    } else if (isTimePeriodParameter(parameter)) {
      const timeSelection = getTimeDefaultSelection(parameter);
      localSelections = {
        ...localSelections,
        time: timeSelection.time,
        timePeriodLength: timeSelection.timePeriodLength,
      };
    }
  }

  return localSelections;
};
