import { type TextPanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PanelType } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { StoreFormatFilterContent } from "./StoreFormatFilterContent";
import { StoreFormatFilterSummary } from "./StoreFormatFilterSummary";

export const getStoreFormatPanel = () => {
  const panel = {
    content: <StoreFormatFilterContent />,
    id: "FastReportingStoreFormatSelect",
    label: "Store format",
    panelType: PanelType.TEXT,
    summary: <StoreFormatFilterSummary />,
  } as TextPanel;

  return [panel];
};
