import {
  FormInputHeight,
  FormInputWidth,
  Select,
  SelectOption,
  Toggle,
} from "@qbit/react";
import {
  ddLog,
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
  formatNumberDate,
} from "@quantium-enterprise/common-ui";
import {
  useFlags,
  type DeferredFormatFunction,
} from "@quantium-enterprise/hooks-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type GroupColumnDef,
  type ExpandedState,
  type CellContext,
  type VisibilityState,
  createColumnHelper,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import {
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { EMPTY_NODE_NUMBER } from "../../../common/constants";
import { type RootState } from "../../../store";
import { KeyDriversOverTimeFeatureFlags } from "../../constants/key-drivers-over-time-feature-flags";
import {
  type KeyDriversOverTimeTableMeasure,
  type KeyDriversOverTimeTableRow,
} from "../../models/key-drivers-over-time-table-models";
import {
  onFocalItemChange,
  onSearchChange,
  selectFocalItem,
  selectHierarchySliceHypercubeId,
  selectInitialDataReceived,
  selectLocalParameterSelections,
  selectLocalParametersInitialised,
  selectRowSelection,
  selectTableMetaData,
  selectTableRows,
  selectSearch,
  selectSearchResultsAreShown,
} from "../../services/key-drivers-over-time-slice";
import {
  useLazyGetTableDataQuery,
  useLazyGetChildrenTableDataQuery,
  useLazySearchTableDataQuery,
  type FetchChildrenTableRequest,
  useLazyGetExportDataQuery,
  useLazyGetInitialTableQuery,
} from "../../services/key-drivers-over-time-table-data-api-slice";
import getUserSelections from "../../utils/getUserSelections";
import styles from "./KeyDriversOverTimeTopDrawer.module.scss";
import { getDescendantRows, getExpandedRows } from "./utils";

const columnMetricNaming = (metricName: string, baseMeasureName: string) => {
  if (metricName === "Latest") {
    return baseMeasureName;
  }

  return metricName;
};

const measuresColumnDefs = (
  measureMetaData: KeyDriversOverTimeTableMeasure[],
  promoWeekId: number,
  promoWeekIndex: number,
  formatter: DeferredFormatFunction
) =>
  measureMetaData.map((metric) => ({
    accessorFn: (row: KeyDriversOverTimeTableRow) => {
      const data = row.data[promoWeekIndex].data.find(
        (item) => metric.measureType === item.measureType
      );

      if (data?.measureValue) {
        return data.measureValue;
      }

      return "";
    },
    cell: ({ getValue }: CellContext<KeyDriversOverTimeTableRow, number>) =>
      ValueCell({
        // sentiment colouring for sales change and contribution
        hasSentiment:
          metric.measureType === "% change" ||
          metric.measureType === "Contribution",
        formatter: formatter(metric.format),
        value: getValue(),
      }),
    // Override the "Latest column with the name of the baseMeasure"
    header: columnMetricNaming(metric.measureType, metric.baseMeasure),
    id: `${promoWeekId}-${metric.measureType}`,
  }));

export type KeyDriversOverTimeTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const KeyDriversOverTimeTopDrawer = ({
  renameReport,
  reportType,
  reportName,
  eventTrackingService,
}: KeyDriversOverTimeTopDrawerProps) => {
  const { name: divisionName } = useDivision();
  const { id: reportId } = useParams();
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[KeyDriversOverTimeFeatureFlags.ReportExport] ?? false;

  const focalItem = useSelector(selectFocalItem);
  const initialDataReceived = useSelector(selectInitialDataReceived);
  const localParameterSelections = useSelector(selectLocalParameterSelections);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const hierarchySliceHypercubeId = useSelector(
    selectHierarchySliceHypercubeId
  );
  const rowSelection = useSelector(selectRowSelection);
  const tableMetaData = useSelector(selectTableMetaData);
  const tableRows = useSelector(selectTableRows);
  const search = useSelector(selectSearch);
  const searchResultsAreShown = useSelector(selectSearchResultsAreShown);

  const { focalItemTableInitialised } = useSelector((state: RootState) => ({
    focalItemTableInitialised:
      state.keyDriversOverTime.focalItemTableInitialised,
  }));

  // Track the expansion state of the table
  const [itemsToRefetch, setItemsToRefetch] = useState<number[]>([]);
  const [expandState, setExpandState] = useState<ExpandedState>(true);

  const expandRow = useCallback(
    (row: KeyDriversOverTimeTableRow) => {
      setItemsToRefetch([...itemsToRefetch, row.hierarchyItem.nodeNumber]);
    },
    [itemsToRefetch]
  );

  const collapseRow = useCallback(
    (row: KeyDriversOverTimeTableRow) => {
      const rowsToCollapse = getDescendantRows(row);
      rowsToCollapse.push(row.hierarchyItem.nodeNumber);
      const filtered = itemsToRefetch.filter(
        (item) => !rowsToCollapse.includes(item)
      );
      setItemsToRefetch(filtered);
    },
    [itemsToRefetch]
  );

  // Track the selected metric
  const [selectedBaseMeasure, setSelectedBaseMeasure] = useState<string>("");

  // Initial Table Query - gets called on initial load of report
  const [
    getInitialTable,
    { data: initialTableData, isFetching: isInitialTableFetching },
  ] = useLazyGetInitialTableQuery();

  const fetchInitialTable = useCallback(async () => {
    await getInitialTable({
      divisionName,
      payload: {
        localSelectedValues: getUserSelections({
          Channel: localParameterSelections.Channel,
          CompStore: localParameterSelections.CompStore,
          KeyDriver: localParameterSelections.KeyDriver,
          LocationHierarchy: localParameterSelections.LocationHierarchy,
          Segment: localParameterSelections.Segment,
          Segmentation: localParameterSelections.Segmentation,
        }),
        reportId: reportId ?? "",
        defaultFocalItem: focalItem,
      },
    });
  }, [
    reportId,
    divisionName,
    focalItem,
    localParameterSelections,
    getInitialTable,
  ]);

  useEffect(() => {
    if (
      !focalItemTableInitialised &&
      localParametersInitialised &&
      focalItem.nodeNumber !== EMPTY_NODE_NUMBER
    ) {
      fetchInitialTable().catch((error) => {
        ddLog(
          "Error retrieving key drivers over time table data",
          {},
          "error",
          error
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focalItemTableInitialised, localParametersInitialised, focalItem]);

  // Set the initial displayed nodes
  useEffect(() => {
    if (initialTableData) {
      setItemsToRefetch(getExpandedRows(initialTableData.tableRows));
      setSelectedBaseMeasure(
        initialTableData.tableMetaData.selectedBaseMeasure
      );
    }
  }, [initialTableData]);

  // Lazy query for refetch of the full table
  const [refetchTableQueryTrigger, { isFetching: refetchTableFetching }] =
    useLazyGetTableDataQuery({
      refetchOnFocus: true,
    });
  const [searchTableQueryTrigger, { isFetching: searchTableFetching }] =
    useLazySearchTableDataQuery({
      refetchOnFocus: true,
    });

  const displayEmptySearch =
    Boolean(search) &&
    searchResultsAreShown &&
    !searchTableFetching &&
    tableRows.length === 0;

  const fetchTable = useCallback(
    async (selectedMeasure?: string) => {
      const { data } = await (search.length > 0
        ? searchTableQueryTrigger({
            divisionName,
            payload: {
              localSelectedValues: getUserSelections({
                Channel: localParameterSelections.Channel,
                CompStore: localParameterSelections.CompStore,
                KeyDriver: localParameterSelections.KeyDriver,
                LocationHierarchy: localParameterSelections.LocationHierarchy,
                Segment: localParameterSelections.Segment,
                Segmentation: localParameterSelections.Segmentation,
              }),
              reportId: reportId ?? "",
              searchText: search,
              selectedMeasure,
            },
          })
        : refetchTableQueryTrigger({
            divisionName,
            payload: {
              hypercubeId: hierarchySliceHypercubeId,
              localSelectedValues: getUserSelections({
                Channel: localParameterSelections.Channel,
                CompStore: localParameterSelections.CompStore,
                KeyDriver: localParameterSelections.KeyDriver,
                LocationHierarchy: localParameterSelections.LocationHierarchy,
                Segment: localParameterSelections.Segment,
                Segmentation: localParameterSelections.Segmentation,
              }),
              parentNodes: itemsToRefetch,
              reportId: reportId ?? "",
              selectedMeasure,
            },
          }));

      // Handle any post fetch things here
      if (data !== undefined) {
        setSelectedBaseMeasure(data.tableMetaData.selectedBaseMeasure);
      }
    },
    [
      itemsToRefetch,
      divisionName,
      hierarchySliceHypercubeId,
      localParameterSelections,
      reportId,
      refetchTableQueryTrigger,
      searchTableQueryTrigger,
      search,
    ]
  );

  // Only call this fetch for entire table when we pick up on local parameter change
  useEffect(() => {
    if (initialDataReceived && localParametersInitialised) {
      fetchTable().catch((error) => {
        // FIXME throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localParameterSelections, search]);

  // Fetch children support
  const [isRowFetchingChildren, setIsRowFetchingChildren] = useState<{
    [id: number]: boolean;
  }>({});

  // Lazy query for fetching children
  const [fetchChildrenQueryTrigger] = useLazyGetChildrenTableDataQuery({
    refetchOnFocus: false,
  });

  const fetchChildren = useCallback(
    async (parentNode: KeyDriversOverTimeTableRow) => {
      const payload: FetchChildrenTableRequest = {
        localSelectedValues: getUserSelections({
          Channel: localParameterSelections.Channel,
          CompStore: localParameterSelections.CompStore,
          KeyDriver: localParameterSelections.KeyDriver,
          LocationHierarchy: localParameterSelections.LocationHierarchy,
          Segment: localParameterSelections.Segment,
          Segmentation: localParameterSelections.Segmentation,
        }),
        parentNode: parentNode.hierarchyItem.nodeNumber,
        reportId: reportId ?? "",
        selectedMeasure: selectedBaseMeasure,
      };

      setIsRowFetchingChildren({ [parentNode.hierarchyItem.nodeNumber]: true });

      await fetchChildrenQueryTrigger({
        divisionName,
        payload,
      });

      setIsRowFetchingChildren({
        [parentNode.hierarchyItem.nodeNumber]: false,
      });
      eventTrackingService(
        [
          TrackingComponent.MyReports,
          TrackingComponent.Report,
          TrackingComponent.FocalItemTableRow,
        ],
        TrackingEvent.Selected,
        { selection: `${focalItem.shortName}:${focalItem.name}` }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      localParameterSelections,
      reportId,
      selectedBaseMeasure,
      fetchChildrenQueryTrigger,
      divisionName,
      eventTrackingService,
    ]
  );

  // Column helper
  const columnHelper = createColumnHelper<KeyDriversOverTimeTableRow>();

  // RowId is just the hierarchy slice node number since that is unique.
  const getRowId = (row: KeyDriversOverTimeTableRow) =>
    row.hierarchyItem.nodeNumber.toString();

  const isSearching = searchTableFetching && search.length > 0;

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchBox}>
        <SearchBox
          enableDebounce
          onChange={(debouncedSearchText) =>
            dispatch(onSearchChange(debouncedSearchText))
          }
          placeholder="Type to search"
          resultCount={
            searchResultsAreShown && !isSearching ? tableRows.length : undefined
          }
          searchQuery={search}
        />
      </div>
    ),
    [searchResultsAreShown, isSearching, tableRows.length, search, dispatch]
  );

  // Create the expandable cells for the first columns
  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<KeyDriversOverTimeTableRow, string>) => (
      <ExpandableNameCell
        canExpand={!row.original.hierarchyItem.isLeaf && !searchResultsAreShown}
        depth={row.depth}
        handleToggleExpanded={(event: MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          // If node already not expanded then expand
          if (itemsToRefetch.includes(row.original.hierarchyItem.nodeNumber)) {
            collapseRow(row.original);
          } else {
            // Fetch children if no data
            if (row.subRows.length === 0) {
              fetchChildren(row.original).catch((error) => {
                // FIXME throw this somewhere
                ddLog("ERROR", {}, "error", error);
              });
              // when fetching children, the row doesn't expand on first click so
              // force it to open manually
              setExpandState((previousState) =>
                typeof previousState === "boolean"
                  ? true
                  : {
                      ...previousState,
                      [row.original.hierarchyItem.nodeNumber]: true,
                    }
              );
            }

            expandRow(row.original);
          }

          row.getToggleExpandedHandler()();
        }}
        handleToggleSelected={row.getToggleSelectedHandler()}
        isCheckboxHidden
        isExpanded={row.getIsExpanded() && row.original.subRows.length > 0}
        isExpanding={
          isRowFetchingChildren[row.original.hierarchyItem.nodeNumber] &&
          row.original.subRows.length === 0
        }
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        onClick={() => {
          if (!row.getIsSelected()) {
            dispatch(onFocalItemChange(row.original.hierarchyItem));
          }
        }}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={getValue()}
      />
    ),
    [
      collapseRow,
      dispatch,
      expandRow,
      itemsToRefetch,
      fetchChildren,
      isRowFetchingChildren,
      searchResultsAreShown,
    ]
  );

  // Create the columns for the rest of the table
  const dataColumns = useMemo(() => {
    if (tableMetaData.promoWeeks.length > 0) {
      return tableMetaData.promoWeeks.map((pw, index) => ({
        columns: measuresColumnDefs(
          tableMetaData.measureMetaData,
          pw,
          index,
          formatter
        ),
        header: formatNumberDate(pw),
        id: `${pw}`,
      }));
    }

    return [];
  }, [formatter, tableMetaData.measureMetaData, tableMetaData.promoWeeks]);

  // Hide and show the Last period metrics
  const [lastPeriodToggle, setLastPeriodToggle] = useState<boolean>(true);

  const lastPeriodVisibility: VisibilityState = useMemo(() => {
    const visibilityState: VisibilityState = {};
    for (const pw of tableMetaData.promoWeeks) {
      for (const metric of tableMetaData.measureMetaData) {
        if (metric.measureType === "Last year") {
          visibilityState[`${pw}-${metric.measureType}`] = lastPeriodToggle;
        }
      }
    }

    return visibilityState;
  }, [lastPeriodToggle, tableMetaData]);

  // Metric select dropdown
  const dropdownOptions = useMemo(() => {
    if (initialTableData) {
      return initialTableData.dropdownOptions.filter(
        (option) =>
          option.keyDriver.toString() ===
          localParameterSelections.KeyDriver.value
      );
    }

    return [];
  }, [initialTableData, localParameterSelections.KeyDriver]);

  const onMeasureSelect = (baseMeasure: string) => {
    if (initialDataReceived && localParametersInitialised) {
      fetchTable(baseMeasure).catch((error) => {
        // FIXME throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }

    setSelectedBaseMeasure(baseMeasure);
  };

  const columns = useMemo(
    () =>
      [
        {
          columns: [
            columnHelper.accessor((row) => row.hierarchyItem.name, {
              cell: createNameCell,
              enableHiding: false,
              enableResizing: true,
              enableSorting: false,
              header: "Item description",
            }),
          ],
          header: memoizedSearchBox,
          enableResizing: false,
          enableSorting: false,
          id: "Search",
        },
        ...dataColumns,
      ] as Array<GroupColumnDef<KeyDriversOverTimeTableRow>>,
    [columnHelper, createNameCell, memoizedSearchBox, dataColumns]
  );
  const [downloadTableTrigger] = useLazyGetExportDataQuery();
  const fileName = useMemo(
    () =>
      cleanFilename(
        `Key_Drivers_Over_Time_${localParameterSelections.TimePeriodLength}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );

  const parameterSummary = useMemo(
    () => [
      { name: "Time", value: localParameterSelections.Time as string },
      { name: "KeyDriver", value: localParameterSelections.KeyDriver.label },
      { name: "Channel", value: localParameterSelections.Channel.label },
      ...(localParameterSelections.CompStore.label
        ? [
            {
              name: "Comp store",
              value: localParameterSelections.CompStore.label,
            },
          ]
        : []),
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localParameterSelections.LocationHierarchy.shortName
        )}) ${localParameterSelections.LocationHierarchy.name}`,
      },
    ],
    [localParameterSelections]
  );

  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: {
        localSelectedValues: getUserSelections(localParameterSelections),
        localSelectedLabels: parameterSummary,
        fileName,
        reportId: reportId ?? "",
        selectedMeasure: selectedBaseMeasure,
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    fileName,
    reportId,
    localParameterSelections,
    parameterSummary,
    selectedBaseMeasure,
  ]);
  return (
    <ReportTopDrawer
      controls={[
        <Select
          height={FormInputHeight.XSmall}
          id="metrics-select"
          key="metrics-select"
          onChange={(event) => onMeasureSelect(event.target.value)}
          value={selectedBaseMeasure}
          width={FormInputWidth.Small}
        >
          {dropdownOptions.map((option) => (
            <SelectOption
              key={option.baseMeasure}
              text={option.baseMeasure}
              value={option.baseMeasure}
            />
          ))}
        </Select>,
        <Toggle
          checked={lastPeriodToggle}
          key="last-period-toggle"
          label="Last year"
          onClick={() => {
            setLastPeriodToggle(!lastPeriodToggle);
          }}
        />,
        <div key={reportId} style={{ width: "30px", overflow: "hidden" }}>
          <DataTableOptions
            filename={fileName}
            invokeCSVDownload={handleDownloadButtonClick}
            isFeatureEnabled={isExportEnabled}
            isInFocalItemHeader
            key={reportId}
          />
        </div>,
      ]}
      items={
        focalItem.nodeNumber === EMPTY_NODE_NUMBER
          ? []
          : [
              {
                code: focalItem.shortName,
                displayName: focalItem.name,
                type: HierarchyItemType.Hierarchy,
              },
            ]
      }
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportHierarchyTableWrapper isSuccess={!isInitialTableFetching}>
          <VirtuosoTableComponent
            columnVisibility={lastPeriodVisibility}
            columns={columns}
            data={tableRows}
            getRowId={getRowId}
            getSubRows={(row) => row.subRows}
            onExpandedChange={setExpandState}
            pinFirstColumn
            refreshingData={searchTableFetching || refetchTableFetching}
            rowExpandedState={expandState}
            rowSelectionState={rowSelection}
          />
          {displayEmptySearch && (
            <div className={styles.emptySearch}>
              <EmptySearch />
            </div>
          )}
        </ReportHierarchyTableWrapper>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default KeyDriversOverTimeTopDrawer;
