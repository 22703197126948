import { type FocalItem } from "@quantium-enterprise/common-ui";
import {
  HierarchyItemType,
  HierarchyShortName,
  HierarchyType,
} from "@quantium-enterprise/common-ui";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { HierarchyGroupIcon } from "components-ui/src/icons";

export type FocalItemIconProps = {
  focalItem: FocalItem;
};

export const FocalItemIcon = ({ focalItem }: FocalItemIconProps) => {
  switch (focalItem.type) {
    case HierarchyItemType.Attribute:
    case HierarchyItemType.Hierarchy:
    case HierarchyItemType.Leaf:
      return (
        <HierarchyLevelIcon
          shortName={focalItem.shortName}
          type={focalItem.type}
        />
      );
    case HierarchyShortName.ProductGroup:
      return (
        <HierarchyGroupIcon
          evaluationType={focalItem.evaluationType}
          hierarchyType={HierarchyType.Product}
        />
      );
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};
