import {
  type TransactionSource,
  type HierarchyMetadataResponseDto,
  type HierarchyItemDto,
} from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  isAnyOf,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type ComparisonPeriodOption } from "../models/ComparisonPeriodOption";
import { type FocusPeriodOption } from "../models/FocusPeriodOption";
import { type MetricOption } from "../models/MetricOption";
import {
  type TableStructureMetadata,
  type RangePerformanceParametersDto,
} from "../models/RangePerformanceParametersDto";
import { type SelectedParameters } from "../models/SelectedParameters";
import {
  type GetExpandedItemsMetricsResponseDto,
  type GetItemsMetricsResponseDto,
} from "../models/rangePerformanceDriverTableDtos";
import {
  type RangePerformanceMeasureGrouping,
  type RangePerformanceTableTree,
} from "../models/rangePerformanceDriverTableTypes";
import { type RootState } from "../store";
import { selectFocusPeriodText } from "../utils/focus-period-text-selector";
import { BrowserStorage } from "./browser-storage";
import { rangePerformanceApi } from "./range-performance-api-slice";
import { getParametersMatchAction } from "./range-performance-slice-actions";
import { STORAGE_KEYS } from "./storage-keys";

export type RangePerformanceDriversTableState = {
  focalItem: HierarchyItemDto | undefined;
  // hierarchyStructure will be a sorted list by ordinal
  hierarchyStructure: HierarchyMetadataResponseDto[];
  measureGroupings: RangePerformanceMeasureGrouping[];
  tableData: RangePerformanceTableTree[];
  transactionSource: TransactionSource | undefined;
};

export type RangePerformanceState = {
  availableHierarchies: TableStructureMetadata;
  driversTableState: RangePerformanceDriversTableState;
  kdtTransactionSource: TransactionSource | undefined;
  parameters: {
    parameterOptions: RangePerformanceParametersDto | undefined;
    selectedParameters: SelectedParameters;
  };
  universeSelections: HierarchyItemDto[];
};

const getInitialState = (): RangePerformanceState => {
  const browserStorageParameters = BrowserStorage.get<SelectedParameters>(
    STORAGE_KEYS.SELECTED_PARAMETERS
  );

  return {
    driversTableState: {
      hierarchyStructure: [],
      measureGroupings: [],
      tableData: [],
      focalItem: undefined,
      transactionSource: undefined,
    },
    availableHierarchies: {
      structureOptions: {},
      hierarchyMetadata: [],
    },
    universeSelections: [],
    parameters: {
      parameterOptions: undefined,
      selectedParameters: browserStorageParameters ?? {
        focusPeriod: undefined,
        comparisonPeriod: undefined,
        location: undefined,
        metrics: undefined,
      },
    },
    kdtTransactionSource: undefined,
  };
};

export const initialState: RangePerformanceState = getInitialState();

export const rangePerformanceSlice = createSlice({
  initialState,
  name: "rangePerformance",
  reducers: {
    // This is the action that will be dispatched when the table is first being loaded, it will put all the roots in place
    onTableLoad: (
      state: RangePerformanceState,
      action: PayloadAction<GetItemsMetricsResponseDto>
    ) => {
      state.driversTableState = {
        ...state.driversTableState,
        measureGroupings: action.payload.measureGroupings,
        tableData: action.payload.rows.map((row) => ({
          row,
          subRows: [],
        })),
        transactionSource: action.payload.transactionSource,
      };
    },

    onExpandChild: (
      state: RangePerformanceState,
      action: PayloadAction<GetExpandedItemsMetricsResponseDto>
    ) => {
      // This is O(n^2) can potentially update to make the search O(n) by making a hashmap on the rows with key as ShortName+Code...would need to benchmark
      const updateChildren = (
        rows: RangePerformanceTableTree[]
      ): RangePerformanceTableTree[] =>
        rows.map((tableRow) => {
          if (
            tableRow.row.item.code === action.payload.parent.code &&
            tableRow.row.item.shortName === action.payload.parent.shortName
          ) {
            return {
              ...tableRow,
              subRows: action.payload.rows.map((childRow) => ({
                row: childRow,
                subRows: [],
              })),
            };
          } else if (tableRow.subRows.length > 0) {
            return {
              ...tableRow,
              subRows: updateChildren(tableRow.subRows),
            };
          }

          return tableRow;
        });

      state.driversTableState.tableData = updateChildren(
        state.driversTableState.tableData
      );
    },
    onFocalItemSelection: (
      state: RangePerformanceState,
      action: PayloadAction<HierarchyItemDto>
    ) => {
      state.driversTableState.focalItem = action.payload;
    },
    onUpdateFocalPeriod: (
      state,
      action: PayloadAction<FocusPeriodOption | undefined>
    ) => {
      state.parameters.selectedParameters.focusPeriod = action.payload;
    },
    onUpdateComparisonPeriod: (
      state,
      action: PayloadAction<ComparisonPeriodOption | undefined>
    ) => {
      state.parameters.selectedParameters.comparisonPeriod = action.payload;
    },
    onUpdateMetrics: (state, action: PayloadAction<string[] | undefined>) => {
      if (!action.payload?.length) {
        state.parameters.selectedParameters.metrics = [];
        return;
      }

      state.parameters.selectedParameters.metrics = action.payload
        .map((value) =>
          state.parameters.parameterOptions?.metrics.find(
            (x) => x.value === value
          )
        )
        .filter((x) => x !== undefined) as MetricOption[];
    },
    onUniverseSelectionsChange: (
      state: RangePerformanceState,
      action: PayloadAction<HierarchyItemDto[]>
    ) => {
      state.universeSelections = action.payload;
      state.driversTableState.focalItem = undefined;
    },
    onKdtLoad: (
      state: RangePerformanceState,
      action: PayloadAction<TransactionSource | undefined>
    ) => {
      state.kdtTransactionSource = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        rangePerformanceApi.endpoints.getParameters.matchFulfilled,
        getParametersMatchAction
      )
      .addMatcher(
        isAnyOf(
          rangePerformanceSlice.actions.onUniverseSelectionsChange,
          rangePerformanceApi.endpoints.getParameters.matchFulfilled
        ),
        (state) => {
          // when the universe selection changes and we have the parameters, then we can update the hierarchy structure
          // we need to check after fetching parameters because the universe selection can be updated before the parameters are fetched
          if (
            state.universeSelections.length > 0 &&
            state.availableHierarchies.hierarchyMetadata.length > 0
          ) {
            const rootLevel = state.universeSelections[0].shortName;
            state.driversTableState.hierarchyStructure =
              state.availableHierarchies.structureOptions[
                rootLevel
              ].defaultStructure
                .map((shortName) =>
                  state.availableHierarchies.hierarchyMetadata.find(
                    (x) => x.shortName === shortName
                  )
                )
                .filter(
                  (item): item is HierarchyMetadataResponseDto =>
                    item !== undefined
                );
          } else {
            state.driversTableState.hierarchyStructure = [];
          }
        }
      );
  },
});

export const selectDriversTableState = createSelector(
  (state: RootState) => state.rangePerformance.driversTableState,
  (driversTableState) => driversTableState
);

// Try to use more specific selectors than this, as this will be rememoized after any change to any parameter
export const selectParameterOptions = createSelector(
  (state: RootState) => state.rangePerformance.parameters.parameterOptions,
  (options: RangePerformanceParametersDto | undefined) => options
);

export const selectFocusPeriodOptions = createSelector(
  (state: RootState) =>
    state.rangePerformance.parameters.parameterOptions?.focusPeriods,
  (focusPeriods: FocusPeriodOption[] | undefined) => focusPeriods
);

export const selectSelectedFocusPeriodText = createSelector(
  (state: RootState) =>
    state.rangePerformance.parameters.selectedParameters.focusPeriod,
  selectFocusPeriodText
);

export const selectSelectedFocalPeriod = createSelector(
  (state: RootState) =>
    state.rangePerformance.parameters.selectedParameters.focusPeriod,
  (focusPeriod: FocusPeriodOption | undefined) => focusPeriod
);

export const selectSelectedComparisonPeriod = createSelector(
  (state: RootState) =>
    state.rangePerformance.parameters.selectedParameters.comparisonPeriod,
  (comparisonPeriods: ComparisonPeriodOption | undefined) => comparisonPeriods
);

export const selectComparisonPeriodOptions = createSelector(
  (state: RootState) =>
    state.rangePerformance.parameters.parameterOptions?.comparisonPeriods,
  (comparisonPeriods: ComparisonPeriodOption[] | undefined) => comparisonPeriods
);

export const selectSelectedParameters = createSelector(
  (state: RootState) => state.rangePerformance.parameters.selectedParameters,
  (selectedParameters: SelectedParameters) => selectedParameters
);

export const selectSelectedMetrics = createSelector(
  (state: RootState) =>
    state.rangePerformance.parameters.selectedParameters.metrics,
  (metrics: MetricOption[] | undefined) => metrics
);

export const selectUniverseSelections = createSelector(
  (state: RootState) => state.rangePerformance.universeSelections,
  (universeSelection: HierarchyItemDto[]) => universeSelection
);

export const selectDriversTableTransactionSource = createSelector(
  (state: RootState) =>
    state.rangePerformance.driversTableState.transactionSource,
  (transactionSource: TransactionSource | undefined) => transactionSource
);

export const selectKdtTransactionSource = createSelector(
  (state: RootState) => state.rangePerformance.kdtTransactionSource,
  (transactionSource: TransactionSource | undefined) => transactionSource
);

export const selectFocalItem = createSelector(
  (state: RootState) => state.rangePerformance.driversTableState.focalItem,
  (focalItem: HierarchyItemDto | undefined) => focalItem
);

export const selectDriversHierarchyStructure = createSelector(
  (state: RootState) =>
    state.rangePerformance.driversTableState.hierarchyStructure,
  (hierarchyStructure: HierarchyMetadataResponseDto[]) => hierarchyStructure
);

export const selectAvailableHierarchiesMetaData = createSelector(
  (state: RootState) =>
    state.rangePerformance.availableHierarchies.hierarchyMetadata,
  (hierarchyMetadata: HierarchyMetadataResponseDto[]) => hierarchyMetadata
);

export const {
  onUpdateFocalPeriod,
  onUpdateComparisonPeriod,
  onUpdateMetrics,
  onTableLoad,
  onExpandChild,
  onFocalItemSelection,
  onUniverseSelectionsChange,
  onKdtLoad,
} = rangePerformanceSlice.actions;

export default rangePerformanceSlice.reducer;
