import { type Option } from "@qbit/react/dist/typeahead";
import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { getTimeDefaultSelection } from "../../common/utils/export-parameter-summary-utils";
import {
  isSingleSelectionParameter,
  isTimePeriodParameter,
  isLocationSelectionParameter,
  isMultiSelectionParameter,
  isSegmentationGroupParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getMultiSelectDefaultSelection,
  getSegmentationGroupSelectDefaultSelection,
  getSingleSelectDefault,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type TableLocalParameterSelections } from "../models/DataTableDto";
import { type KeyMeasureTrendsLocalSelections } from "../services/key-measure-trends-slice";

const handleTimeParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isTimePeriodParameter(parameter)) {
    const selection = getTimeDefaultSelection(parameter);
    selections.time = selection.time;
    selections.timePeriodLength = selection.timePeriodLength;
  }
};

const handleDataSetParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isSingleSelectionParameter(parameter)) {
    const selection = getSingleSelectDefaultSelection(parameter);
    if (selection) {
      selections.dataset = { ...selection };
    }
  }
};

const handleChannelParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isSingleSelectionParameter(parameter)) {
    const selection = getSingleSelectDefaultSelection(parameter);
    if (selection) {
      selections.channel = { ...selection };
    }
  }
};

const handleChangeOnParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isSingleSelectionParameter(parameter)) {
    selections.changeOn = getSingleSelectDefault(parameter);
  }
};

const handleCompStoreParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isSingleSelectionParameter(parameter)) {
    const selection = getSingleSelectDefaultSelection(parameter);
    if (selection) {
      selections.compStore = { ...selection };
    }
  }
};

const handleLocationHierarchyParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isLocationSelectionParameter(parameter)) {
    selections.locationHierarchy = getLocationSelectDefault(parameter);
  }
};

const handleMetricsParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isMultiSelectionParameter(parameter)) {
    const selection = getMultiSelectDefaultSelection(parameter);
    selections.metrics = { ...selection };
  }
};

const handlePromotionParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isSingleSelectionParameter(parameter)) {
    const selection = getSingleSelectDefaultSelection(parameter);
    if (selection) {
      selections.promotion = { ...selection };
    }
  }
};

const handleSegmentationParameter = (
  parameter: SidePanelParameter,
  selections: KeyMeasureTrendsLocalSelections
) => {
  if (isSegmentationGroupParameter(parameter)) {
    const selection = getSegmentationGroupSelectDefaultSelection(parameter);
    if (selection) {
      selections.segmentation = {
        value: selection.id,
        label: selection.name,
      };
    }
  }
};

export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): KeyMeasureTrendsLocalSelections => {
  const selections: KeyMeasureTrendsLocalSelections = {
    changeOn: "",
    channel: {
      value: "",
      label: "",
    },
    compStore: {
      value: "",
      label: "",
    },
    metrics: [],
    locationHierarchy: {} as LocalHierarchyNodeSelection,
    promotion: {
      value: "",
      label: "",
    },
    segmentation: {
      value: "",
      label: "",
    },
    time: "",
    timePeriodLength: "",
    dataset: {
      label: "",
      value: "",
    },
  };
  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.Time:
        handleTimeParameter(parameter, selections);
        break;
      case LocalParameters.Dataset:
        handleDataSetParameter(parameter, selections);
        break;
      case LocalParameters.Channel:
        handleChannelParameter(parameter, selections);
        break;
      case LocalParameters.ChangeOn:
        handleChangeOnParameter(parameter, selections);
        break;
      case LocalParameters.CompStore:
        handleCompStoreParameter(parameter, selections);
        break;
      case LocalParameters.LocationHierarchy:
        handleLocationHierarchyParameter(parameter, selections);
        break;
      case LocalParameters.Metrics:
        handleMetricsParameter(parameter, selections);
        break;
      case LocalParameters.Promotion:
        handlePromotionParameter(parameter, selections);
        break;
      case LocalParameters.Segmentation:
        handleSegmentationParameter(parameter, selections);
        break;
      default:
        break;
    }
  }

  return selections;
};

export const getFocalItemTableLocalSelections = (
  location: number,
  channel?: string,
  compStore?: string,
  promotion?: string,
  dataset?: string
): TableLocalParameterSelections => ({
  location,
  channel,
  compStore,
  promotion,
  dataset,
});

export const getSelectedDatasetMetrics = (
  localSelections: KeyMeasureTrendsLocalSelections,
  localParameters: SidePanelParameter[]
): Option[] => {
  const metricsParameter = localParameters.find(
    (parameter) => parameter.id === LocalParameters.Metrics
  );
  if (!metricsParameter) return [];

  const dataset = localSelections.dataset.value.toString();
  const metricsSet = new Set(
    Object.values(localSelections.metrics).map((option) =>
      option.value.toString()
    )
  );

  if (isMultiSelectionParameter(metricsParameter)) {
    const selections = metricsParameter.selections.filter(
      (selection) =>
        metricsSet.has(selection.value) &&
        selection.dataSource?.includes(dataset)
    );

    return selections.length
      ? selections
      : getMultiSelectDefaultSelection(metricsParameter);
  }

  return [];
};
