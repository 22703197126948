import { ReportStatus } from "../enums";

const ONE_MINUTE = 1_000 * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

export const isTimeLeftLessThanThreeDays = function (
  createTimeStamp: string,
  reportRetentionDays: number,
  compareDate?: Date
): boolean {
  const threeDays = ONE_DAY * 3;

  const createDate: Date = new Date(createTimeStamp);
  const deleteDate = createDate.getTime() + ONE_DAY * reportRetentionDays;
  const rightNow = compareDate ?? new Date();

  const difference = deleteDate - rightNow.getTime();

  if (difference >= 0 && difference <= threeDays) {
    return true;
  }

  return false;
};

export const getExpiresReportStatus = function (
  createTimeStamp: string,
  reportRetentionDays: number,
  compareDate?: Date
): ReportStatus {
  const twoDays = ONE_DAY * 2;
  const threeDays = ONE_DAY * 3;

  const createDate: Date = new Date(createTimeStamp);
  const deleteDate = createDate.getTime() + ONE_DAY * reportRetentionDays;
  const rightNow = compareDate ?? new Date();

  const difference = deleteDate - rightNow.getTime();
  if (difference < 0) {
    return ReportStatus.Failed;
  }

  if (difference <= ONE_DAY) {
    return ReportStatus.ExpiresOneDay;
  }

  if (difference <= twoDays) {
    return ReportStatus.ExpiresTwoDays;
  }

  if (difference <= threeDays) {
    return ReportStatus.ExpiresThreeDays;
  }

  return ReportStatus.Failed;
};
