import {
  Checkbox,
  FormBlock,
  FormBlockEditability,
  FormBlockType,
  Input,
  Spinner,
} from "@qbit/react";
import {
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  UnknownTrackingPropertyValue,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import Zod from "zod";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import styles from "./ChannelFilterContent.module.css";

export const ChannelValueSchema = Zod.string().nullable();
export type ChannelValue = Zod.infer<typeof ChannelValueSchema>;

export const ChannelFilterContent = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.globalParameters.channel,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.Channel,
    ChannelValueSchema,
    (config) => config.globalParameters.channel
  );

  const isValueSelected = (value: string | null) =>
    selection?.find((item) => item === value) !== undefined;

  const removeValueFromSelection = (value: string | null) => {
    let newSelection = selection?.filter((item) => item !== value) ?? [];

    // If last option is de-selected, select the aggregate option
    if (newSelection.length === 0) {
      newSelection = [null];
    }

    setSelection(newSelection);
  };

  const addValueToSelection = (value: string | null) => {
    if (reportConfig.configuration) {
      const parameter = reportConfig.configuration.options.find(
        (option) => option.value === value
      );

      eventTrackingService.trackEvent(
        TrackingComponent.FastReportingGlobal,
        TrackingEvent.Parameters,
        GenericTrackingProperties.single(
          ParametersTrackingProperty.ChannelSelected,
          parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
        )
      );
    }

    if (value === null) {
      // If aggregate option is selected, clear all other values
      setSelection([null]);
    } else {
      const currentSelection = selection ?? [];
      let newSelection = [...currentSelection, value];

      // If a non-aggregate value is selected, remove any aggregate value
      newSelection = newSelection.filter((item) => item !== null);

      setSelection(newSelection);
    }
  };

  const toggleValue = (value: string | null) => {
    if (isValueSelected(value)) {
      removeValueFromSelection(value);
    } else {
      addValueToSelection(value);
    }
  };

  return reportConfig.configuration ? (
    <div data-cy="ChannelGlobalParameter">
      {reportConfig.configuration.options.map((option) => (
        <FormBlock blockType={FormBlockType.Checkbox} key={option.value}>
          <Input className={styles.nonUppercaseCheckbox}>
            <Checkbox
              checked={isValueSelected(option.value)}
              data-key={option.value}
              data-selected={isValueSelected(option.value)}
              editability={
                // Disable the checkbox for the aggregate option when it is already selected
                reportConfig.configuration?.isReadOnly ||
                (option.value === null && isValueSelected(null))
                  ? FormBlockEditability.Disabled
                  : FormBlockEditability.Editable
              }
              id={`channel-${option.value}`}
              label={option.displayName}
              name={option.displayName}
              onChange={() => toggleValue(option.value)}
            />
          </Input>
        </FormBlock>
      ))}
    </div>
  ) : (
    <Spinner />
  );
};
