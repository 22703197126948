import {
  Menu,
  MenuItemButton,
  MenuModule,
  MenuSection,
  Text,
} from "@qbit/react";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import { getDefaultGroupsPath } from "../../utilities/route-path-formats";
import styles from "./GroupManagement.module.css";

type CustomerGroupTabWithDisplayName = {
  displayName: string;
  id: CustomerGroupTab;
  key: number;
};

const customerGroupTabs: CustomerGroupTabWithDisplayName[] = [
  {
    displayName: "Customer groups",
    id: CustomerGroupTab.CustomerGroups,
    key: 0,
  },
  {
    displayName: "Segment library",
    id: CustomerGroupTab.SegmentLibrary,
    key: 1,
  },
];

export type CustomerGroupManagementSegmentLibraryTabsProps = {
  customerGroupTab: CustomerGroupTab;
};

export const CustomerGroupManagementSegmentLibraryTabs = ({
  customerGroupTab,
}: CustomerGroupManagementSegmentLibraryTabsProps) => {
  const navigate = useNavigate();
  const division = useDivision();
  const divisionName = division.name;

  const setSelectedCustomerGroupTabAndOtherState = useCallback(
    (newTab: CustomerGroupTabWithDisplayName) => {
      if (customerGroupTab !== newTab.id) {
        navigate(getDefaultGroupsPath(divisionName, "Customer", newTab.id));
      }
    },
    [navigate, customerGroupTab, divisionName]
  );

  /* Not in a <Group> because the style of the menu would change to something we don't want */
  return (
    <MenuModule className={styles.customerGroupListMenuArea}>
      <Menu>
        <MenuSection>
          {customerGroupTabs.map((tab) => (
            <MenuItemButton
              key={`customer-group-tab-${tab.id}`}
              onClick={() => {
                setSelectedCustomerGroupTabAndOtherState(tab);
              }}
              selected={customerGroupTab === tab.id}
              text={<Text>{tab.displayName}</Text>}
            />
          ))}
        </MenuSection>
      </Menu>
    </MenuModule>
  );
};
