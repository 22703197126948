import { MessageVariant, QbitEmitToast, QbitToastMessage } from "@qbit/react";
import {
  getErrorsForFields,
  CustomerGroupType,
  isProblemDetail,
  GenericTrackingProperties,
  GroupsTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  getBasePath,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import FileUploadIcon from "components-ui/src/assets/common/file-upload-outline-icon.svg";
import WorkspacesFilledIcon from "components-ui/src/assets/common/workspaces-filled-icon.svg";
import { CustomerGroupIcon } from "components-ui/src/icons";
import {
  useState,
  type PropsWithChildren,
  useCallback,
  type DragEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import { GroupType } from "../../enums/group-type";
import {
  getGroupCreatorPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import { FileDetails } from "../file-details/FileDetails";
import { FileInput } from "../file-input/FileInput";
import { GroupEditor } from "../group-editor/GroupEditor";
import { TextAreaParameter } from "../text-area-parameter/TextAreaParameter";
import { TwoPanelForm } from "../two-panel-form/TwoPanelForm";
import styles from "./CustomSegmentationEditor.module.css";
import { maxMbSize, templateUri, validateFile } from "./file-specs";

const { Header, BackButton, Title, Content, Footer, SaveButton } = GroupEditor;
const { Divider, LeftPanel, RightPanel } = TwoPanelForm;

type DetailField = {
  name: string;
  placeholder?: string;
  value: string;
};

enum UploadErrorCodes {
  DuplicateCustomerId = "DuplicateCustomerId",
  MaxSegmentsPerSegmentation = "MaxSegmentsPerSegmentation",
  MinCustomerPerSegment = "MinCustomerPerSegment",
  Unexpected = "Unexpected",
}

const defaultDetails: DetailField[] = [
  {
    name: "Products",
    placeholder:
      "Provide details on the products used to create the customer segment(s)",
    value: "",
  },
  {
    name: "Time",
    placeholder:
      "Outline the time period that was used to create the customer segment(s)",
    value: "",
  },
  {
    name: "Location",
    placeholder:
      "Outline the location that was used to create the customer segment(s)",
    value: "",
  },
  {
    name: "Business rules",
    placeholder:
      "Outline any business rules that were used to determine the customer segment(s)",
    value: "",
  },
];

type ParameterGroupProps = {
  description: JSX.Element;
  icon: string;
  title: string;
};

const ParameterGroup = ({
  icon,
  title,
  description,
  children,
}: PropsWithChildren<ParameterGroupProps>) => (
  <div className={styles.parameterGroup}>
    <h4 className={styles.parameterGroupTitle}>
      <img alt="" src={icon} />
      {title}
    </h4>
    <div className={styles.parameterGroupDescription}>{description}</div>
    {children}
  </div>
);

const buildFormProperties = (
  name: string,
  details: DetailField[],
  file: File
) => {
  const formData = new FormData();

  for (const detail of details) {
    formData.append(detail.name, detail.value);
  }

  formData.append("File", file);
  return formData;
};

export const CustomSegmentationEditor = () => {
  const { name: divisionName } = useDivision();
  const navigate = useNavigate();
  const eventTrackingService = useEventTrackingServiceContext();
  const [file, setFile] = useState<File | null>(null);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [fileValidating, setFileValidating] = useState<boolean>(false);
  const [details, setDetails] = useState<DetailField[]>(defaultDetails);
  const uploadTimeout = 30 * 60 * 1_000;

  const icon = (
    <CustomerGroupIcon type={CustomerGroupType.CustomSegmentation} />
  );

  const handleDetailInput = useCallback(
    (index: number, input: string) => {
      const newDetails = details.map((detail) => ({
        ...detail,
      }));
      newDetails[index].value = input;
      setDetails(newDetails);
    },
    [details]
  );

  const handleFileErrors = (errors: string[]) => {
    setFileErrors(errors);
    setFileValidating(false);
  };

  const handleFileInput = (input: File | null) => {
    setFile(input);
    setFileErrors([]);
    if (input !== null) {
      setFileValidating(true);
      validateFile(input, handleFileErrors);
    }
  };

  const clearFileInputs = () => {
    setFile(null);
    setFileErrors([]);
  };

  const handleSubmit = useCallback(
    async (name: string) => {
      if (file === null) {
        throw new Error("File is null.");
      }

      const formData = buildFormProperties(name, details, file);

      const xhr = new XMLHttpRequest();
      xhr.timeout = uploadTimeout;
      // to have path name appended to the api path ex: asda will have /checkout in the baseURL which has to be appended.
      const basePath = getBasePath(`/api/customer-group-service`);
      await new Promise<void>((resolve, reject) => {
        xhr.open(
          "POST",
          `${basePath}/${divisionName}/CustomerGroup/CreateCustomGroup?groupName=${name}`,
          true
        );
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              const response = JSON.parse(xhr.responseText);
              const path = getGroupListPath(
                divisionName,
                GroupType.Customer,
                response.groupId
              );
              navigate(path);
              QbitEmitToast(
                <QbitToastMessage
                  content={<p>Customer group creation in progress.</p>}
                  heading={<h5>Customer group</h5>}
                  showCloseButton
                  showIcon
                  variant={MessageVariant.Notification}
                />
              );
              eventTrackingService.trackEvent(
                [TrackingComponent.Groups, TrackingComponent.CustomerGroup],
                TrackingEvent.Submitted,
                new GenericTrackingProperties({
                  [GroupsTrackingProperty.GroupStatus]: "Success",
                  [GroupsTrackingProperty.GroupType]:
                    CustomerGroupType.CustomSegmentation,
                })
              );
              resolve();
            } else if (xhr.status === 401) {
              window.location.assign(
                `/sso-proxy/challenge-with-fragment?redirectUri=${encodeURIComponent(
                  window.location.href
                )}`
              );
            } else {
              eventTrackingService.trackEvent(
                [TrackingComponent.Groups, TrackingComponent.CustomerGroup],
                TrackingEvent.Submitted,
                new GenericTrackingProperties({
                  [GroupsTrackingProperty.GroupStatus]: "Fail",
                  [GroupsTrackingProperty.GroupType]:
                    CustomerGroupType.CustomSegmentation,
                })
              );

              let jsonResponseError;
              try {
                jsonResponseError = JSON.parse(xhr.responseText);
              } catch {
                reject(xhr.responseText);
              }

              if (isProblemDetail(jsonResponseError)) {
                const fileErrorsFound = getErrorsForFields(
                  jsonResponseError.errors,
                  [
                    UploadErrorCodes.DuplicateCustomerId,
                    UploadErrorCodes.MaxSegmentsPerSegmentation,
                    UploadErrorCodes.MinCustomerPerSegment,
                    UploadErrorCodes.Unexpected,
                  ]
                );

                if (fileErrorsFound.length > 0) {
                  setFileErrors(fileErrorsFound);
                  QbitEmitToast(
                    <QbitToastMessage
                      content={<p>Please upload a valid file.</p>}
                      heading={<h5>File upload failed</h5>}
                      showCloseButton
                      showIcon
                      variant={MessageVariant.Danger}
                    />
                  );
                } else {
                  reject(jsonResponseError);
                }
              } else {
                reject(jsonResponseError);
              }
            }
          }
        };

        xhr.send(formData);
      });
    },
    [file, details, uploadTimeout, divisionName, navigate, eventTrackingService]
  );

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (file !== null) {
      event.dataTransfer.dropEffect = "none";
    }
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files.item(0);
    if (droppedFile && file === null) {
      handleFileInput(droppedFile);
    }
  };

  const disableCreateButton =
    file === null || fileValidating || fileErrors.length > 0;

  return (
    <div onDragOver={onDragOver} onDrop={onDrop}>
      <GroupEditor>
        <Header>
          <BackButton
            returnPath={getGroupCreatorPath(divisionName, GroupType.Customer)}
          />
          <Title
            icon={icon}
            subtitle="Custom segmentation"
            title="Create a customer group"
          />
        </Header>
        <Content>
          <TwoPanelForm>
            <LeftPanel>
              <ParameterGroup
                description={
                  <p>
                    Download{" "}
                    <a
                      download="Custom segmentation template.csv"
                      href={templateUri}
                    >
                      this template
                    </a>{" "}
                    and populate it with the necessary segment names and
                    customer identifiers in CSV format. Then upload the file
                    using the instructions below.
                  </p>
                }
                icon={FileUploadIcon}
                title="File upload"
              >
                <FileInput
                  disabled={file !== null}
                  fileType="csv"
                  handleFileInput={handleFileInput}
                  maxMbSize={maxMbSize}
                />
                {file && (
                  <>
                    <FileDetails
                      errors={fileErrors}
                      file={file}
                      processing={fileValidating}
                      removeFile={() => clearFileInputs()}
                    />
                    <p className={styles.footnote}>
                      File upload is limited to one CSV. Remove the file above
                      to upload a new file.
                    </p>
                  </>
                )}
              </ParameterGroup>
            </LeftPanel>
            <Divider buttons={[]} showBar />
            <RightPanel>
              <ParameterGroup
                description={
                  <p>
                    Specify the group parameters that are relevant to this
                    custom segmentation.
                  </p>
                }
                icon={WorkspacesFilledIcon}
                title="Details"
              >
                {details.map((parameter, index) => (
                  <TextAreaParameter
                    handleChange={(input) => handleDetailInput(index, input)}
                    key={parameter.name}
                    label={parameter.name}
                    optional
                    placeholder={parameter.placeholder}
                    value={parameter.value}
                  />
                ))}
              </ParameterGroup>
            </RightPanel>
          </TwoPanelForm>
        </Content>
        <Footer>
          <SaveButton
            disabled={disableCreateButton}
            groupTypeIcon={icon}
            groupTypeName="Custom segmentation"
            handleCreateGroup={handleSubmit}
          />
        </Footer>
      </GroupEditor>
    </div>
  );
};
