import { Spinner, SpinnerSize } from "@qbit/react";
import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import {
  ReportView,
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useEffect, useState } from "react";
import { FastReportingTopDrawer } from "./FastReportingTopDrawer";
import {
  closeSearchModal,
  openSearchModal,
} from "./focal-item-search/fastReportingSearchSlice";
import { useAppDispatch } from "./hooks";
import CustomerProfilingReportPanel from "./report-panels/customer-profiling/CustomerProfilingReportPanel";
import KeyDriverTreeReportPanel from "./report-panels/key-driver-tree/KeyDriverTreeReportPanel";
import PerformanceReportPanel from "./report-panels/performance/PerformanceReportPanel";
import RangeReportPanel from "./report-panels/range/RangeReportPanel";
import StoreByStorePanel from "./report-panels/store-by-store/StoreByStorePanel";
import TopAndBottomReportPanel from "./report-panels/top-and-bottom/TopAndBottomReportPanel";
import { ReportSummary } from "./report-summary/ReportSummary";
import { FastReportingSidePanel } from "./side-panel/FastReportingSidePanel";
import { useActiveItem } from "./useActiveItem";

export const FAST_REPORTING_FEATURE_NAME = "fast-reporting";

export const FastReporting = () => {
  const dispatch = useAppDispatch();

  const activeItem = useActiveItem();
  if (!activeItem) {
    dispatch(openSearchModal());
  }

  useEffect(() => {
    if (activeItem) {
      dispatch(closeSearchModal());
    }
  }, [activeItem, dispatch]);

  const [showReportSummary, setShowReportSummary] = useState<boolean>(false);

  useEffect(() => {
    // If not leaf close the show report summary in-case its been opened and swapped tabs to hierarchy / attribute item
    if (activeItem?.type !== HierarchyItemType.Leaf) {
      setShowReportSummary(false);
    }
  }, [activeItem]);

  const focalItemValid =
    activeItem &&
    (activeItem.type !== HierarchyItemType.Attribute ||
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (activeItem.type === HierarchyItemType.Attribute &&
        activeItem.additionalHierarchyFilter));

  return activeItem ? (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.FastReporting}>
        <ReportView.HeaderPanel>
          <FastReportingTopDrawer
            onShowReportSummary={() => setShowReportSummary(true)}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <FastReportingSidePanel />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          {focalItemValid && (
            <>
              <PerformanceReportPanel />
              <KeyDriverTreeReportPanel />
              <TopAndBottomReportPanel />
              <CustomerProfilingReportPanel />
              <RangeReportPanel />
              <StoreByStorePanel />
            </>
          )}
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      {activeItem.type === HierarchyItemType.Leaf && (
        <ReportSummary
          onClose={() => setShowReportSummary(false)}
          show={showReportSummary}
        />
      )}
    </ReportView>
  ) : (
    <Spinner size={SpinnerSize.Medium} />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default FastReporting as React.ComponentType<any>;
