import { Spinner } from "@qbit/react";
import {
  type TransactionSource,
  type FocalItem,
  HierarchyShortName,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { differenceInWeeks } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useReportTabState } from "report-tabs-ui";
import ReportLoadingWrapper from "../../fast-report/ReportLoadingWrapper";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { type MetricKeyDisplayName } from "../../fast-report-content/MetricsTypeahead";
import {
  MetricNameSchema,
  MetricsTypeahead,
} from "../../fast-report-content/MetricsTypeahead";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";
import styles from "../ReportPanel.module.css";
import { convertFocalItemToDto } from "../focalItemDto";
import { useFilenameForExport, useParameterValuesForCSVExport } from "../hooks";
import { type ShareOfValue } from "./ShareOfDropdown";
import { ShareOfDropdown, ShareOfValueSchema } from "./ShareOfDropdown";
import { StoreByStoreTable } from "./StoreByStoreTable";
import { type StoreByStoreRow } from "./storeByStoreApi";
import { useStoreByStoreReportQuery } from "./storeByStoreApi";

const MAX_GROWTH_METRIC_WEEKS = 52;

export type StoreByStoreReportProps = {
  activeItem?: FocalItem;
  onTransactionSourceChanged: (
    transactionSource: TransactionSource | null
  ) => void;
};

export const StoreByStoreReport = ({
  activeItem,
  onTransactionSourceChanged,
}: StoreByStoreReportProps) => {
  const division = useDivision();
  const [globalParameters, areGlobalParametersSet] = useGlobalParameters();
  const reportConfiguration = useReportConfigurationQuery(
    { division: division.name },
    { skip: !division.name }
  );
  const { configuration } = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.storeByStore,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selections, setSelections] = useFastReportingParameterState(
    Parameter.StoreByStoreMetrics,
    MetricNameSchema,
    (config) => config.reports.storeByStore?.metrics
  );

  const focusPeriodWeeks = useMemo(() => {
    if (globalParameters.focusPeriod) {
      return (
        differenceInWeeks(
          new Date(globalParameters.focusPeriod.endDate),
          new Date(globalParameters.focusPeriod.startDate)
        ) + 1
      );
    }

    return 0;
  }, [globalParameters.focusPeriod]);

  useEffect(() => {
    if (
      activeItem?.type !== HierarchyShortName.ProductGroup &&
      focusPeriodWeeks <= MAX_GROWTH_METRIC_WEEKS
    ) {
      return;
    }

    let updatedSelections = selections ?? [];

    if (activeItem?.type === HierarchyShortName.ProductGroup) {
      updatedSelections = updatedSelections.filter(
        (selection) =>
          !configuration?.metricsExcludedForGroups.includes(selection)
      );
    }

    if (focusPeriodWeeks > MAX_GROWTH_METRIC_WEEKS) {
      updatedSelections = updatedSelections.filter(
        (selection) =>
          !configuration?.metricMetadata.find(
            (metadata) => metadata.key === selection
          )?.isGrowthMetric
      );
    }

    setSelections(updatedSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- causes infinite loop to include selections/setSelections
  }, [activeItem, configuration, focusPeriodWeeks]);

  const [sortedStoreByStoreRows, setSortedStoreByStoreRows] =
    useState<StoreByStoreRow[]>();

  const availableMetrics = useMemo(
    () =>
      configuration?.metrics.options
        .filter((metric) => {
          const isExcludedForGroups =
            activeItem?.type === HierarchyShortName.ProductGroup &&
            configuration.metricsExcludedForGroups.includes(metric.value);

          const isExcludedGrowthMetric =
            focusPeriodWeeks > MAX_GROWTH_METRIC_WEEKS &&
            configuration.metricMetadata.find(
              (metadata) => metadata.key === metric.value
            )?.isGrowthMetric;

          return !isExcludedForGroups && !isExcludedGrowthMetric;
        })
        .map((metric) => ({
          key: metric.value,
          displayName: metric.displayName,
        })) ?? [],
    [configuration, activeItem, focusPeriodWeeks]
  );

  const selectedMetrics = availableMetrics.filter((available) =>
    selections?.includes(available.key)
  );

  const selectionChangedCallback = useCallback(
    (selected: MetricKeyDisplayName[]) => {
      setSelections(selected.map((sel) => sel.key));
    },
    [setSelections]
  );

  const [shareOf] = useReportTabState<ShareOfValue>(
    Parameter.StoreByStoreShareOf,
    ShareOfValueSchema
  );

  const reportData = useStoreByStoreReportQuery(
    {
      metrics:
        configuration?.metricMetadata.filter((meta) =>
          selections?.includes(meta.key)
        ) ?? [],
      division: division.name,
      focusPeriod: globalParameters.focusPeriod,
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      item: activeItem ? convertFocalItemToDto(activeItem) : null!,
      location: globalParameters.location,
      channel: globalParameters.channel,
      promotion: globalParameters.promotion,
      banner: globalParameters.banner,
      transactionSource: globalParameters.transactionSource,
      storeFormat: globalParameters.storeFormat,
      compStore: globalParameters.compStore,
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      shareOfShortname: shareOf!,
    },
    {
      skip:
        !shareOf ||
        !division.name ||
        !areGlobalParametersSet ||
        !configuration?.metricMetadata,
    }
  );

  useEffect(() => {
    onTransactionSourceChanged(
      reportData.currentData?.transactionSource ?? null
    );
  }, [onTransactionSourceChanged, reportData.currentData?.transactionSource]);

  const table = useMemo(() => {
    if (reportData.data && configuration && selections) {
      return (
        <StoreByStoreTable
          data={reportData.data}
          metadata={configuration.metricMetadata}
          onSetSortedStoreByStoreRows={setSortedStoreByStoreRows}
        />
      );
    }

    return undefined;
  }, [reportData.data, configuration, selections]);

  const [globalParameterCsvValues] = useParameterValuesForCSVExport();
  const filename = useFilenameForExport("StoreByStore");
  const getDataForCsvExport = useCallback(() => {
    if (sortedStoreByStoreRows) {
      const parameters = [...globalParameterCsvValues];
      const accessors = sortedStoreByStoreRows[0].results.map(
        (result) => result.displayName
      );
      const header = accessors.map(
        (accessor) =>
          configuration?.metricMetadata.find(
            (meta) => meta.metricName === accessor
          )?.displayName ?? accessor
      );
      const dataRows = sortedStoreByStoreRows.map((row) =>
        accessors.map(
          (accessor) =>
            row.results.find((result) => result.displayName === accessor)?.value
        )
      );

      return [parameters, header, ...dataRows];
    }

    throw new Error("Unable to get CSV data as report has not loaded");
  }, [
    globalParameterCsvValues,
    sortedStoreByStoreRows,
    configuration?.metricMetadata,
  ]);

  return (
    <div data-cy="StoreByStoreReportPanel">
      <div className={styles.reportHeader}>
        {availableMetrics.length ? (
          <MetricsTypeahead
            metrics={availableMetrics}
            selectedMetrics={selectedMetrics}
            setSelectedMetrics={selectionChangedCallback}
          />
        ) : (
          <Spinner />
        )}
        <ShareOfDropdown activeItem={activeItem} />
        <div className={styles.reportOptions}>
          {!reportConfiguration.currentData?.exportDisabled && (
            <DataTableOptions
              disabled={!reportData.data || reportData.isFetching}
              filename={filename}
              invokeCSVDownload={getDataForCsvExport}
              isFeatureEnabled
            />
          )}
        </div>
      </div>
      <ReportLoadingWrapper
        data-cy="StoreByStoreReport"
        isError={reportData.isError}
        isLoading={reportData.isFetching}
        noData={!reportData.data?.rows.length}
        reportMinimumHeight={560}
        retry={reportData.refetch}
      >
        {table}
      </ReportLoadingWrapper>
    </div>
  );
};
