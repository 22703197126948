import * as tokens from "@qbit/core/dist/tokens.json";
import {
  HierarchyShortName,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  useDivision,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { type DriverTreeType } from "components-ui/src/driver-tree/DriverTree";
import DriverTree from "components-ui/src/driver-tree/DriverTree";
import { DriverTreeHeatMap } from "components-ui/src/driver-tree/DriverTreeHeatMap";
import { DriverTreeNode } from "components-ui/src/driver-tree/DriverTreeNode";
import KeyDriverTreeLegendContent from "components-ui/src/driver-tree/key-driver-tree/KeyDriverTreeLegendContent";
import { KeyDriverTreeNodeContent } from "components-ui/src/driver-tree/key-driver-tree/KeyDriverTreeNodeContent";
import { getHeatMapColour } from "components-ui/src/driver-tree/utils";
import { useCallback, useEffect, useMemo } from "react";
import ReportLoadingWrapper from "../../fast-report/ReportLoadingWrapper";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";
import { useGlobalParameters } from "../../useGlobalParameters";
import { convertFocalItemToDto } from "../focalItemDto";
import { BenchmarkValueSchema } from "./BenchmarkDropdown";
import { ComparisonPeriodValueSchema } from "./ComparisonPeriodDropdown";
import styles from "./KeyDriverTree.module.css";
import { useKeyDriverTreeMeasuresQuery } from "./api/keyDriverTreeApi";
import {
  type keyDriverTreeMeasureValue,
  type keyDriverTreeNodeValues,
} from "./api/keyDriverTreeDtos";

export type KeyDriverTreeProps = {
  onTransactionSourceChanged: (
    transactionSource: TransactionSource | null
  ) => void;
};

export const KeyDriverTree = ({
  onTransactionSourceChanged,
}: KeyDriverTreeProps) => {
  const division = useDivision();
  const activeItem = useActiveItem();
  const [globalParameters, areGlobalParametersSet] = useGlobalParameters();
  const [comparisonPeriod] = useFastReportingParameterState(
    Parameter.KDTComparisonPeriod,
    ComparisonPeriodValueSchema,
    (config) => config.reports.keyDriverTree?.comparisonPeriod
  );
  const [benchmarkParameter] = useFastReportingParameterState(
    Parameter.KDTBenchmark,
    BenchmarkValueSchema,
    (config) => config.reports.keyDriverTree?.benchmark
  );
  const benchmark =
    activeItem?.type === HierarchyShortName.ProductGroup
      ? null
      : benchmarkParameter;

  const formatter = useFormatter();
  const measures = useKeyDriverTreeMeasuresQuery(
    {
      division: division.name,
      focusPeriod: globalParameters.focusPeriod,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- skipped if undefined
      item: activeItem ? convertFocalItemToDto(activeItem) : null!,
      location: globalParameters.location,
      channel: globalParameters.channel,
      promotion: globalParameters.promotion,
      banner: globalParameters.banner,
      transactionSource: globalParameters.transactionSource,
      comparisonPeriod: comparisonPeriod ?? null,
      benchmark: benchmark ?? null,
      storeFormat: globalParameters.storeFormat,
      compStore: globalParameters.compStore,
    },
    {
      skip:
        activeItem === undefined ||
        !division.name ||
        comparisonPeriod === undefined ||
        benchmark === undefined ||
        !areGlobalParametersSet,
    }
  );

  useEffect(() => {
    onTransactionSourceChanged(measures.currentData?.transactionSource ?? null);
  }, [onTransactionSourceChanged, measures.currentData?.transactionSource]);

  const formatValue = (
    node: keyDriverTreeNodeValues,
    measureValue?: keyDriverTreeMeasureValue
  ): string => {
    if (!measureValue) {
      return "";
    }

    // This addresses an edge case where when the main measure is a percentage,
    // the growth and benchmark values are a percentage point change instead
    if (
      measureValue !== node.measureValue &&
      measureValue.format === MetricTypes.Percentage &&
      node.measureValue.format === MetricTypes.Percentage
    ) {
      return formatter(measureValue.format)(measureValue.value) + " pt";
    }

    return formatter(measureValue.format)(measureValue.value);
  };

  const formatValueWithSign = (
    node: keyDriverTreeNodeValues,
    measureValue?: keyDriverTreeMeasureValue
  ): string => {
    if (!measureValue) {
      return "";
    }

    let isNegativeZero: boolean;
    if (measureValue.format === MetricTypes.Percentage) {
      isNegativeZero = measureValue.value?.toFixed(4) === "-0.0000";
    } else {
      isNegativeZero = measureValue.value?.toFixed(2) === "-0.00";
    }

    const formattedValue = isNegativeZero
      ? formatValue(node, { ...measureValue, value: 0 })
      : formatValue(node, measureValue);
    const nonNullValue = measureValue.value ?? 0;

    if (nonNullValue > 0) {
      return `+${formattedValue}`;
    }

    return formattedValue;
  };

  const getMaxContribution = useCallback(
    (dto: keyDriverTreeNodeValues): number => {
      const absoluteValue = Math.abs(dto.contribution?.value ?? 0);
      const maxOfChildren = dto.children?.map(getMaxContribution) ?? [];
      return Math.max(absoluteValue, ...maxOfChildren);
    },
    []
  );

  const maxContribution = useMemo(() => {
    if (measures.data) {
      return getMaxContribution(measures.data.tree);
    }

    return 0;
  }, [measures.data, getMaxContribution]);

  const disableBenchmark = useMemo(
    () => !measures.data?.tree.benchmark,
    [measures.data]
  );

  const convertTree = (dto: keyDriverTreeNodeValues): DriverTreeType => ({
    nodes: [
      {
        element: (
          <DriverTreeNode
            barColour={getHeatMapColour(
              maxContribution,
              dto.contribution?.value
            )}
            content={
              <KeyDriverTreeNodeContent
                benchmark={formatValueWithSign(dto, dto.benchmark)}
                change={formatValueWithSign(dto, dto.change)}
                contribution={formatValueWithSign(dto, dto.contribution)}
                key={dto.measure}
                measure={dto.measure}
                value={formatValue(dto, dto.measureValue)}
              />
            }
            key={dto.measure}
          />
        ),
        connectorStyles: {
          parent: dto.contribution ? undefined : "dashed",
          child:
            !dto.children || dto.children.length === 0
              ? "hidden"
              : dto.children.some((child) => child.contribution)
              ? undefined
              : "dashed",
        },
      },
    ],
    children: dto.children?.map(convertTree),
  });

  return (
    <div className={styles.container}>
      <ReportLoadingWrapper
        data-cy="KeyDriverTreeReport"
        isError={measures.isError}
        isLoading={measures.isFetching || !measures.data}
        reportMinimumHeight={560}
        retry={measures.refetch}
      >
        {measures.data && (
          <div
            className={classNames(
              styles.keyDriverTree,
              styles.driverTreeResizableContainer
            )}
          >
            <div className={styles.driverTreeLegendContainer}>
              <div className={styles.driverTreeLegends}>
                <DriverTreeNode.LegendNode
                  barColour={tokens.colour["shade-16"]}
                  content={
                    <KeyDriverTreeLegendContent
                      disableBenchmark={disableBenchmark}
                      primaryMeasure={measures.data.tree.measure}
                    />
                  }
                />
                <DriverTreeHeatMap
                  maxAbsGrowth={formatter(MetricTypes.Currency)(
                    maxContribution
                  )}
                />
              </div>
            </div>
            <div className={styles.conditionalFlexAlignCenter}>
              <div className={styles.conditionalMinContent}>
                <DriverTree
                  createHiddenNode={DriverTreeNode.HiddenNode}
                  root
                  tree={convertTree(measures.data.tree)}
                />
              </div>
            </div>
          </div>
        )}
      </ReportLoadingWrapper>
    </div>
  );
};
