import { type FocalItemDto } from "../../report-panels/focalItemDto";

export type reportSummaryLeafRequest = {
  item: FocalItemDto;
};

export enum ReportSummaryDisplayType {
  Date = "Date",
  ShorthandYesNo = "ShorthandYesNo",
}

export type hierarchyReportSummary = {
  code: string;
  name: string;
  shortName: string;
};

export type attributeReportSummary = {
  code: string;
  displayFormat?: ReportSummaryDisplayType;
  displayName?: string;
  displayValue: string;
  name: string;
  navigation: boolean;
  shortName: string;
};

export type reportSummaryLeafResponse = {
  attributeSummaries: attributeReportSummary[];
  parentHierarchySummary: hierarchyReportSummary;
};
