import { Spinner } from "@qbit/react";
import {
  GenericTrackingProperties,
  HierarchyItemType,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  UnknownTrackingPropertyValue,
  useEventTrackingServiceContext,
  HierarchyShortName,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useMemo } from "react";
import Zod from "zod";
import { ParameterSingleSelect } from "../../fast-report/ParameterSingleSelect";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { type LevelOfAnalysisConfiguration } from "../../fast-report/api/reportConfigurationDto";
import { useActiveItem } from "../../useActiveItem";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";

export const LevelOfAnalysisValueSchema = Zod.string().nullable();
export type LevelOfAnalysisValue = Zod.infer<typeof LevelOfAnalysisValueSchema>;

const LevelOfAnalysisDropdown = () => {
  const eventTrackingService = useEventTrackingServiceContext();

  const division = useDivision();
  const activeItem = useActiveItem();
  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.topAndBottom?.levelOfAnalysis,
        state,
      }),
      skip: !division.name,
    }
  );

  const options = useMemo(() => {
    if (reportConfig.configuration && activeItem) {
      if (
        activeItem.type === HierarchyItemType.Hierarchy ||
        activeItem.type === HierarchyItemType.Attribute
      ) {
        const availableLevels = reportConfig.configuration.levels.find(
          (level) => level.shortName === activeItem.shortName
        )?.availableLevels;

        if (availableLevels) {
          // level of analysis can’t be higher hierarchy than the selected hierarchy for attribute
          if (
            activeItem.type === HierarchyItemType.Attribute &&
            activeItem.additionalHierarchyFilter &&
            availableLevels.includes(
              activeItem.additionalHierarchyFilter.shortName
            )
          ) {
            const atOrLowerAvailableLevels = availableLevels.slice(
              availableLevels.indexOf(
                activeItem.additionalHierarchyFilter.shortName
              )
            );

            return reportConfig.configuration.options
              .filter((option) =>
                atOrLowerAvailableLevels.includes(option.value ?? "")
              )
              .map((option) => ({
                ...option,
              }));
          } else {
            return reportConfig.configuration.options
              .filter((option) => availableLevels.includes(option.value ?? ""))
              .map((option) => ({
                ...option,
              }));
          }
        }
      } else if (activeItem.type === HierarchyShortName.ProductGroup) {
        const productGroupVirtualShortName = "PRDGRP";
        const availableLevels = reportConfig.configuration.levels.find(
          (level) => level.shortName === productGroupVirtualShortName
        )?.availableLevels;
        if (availableLevels) {
          return reportConfig.configuration.options
            .filter((option) => availableLevels.includes(option.value ?? ""))
            .map((option) => ({
              ...option,
            }));
        }
      }
    }

    return undefined;
  }, [reportConfig.configuration, activeItem]);

  const [selection, setSelection] = useFastReportingParameterState<
    LevelOfAnalysisValue,
    LevelOfAnalysisConfiguration
  >(
    Parameter.TopAndBottomLevelOfAnalysis,
    LevelOfAnalysisValueSchema,
    (config) => config.reports.topAndBottom?.levelOfAnalysis,
    options?.[0]?.value,
    false
  );

  useEffect(() => {
    if (options && selection) {
      const currentlySelectedOption = options.find(
        (opt) => opt.value === selection
      );
      if (!currentlySelectedOption) {
        setSelection(options[0].value);
      }
    }
  }, [options, selection, setSelection]);

  const selectionChangedHandler = useCallback(
    (value: LevelOfAnalysisValue) => {
      if (selection && value !== selection && reportConfig.configuration) {
        const parameter = reportConfig.configuration.options.find(
          (option) => option.value === value
        );
        eventTrackingService.trackEvent(
          TrackingComponent.FastReportingTopAndBottomReport,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.LevelOfAnalysisSelected,
            parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
          )
        );

        if (parameter) {
          setSelection(parameter.value);
        }
      }
    },
    [reportConfig.configuration, selection, eventTrackingService, setSelection]
  );

  return reportConfig.configuration && selection && options ? (
    <ParameterSingleSelect
      configuration={{
        ...reportConfig.configuration,
        options,
      }}
      data-cy="LevelOfAnalysisSelector"
      onSelection={selectionChangedHandler}
      selection={selection}
    />
  ) : (
    <Spinner />
  );
};

export default LevelOfAnalysisDropdown;
