export const joinArrayWithCharacterLimit = (
  array: Array<string | undefined> | undefined,
  maxCharLength: number
): string => {
  if (!array) {
    return "";
  }

  const moreCharacterLength = 10;
  const ellipse = "...";
  let moreCount = 0;
  const displayNames: string[] = [];

  let displayAsMoreCount = false;
  for (const { selectedValue, index } of array.map(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (selectedValue, index) => ({ selectedValue, index })
  )) {
    if (selectedValue === undefined) {
      continue;
    }

    if (index === 0) {
      const additionalCharacters = array.length > 1 ? moreCharacterLength : 0;

      if (selectedValue.length > maxCharLength - additionalCharacters) {
        displayNames.push(
          selectedValue
            .slice(0, Math.max(0, maxCharLength - additionalCharacters))
            .trimEnd() + ellipse
        );
        displayAsMoreCount = true;
      } else {
        displayNames.push(selectedValue);
      }
    } else {
      let currentLength = 0;

      for (const displayName of displayNames) {
        currentLength +=
          displayName.length + (currentLength > 0 ? ellipse.length : 0);
      }

      const additionalCharacters =
        array.length - 1 > index ? moreCharacterLength : 0;

      if (
        !displayAsMoreCount &&
        currentLength + selectedValue.length <
          maxCharLength - additionalCharacters
      ) {
        displayNames.push(selectedValue);
      } else {
        displayAsMoreCount = true;
        moreCount++;
      }
    }
  }

  if (moreCount > 0) {
    if (displayNames.length === 0) {
      return `+${moreCount} more`;
    } else {
      return displayNames.join(", ") + `, +${moreCount} more`;
    }
  } else {
    return displayNames.join(", ");
  }
};
