import {
  Icon,
  IconColour,
  IconGlyph,
  Message,
  MessageVariant,
  Nav,
  NavButton,
  NavVariant,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  FeatureFlag,
  useGetCustomerGroupSharingActivitiesQuery,
  useGetUsersByIdQuery,
  CustomerGroupStatus,
  type CustomerGroupDtoWithDisplayName,
  useGetCustomerGroupQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { CustomerGroupSegments } from "components-ui/src/customer-group-segments/CustomerGroupSegments";
import { CustomerGroupSummary } from "components-ui/src/customer-group-summary/CustomerGroupSummary";
import { InfoPanelActivity } from "components-ui/src/info-panel/info-panel-body/InfoPanelActivity";
import { PanelWithSideDrawer } from "components-ui/src/panel-with-side-drawer/PanelWithSideDrawer";
import { useState, type PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import { GroupType } from "../../enums/group-type";
import {
  selectFocalCustomerGroups,
  setFocalCustomerGroup,
  setShareCustomerGroup,
} from "../../states/group-list-slice";
import { getDefaultGroupsPath } from "../../utilities/route-path-formats";
import styles from "./GroupInfoPanel.module.css";
import { GroupInfoPanelHeader } from "./group-info-panel-header/GroupInfoPanelHeader";

export const CustomerGroupInfoPanel = ({ children }: PropsWithChildren) => {
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const flags = useFlags();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const division = useDivision();
  const divisionName = division.name;

  const focalGroup = useSelector(selectFocalCustomerGroups);
  const showDrawer = Boolean(divisionName && groupId && focalGroup);
  const showSharing = showDrawer && flags[FeatureFlag.SharingGroups];

  const { data: customerGroupData } = useGetCustomerGroupQuery(
    {
      divisionName,
      groupId: groupId ?? "",
    },
    {
      skip: !showDrawer,
    }
  );

  const customerGroupDataWithDisplayName:
    | CustomerGroupDtoWithDisplayName
    | undefined =
    customerGroupData && customerGroupData.id === focalGroup?.id
      ? {
          ...customerGroupData,
          typeDisplayName: focalGroup.typeDisplayName,
        }
      : focalGroup;

  const { data: sharingActivities, isLoading: isActivityLoading } =
    useGetCustomerGroupSharingActivitiesQuery(
      {
        divisionName,
        groupId: groupId ?? "",
      },
      {
        skip: !showSharing,
      }
    );
  const { data: reportSharedUsers, isLoading: isReportSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds:
            sharingActivities?.flatMap(
              (sharingActivity) => sharingActivity.salesforceUserIds
            ) ?? [],
        },
      },
      {
        skip: !(showSharing && Boolean(sharingActivities?.length)),
      }
    );

  const onClose = () => {
    dispatch(setFocalCustomerGroup(undefined));
    navigate(
      getDefaultGroupsPath(
        divisionName,
        "Customer",
        CustomerGroupTab.CustomerGroups
      )
    );
  };

  const label = (
    <div className={styles.infoPanelLabel}>
      <Icon
        colour={IconColour.Notification}
        glyph={IconGlyph.AccountAndUserAccount}
        text="Customer group"
      />
      <Text>{`${GroupType.Customer} group`}</Text>
    </div>
  );

  const infoPanelTabs = [
    {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          <CustomerGroupSummary
            group={customerGroupDataWithDisplayName}
            title="Group info"
          />
        </div>
      ),
      title: "Summary",
    },
  ];

  const showSegmentBuilder =
    flags[FeatureFlag.SegmentBuilder] &&
    division.moduleAccess.groups.customerGroups.segmentBuilder.hasAccess;

  if (showSegmentBuilder) {
    const segmentsTab = {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          <CustomerGroupSegments
            focalGroup={customerGroupDataWithDisplayName}
          />
        </div>
      ),
      title: "Segments",
    };

    infoPanelTabs.push(segmentsTab);
  }

  if (flags[FeatureFlag.SharingGroups]) {
    const activityTab = {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          {isActivityLoading || isReportSharedUsersLoading ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <InfoPanelActivity
              resourceType="group"
              sharedUsers={reportSharedUsers}
              sharingActivities={sharingActivities}
            />
          )}
        </div>
      ),
      title: "Activity",
    };
    infoPanelTabs.push(activityTab);
  }

  const header = (
    <>
      <GroupInfoPanelHeader
        focalGroup={customerGroupDataWithDisplayName}
        groupStatus={customerGroupDataWithDisplayName?.status}
        groupType={GroupType.Customer}
        onClose={onClose}
        onShare={() => dispatch(setShareCustomerGroup(focalGroup))}
        templateType={customerGroupDataWithDisplayName?.type}
      />
      {customerGroupDataWithDisplayName?.status ===
        CustomerGroupStatus.Invalid && (
        <Message
          className={styles.groupSummaryMessage}
          heading={<h5>Customer group is invalid</h5>}
          showIcon
          text="One or more segments have a customer count less than 100."
          variant={MessageVariant.Danger}
        />
      )}
      <Nav activeIndex={currentNavIndex} variant={NavVariant.Tab}>
        {infoPanelTabs.map((tab, index) => (
          <NavButton key={tab.title} onClick={() => setCurrentNavIndex(index)}>
            {tab.title}
          </NavButton>
        ))}
      </Nav>
    </>
  );

  const body = infoPanelTabs[currentNavIndex].content;

  return (
    <PanelWithSideDrawer
      closeFunc={onClose}
      drawerContent={{
        body,
        header,
        label,
      }}
      isOverlay={false}
      showDrawer={showDrawer}
    >
      {children}
    </PanelWithSideDrawer>
  );
};
