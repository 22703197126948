import {
  MetricTypes,
  useDivision,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import { useActiveItem } from "../../useActiveItem";
import { useGlobalParameters } from "../../useGlobalParameters";
import { convertFocalItemToDto } from "../focalItemDto";
import { AggregateValue } from "./AggregateValue";
import styles from "./PerformanceAggregateValues.module.css";
import { usePerformanceAggregateMeasuresQuery } from "./api/performanceReportApi";

export type PerformanceAggregateValuesProps = {
  selectedMetricKeys: string[];
};

export const PerformanceAggregateValues = ({
  selectedMetricKeys,
}: PerformanceAggregateValuesProps) => {
  const division = useDivision();
  const activeItem = useActiveItem();
  const [globalParameters, areGlobalParametersSet] = useGlobalParameters();
  const formatter = useFormatter();

  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.performance,
        state,
      }),
      skip: !division.name,
    }
  );

  const aggregateValues = usePerformanceAggregateMeasuresQuery(
    {
      division: division.name,
      focusPeriod: globalParameters.focusPeriod,
      location: globalParameters.location,
      channel: globalParameters.channel,
      promotion: globalParameters.promotion,
      banner: globalParameters.banner,
      transactionSource: globalParameters.transactionSource,
      storeFormat: globalParameters.storeFormat,
      compStore: globalParameters.compStore,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- skipped if undefined
      item: activeItem ? convertFocalItemToDto(activeItem) : null!,
    },
    {
      skip:
        activeItem === undefined || !division.name || !areGlobalParametersSet,
    }
  );

  let aggregateMetricKeys: string[] = [];
  if (reportConfig.configuration?.aggregateMapping) {
    for (const mapping of reportConfig.configuration.aggregateMapping) {
      if (
        mapping.selectedMetric === null ||
        selectedMetricKeys.includes(mapping.selectedMetric)
      ) {
        aggregateMetricKeys = mapping.aggregates;
        break;
      }
    }
  }

  return (
    <div className={styles.aggregateValues}>
      {reportConfig.configuration &&
        aggregateMetricKeys.map((key) => (
          <AggregateValue
            baseValue={aggregateValues.currentData?.results[key]?.value}
            baseValueFormatter={formatter(
              reportConfig.configuration?.metricMetadata.find(
                (meta) => meta.key === key
              )?.format ?? MetricTypes.Integer
            )}
            displayName={
              reportConfig.configuration?.metricMetadata.find(
                (meta) => meta.key === key
              )?.displayName ?? key
            }
            growthValue={aggregateValues.currentData?.results[key]?.growth}
            isError={aggregateValues.isError && !aggregateValues.isFetching}
            isFetching={
              aggregateValues.isUninitialized || aggregateValues.isFetching
            }
            key={key}
            onRetry={() => aggregateValues.refetch()}
          />
        ))}
    </div>
  );
};
