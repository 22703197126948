import {
  FeatureFlag,
  useGetOrganizationUsersQuery,
  useGetUsersByIdQuery,
  useShareGroupMutation,
  useUnshareGroupMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { ShareModal } from "components-ui/src/modals";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShareFolderOrGroup,
  setShareFolderOrGroup,
} from "../../states/group-list-slice";
import { isFolder } from "../../utilities/folder-helper";

export const ShareHierarchyGroupModal = () => {
  const dispatch = useDispatch();
  const flags = useFlags();
  const enableTrueShare = flags[FeatureFlag.ProductGroupsTrueShare];
  const [shareGroup] = useShareGroupMutation();
  const [unshareGroup] = useUnshareGroupMutation();
  const division = useDivision();
  const shareFolderOrGroup = useSelector(selectShareFolderOrGroup);

  const entityType = isFolder(shareFolderOrGroup) ? "Folder" : "Group";
  const groupId = shareFolderOrGroup?.id;
  const groupName = shareFolderOrGroup?.name;
  const ownerId = shareFolderOrGroup?.userId;
  const sharedWithUserIds = shareFolderOrGroup?.sharedWithUserIds;

  const { data: organisationUsers } = useGetOrganizationUsersQuery({
    divisionName: division.name,
  });
  const { data: owner } = useGetUsersByIdQuery(
    {
      payload: {
        SalesforceUserIds: ownerId ? [ownerId] : [],
      },
    },
    {
      skip: !ownerId,
    }
  );
  const shareGroupCallback = useCallback(
    async (
      divisionName: string,
      salesforceUserIds: string[],
      sharingNotes: string
    ) =>
      await shareGroup({
        divisionName,
        payload: {
          entityType,
          userIds: salesforceUserIds,
          sharingNotes,
          sourceId: groupId ?? "",
        },
      }),
    [shareGroup, groupId, entityType]
  );
  const unshareGroupCallback = useCallback(
    async (salesforceUserId: string) => {
      await unshareGroup({
        divisionName: division.name,
        payload: {
          entityType,
          userIds: [salesforceUserId],
          sharingNotes: "",
          sourceId: groupId ?? "",
        },
      });
    },
    [unshareGroup, groupId, entityType, division]
  );

  return (
    <ShareModal
      enableTrueShare={enableTrueShare}
      onClose={() => dispatch(setShareFolderOrGroup(undefined))}
      organisationUsers={organisationUsers}
      owner={owner ? owner[0] : undefined}
      resourceType={entityType.toLowerCase()}
      share={shareGroupCallback}
      sharedWithUserIds={sharedWithUserIds}
      showModal={Boolean(shareFolderOrGroup)}
      subtitle={entityType.toUpperCase()}
      title={groupName ?? ""}
      unshare={unshareGroupCallback}
    />
  );
};
