import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui/src/models/hierarchy-slice-dto";
import { createSelector, type PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { type RowSelectionState } from "@tanstack/react-table";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { EMPTY_HIERARCHY_SLICE_NODE } from "../../common/constants";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import { buildNestedRows, findNode } from "../components/top-drawer/utils";
import {
  type KeyDriversOverTimeMetaData,
  type KeyDriversOverTimeTableRow,
} from "../models/key-drivers-over-time-table-models";
import { type ReportletDataResponseDto } from "../models/reportlet-reponse";
import { getDefaultSelections } from "../utils/getDefaultSelections";
import { type LocalParametersResponse } from "./key-drivers-over-time-local-parameters-api-slice";
import {
  type RefetchTableResponse,
  type InitialTableResponse,
  type FetchChildrenTableResponse,
} from "./key-drivers-over-time-table-data-api-slice";

const getPersistenceKey = (reportId: string) =>
  `key-drivers-over-time-${reportId}`;

export type KeyDriversOverTimeLocalSelections = {
  Channel: PanelOption;
  CompStore: PanelOption;
  KeyDriver: PanelOption;
  LocationHierarchy: LocalHierarchyNodeSelection;
  Segment: PanelOption;
  Segmentation: PanelOption;
  Time?: string;
  TimePeriodLength?: string;
};

export type KeyDriversOverTimePersistedSelections = {
  focalItem: HierarchySliceNodeDto;
  focalItemTableRowSelectionState: RowSelectionState;
  localParameterSelections: KeyDriversOverTimeLocalSelections;
};

export type KeyDriversOverTimeState = {
  // FOCAL ITEM TABLE
  focalItemSearch: string;
  focalItemSearchResultsAreShown: boolean;
  focalItemTableInitialised: boolean;
  focalItemTableMetaData: KeyDriversOverTimeMetaData;
  focalItemTableRows: KeyDriversOverTimeTableRow[];
  hierarchySliceHypercubeId: string;

  // LOCAL PARAMETERS
  localParameterConfig: SidePanelParameter[];
  localParametersInitialised: boolean;

  //   displayed items
  //   selected focal item
  //   focal item table data
  metaData: {
    reportId: string;
    reportName: string;
  };
  // PERSISTED SELECTIONS
  persistedSelections: KeyDriversOverTimePersistedSelections;
  persistedSelectionsLoaded: boolean;

  // Reportlet
  reportletData: ReportletDataResponseDto;
  reportletTabActive: string;
  showChartDataLabels: boolean;
};

export const initialState: KeyDriversOverTimeState = {
  persistedSelections: {
    focalItem: EMPTY_HIERARCHY_SLICE_NODE,
    focalItemTableRowSelectionState: {},
    localParameterSelections: {
      Channel: {
        value: "",
        label: "",
      },
      CompStore: {
        value: "",
        label: "",
      },
      KeyDriver: {
        value: "",
        label: "",
      },
      LocationHierarchy: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
        isDefault: false,
      },
      Time: "",
      TimePeriodLength: "",
      Segmentation: {
        value: "",
        label: "",
      },
      Segment: {
        value: "",
        label: "",
      },
    },
  },
  persistedSelectionsLoaded: false,

  focalItemTableInitialised: false,
  focalItemTableMetaData: {
    measureMetaData: [],
    promoWeeks: [],
    selectedBaseMeasure: "",
  } as KeyDriversOverTimeMetaData,
  focalItemTableRows: [],
  focalItemSearch: "",
  focalItemSearchResultsAreShown: false,
  hierarchySliceHypercubeId: "",

  localParameterConfig: [],

  localParametersInitialised: false,

  metaData: {
    reportId: "",
    reportName: "Key drivers over time",
  },

  reportletData: {
    label: "",
    promoWeeks: [],
  },
  reportletTabActive: "Chart",
  showChartDataLabels: false,
};

export const keyDriversOverTimeSlice = createSlice({
  initialState,
  name: "key-drivers-over-time",
  reducers: {
    onChannelChange: (
      state: KeyDriversOverTimeState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.Channel =
        action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },

    onCompStoreChange: (
      state: KeyDriversOverTimeState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.CompStore = {
        label: action.payload.label,
        value: action.payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },

    onFocalItemChange: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<HierarchySliceNodeDto>
    ) => {
      // deselect old focal item
      for (const key of Object.keys(
        state.persistedSelections.focalItemTableRowSelectionState
      )) {
        state.persistedSelections.focalItemTableRowSelectionState[key] = false;
      }

      // select new focal item
      state.persistedSelections.focalItem = payload;
      state.persistedSelections.focalItemTableRowSelectionState[
        payload.nodeNumber
      ] = true;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },

    onSearchChange: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<string>
    ) => {
      // we want to treat " " as no search
      state.focalItemSearch = payload.trim();
    },

    onInitialTableDataReceived: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<InitialTableResponse>
    ) => {
      state.focalItemTableMetaData = payload.tableMetaData;
      state.hierarchySliceHypercubeId = payload.hierarchySliceHypercubeId;
      state.focalItemTableRows = buildNestedRows(payload.tableRows);

      state.focalItemTableInitialised = true;
    },

    onKeyDriverChange: (
      state: KeyDriversOverTimeState,
      action: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParameterSelections.KeyDriver =
        action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },

    onLocalParametersReceived: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<LocalParametersResponse>
    ) => {
      state.localParameterConfig = payload.localParameters;

      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParameterSelections =
          getDefaultSelections(payload.localParameters);

        if (payload.defaultFocalItem) {
          state.persistedSelections.focalItem = payload.defaultFocalItem;

          for (const key of Object.keys(
            state.persistedSelections.focalItemTableRowSelectionState
          )) {
            state.persistedSelections.focalItemTableRowSelectionState[key] =
              false;
          }

          state.persistedSelections.focalItemTableRowSelectionState[
            payload.defaultFocalItem.nodeNumber
          ] = true;
        }

        persistSelections(
          getPersistenceKey(state.metaData.reportId),
          state.persistedSelections
        );
      }

      state.localParametersInitialised = true;
    },

    onLocationChange: (
      state: KeyDriversOverTimeState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localParameterSelections.LocationHierarchy =
        action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },

    onMetadataSuccess: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<{ reportId: string; reportName: string }>
    ) => {
      state.metaData = payload;
    },

    onReportletDataReceived: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<ReportletDataResponseDto>
    ) => {
      state.reportletData = payload;
    },

    onReportletTabChange: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<string>
    ) => {
      state.reportletTabActive = payload;
    },
    onTableChildrenDataReceived: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<FetchChildrenTableResponse>
    ) => {
      const parentRow = findNode(state.focalItemTableRows, payload.parentNode);
      if (parentRow) {
        parentRow.subRows = payload.tableRows.map((row) => ({
          data: row.data,
          hierarchyItem: row.hierarchyItem,
          subRows: [],
        }));
      }
    },
    onTableDataReceived: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<RefetchTableResponse>
    ) => {
      state.focalItemTableMetaData = payload.tableMetaData;
      state.focalItemTableRows = buildNestedRows(payload.tableRows);
      state.focalItemSearchResultsAreShown = false;
    },
    onSearchResultsReceived: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<RefetchTableResponse>
    ) => {
      state.focalItemTableMetaData = payload.tableMetaData;
      state.focalItemTableRows = buildNestedRows(payload.tableRows, true);
      state.focalItemSearchResultsAreShown = true;
    },
    reset: () => initialState,
    onSegmentationChange: (
      state: KeyDriversOverTimeState,
      { payload }: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localParameterSelections.Segmentation = {
        value: payload.segmentationValue,
        label: payload.segmentationLabel,
      };
      state.persistedSelections.localParameterSelections.Segment = {
        value: payload.segmentValue,
        label: payload.segmentLabel,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onToggleShowChartDataLabels: (state: KeyDriversOverTimeState) => {
      state.showChartDataLabels = !state.showChartDataLabels;
    },
    onReportOpen: (
      state: KeyDriversOverTimeState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      const persistedSelections: KeyDriversOverTimePersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
          reportId: action.payload.reportId,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
        reportId: action.payload.reportId,
      };
    },
  },
});

export const {
  onChannelChange,
  onCompStoreChange,
  onFocalItemChange,
  onSearchChange,
  onKeyDriverChange,
  onLocalParametersReceived,
  onLocationChange,
  onMetadataSuccess,
  onReportletDataReceived,
  onReportletTabChange,
  onInitialTableDataReceived,
  onTableChildrenDataReceived,
  onTableDataReceived,
  onSearchResultsReceived,
  reset,
  onSegmentationChange,
  onToggleShowChartDataLabels,
  onReportOpen,
} = keyDriversOverTimeSlice.actions;

export const selectFocalItem = createSelector(
  (state: RootState) => state.keyDriversOverTime.persistedSelections.focalItem,
  (focalItem) => focalItem
);
export const selectHierarchySliceHypercubeId = createSelector(
  (state: RootState) => state.keyDriversOverTime.hierarchySliceHypercubeId,
  (hierarchySliceHypercubeId) => hierarchySliceHypercubeId
);
export const selectInitialDataReceived = createSelector(
  (state: RootState) => state.keyDriversOverTime.focalItemTableInitialised,
  (focalItemTableInitialised) => focalItemTableInitialised
);
export const selectLocalParameterSelections = createSelector(
  (state: RootState) =>
    state.keyDriversOverTime.persistedSelections.localParameterSelections,
  (localParameterSelections) => localParameterSelections
);
export const selectKeyDriver = createSelector(
  (state: RootState) =>
    state.keyDriversOverTime.persistedSelections.localParameterSelections
      .KeyDriver,
  (keyDriver) => keyDriver
);
export const selectChannel = createSelector(
  (state: RootState) =>
    state.keyDriversOverTime.persistedSelections.localParameterSelections
      .Channel,
  (channel) => channel
);
export const selectCompStore = createSelector(
  (state: RootState) =>
    state.keyDriversOverTime.persistedSelections.localParameterSelections
      .CompStore,
  (compStore) => compStore
);
export const selectLocationHierarchy = createSelector(
  (state: RootState) =>
    state.keyDriversOverTime.persistedSelections.localParameterSelections
      .LocationHierarchy,
  (locationHierarchy) => locationHierarchy
);
export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.keyDriversOverTime.persistedSelections.localParameterSelections
      .Segmentation,
  (segmentation) => segmentation
);
export const selectSegment = createSelector(
  (state: RootState) =>
    state.keyDriversOverTime.persistedSelections.localParameterSelections
      .Segment,
  (segment) => segment
);
export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.keyDriversOverTime.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);
export const selectRowSelection = createSelector(
  (state: RootState) =>
    state.keyDriversOverTime.persistedSelections
      .focalItemTableRowSelectionState,
  (focalItemTableRowSelectionState) => focalItemTableRowSelectionState
);
export const selectTableMetaData = createSelector(
  (state: RootState) => state.keyDriversOverTime.focalItemTableMetaData,
  (focalItemTableMetaData) => focalItemTableMetaData
);
export const selectTableRows = createSelector(
  (state: RootState) => state.keyDriversOverTime.focalItemTableRows,
  (focalItemTableRows) => focalItemTableRows
);
export const selectSearch = createSelector(
  (state: RootState) => state.keyDriversOverTime.focalItemSearch,
  (focalItemSearch) => focalItemSearch
);
export const selectSearchResultsAreShown = createSelector(
  (state: RootState) => state.keyDriversOverTime.focalItemSearchResultsAreShown,
  (focalItemSearchResultsAreShown) => focalItemSearchResultsAreShown
);

export default keyDriversOverTimeSlice.reducer;
