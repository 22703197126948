import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  QbitToastPosition,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  ddLog,
  useCreateSavedParametersMutation,
  useLazyExportHypercubeQuery,
  type FolderDtoReduced,
  type FolderOrReportDto,
} from "@quantium-enterprise/common-ui";
import {
  useRenameReportMutation,
  useLazyGetGeneratedReportsQuery,
  useCreateFolderMutation,
  useRenameFolderMutation,
  useGetGeneratedReportsQuery,
  onRootFolderReceived,
  setIsPollingEnabled,
  EMIT_TOAST_DURATION,
  updateRowData,
  setRenameId,
  setFolderExpandedState,
  convertToFolderOrReportDto,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  isReportRow,
  MyReportsGridWithFolders,
} from "../my-reports-grid/MyReportsGridWithFolders";
import { NoContent, Variant } from "../no-content/NoContent";
import { type RootState } from "../store";
import styles from "./GeneratedReports.module.css";

export enum ReportType {
  ReportParameters = "ReportParameters",
  SavedParameters = "SavedParameters",
}

export const GeneratedReports = () => {
  const { id: focalRowId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { name: activeDivisionName } = useDivision();
  const [renameReport] = useRenameReportMutation();
  const [renameFolder] = useRenameFolderMutation();
  const [createFolder] = useCreateFolderMutation();
  const [triggerGetGeneratedReports, { data: triggerGetGeneratedReportsData }] =
    useLazyGetGeneratedReportsQuery();
  const [createSavedParametersTrigger] = useCreateSavedParametersMutation();
  const [exportHypercubeTrigger] = useLazyExportHypercubeQuery();
  const [isCreateFolderLoading, setIsCreateFolderLoading] = useState(false);

  const { rowData, isPollingEnabled, rowDataInitialized } = useSelector(
    (state: RootState) => ({
      rowData: state.generatedReports.rowData,
      isPollingEnabled: state.generatedReports.isPollingEnabled,
      rowDataInitialized: state.generatedReports.rowDataInitialized,
    })
  );

  const selectedRowData = useMemo(
    () => rowData[focalRowId ?? ""] as FolderOrReportDto | undefined,
    [focalRowId, rowData]
  );

  const { data: rootFolder } = useGetGeneratedReportsQuery(
    {
      divisionName: activeDivisionName,
    },
    {
      skip: !activeDivisionName || !isPollingEnabled,
      // refetchOnMountOrArgChange: true,
      pollingInterval: 30_000,
    }
  );

  // handles scenario where redirected from report wizard
  useEffect(() => {
    if (
      activeDivisionName &&
      Boolean(new URLSearchParams(location.search).get("reload"))
    ) {
      triggerGetGeneratedReports({
        divisionName: activeDivisionName,
      }).catch((error: Error) => ddLog("ERROR", {}, "error", error));
    }
  }, [location, activeDivisionName, triggerGetGeneratedReports]);

  useEffect(() => {
    if (triggerGetGeneratedReportsData)
      dispatch(onRootFolderReceived(triggerGetGeneratedReportsData));
  }, [triggerGetGeneratedReportsData, dispatch]);

  useEffect(() => {
    if (rootFolder) {
      dispatch(onRootFolderReceived(rootFolder));
    }
  }, [rootFolder, dispatch]);

  const updateRowName = useCallback(
    (newRowName: string, rowId: string) => {
      dispatch(
        updateRowData({
          ...rowData,
          [String(rowId)]: { ...rowData[rowId], name: newRowName },
        })
      );
    },
    [rowData, dispatch]
  );

  const handleRenameRow = useCallback(
    async (newRowName: string, rowType: string, rowId?: string) => {
      const genericToast = () =>
        QbitEmitToast(
          <QbitToastMessage
            content={
              <>
                <p>Something went wrong. Please try again later</p>
                <p> Contact support if error persists. </p>
              </>
            }
            heading={<h5>An error occurred during rename operation</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );

      if (!rowId) {
        genericToast();
        return true;
      }

      dispatch(setRenameId({ id: undefined }));
      const oldRowName = rowData[rowId].name;
      updateRowName(newRowName, rowId);
      (rowType === "Report"
        ? renameReport({
            divisionName: activeDivisionName,
            payload: { newReportName: newRowName, reportId: rowId },
          })
        : renameFolder({
            divisionName: activeDivisionName,
            payload: { newFolderName: newRowName, folderId: rowId },
          })
      )
        .unwrap()
        .then(() => {
          QbitEmitToast(
            <QbitToastMessage
              content={<p>{`${rowType} has been renamed`}</p>}
              showIcon
              variant={MessageVariant.Success}
            />,
            {
              autoClose: EMIT_TOAST_DURATION,
            }
          );
        })
        .catch(({ status: errorStatus }) => {
          updateRowName(oldRowName, rowId);
          if (errorStatus === 409) {
            QbitEmitToast(
              <QbitToastMessage
                content={
                  <p>{`A ${rowType} with the same name already exists.`}</p>
                }
                heading={<h5>{`${rowType} creation failed.`}</h5>}
                showIcon
                variant={MessageVariant.Danger}
              />,
              {
                autoClose: EMIT_TOAST_DURATION,
              }
            );
          } else {
            genericToast();
          }
        })
        .finally(() => {
          dispatch(setIsPollingEnabled(true));
        });

      return true;
    },
    [
      renameReport,
      activeDivisionName,
      rowData,
      updateRowName,
      dispatch,
      renameFolder,
    ]
  );

  const handleCreateFolder = useCallback(async () => {
    setIsCreateFolderLoading(true);
    createFolder({
      divisionName: activeDivisionName,
      payload: {
        reportType: ReportType.ReportParameters,
        parentFolderId: selectedRowData?.isFolder ? focalRowId : undefined,
      },
    })
      .unwrap()
      .then(async () => {
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={<span>Created new folder.</span>}
            showCloseButton
            showIcon
            variant={MessageVariant.Success}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );
        const response = await triggerGetGeneratedReports({
          divisionName: activeDivisionName,
        }).unwrap();

        if (focalRowId) {
          dispatch(
            setFolderExpandedState({ id: focalRowId, isExpanded: true })
          );
        }

        const latestFolder = convertToFolderOrReportDto(response)
          .filter((row) => row.isFolder)
          // eslint-disable-next-line unicorn/no-array-reduce
          .reduce((newest, item) =>
            new Date((item as FolderDtoReduced).creationDateUTC) >
            new Date((newest as FolderDtoReduced).creationDateUTC)
              ? item
              : newest
          );
        setIsCreateFolderLoading(false);
        dispatch(setIsPollingEnabled(false));
        dispatch(setRenameId({ id: latestFolder.id }));
      })
      .catch(() => {
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={<span>Failed to create folder.</span>}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );
        setIsCreateFolderLoading(false);
      });
  }, [
    activeDivisionName,
    focalRowId,
    selectedRowData,
    dispatch,
    triggerGetGeneratedReports,
    createFolder,
  ]);

  const handleCreateSavedParameters = useCallback(
    async (reportId: string) => {
      try {
        await createSavedParametersTrigger({
          divisionName: activeDivisionName,
          payload: { reportParametersId: reportId },
        }).unwrap();
        const heading = "Success";
        const content = "Selections for 1 report saved";
        QbitEmitToast(
          <QbitToastMessage
            content={<p>{content}</p>}
            heading={<h5>{heading}</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Success}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );
      } catch (error) {
        const content = "An unknown error has occurred";
        const heading = "Unknown error";
        ddLog("ERROR", {}, "error", error as Error);
        QbitEmitToast(
          <QbitToastMessage
            content={<p>{content}</p>}
            heading={<h5>{heading}</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: 3_000,
            position: QbitToastPosition.BottomRight,
          }
        );
      }
    },
    [activeDivisionName, createSavedParametersTrigger]
  );

  const handleExportHypercube = useCallback(
    async (reportId: string) => {
      try {
        const report = rowData[reportId];

        if (!isReportRow(report)) {
          return;
        }

        await exportHypercubeTrigger({
          divisionName: activeDivisionName,
          payload: {
            reportId,
            reportType: report.reportType,
          },
        }).unwrap();
      } catch (error) {
        const content = "An unknown error has occurred";
        const heading = "Unknown error";
        ddLog("ERROR", {}, "error", error as Error);
        QbitEmitToast(
          <QbitToastMessage
            content={<p>{content}</p>}
            heading={<h5>{heading}</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: 3_000,
            position: QbitToastPosition.BottomRight,
          }
        );
      }
    },
    [activeDivisionName, exportHypercubeTrigger, rowData]
  );

  return (
    <div className={styles.generatedReportsContainer}>
      {rowDataInitialized && (
        <Button
          className={styles.newFolderButton}
          height={ButtonHeight.XSmall}
          loading={isCreateFolderLoading}
          onClick={handleCreateFolder}
          variant={ButtonVariant.Secondary}
        >
          <Icon
            glyph={IconGlyph.AddAndPlusFolderPlus}
            text="Create new folder"
          />
          <Text>New folder</Text>
        </Button>
      )}
      <div className={styles.generatedReportsList}>
        {rowDataInitialized ? (
          Object.keys(rowData).length > 0 ? (
            <MyReportsGridWithFolders
              exportHypercube={handleExportHypercube}
              renameFolder={async (newRowName: string, rowId?: string) =>
                await handleRenameRow(newRowName, "Folder", rowId)
              }
              renameReport={async (newRowName: string, rowId?: string) =>
                await handleRenameRow(newRowName, "Report", rowId)
              }
              saveParameters={handleCreateSavedParameters}
            />
          ) : (
            <NoContent
              activeDivisionName={activeDivisionName}
              variant={Variant.GeneratedReports}
            />
          )
        ) : (
          <Spinner size={SpinnerSize.Large} />
        )}
      </div>
    </div>
  );
};

export default GeneratedReports;
