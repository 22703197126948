import {
  customerGroupsApi,
  type CustomerGroupWithSharingDto,
  type FolderOrGroupDto,
  type GroupFolderDto,
  groupsApi,
  mapFolderDtoToFolderOrGroupDto,
  ShareRootFolderId,
} from "@quantium-enterprise/common-ui";
import {
  type PayloadAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { type SegmentRow } from "../components/segment-library-table/SegmentLibraryTableCells";
import { GroupType } from "../enums/group-type";
import { type RootState } from "../store";
import { getAllSharedFolderIds, getChildIds } from "../utilities/folder-helper";

export type ScrollState = {
  initialised: boolean;
  scrollToId?: string;
};

export type GroupListState = {
  activatedGroupIds: string[];
  customerGroups: CustomerGroupWithSharingDto[];
  deleteCustomerGroups: CustomerGroupWithSharingDto[];
  deleteFolderOrGroups: FolderOrGroupDto[];
  expandedIds: string[];
  focalCustomerGroup?: CustomerGroupWithSharingDto;
  focalGroup?: FolderOrGroupDto;
  focalSegment?: SegmentRow;
  folders?: GroupFolderDto;
  moveFolderOrGroup?: FolderOrGroupDto;
  productOrLocationFlatFoldersAndGroups: FolderOrGroupDto[];
  renameState: { id?: string; isEditing: boolean };
  scrollStates: Record<GroupType, ScrollState>;
  searchText: string;
  selectedCustomerGroups: CustomerGroupWithSharingDto[];
  selectedFolderOrGroups: FolderOrGroupDto[];
  selectedSegments: SegmentRow[];
  shareCustomerGroup?: CustomerGroupWithSharingDto;
  shareFolderOrGroup?: FolderOrGroupDto;
  showActiveSegments: boolean;
};
const initialState: GroupListState = {
  deleteFolderOrGroups: [],
  deleteCustomerGroups: [],
  expandedIds: [],
  focalGroup: undefined,
  focalCustomerGroup: undefined,
  folders: undefined,
  moveFolderOrGroup: undefined,
  shareFolderOrGroup: undefined,
  shareCustomerGroup: undefined,
  renameState: { id: undefined, isEditing: false },
  productOrLocationFlatFoldersAndGroups: [],
  customerGroups: [],
  scrollStates: {
    [GroupType.Product]: { scrollToId: undefined, initialised: false },
    [GroupType.Location]: { scrollToId: undefined, initialised: false },
    [GroupType.Customer]: { scrollToId: undefined, initialised: false },
  },
  searchText: "",
  activatedGroupIds: [],
  showActiveSegments: false,
  selectedFolderOrGroups: [],
  selectedCustomerGroups: [],
  selectedSegments: [],
};

export const groupListSlice = createSlice({
  initialState,
  name: "group-list-slice",
  reducers: {
    // toggling and unsetting when child folders change expanded state
    setFolderExpandedState: (
      state: GroupListState,
      {
        payload: { currentUserId, id, isExpanded },
      }: PayloadAction<{
        currentUserId: string | undefined;
        id: string;
        isExpanded: boolean;
      }>
    ) => {
      let newExpandedIds = [...state.expandedIds];

      if (isExpanded) {
        if (!state.expandedIds.includes(id)) {
          newExpandedIds.push(id);
        }
      } else {
        // recursively remove this id and any of its children from the newExpandedIds array
        // eslint-disable-next-line no-lonely-if -- readability
        if (state.folders) {
          const idsToRemove =
            id === ShareRootFolderId
              ? [id, ...getAllSharedFolderIds(state.folders, currentUserId)]
              : getChildIds(id, state.folders);

          newExpandedIds = newExpandedIds.filter(
            (groupOrFolderId: string) => !idsToRemove.includes(groupOrFolderId)
          );
        }
      }

      state.expandedIds = newExpandedIds;
    },
    setRenameId: (
      state: GroupListState,
      { payload: { id } }: PayloadAction<{ id?: string }>
    ) => {
      state.renameState.id = id;
    },
    setEditingState: (
      state: GroupListState,
      { payload: { editing } }: PayloadAction<{ editing: boolean }>
    ) => {
      state.renameState.isEditing = editing;
      if (!editing) {
        state.renameState.id = undefined;
      }
    },
    resetRenameState: (state: GroupListState) => {
      state.renameState.id = undefined;
      state.renameState.isEditing = false;
    },
    setSearchText: (state: GroupListState, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setActivatedGroupIds: (
      state: GroupListState,
      action: PayloadAction<string[]>
    ) => {
      state.activatedGroupIds = action.payload;
    },
    setShowActiveSegments: (
      state: GroupListState,
      action: PayloadAction<boolean>
    ) => {
      state.showActiveSegments = action.payload;
    },
    addSelectedSegments: (
      state: GroupListState,
      action: PayloadAction<SegmentRow>
    ) => {
      state.selectedSegments = [...state.selectedSegments, action.payload];
    },
    removeSelectedSegments: (
      state: GroupListState,
      action: PayloadAction<SegmentRow>
    ) => {
      state.selectedSegments = state.selectedSegments.filter(
        (segment) =>
          segment.customerGroupId !== action.payload.customerGroupId ||
          segment.key !== action.payload.key
      );
    },
    clearSelectedSegments: (state: GroupListState) => {
      state.selectedSegments = [];
    },
    setScrollToId: (
      state: GroupListState,
      {
        payload: { groupType, scrollToId },
      }: PayloadAction<{
        groupType: GroupType;
        scrollToId: string | undefined;
      }>
    ) => {
      state.scrollStates[groupType].scrollToId = scrollToId;
    },
    setScrollInitialised: (
      state: GroupListState,
      {
        payload: { groupType, initialised },
      }: PayloadAction<{
        groupType: GroupType;
        initialised: boolean;
      }>
    ) => {
      state.scrollStates[groupType].initialised = initialised;
    },
    resetAllScrollStates: (state: GroupListState) => {
      state.scrollStates = {
        [GroupType.Product]: { scrollToId: undefined, initialised: false },
        [GroupType.Location]: { scrollToId: undefined, initialised: false },
        [GroupType.Customer]: { scrollToId: undefined, initialised: false },
      };
    },
    setFocalGroup: (
      state: GroupListState,
      action: PayloadAction<FolderOrGroupDto | undefined>
    ) => {
      state.focalGroup = action.payload;
    },
    setMoveFolderOrGroup: (
      state: GroupListState,
      action: PayloadAction<FolderOrGroupDto | undefined>
    ) => {
      state.moveFolderOrGroup = action.payload;
    },
    setShareFolderOrGroup: (
      state: GroupListState,
      action: PayloadAction<FolderOrGroupDto | undefined>
    ) => {
      state.shareFolderOrGroup = action.payload;
    },
    setShareCustomerGroup: (
      state: GroupListState,
      action: PayloadAction<CustomerGroupWithSharingDto | undefined>
    ) => {
      state.shareCustomerGroup = action.payload;
    },
    toggleSelectedFolderOrGroups: (
      state: GroupListState,
      { payload }: PayloadAction<string>
    ) => {
      const listWithItemRemoved = state.selectedFolderOrGroups.filter(
        (selected) => selected.id !== payload
      );
      if (listWithItemRemoved.length < state.selectedFolderOrGroups.length) {
        state.selectedFolderOrGroups = [...listWithItemRemoved];
      } else {
        const folderOrGroup = state.productOrLocationFlatFoldersAndGroups.find(
          (grp) => grp.id === payload
        );
        if (folderOrGroup) {
          state.selectedFolderOrGroups = [
            ...state.selectedFolderOrGroups,
            folderOrGroup,
          ];
        }
      }
    },
    resetSelectedFolderOrGroups: (state: GroupListState) => {
      state.selectedFolderOrGroups = [];
    },
    setDeleteFolderOrGroups: (
      state: GroupListState,
      action: PayloadAction<FolderOrGroupDto[]>
    ) => {
      state.deleteFolderOrGroups = [...action.payload];
    },
    setDeleteFolderOrGroupsToCurrentSelection: (state: GroupListState) => {
      state.deleteFolderOrGroups = [...state.selectedFolderOrGroups];
    },
    resetDeleteFolderOrGroups: (state: GroupListState) => {
      state.deleteFolderOrGroups = [];
    },
    confirmDeleteFolderOrGroupsSuccess: (state: GroupListState) => {
      const deletedIds: Record<string, boolean> = {};
      for (const deletedFolderOrGroup of state.deleteFolderOrGroups) {
        deletedIds[deletedFolderOrGroup.id ?? ""] = true;
      }

      state.selectedFolderOrGroups = [
        ...state.selectedFolderOrGroups.filter(
          (selected) => !deletedIds[selected.id ?? ""]
        ),
      ];
      state.deleteFolderOrGroups = [];
    },
    setFocalCustomerGroup: (
      state: GroupListState,
      { payload }: PayloadAction<CustomerGroupWithSharingDto | undefined>
    ) => {
      state.focalCustomerGroup = payload;
    },
    toggleSelectedCustomerGroups: (
      state: GroupListState,
      { payload }: PayloadAction<string>
    ) => {
      const listWithItemRemoved = state.selectedCustomerGroups.filter(
        (selected) => selected.id !== payload
      );
      if (listWithItemRemoved.length < state.selectedCustomerGroups.length) {
        state.selectedCustomerGroups = [...listWithItemRemoved];
      } else {
        const customerGroup = state.customerGroups.find(
          (grp) => grp.id === payload
        );
        if (customerGroup) {
          state.selectedCustomerGroups = [
            ...state.selectedCustomerGroups,
            customerGroup,
          ];
        }
      }
    },
    resetSelectedCustomerGroups: (state: GroupListState) => {
      state.selectedCustomerGroups = [];
    },
    setDeleteCustomerGroups: (
      state: GroupListState,
      action: PayloadAction<CustomerGroupWithSharingDto[]>
    ) => {
      state.deleteCustomerGroups = [...action.payload];
    },
    setDeleteCustomerGroupsToCurrentSelection: (state: GroupListState) => {
      state.deleteCustomerGroups = [...state.selectedCustomerGroups];
    },
    resetDeleteCustomerGroups: (state: GroupListState) => {
      state.deleteCustomerGroups = [];
    },
    confirmDeleteCustomerGroupsSuccess: (state: GroupListState) => {
      const deletedIds: Record<string, boolean> = {};
      for (const deletedCustomerGroup of state.deleteCustomerGroups) {
        deletedIds[deletedCustomerGroup.id] = true;
      }

      state.selectedCustomerGroups = [
        ...state.selectedCustomerGroups.filter(
          (selected) => !deletedIds[selected.id]
        ),
      ];
      state.deleteCustomerGroups = [];
    },
    setFocalSegment: (
      state: GroupListState,
      { payload }: PayloadAction<SegmentRow | undefined>
    ) => {
      state.focalSegment = payload;
    },
  },
  extraReducers: (builder) => {
    // whenever getGroupsAndFolders is called in the api slice, update the folders here.
    builder.addMatcher(
      groupsApi.endpoints.getGroupsAndFolders.matchFulfilled,
      (state, action) => {
        const flatFoldersAndGroups = mapFolderDtoToFolderOrGroupDto(
          action.payload,
          action.meta.arg.originalArgs.currentUserId,
          action.meta.arg.originalArgs.trueShareFeatureFlag
        );
        // remove the null root
        flatFoldersAndGroups.shift();

        state.folders = action.payload;
        state.productOrLocationFlatFoldersAndGroups = flatFoldersAndGroups;

        // Ensure context menu refreshes if entity has changed.
        // This resolves issues in sharing modal when performing unshare operation
        // which would contain the wrong state after an unshare operation.
        // NOTE:
        // Move is a single operation that closes the modal so that doesn't need updating here.
        state.shareFolderOrGroup =
          state.shareFolderOrGroup === undefined
            ? undefined
            : flatFoldersAndGroups.find(
                (row) => row.id === state.shareFolderOrGroup?.id
              );
      }
    );
    builder.addMatcher(
      customerGroupsApi.endpoints.getCustomerGroups.matchFulfilled,
      (state, action) => {
        state.customerGroups = action.payload;
      }
    );
  },
});

export const {
  setFolderExpandedState,
  setRenameId,
  setEditingState,
  resetRenameState,
  setSearchText,
  setActivatedGroupIds,
  setShowActiveSegments,
  addSelectedSegments,
  removeSelectedSegments,
  clearSelectedSegments,
  setScrollToId,
  setScrollInitialised,
  resetAllScrollStates,
  setFocalGroup,
  setMoveFolderOrGroup,
  setShareFolderOrGroup,
  setShareCustomerGroup,
  toggleSelectedFolderOrGroups,
  resetSelectedFolderOrGroups,
  setDeleteFolderOrGroups,
  setDeleteFolderOrGroupsToCurrentSelection,
  resetDeleteFolderOrGroups,
  confirmDeleteFolderOrGroupsSuccess,
  setFocalCustomerGroup,
  toggleSelectedCustomerGroups,
  resetSelectedCustomerGroups,
  setDeleteCustomerGroups,
  setDeleteCustomerGroupsToCurrentSelection,
  resetDeleteCustomerGroups,
  confirmDeleteCustomerGroupsSuccess,
  setFocalSegment,
} = groupListSlice.actions;

export const groupListSliceReducer = groupListSlice.reducer;

/* Selectors */

export const selectSearchText = createSelector(
  (state: RootState) => state.groupListSlice.searchText,
  (searchText) => searchText
);

export const selectFoldersRaw = createSelector(
  (state: RootState) => state.groupListSlice.folders,
  (folders) => folders
);

export const selectExpandedIds = createSelector(
  (state: RootState) => state.groupListSlice.expandedIds,
  (expandedIds) => expandedIds
);

export const selectRenameState = createSelector(
  (state: RootState) => state.groupListSlice.renameState,
  (renameState) => renameState
);

export const selectActivatedGroupIds = createSelector(
  (state: RootState) => state.groupListSlice.activatedGroupIds,
  (activatedGroupIds) => activatedGroupIds
);

export const selectShowActivatedSegments = createSelector(
  (state: RootState) => state.groupListSlice.showActiveSegments,
  (showActiveSegments) => showActiveSegments
);

export const selectSelectedSegments = createSelector(
  (state: RootState) => state.groupListSlice.selectedSegments,
  (selectedSegments) => selectedSegments
);

export const selectHaveSelectedSegments = createSelector(
  (state: RootState) => state.groupListSlice.selectedSegments,
  (selectedSegments) => selectedSegments.length > 0
);

export const selectScrollToId = createSelector(
  [
    (state: RootState) => state.groupListSlice.scrollStates,
    (_, groupType: GroupType) => groupType,
  ],
  (scrollStates, groupType) => scrollStates[groupType].scrollToId
);

export const selectScrollInitialised = createSelector(
  [
    (state: RootState) => state.groupListSlice.scrollStates,
    (_, groupType: GroupType) => groupType,
  ],
  (scrollStates, groupType) => scrollStates[groupType].initialised
);

export const selectProductOrLocationFlatFoldersAndGroups = createSelector(
  (state: RootState) =>
    state.groupListSlice.productOrLocationFlatFoldersAndGroups,
  (productOrLocationFlatFoldersAndGroups) =>
    productOrLocationFlatFoldersAndGroups
);

export const selectFocalGroup = createSelector(
  (state: RootState) => state.groupListSlice.focalGroup,
  (focalGroup) => focalGroup
);

export const selectMoveFolderOrGroup = createSelector(
  (state: RootState) => state.groupListSlice.moveFolderOrGroup,
  (moveFolderOrGroup) => moveFolderOrGroup
);

export const selectShareFolderOrGroup = createSelector(
  (state: RootState) => state.groupListSlice.shareFolderOrGroup,
  (shareFolderOrGroup) => shareFolderOrGroup
);

export const selectShareCustomerGroup = createSelector(
  (state: RootState) => state.groupListSlice.shareCustomerGroup,
  (shareCustomerGroup) => shareCustomerGroup
);

export const selectSelectedFolderOrGroups = createSelector(
  (state: RootState) => state.groupListSlice.selectedFolderOrGroups,
  (selectedFolderOrGroups) => selectedFolderOrGroups
);

export const selectDeleteFolderOrGroups = createSelector(
  (state: RootState) => state.groupListSlice.deleteFolderOrGroups,
  (deleteFolderOrGroups) => deleteFolderOrGroups
);

export const selectFocalCustomerGroups = createSelector(
  (state: RootState) => state.groupListSlice.focalCustomerGroup,
  (customerGroup) => customerGroup
);

export const selectCustomerGroups = createSelector(
  (state: RootState) => state.groupListSlice.customerGroups,
  (customerGroups) => customerGroups
);

export const selectSelectedCustomerGroups = createSelector(
  (state: RootState) => state.groupListSlice.selectedCustomerGroups,
  (selectedCustomerGroups) => selectedCustomerGroups
);

export const selectHaveSelectedCustomerGroups = createSelector(
  (state: RootState) => state.groupListSlice.selectedCustomerGroups,
  (selectedCustomerGroups) => selectedCustomerGroups.length > 0
);

export const selectDeleteCustomerGroups = createSelector(
  (state: RootState) => state.groupListSlice.deleteCustomerGroups,
  (deleteCustomerGroups) => deleteCustomerGroups
);

export const selectIsSegmentLibrarySegmentsSelected = createSelector(
  [selectSelectedCustomerGroups, selectActivatedGroupIds],
  (selectedCustomerGroups, activatedGroupIds) =>
    selectedCustomerGroups.some((grp) => activatedGroupIds.includes(grp.id))
);

export const selectFocalSegment = createSelector(
  (state: RootState) => state.groupListSlice.focalSegment,
  (focalSegment) => focalSegment
);
