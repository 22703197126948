import { createBrowserStorage } from "@quantium-enterprise/common-ui";

/**
 * Browser local storage service for the Range Performance application
 * Usage:
 * import { BrowserStorage } from (here)
 * then:
 * BrowserStorage.set("key", value) - value can be any serializable object. Typed as unknown
 * BrowserStorage.set<MyDto>("key", value) - can supply a generic type to set the type of the value
 * BrowserStorage.get("key") - returns the value or undefined if not found
 * BrowserStorage.remove("key") - removes the key in the configured namespace from storage
 * BrowserStorage.clear() - removes all keys in the configured namespace from storage
 * BrowserStorage.has("key") - returns true if the key exists in storage in the configured namespace
 *
 * IMPORTANT: If you modify any type definitions for data that may already be stored
 * in users' browsers, you MUST increment the version number to invalidate their
 * local storage cache and force a refresh.
 */
export const BrowserStorage = createBrowserStorage({
  namespace: "range-performance",
  storageType: localStorage,
  // increment this if the storage structure changes, to invalidate the users local storage and force a refresh
  version: "1.0.1",
});
