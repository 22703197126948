import { Spinner } from "@qbit/react";
import {
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  UnknownTrackingPropertyValue,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useCallback } from "react";
import Zod from "zod";
import { ParameterSingleSelect } from "../../fast-report/ParameterSingleSelect";
import { useReportConfigurationQuery } from "../../fast-report/api/fastReportConfigurationApi";
import useFastReportingParameterState, {
  Parameter,
} from "../../useFastReportingParameterState";

export const SegmentationValueSchema = Zod.string();
export type SegmentationValue = Zod.infer<typeof SegmentationValueSchema>;

export const SegmentationDropdown = () => {
  const eventTrackingService = useEventTrackingServiceContext();
  const division = useDivision();

  const reportConfig = useReportConfigurationQuery(
    { division: division.name },
    {
      selectFromResult: (state) => ({
        configuration: state.data?.reports.customerProfiling,
        state,
      }),
      skip: !division.name,
    }
  );

  const [selection, setSelection] = useFastReportingParameterState(
    Parameter.CustomerProfilingSegmentation,
    SegmentationValueSchema,
    (config) => config.reports.customerProfiling?.segmentations
  );

  const selectionChangedHandler = useCallback(
    (value: string) => {
      const parameter = reportConfig.configuration?.segmentations?.options.find(
        (option) => option.value === value
      );
      eventTrackingService.trackEvent(
        TrackingComponent.FastReportingCustomerProfilingReport,
        TrackingEvent.Parameters,
        GenericTrackingProperties.single(
          ParametersTrackingProperty.SegmentationSelected,
          parameter?.displayName ?? UnknownTrackingPropertyValue.Unknown
        )
      );

      setSelection(value);
    },
    [
      eventTrackingService,
      reportConfig.configuration?.segmentations?.options,
      setSelection,
    ]
  );

  return reportConfig.configuration?.segmentations && selection ? (
    <ParameterSingleSelect
      configuration={{
        ...reportConfig.configuration.segmentations,
      }}
      data-cy="SegmentationSelector"
      onSelection={selectionChangedHandler}
      selection={selection}
    />
  ) : (
    <Spinner />
  );
};
