import { type TextPanel } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { PanelType } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { TimePeriodFilterContent } from "./TimePeriodFilterContent";
import { TimePeriodFilterSummary } from "./TimePeriodFilterSummary";

export const getFocusPeriodPanel = () => {
  const panel = {
    content: <TimePeriodFilterContent />,
    id: "FastReportingTimePeriodSelect",
    label: "Time period",
    panelType: PanelType.TEXT,
    summary: <TimePeriodFilterSummary />,
  } as TextPanel;

  return [panel];
};
