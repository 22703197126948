export enum ReportType {
  AggregateRank = "aggregate-rank",
  BasketAffinities = "basket-affinities",
  BasketLimits = "basket-limits",
  BasketQuantities = "basket-quantities",
  CompareMetrics = "compare-metrics",
  CustomerCrossShop = "customer-cross-shop",
  CustomerLoyalty = "customer-loyalty",
  CustomerProfiling = "customer-profiling",
  GainsAndLoss = "gains-and-loss",
  KeyDriverTree = "key-driver-tree",
  KeyDriversOverTime = "key-drivers-over-time",
  KeyMeasureTrends = "key-measure-trends",
  NewProductBenchmarking = "new-product-benchmarking",
  PricingLadders = "pricing-ladders",
  ProductSubstitutability = "product-substitutability",
  RawData = "raw-data",
  Repertoire = "repertoire",
  TimeOfDayDayOfWeek = "time-of-day-day-of-week",
  TrialAndRepeat = "trial-and-repeat",
}
