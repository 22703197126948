import {
  Group,
  Item,
  ItemValign,
  Button,
  ButtonVariant,
  IconSize,
  Tooltip,
  InlineIcon,
  InlineIconGlyph,
  TooltipVariant,
  TooltipPlacement,
  TooltipSpaceInside,
} from "@qbit/react";
import {
  type HierarchyGroupEvaluationType,
  type HierarchyItemType,
  HierarchyShortName,
  HierarchyType,
} from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { type MouseEvent, useState, useMemo } from "react";
import ContextMenuIcon from "../../../assets/common/context-menu-icon.svg";
import { ConditionalTooltipCheckbox } from "../../../checkboxes/ConditionalTooltipCheckbox";
import { HierarchyLevelIcon } from "../../../hierarchy-level-icon/HierarchyLevelIcon";
import { HierarchyGroupIcon } from "../../../icons";
import { ExpandChevron } from "../../../icons/expand-chevron/ExpandChevron";
import { ParentTooltip } from "../../../parent-tooltip/ParentTooltip";
import { TestId } from "../../report-hierarchy-table/constants";
import {
  DEFAULT_COLUMN_MIN_WIDTH,
  FIRST_COLUMN_WIDTH_MULTIPLY_BY_5,
} from "../constants";
import styles from "./Cell.module.css";
import { type ContextMenuWrapper, ContextMenuType } from "./ContextMenu";
import { ContextMenu, ContextMenuOverlay } from "./ContextMenu";

export enum ExpandableNameCellClickTarget {
  NameAndHierarchyIcon = "NameAndHierarchyIcon",
  WholeCell = "WholeCell",
}

type ExpandableNameCellProps = {
  additionalControlWhenSelected?: JSX.Element;
  canExpand: boolean;
  checkboxDisabledTooltip?: string;
  clickTarget?: ExpandableNameCellClickTarget;
  code?: string;
  columnSize?: number;
  contextMenuWrapper?: ContextMenuWrapper;
  cssClassName?: string;
  depth: number;
  handleToggleExpanded: (event: MouseEvent<HTMLElement>) => void;
  handleToggleSelected: (event: unknown) => void;
  hideCellTooltip?: boolean;
  hideTooltip?: boolean;
  isCheckboxDisabled?: boolean;
  isCheckboxHidden?: boolean;
  isCompact?: boolean;
  isDeletedLine?: boolean;
  isExpanded: boolean;
  isExpanding?: boolean;
  isNewLine?: boolean;
  isSelected: boolean;
  minWidth?: number;
  name: string;
  onClick?: () => void;
  productGroupEvaluationType?: HierarchyGroupEvaluationType;
  shortName: string;
  showContextMenuTrigger?: boolean;
  suffix?: string;
  type: HierarchyItemType | HierarchyShortName.ProductGroup;
  value: string;
};

const defaultOnClick = () => {};

// eslint-disable-next-line complexity -- This component has a lot of optional properties
export const ExpandableNameCell = ({
  canExpand,
  checkboxDisabledTooltip,
  code,
  cssClassName,
  depth,
  handleToggleExpanded,
  handleToggleSelected,
  hideTooltip = false,
  hideCellTooltip = false,
  isCheckboxDisabled = false,
  isCheckboxHidden = false,
  isCompact = false,
  type,
  isExpanded,
  isExpanding = false,
  isSelected,
  isNewLine = false,
  isDeletedLine = false,
  onClick = defaultOnClick,
  clickTarget = ExpandableNameCellClickTarget.WholeCell,
  name,
  shortName,
  showContextMenuTrigger = false,
  suffix,
  value,
  columnSize,
  minWidth,
  contextMenuWrapper,
  productGroupEvaluationType,
  additionalControlWhenSelected,
}: ExpandableNameCellProps) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      onClick();
    }
  };

  const contextMenu =
    contextMenuWrapper &&
    contextMenuWrapper.contextMenuType === ContextMenuType.Menu
      ? contextMenuWrapper.contextMenuElements
      : undefined;

  const [isSelectedInternal, setIsSelectedInternal] =
    useState<boolean>(isSelected);

  const toggleSelectedInternal = (event: unknown) => {
    const newIsSelectedInternalValue = !isSelectedInternal;
    setIsSelectedInternal(newIsSelectedInternalValue);
    handleToggleSelected(event);
  };

  const contextMenuTrigger = useMemo(
    () =>
      showContextMenuTrigger &&
      (contextMenuWrapper?.contextMenuType === ContextMenuType.Menu ? (
        <Button
          className={classNames(styles.contextMenuButton, {
            [styles.contextMenuButtonHidden]: !canExpand || isCheckboxDisabled,
          })}
          onClick={contextMenu?.toggleContextMenu}
          variant={ButtonVariant.Stealth}
        >
          <img alt="Context Menu Icon" src={ContextMenuIcon} />
        </Button>
      ) : (
        contextMenuWrapper?.contextMenuType === ContextMenuType.Tooltip && (
          <Tooltip
            className={classNames({
              [styles.contextMenuTooltipHidden]: hideCellTooltip,
            })}
            placement={TooltipPlacement.TopCentre}
            spaceInside={TooltipSpaceInside.Large}
            trigger={
              <span>
                <InlineIcon
                  className={classNames(styles.contextMenuTooltip, {
                    [styles.contextMenuTooltipHidden]: hideCellTooltip,
                  })}
                  glyph={InlineIconGlyph.AlertsAndNotificationsInform}
                  size={IconSize.Small}
                  text=""
                />
              </span>
            }
            variant={TooltipVariant.ArrowDark}
          >
            <div>This report is run at a Product level</div>
          </Tooltip>
        )
      )),
    [
      canExpand,
      contextMenu,
      contextMenuWrapper,
      hideCellTooltip,
      isCheckboxDisabled,
      showContextMenuTrigger,
    ]
  );

  return (
    <Group>
      <Item valign={ItemValign.Middle}>
        <div
          className={classNames(styles.flex, cssClassName)}
          onClick={
            clickTarget === ExpandableNameCellClickTarget.WholeCell
              ? onClick
              : undefined
          }
          onKeyDown={handleKeyPress}
          role="presentation"
          style={{
            cursor: "pointer",
            minWidth: minWidth ? minWidth : DEFAULT_COLUMN_MIN_WIDTH,
            width: columnSize
              ? columnSize * FIRST_COLUMN_WIDTH_MULTIPLY_BY_5
              : "100%",
          }}
        >
          {contextMenu?.isOpen && (
            <ContextMenuOverlay
              closeContextMenu={contextMenu.closeContextMenu}
            />
          )}
          <div
            className={styles.flex}
            style={{
              maxWidth: columnSize
                ? columnSize * FIRST_COLUMN_WIDTH_MULTIPLY_BY_5
                : "100%",
              minWidth: minWidth ? minWidth : DEFAULT_COLUMN_MIN_WIDTH,
            }}
          >
            {isSelected && (
              <div
                className={styles.selectedHighlight}
                data-testid={TestId.SelectedHighlight}
              />
            )}
            <div
              className={classNames(styles.flex, {
                [styles.marginLeft]: !isSelected && isCheckboxHidden,
                [styles.marginLeftSelected]: isSelected && isCheckboxHidden,
                [styles.xsmallMarginLeft]: !isSelected && !isCheckboxHidden,
                [styles.xsmallMarginLeftSelected]:
                  isSelected && !isCheckboxHidden,
              })}
            >
              <div
                className={classNames(styles.nameCellActiveArea, {
                  [styles.nameCellActiveAreaOpen]: contextMenu?.isOpen,
                })}
              >
                {isCheckboxHidden || (
                  <ConditionalTooltipCheckbox
                    className="name-cell-checkbox"
                    data-testid={TestId.Checkbox}
                    hideTooltip={!isCheckboxDisabled || hideTooltip}
                    isChecked={isSelected}
                    isDisabled={isCheckboxDisabled}
                    label={`Toggle row ${name}`}
                    onChange={toggleSelectedInternal}
                    tooltipText={
                      checkboxDisabledTooltip ??
                      "The hierarchy level is not available for selection in this report."
                    }
                  />
                )}
                {contextMenuTrigger}

                {contextMenu?.isOpen && (
                  <ContextMenu
                    hierarchyItemType={contextMenu.hierarchyItemType}
                    resetTimeout={contextMenu.resetTimeout}
                    selectOptions={contextMenu.selectOptions}
                    stopTimeout={contextMenu.stopTimeout}
                  />
                )}
              </div>

              <div
                className={classNames(styles.flex)}
                data-testid={TestId.IconGroup}
                style={{
                  paddingLeft: `${depth * 1.5}rem`,
                }}
              >
                {canExpand ? (
                  <ExpandChevron
                    isCompact={isCompact}
                    isExpanded={isExpanded}
                    isLoading={isExpanding}
                    onClick={handleToggleExpanded}
                  />
                ) : (
                  <span
                    className={classNames({
                      [styles.marginLeftNoExpand]: isCheckboxHidden,
                      [styles.xsmallMarginLeftNoExpand]: !isCheckboxHidden,
                    })}
                  />
                )}
                <div
                  className={classNames(styles.flex, styles.paddingLeft)}
                  onClick={
                    clickTarget ===
                    ExpandableNameCellClickTarget.NameAndHierarchyIcon
                      ? onClick
                      : undefined
                  }
                  role="presentation"
                >
                  {type === HierarchyShortName.ProductGroup &&
                  productGroupEvaluationType ? (
                    <HierarchyGroupIcon
                      evaluationType={productGroupEvaluationType}
                      hierarchyType={HierarchyType.Product}
                    />
                  ) : code ? (
                    <ParentTooltip
                      code={code}
                      shortName={shortName}
                      type={HierarchyType.Product}
                    >
                      <HierarchyLevelIcon shortName={shortName} type={type} />
                    </ParentTooltip>
                  ) : (
                    <HierarchyLevelIcon shortName={shortName} type={type} />
                  )}
                </div>
              </div>
            </div>
            <span
              className={styles.rowValue}
              data-testid={TestId.ItemName}
              onClick={
                clickTarget ===
                ExpandableNameCellClickTarget.NameAndHierarchyIcon
                  ? onClick
                  : undefined
              }
              role="presentation"
            >
              {value}
            </span>
            {suffix && (
              <span
                className={styles.rowSuffix}
                data-testid={TestId.ItemSuffix}
              >
                {suffix}
              </span>
            )}
          </div>
          {(isNewLine || isDeletedLine) && (
            <div
              className={classNames(
                styles.flex,
                styles.paddingLeft,
                styles.paddingRight
              )}
            >
              <HierarchyLevelIcon
                shortName={isNewLine ? "NL" : "DL"}
                type={
                  isNewLine
                    ? HierarchyShortName.NewLine
                    : HierarchyShortName.DeletedLine
                }
              />
            </div>
          )}
        </div>
      </Item>

      {isSelectedInternal && additionalControlWhenSelected}
    </Group>
  );
};
